<template>
  <div class="card-item">
    <div class="card-number-container">
      <div class="card-number" @click="selectPaymentMethod">
        <img
          v-if="cardData == selectedPaymentMethod"
          class="checkbox"
          src="@/assets/img/checkbox-checked.svg"
        />
        <img
          v-else
          class="checkbox"
          src="@/assets/img/checkbox-unchecked.svg"
        />
        <img :src="require(`@/assets/img/${cardData.card.brand}-icon.svg`)" />
        **** **** **** {{ cardData.card.last4 }}
      </div>
      <button class="delete-card" @click="openRemove">
        <i class="fas fa-times" v-if="!loading"></i>
        <i class="fas fa-circle-notch fa-spin" v-else></i>
      </button>
    </div>
    <div class="remove-container" v-if="showRemove">
      <div>
        {{ $t("payermax.remove_credit_card") }}
      </div>
      <div class="columns is-mobile">
        <div class="column is-half">
          <button class="button custom-button gray-button" @click="closeRemove">
            {{ $t("labels.cancel") }}
          </button>
        </div>
        <div class="column is-half">
          <button class="button custom-button red-button" @click="detach">
            {{ $t("labels.remove") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/components/Payment/Payermax/PayermaxCheckout.scss";
</style>
<script>
"use strict";

import { Logger } from "@/logging";
import { mapActions } from "vuex";
import { generatePaymentUUID } from "@/helper/index.js";
const logger = new Logger("PayermaxCardItem");
export default {
  name: "PayermaxCardItem",
  props: ["cardData", "selectedPaymentMethod"],
  data() {
    return {
      showRemove: false,
      loading: false,
    };
  },
  beforeMount() {},
  mounted() {},
  watch: {
    showRemove() {
      this.$emit("onRemove", this.showRemove);
    },
  },
  methods: {
    ...mapActions("payment", ["stripeDetachPaymentMethod"]),
    openRemove() {
      if (this.loading == false) {
        this.showRemove = true;
      }
    },
    closeRemove() {
      this.showRemove = false;
    },
    detach() {
      this.showRemove = false;
      this.loading = true;
      this.stripeDetachPaymentMethod(this.cardData.id)
        .then((detachResponse) => {
          this.loading = false;
          if (detachResponse.error) {
            // Error response from server
            logger.debugError(
              "stripeDetachPaymentMethod error",
              detachResponse.error
            );
            this.$toast.error(
              detachResponse.error.message || this.$t("errors.general_error")
            );
          } else {
            // Success response from server
            logger.debug("stripeDetachPaymentMethod success", detachResponse);
            this.$emit("detached", this.cardData.id);
          }
        })
        .catch((error) => {
          // Network error or server fail
          logger.debugError("stripeDetachPaymentMethod failed", error);
          this.loading = false;
          this.$toast.error(this.$t("errors.general_error"));
        });
    },
    selectPaymentMethod() {
      if (this.loading == false) {
        // Generate new UUID for next attempt when method changes.
        generatePaymentUUID();

        this.$store.dispatch("payment/setSelectedPaymentMethod", this.cardData);
        this.$emit("removeError", null);
      }
    },
  },
};
</script>
