function hybridapi(methodName, ...methodArguments) {
  if (window["hybridapi"] && window["hybridapi"][methodName]) {
    try {
      window["hybridapi"][methodName](
        ...methodArguments.map((arg) => {
          if (typeof arg == "object") {
            return JSON.stringify(arg);
          } else if (typeof arg == "number") {
            return "" + arg;
          }
          return arg;
        })
      );
    } catch (error) {
      console.error("hybridapi android error method name:", methodName);
      console.error(
        "hybridapi android error method arguments:",
        methodArguments
      );
      console.error("hybridapi android error", error);
    }
  } else if (
    window["webkit"] &&
    window["webkit"]["messageHandlers"] &&
    window["webkit"]["messageHandlers"][methodName]
  ) {
    try {
      window["webkit"]["messageHandlers"][methodName]["postMessage"](
        methodArguments
      );
    } catch (error) {
      console.error("hybridapi iOS error", error);
    }
  } else {
    // NOTE(baris): This log is used for testing. Hybrid methods should not be called in production if running as website.
    // console.error("$hybridapi:NONE " + methodName + " " + methodArguments);
  }
}
module.exports = hybridapi;
