<template>
  <div class="pt-wrap" :class="{ 'is-selected': active }">
    <div class="pt-container">
      <div class="pt-detail">
        <template v-if="isPaymentTokenForCard">
          <div class="pt-masked-pan">
            {{
              paymentToken.masked_card_number != null
                ? paymentToken.masked_card_number
                : paymentToken.data.masked_card_number
            }}
          </div>
          <div
            v-if="
              paymentToken.expiry ||
              (paymentToken.data && paymentToken.data.expiry)
            "
            class="pt-expiry"
          >
            {{
              paymentToken.expiry != null
                ? paymentToken.expiry.expiry_month
                : paymentToken.data.expiry.month
            }}
            /
            {{
              paymentToken.expiry != null
                ? paymentToken.expiry.expiry_year
                : paymentToken.data.expiry.year
            }}
          </div>
        </template>
        <template v-else-if="isPaymentTokenForEmail">
          <div class="pt-email-address" :style="{ width }">
            {{ paymentToken.data.email }}
          </div>
        </template>
      </div>
      <div class="btn-delete" @click="$emit('delete')">
        <img
          :src="require('@/assets/img/payment/trash-icon.svg')"
          alt="Delete"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { SavedPaymentTokenType } from "@/helper/enums";

/** @import  {Payment} from '@/components/Payment/types/payment' */

/**
 * @typedef Props
 * @property {Payment.SavedPaymentToken | Payment.OlderSavedPaymentToken} paymentToken
 * @property {boolean} active
 */

/** @typedef {import('vue') & Props} Component */

export default {
  name: "PaymentToken",
  props: {
    paymentToken: {
      type: Object,
      require: true,
    },
    active: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      width: "0px",
    };
  },
  computed: {
    /**
     * @this Component
     */
    isPaymentTokenForCard() {
      return (
        !this.paymentToken.is_newer_version ||
        this.paymentToken.type === SavedPaymentTokenType.CARD
      );
    },
    /**
     * @this Component
     */
    isPaymentTokenForEmail() {
      return (
        this.paymentToken.is_newer_version &&
        this.paymentToken.type === SavedPaymentTokenType.EMAIL
      );
    },
  },
  methods: {
    onDocumentResize() {
      this.width = `${window.innerWidth * 0.65}px`;
    },
  },
  mounted() {
    this.onDocumentResize();
    window.addEventListener("resize", this.onDocumentResize, true);
  },
};
</script>

<style scoped lang="scss">
@import "./PaymentToken.scss";
</style>
