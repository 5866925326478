var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payermax-checkout-container pt-0",class:{ 'hybrid-container': _vm.isHybridPage }},[(_vm.isPromotionPayment)?_c('div',{staticClass:"promotion-top"},[_c('div',{staticClass:"user-coin-circle"},[_c('div',{staticClass:"user-coin"},[_c('img',{staticClass:"coin-svg",attrs:{"src":require("@/assets/img/coin.svg"),"alt":_vm.$t('general.coin_icon')}}),_c('span',{staticClass:"coin-amount"},[_vm._v(" "+_vm._s(_vm.formatCoin(_vm.userCoinInfo.coin_info.coins))+" ")])])]),_c('div',{staticClass:"purchase-header"},[_vm._v(" "+_vm._s(_vm.$t("menu.purchase"))+" ")]),_c('div',{staticClass:"close-right"},[_c('div',{staticClass:"close"},[_c('i',{staticClass:"fas fa-times",on:{"click":_vm.closeHybridPage}})])])]):_vm._e(),_c('div',{staticClass:"hybrid-section"},[_c('div',{staticClass:"section-header"},[_c('div',[_vm._v(_vm._s(_vm.$t("payermax.order_details")))]),(!_vm.isPromotionPayment)?_c('div',{staticClass:"close"},[_c('i',{staticClass:"fas fa-times",on:{"click":_vm.closeCreditCard}})]):_vm._e()]),(_vm.isPromotionPayment)?_c('div',{staticClass:"order-amount-container"},[_c('div',{staticClass:"order-amount-label"},[_vm._v(" "+_vm._s(_vm.$t("payermax.amount_of_coin"))+" ")]),_c('div',{staticClass:"price"},[_c('img',{staticClass:"diamond-icon",attrs:{"src":require("@/assets/img/coin.svg"),"alt":_vm.$t('general.coin_icon')}}),_vm._v(" "+_vm._s(_vm.selectedProduct.amount)+" ")])]):_vm._e(),_c('div',{staticClass:"order-price-container"},[_c('div',{staticClass:"order-price-label"},[_vm._v(_vm._s(_vm.$t("payermax.order_total")))]),(
          _vm.selectedProduct &&
          _vm.selectedProduct.stripe_price &&
          _vm.selectedProduct.stripe_price.currency
        )?_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.currencies[_vm.selectedProduct.stripe_price.currency.toUpperCase()])+_vm._s(_vm.selectedProduct.stripe_price.price)+" ")]):_vm._e()])]),_c('div',{staticClass:"hybrid-section mt-4"},[_c('div',{staticClass:"section-cards"},[_vm._v(_vm._s(_vm.$t("payermax.payment_details")))]),_c('div',{staticClass:"card-item-container"},_vm._l((_vm.paymentMethods),function(paymentMethod){return _c('payermax-card-item',{key:paymentMethod.id,attrs:{"cardData":paymentMethod,"selectedPaymentMethod":_vm.selectedPaymentMethod},on:{"onRemove":function (val) { return (_vm.onRemove = val); },"detached":_vm.deletePaymentMethod,"removeError":_vm.removeError}})}),1),_c('div',{staticClass:"pay-with-another-card",on:{"click":_vm.goCardPayment}},[_c('img',{attrs:{"src":require("@/assets/img/plus-icon.svg")}}),_vm._v(" "+_vm._s(_vm.$t("checkout.buy_with_another_credit_card"))+" ")]),_c('div',{staticClass:"pay-button-container"},[(
          this.paymentMethods &&
          this.paymentMethods.length > 0 &&
          _vm.selectedProduct &&
          _vm.selectedProduct.stripe_price &&
          _vm.selectedProduct.stripe_price.currency
        )?_c('button',{staticClass:"green-button",attrs:{"disabled":_vm.loading || _vm.onRemove},on:{"click":_vm.payWithMethod}},[_vm._v(" "+_vm._s(_vm.$t("payermax.confirm_payment"))+" "+_vm._s(_vm.currencies[_vm.selectedProduct.stripe_price.currency.toUpperCase()])+_vm._s(_vm.selectedProduct.stripe_price.price)+" "),(_vm.loading)?_c('img',{staticClass:"loading",attrs:{"src":require("@/assets/img/loading.svg"),"alt":"loading"}}):_vm._e()]):_vm._e(),(_vm.cardError)?_c('div',{staticClass:"card-error"},[_vm._v(_vm._s(_vm.cardError))]):_vm._e()])]),_c('div',{staticClass:"security-footer"},[_c('img',{staticClass:"lock-svg",attrs:{"src":require("@/assets/img/lock.svg"),"alt":_vm.$t('payermax.stripe_secure_and_protected_payment')}}),_c('span',{staticClass:"security-text"},[_vm._v(" "+_vm._s(_vm.$t("payermax.stripe_secure_and_protected_payment"))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }