<template>
  <div
    class="stream-image"
    :style="'background-image:url(' + profileImagePath + ')'"
  >
    <router-link
      :to="
        '/stream/' +
        getCryptedString(
          streamItem.stream_details.livestream_id,
          streamItem.user.user_id
        )
      "
    >
      <div
        class="premium-bg"
        v-if="streamItem.stream_details && streamItem.stream_details.type === 2"
      >
        <img src="@/assets/img/premium-key-vertical.svg" alt="Premium Stream" />
      </div>
      <div class="stream-info" @click="clickHandler()">
        <div class="top-count-container">
          <div class="watch-count">
            <img src="@/assets/img/fire.svg" alt="watch count icon" />
            {{ streamItem.stream_details.viewer_count }}
          </div>
          <div class="diamond-count">
            <img src="@/assets/img/diamond-icon.svg" alt="diamond count icon" />
            {{ showThousund(streamItem.stream_details.diamond_count) }}
          </div>
        </div>
        <div class="stream-name" v-if="streamItem.stream_details">
          {{ streamItem.stream_details.headline }}
        </div>
      </div>
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
@import "StreamCard.scss";
</style>

<script>
import { showThousund } from "@/helper/index.js";
import emptyProfile from "@/assets/img/empty_profile.png";

import { encrypt } from "@/helper/index.js";

export default {
  props: ["streamItem"],
  computed: {
    profileImagePath() {
      return this.streamItem.user.profile_image
        ? this.streamItem.user.profile_image.thumbnail_url
        : emptyProfile;
    },
  },
  methods: {
    showThousund: showThousund,
    getCryptedString(streamId, userId) {
      let cryptObj = { streamId: streamId, userId: userId };
      return encrypt(cryptObj);
    },
    clickHandler() {
      this.$emit("clicked");
    },
  },
};
</script>
