export const StreamType = Object.freeze({
  PUBLIC: 1,
  PRIVATE: 2,
});

export const DiscoverFilterTypeEnum = Object.freeze({
  POPULAR: 1,
  COUNTRY: 2,
});

export const LeaderBoardEnums = Object.freeze({
  DEFAULT: 0,
  DAILY: 1,
  WEEKLY: 2,
});

export const ConversationMessageDataEnums = Object.freeze({
  TEXT: 1,
  PHOTO: 2,
  VIDEO: 3,
  AUDIO: 4,
  CALL_INFO: 5,
  GIFT: 7,
  SYSTEM: 8,
});

export const NotificationsTypeEnums = Object.freeze({
  FOLLOWER: 1,
  VIP_STATUS_CHANGE: 2,
  PROFILE_VIEWER: 1000,
});

export const FollowPageEnums = Object.freeze({
  LIVESTREAM_NAME: 1,
  LIVESTREAM_FINISH: 2,
  OTHER_PROFILE: 3,
  OTHER_FOLLOWERS: 4,
  OTHER_FOLLOWINGS: 5,
  PRIVATE_CALL_NAME: 6,
  USER_SEARCH: 7,
  LIVESTREAM_OVERLAY_STREAMER: 8,
  LIVESTREAM_OVERLAY_OTHER: 9,
  NOTIFICATIONS: 10,
  FOLLOWERS: 11,
  FOLLOWINGS: 12,
});

export const GenderEnums = Object.freeze({
  UNKNOWN: 0,
  MALE: 1,
  FEMALE: 2,
});

export const PayermexErrorCodes = Object.freeze({
  CREDIT_CARD_INFO_ERROR: 1007,
  IDEMPOTENCY_KEY_ERROR: 1009,
});

/**
 * @enum {number}
 */
export const PaymentProvider = Object.freeze({
  PAYERMAX: 0,
  STRIPE: 1,
  GOOGLE: 2,
  APPLE: 3,
  RAPYD: 4,
  DEALER: 5,
  RAZER: 6,
  TRUSTPAY: 7,
  PAYPAL: 8,
});

/**
 * @enum {number}
 */
export const SavedPaymentTokenType = Object.freeze({
  CARD: 1,
  EMAIL: 2,
});

export const HybridStates = Object.freeze({
  payermax: "payermax",
  stripe: "stripe",
  google_play: "google_play",
  apple: "apple",
  rapyd: "rapyd",
  dealer: "dealer",
  trustpay: "trustpay",
});

export const PaymentStatus = Object.freeze({
  SUCCESS: "success",
  PENDING: "pending",
  INCOMPLETE: "incomplete",
  FAILED: "failed",
});

/**
 * @enum {number}
 */
export const ProductTag = Object.freeze({
  NO_TAG: 0,
  BEST_TAG: 1, // aka Hot
  NEW_USER_TAG: 2,
  DISCOUNT_TAG: 3,
});

/**
 * It handles operations final status in the view for user
 * @enum {number}
 */
export const PaymentOperationStatus = Object.freeze({
  PENDING: 0,
  SUCCESS: 1,
  FAILED: 2,
});

/**
 * @type {Object<string, PaymentOperationStatus>}
 */
export const PayermaxOperationStatus = Object.freeze({
  PENDING: PaymentOperationStatus.PENDING,
  SUCCESS: PaymentOperationStatus.SUCCESS,
  FAILED: PaymentOperationStatus.FAILED,
  CLOSED: PaymentOperationStatus.FAILED,
});

/**
 * @type {Object<string, string>}
 */
export const PaymentMethodCodes = Object.freeze({
  APPLEPAY: "APPLEPAY",
  GOOGLEPAY: "GOOGLEPAY",
});
