<template>
  <div
    class="guest-container"
    :class="{ show: showGuest }"
    v-if="activeGuest && isDesktop()"
  >
    <!-- EF: guest kapatma butonunu sadece yayıncı ve guest görebilir, şimdilik pasif edildi -->
    <a href="javascript:" @click="closeGuest" class="close-guest" v-if="false"
      ><i class="fas fa-times"></i
    ></a>
    <div class="guest-inner"></div>
    <div
      class="guest-stopped"
      :style="{
        backgroundImage: 'url(' + activeGuest.profile_image.thumbnail_url + ')',
      }"
      v-if="guestStopped && activeGuest"
    >
      <div class="overlay">
        <h2>{{ $t("livestream.guest_waiting") }}</h2>
      </div>
    </div>
    <v-btn
      class="guest-name"
      v-if="activeGuest"
      @click="openHalfProfile(activeGuest.user_id)"
      text
    >
      {{ activeGuest.name }}
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
@import "GuestCard.scss";
</style>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "GuestCard",
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      mobileGuestContainer: {
        x: 0,
        y: 110,
      },
      adjustGuest: false,
    };
  },
  computed: {
    ...mapState({
      activeGuest: (state) => state.live.activeGuest,
      guestStopped: (state) => state.live.guestStopped,
      showGuest: (state) => state.live.showGuest,
    }),
  },
  methods: {
    ...mapActions("live", ["closeGuest"]),
    ...mapActions("client", ["openHalfProfile"]),
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
};
</script>
