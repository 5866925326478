// @ts-check

/** @typedef {import('axios').AxiosInstance} AxiosInstance */
/**
 * @template T
 * @typedef {import('axios').AxiosResponse<T>} AxiosResponse
 */

const enums = require("../helper/enums.js");

const ENDPOINT_PATH = Object.freeze({
  ECHO: "echo",
  SETTINGS: "settings",
  DEVICE_REGISTER: "device/register",
  DEVICE_RECORD_RTC: "device/record_rtc",
  LOGIN_PHONE: "signup/phone",
  LOGIN_FACEBOOK: "signup/facebook",
  LOGIN_GOOGLE: "signup/google",
  LOGIN_APPLE: "signup/apple",
  EMAIL_SIGNIN: "signup/email_signin",
  EMAIL_SIGNUP: "signup/email",
  TWITTER_SIGNUP: "signup/twitter",
  TWITTER_REQUEST_TOKEN: "signup/twitter/request_token",
  TWITTER_ACCESS_TOKEN: "signup/twitter/access_token",
  ENTRY: "entry",
  OWN_PROFILE: "own_profile",
  DISCOVER: "discover",
  FOLLOWINGS_STREAMS: "followings/streams",
  LIVESTREAM_RETRIEVE: "livestream/retrieve",
  LIVESTREAM_ENTER: "livestream/enter",
  LIVESTREAM_SEND_MESSAGE: "livestream/chat/send_text_message",
  LIVESTREAM_EXIT: "livestream/exit",
  LIVESTREAM_CONTINUE_LIST: "livestream/continue_list",
  LIVESTREAM_SUGGESTION: "livestream/suggestion",
  LIVESTREAM_ACTIVE_VIEWERS: "livestream/active_viewers",
  TOP_GIFTERS: "livestream/top_gifters",
  SENSITIVE_CONTENT: "set_sensitive_content_allowance",
  UPLOAD_IMAGE: "users/image/upload",
  UPDATE_USER: "users/update",
  USER_PROFILE: "users/profile",
  SEND_RENEWAL_CODE: "signup/send_password_renewal_code",
  VERIFY_PASSWORD_RENEWAL: "signup/verify_password_renewal",
  RENEW_PASSWORD: "signup/renew_password",
  CONFIRM_PAYMENT: "inapp_purchase/stripe/confirm",
  RECONFIRM_PAYMENT: "inapp_purchase/stripe/reconfirm",
  PAYMENT: "inapp_purchase/stripe/payment",
  PAYMENT_METHODS: "inapp_purchase/stripe/payment_settings",
  DETACH_PAYMENT_METHOD: "inapp_purchase/stripe/detach_payment_method",
  CREATE_PAYMENT_METHOD: "inapp_purchase/stripe/payment_method",
  LIVESTREAM_SEND_GIFT: "livestream/chat/send_gift",
  ACCEPT_PRIVATE: "livestream/accept_private",
  FOLLOW_USER: "user/follow",
  UNFOLLOW_USER: "user/unfollow",
  USER_FOLLOWERS: "user/followers",
  USER_FOLLOWINGS: "user/followings",
  SEND_TEXT_MESSAGE: "conversation/send_text_message",
  MESSAGE_HISTORY: "conversation/messages",
  MESSAGE_INFO: "conversation/info",
  MESSAGE_STATUSES: "conversation/statuses",
  LEADERBOARD: "leaderboard",
  GIFTERS_LEADERBOARD: "gifters_leaderboard",
  SET_GIFTERS_LEADERBOARD_ANONYMOUS: "user/set_gifters_leaderboard_anonymous",
  DISCOVER_COUNTRIES: "discover/countries",
  SET_DISCOVER_COUNTRIES: "discover/user_countries",
  COUNTRY_DISCOVER: "discover/country_items",
  FIREBASE_ANALYTICS_RECORD: "firebase/analytics_record",
  RECORD_ADJUST_DATA: "adjust/record",
  SEND_GIFT_MESSAGE: "conversation/send_gift_message",
  INAPP_PURCHASE_SETTINGS: "inapp_purchase/purchase_settings",
  PROMOTION_INAPP_PURCHASE_SETTINGS:
    "inapp_purchase/promotion_banner/purchase_settings",
  PAYMENT_COUNTRIES: "inapp_purchase/payment_countries",
  GET_BANNER: "/banner/get_banner",
  SET_PAYMENT_COUNTRY: "inapp_purchase/payment_country",
  SET_PROMOTION_PAYMENT_COUNTRY:
    "inapp_purchase/promotion_banner/payment_country",
  PAYERMAX_PAYMENT: "inapp_purchase/payermax/payment",
  GET_NOTIFICATIONS: "user/notifications",
  USER_ENTRY: "entry",
  CONNECT_EMAIL: "user/connect/email",
  CONNECT_GOOGLE: "user/connect/google",
  RAPYD_PAYMENT: "inapp_purchase/rapyd/payment",
  RAZER_PAYMENT: "inapp_purchase/razer/payment",
  COIN_RESELLER_ACCOUNTS: "coin_reseller_accounts/list_by_region",
  RETRIEVE_PAYMENT_STATUS: "inapp_purchase/retrieve_payment_status",
  ACTIVE_PROMOTION_BANNERS: "banner/active_promotion_banners",
  TRUSTPAY_PAYMENT: "inapp_purchase/trustpay/payment",
  PAYPAL_PAYMENT: "inapp_purchase/paypal/payment",
  PAYPAL_CAPTURE: "inapp_purchase/paypal/capture",
  SAVED_CARDS: "inapp_purchase/get_saved_cards",
  DELETE_SAVED_CARD: "inapp_purchase/delete_saved_card",
});

/**
 * @typedef ApiMeta
 * @property {string?} next
 */

/**
 * @typedef SuccessResponse
 * @property {boolean} success
 */

/**
 * @typedef ApiOwnUserData
 * @property {string} user_id
 * @property {string} shared_id
 * @property {string} name
 * @property {string?} bio
 * @property {number} gender -- int, enum
 * @property {number?} birthday -- epoch ms
 * @property {string?} country
 * @property {ApiUserImageData[]} profile_images
 * @property {string?} livestream_id
 * @property {number} vip -- int, enum
 * @property {number} follower_count -- int
 * @property {number} following_count -- int
 * @property {number} coins -- int
 * @property {number} diamonds -- int
 * @property {number?} private_call_gift_id -- int, enum
 * @property {number} total_punishment_count -- int
 * @property {number} total_punishment_diamonds -- int
 */
/**
 * @typedef ApiUserImageData
 * @property {string} image_id
 * @property {string} url
 * @property {string} thumbnail_url
 */

/**
 * @typedef ApiUserData
 * @property {string} user_id
 * @property {string} shared_id
 * @property {string} name
 * @property {string?} bio
 * @property {ApiUserImageData?} profile_image
 * @property {string?} livestream_id
 * @property {boolean} is_followed
 * @property {number} vip -- int, enum
 */

/**
 * @typedef ApiBriefLivestreamData
 * @property {string} livestream_id
 * @property {number} type -- int, enum
 * @property {string} headline
 * @property {number} viewer_count -- int
 */

/**
 * @typedef ApiUserWithLivestreamData
 * @property {ApiUserData} user
 * @property {ApiBriefLivestreamData} stream_details
 */

/**
 * @typedef ApiLivestreamData
 * @property {number} type -- int, enum
 * @property {string} headline
 * @property {number} viewer_count -- int
 * @property {number} diamond_count -- int
 * @property {number?} restrict_type -- int
 * @property {string?} initial_stream_message
 * @property {number?} viewer_count -- int
 * @property {number?} gained_follower_count -- int
 * @property {number?} overall_viewer_count -- int
 * @property {number?} diamond_count -- int
 * @property {number?} private_livestream_gift -- int, enum
 * @property {number} finished_at -- epoch ms
 * @property {boolean} is_accepted -- private accepted
 * @property {string?} channel_id
 * @property {number?} agora_id -- int
 * @property {string?} agora_channel_token
 * @property {string?} agora_rtm_token
 */

/**
 * @typedef PaymentMethod
 * @property {string?} id
 * @property {StripeCard} card
 * @property {string?} customer
 * @property {boolean} livemode
 * @property {string?} type
 */

/**
 * @typedef StripeCard
 * @property {string?} brand
 * @property {string?} fingerprint
 */

/** */

//

/**
 * @template E
 * @param {AxiosInstance} axios
 * @param {E} data
 * @returns {Promise<AxiosResponse<{"success": true}&E>>}
 */
module.exports.echo = function echo(axios, data) {
  return axios.post(ENDPOINT_PATH.ECHO, data);
};

/**
 * @typedef {{}} ApiDeviceRegisterRequest
 */

/**
 * @typedef ApiDeviceRegisterResponse
 * @property {string} guid
 */

/**
 * @param {AxiosInstance} axios
 * @param {ApiDeviceRegisterRequest} data
 * @returns {Promise<AxiosResponse<ApiDeviceRegisterResponse>>}
 */
module.exports.device_register = function device_register(axios, data) {
  return axios.post(ENDPOINT_PATH.DEVICE_REGISTER, data);
};

/**
 * @typedef {{}} ApiSettingsRequest
 */

/**
 * @typedef ApiSettingsResponse
 * @property {string} uid
 * @property {string} agora_app_id
 * @property {string} agora_public_key
 * @property {ApiSettingsResponseWebsocketData} websocket
 * @property {ApiSettingsResponseImplementationSettingsData} implementation_settings
 * @property {number} discover_period -- int
 */
/**
 * @typedef ApiSettingsResponseWebsocketData
 * @property {number} heartbeat -- int
 * @property {string} url
 */
/**
 * @typedef ApiSettingsResponseImplementationSettingsData
 * @property {{"disabled_for_viewer": boolean}} private_stream
 */

/**
 * @param {AxiosInstance} axios
 * @param {ApiSettingsRequest} [data={}]
 * @returns {Promise<AxiosResponse<ApiSettingsResponse>>}
 */
module.exports.settings = function settings(axios, data = {}) {
  return axios.post(ENDPOINT_PATH.SETTINGS, data);
};

/**
 * @typedef {{}} ApiEntryRequest
 */

/**
 * @typedef {{}} ApiEntryResponse
 */

/**
 * @param {AxiosInstance} axios
 * @param {ApiEntryRequest} data
 * @returns {Promise<AxiosResponse<ApiEntryResponse>>}
 */
module.exports.entry = function entry(axios, data) {
  return axios.post(ENDPOINT_PATH.ENTRY, data);
};

/**
 * @typedef ApiLoginRequest
 * @property {string} token
 */
/**
 * NOTE(baris): Hardcoded values are current expected values for web. May change later.
 * @typedef ApiLoginResponse
 * @property {string} token
 * @property {false} logged_in
 * @property {true} existed
 * @property {boolean} extra_information_required
 * @property {null} initial_free_coin_offer
 */

/**
 * @typedef RenewalCodeResponse
 * @property {any} password_renewal_id
 */

/**
 * @param {AxiosInstance} axios
 * @param {ApiLoginRequest} data
 * @returns {Promise<AxiosResponse<ApiLoginResponse>>}
 */
module.exports.login_phone = function login_phone(axios, data) {
  return axios.post(ENDPOINT_PATH.LOGIN_PHONE, data);
};

/**
 * @param {AxiosInstance} axios
 * @param {ApiLoginRequest} data
 * @returns {Promise<AxiosResponse<ApiLoginResponse>>}
 */
module.exports.login_facebook = function login_facebook(axios, data) {
  return axios.post(ENDPOINT_PATH.LOGIN_FACEBOOK, data);
};

/**
 * @param {AxiosInstance} axios
 * @param {ApiLoginRequest} data
 * @returns {Promise<AxiosResponse<ApiLoginResponse>>}
 */
module.exports.login_google = function login_google(axios, data) {
  return axios.post(ENDPOINT_PATH.LOGIN_GOOGLE, data);
};

/**
 * @param {AxiosInstance} axios
 * @param {ApiLoginRequest} data
 * @returns {Promise<AxiosResponse<ApiLoginResponse>>}
 */
module.exports.login_apple = function login_apple(axios, data) {
  return axios.post(ENDPOINT_PATH.LOGIN_APPLE, data);
};

/**
 * @param {AxiosInstance} axios
 * @param {ApiLoginRequest} data
 * @returns {Promise<AxiosResponse<ApiLoginResponse>>}
 */
module.exports.login_email = function login_email(axios, data) {
  return axios.post(ENDPOINT_PATH.EMAIL_SIGNIN, data);
};

/**
 * @param {AxiosInstance} axios
 * @param {ApiLoginRequest} data
 * @returns {Promise<AxiosResponse<ApiLoginResponse>>}
 */
module.exports.register_email = function register_email(axios, data) {
  return axios.post(ENDPOINT_PATH.EMAIL_SIGNUP, data);
};

/**
 * @param {AxiosInstance} axios
 * @param {ApiLoginRequest} data
 * @returns {Promise<AxiosResponse<ApiLoginResponse>>}
 */
module.exports.signup_twitter = function signup_twitter(axios, data) {
  return axios.post(ENDPOINT_PATH.TWITTER_SIGNUP, data);
};

/**
 * @param {AxiosInstance} axios
 * @returns {Promise<AxiosResponse<RenewalCodeResponse>>}
 */
module.exports.send_renewal_code = function send_renewal_code(axios, data) {
  return axios.post(ENDPOINT_PATH.SEND_RENEWAL_CODE, data);
};

/**
 * @typedef VerifyCodeData
 * @property {string} password_renewal_id
 * @property {string} code
 */

/**
 * @param {AxiosInstance} axios
 * @param {VerifyCodeData} data
 * @returns {Promise<AxiosResponse<SuccessResponse>>}
 */
module.exports.verify_password_renewal = function verify_password_renewal(
  axios,
  data
) {
  return axios.post(ENDPOINT_PATH.VERIFY_PASSWORD_RENEWAL, data);
};

/**
 * @typedef RenewPasswordData
 * @property {string} password_renewal_id
 * @property {string} password
 */

/**
 * @param {AxiosInstance} axios
 * @param {RenewPasswordData} data
 * @returns {Promise<AxiosResponse<SuccessResponse>>}
 */
module.exports.renew_password = function renew_password(axios, data) {
  return axios.post(ENDPOINT_PATH.RENEW_PASSWORD, data);
};

/**
 * @typedef {{}} ApiOwnProfileRequest
 */

/**
 * @typedef ApiOwnProfileResponse
 * @property {ApiUserData} user
 * @property {boolean} extra_information_required
 * @property {boolean} profile_photo_missing_for_stream
 */

/**
 * @param {AxiosInstance} axios
 * @param {ApiOwnProfileRequest} data
 * @returns {Promise<AxiosResponse<ApiOwnProfileResponse>>}
 */
module.exports.own_profile = function own_profile(axios, data) {
  return axios.post(ENDPOINT_PATH.OWN_PROFILE, data);
};

/**
 * @param {AxiosInstance} axios
 */
module.exports.upload_image = function upload_image(axios, data) {
  return axios.post(ENDPOINT_PATH.UPLOAD_IMAGE, data);
};

/**
 * @param {AxiosInstance} axios
 * @param {ApiOwnProfileRequest} data
 * @returns {Promise<AxiosResponse<ApiOwnProfileResponse>>}
 */
module.exports.update_user = function upload_image(axios, data) {
  return axios.post(ENDPOINT_PATH.UPDATE_USER, data);
};

/**
 * @param {AxiosInstance} axios
 */
module.exports.set_sensitive_content_allowance =
  function set_sensitive_content_allowance(axios, data) {
    return axios.post(ENDPOINT_PATH.SENSITIVE_CONTENT, data);
  };

/**
 * @typedef ApiDiscoverRequest
 * @property {string?} next
 */

/**
 * @typedef ApiDiscoverResponse
 * @property {ApiUserWithLivestreamData[]} items
 * @property {ApiMeta} meta
 */

/**
 * @param {AxiosInstance} axios
 * @param {ApiDiscoverRequest} data
 * @returns {Promise<AxiosResponse<ApiDiscoverResponse>>}
 */
module.exports.discover = function discover(
  axios,
  data,
  filterType = enums.DiscoverFilterTypeEnum.POPULAR
) {
  let discoverURL =
    filterType == enums.DiscoverFilterTypeEnum.POPULAR
      ? ENDPOINT_PATH.DISCOVER
      : ENDPOINT_PATH.COUNTRY_DISCOVER;
  return axios.post(discoverURL, data);
};

/**
 * @typedef ApiFollowingsStreamsRequest
 * @property {string?} next
 */

/**
 * @typedef ApiFollowingsStreamsResponse
 * @property {ApiUserWithLivestreamData[]} items
 * @property {ApiMeta} meta
 */

/**
 * @param {AxiosInstance} axios
 * @param {ApiFollowingsStreamsRequest} data
 * @returns {Promise<AxiosResponse<ApiFollowingsStreamsResponse>>}
 */
module.exports.followings_streams = function followings_streams(axios, data) {
  return axios.post(ENDPOINT_PATH.FOLLOWINGS_STREAMS, data);
};

/**
 * @typedef ApiLivestreamRetrieveRequest
 * @property {string} livestream_id
 */

/**
 * @typedef ApiLivestreamRetrieveResponse
 * @property {ApiUserWithLivestreamData[]} items
 * @property {ApiMeta} meta
 */

/**
 * @param {AxiosInstance} axios
 * @param {ApiLivestreamRetrieveRequest} data
 * @returns {Promise<AxiosResponse<ApiLivestreamRetrieveResponse>>}
 */
module.exports.livestream_retrieve = function livestream_retrieve(axios, data) {
  return axios.post(ENDPOINT_PATH.LIVESTREAM_RETRIEVE, data);
};

/**
 * @typedef ApiLivestreamEnterRequest
 * @property {string} livestream_id
 */

/**
 * @typedef ApiLivestreamEnterResponse
 * @property {import('../stream_event/type').RawStreamEvent[]} agora_events
 */

/**
 * @param {AxiosInstance} axios
 * @param {ApiLivestreamEnterRequest} data
 * @returns {Promise<AxiosResponse<ApiLivestreamEnterResponse>>}
 */
module.exports.livestream_enter = function livestream_enter(axios, data) {
  return axios.post(ENDPOINT_PATH.LIVESTREAM_ENTER, data);
};

/**
 * @typedef ApiLivestreamSendMessageRequest
 * @property {string} guid
 * @property {string} text
 * @property {string} livestream_id
 */

/**
 * @typedef ApiLivestreamSendMessageResponse
 * @property {boolean} success
 * @property {import('../stream_event/type').TextStreamEvent[]} agora_events
 */

/**
 * @param {AxiosInstance} axios
 * @param {ApiLivestreamSendMessageRequest} data
 * @returns {Promise<AxiosResponse<ApiLivestreamSendMessageResponse>>}
 */
module.exports.livestream_send_message = function livestream_send_message(
  axios,
  data
) {
  return axios.post(ENDPOINT_PATH.LIVESTREAM_SEND_MESSAGE, data);
};

/**
 * @typedef ApiLivestreamExitRequest
 * @property {string} livestream_id
 */

/**
 * @typedef ApiLivestreamExitResponse
 * @property {import('../stream_event/type').RawStreamEvent[]} agora_events
 */

/**
 * @param {AxiosInstance} axios
 * @param {ApiLivestreamExitRequest} data
 * @returns {Promise<AxiosResponse<ApiLivestreamExitResponse>>}
 */
module.exports.livestream_exit = function livestream_exit(axios, data) {
  return axios.post(ENDPOINT_PATH.LIVESTREAM_EXIT, data);
};

/**
 * @typedef ApiLivestreamContinueListRequest
 * @property {string?} next
 */

/**
 * @typedef ApiLivestreamContinueListResponse
 * @property {ApiUserWithLivestreamData[]} items
 * @property {ApiMeta} meta
 */

/**
 * @param {AxiosInstance} axios
 * @param {ApiLivestreamContinueListRequest} data
 * @returns {Promise<AxiosResponse<ApiLivestreamContinueListResponse>>}
 */
module.exports.livestream_continue_list = function livestream_continue_list(
  axios,
  data
) {
  return axios.post(ENDPOINT_PATH.LIVESTREAM_CONTINUE_LIST, data);
};

/**
 * @typedef ApiLivestreamSuggestionRequest
 * @property {string?} next
 */

/**
 * @typedef ApiLivestreamSuggestionResponse
 * @property {ApiUserWithLivestreamData[]} items
 * @property {ApiMeta} meta
 */

/**
 * @param {AxiosInstance} axios
 * @param {ApiLivestreamSuggestionRequest} data
 * @returns {Promise<AxiosResponse<ApiLivestreamSuggestionResponse>>}
 */
module.exports.livestream_suggestion = function livestream_suggestion(
  axios,
  data
) {
  return axios.post(ENDPOINT_PATH.LIVESTREAM_SUGGESTION, data);
};

/**
 * @typedef ApiGetTopGiftersRequest
 * @property {string?} next
 */

/**
 * @param {AxiosInstance} axios
 * @param {ApiGetTopGiftersRequest} data
 * @returns {Promise<AxiosResponse<ApiGetTopGiftersRequest>>}
 */
module.exports.get_topgifters = function get_topgifters(axios, data) {
  return axios.post(ENDPOINT_PATH.TOP_GIFTERS, data);
};

/**
 * @typedef TwitterRequestToken
 * @property {string?} callback_uri
 */

/**
 * @param {AxiosInstance} axios
 * @param {TwitterRequestToken} data
 * @returns {Promise<AxiosResponse<TwitterRequestToken>>}
 */
module.exports.twitter_request_token = function twitter_request_token(
  axios,
  data
) {
  return axios.post(ENDPOINT_PATH.TWITTER_REQUEST_TOKEN, data);
};

/**
 * @typedef TwitterAccessToken
 * @property {string?} oauth_token_key
 * @property {string?} oauth_token_secret
 * @property {string?} oauth_verifier
 */

/**
 * @param {AxiosInstance} axios
 * @param {TwitterAccessToken} data
 * @returns {Promise<AxiosResponse<TwitterAccessToken>>}
 */
module.exports.twitter_access_token = function twitter_access_token(
  axios,
  data
) {
  return axios.post(ENDPOINT_PATH.TWITTER_ACCESS_TOKEN, data);
};

module.exports.payment_methods = function payment_methods(axios, data) {
  return axios.post(ENDPOINT_PATH.PAYMENT_METHODS, data);
};

/**
 * @typedef DetachPaymentMethodInstance
 * @property {string?} payment_method_id
 */

/**
 * @param {AxiosInstance} axios
 * @param {DetachPaymentMethodInstance} data
 * @returns {Promise<AxiosResponse<PaymentMethod>>}
 */
module.exports.detach_payment_method = function detach_payment_method(
  axios,
  data
) {
  return axios.post(ENDPOINT_PATH.DETACH_PAYMENT_METHOD, data);
};

const helpers = require("../helper/index.js");

module.exports.payment = function payment(axios, data) {
  return axios.post(ENDPOINT_PATH.PAYMENT, {
    ...data,
    fbc: helpers.getCookie("_fbc"),
    fbp: helpers.getCookie("_fbp"),
    source_url: window.location.href,
  });
};

module.exports.send_gift = function send_gift(axios, data) {
  return axios.post(ENDPOINT_PATH.LIVESTREAM_SEND_GIFT, data);
};

module.exports.accept_private = function accept_private(axios, data) {
  return axios.post(ENDPOINT_PATH.ACCEPT_PRIVATE, data);
};

module.exports.user_profile = function user_profile(axios, data) {
  return axios.post(ENDPOINT_PATH.USER_PROFILE, data);
};

module.exports.follow_user = function follow_user(axios, data) {
  return axios.post(ENDPOINT_PATH.FOLLOW_USER, data);
};

module.exports.unfollow_user = function unfollow_user(axios, data) {
  return axios.post(ENDPOINT_PATH.UNFOLLOW_USER, data);
};

module.exports.user_followers = function user_followers(axios, data) {
  return axios.post(ENDPOINT_PATH.USER_FOLLOWERS, data);
};

module.exports.user_followings = function user_followings(axios, data) {
  return axios.post(ENDPOINT_PATH.USER_FOLLOWINGS, data);
};

module.exports.send_text_message = function send_text_message(axios, data) {
  return axios.post(ENDPOINT_PATH.SEND_TEXT_MESSAGE, data);
};

module.exports.message_history = function message_history(axios, data) {
  return axios.post(ENDPOINT_PATH.MESSAGE_HISTORY, data);
};

module.exports.message_info = function message_info(axios, data) {
  return axios.post(ENDPOINT_PATH.MESSAGE_INFO, data);
};

module.exports.message_statuses = function message_statuses(axios, data) {
  return axios.post(ENDPOINT_PATH.MESSAGE_STATUSES, data);
};

module.exports.leaderboard = function leaderboard(axios, data) {
  return axios.post(ENDPOINT_PATH.LEADERBOARD, data);
};

module.exports.gifters_leaderboard = function gifters_leaderboard(axios, data) {
  return axios.post(ENDPOINT_PATH.GIFTERS_LEADERBOARD, data);
};

module.exports.gifters_leaderboard_anonymous =
  function gifters_leaderboard_anonymous(axios, data) {
    return axios.post(ENDPOINT_PATH.SET_GIFTERS_LEADERBOARD_ANONYMOUS, data);
  };

module.exports.discover_countries = function discover_countries(axios) {
  return axios.post(ENDPOINT_PATH.DISCOVER_COUNTRIES);
};

module.exports.set_discover_countries = function set_discover_countries(
  axios,
  data
) {
  return axios.post(ENDPOINT_PATH.SET_DISCOVER_COUNTRIES, data);
};

module.exports.firebase_analytics_record = function firebase_analytics_record(
  axios
) {
  return axios.post(ENDPOINT_PATH.FIREBASE_ANALYTICS_RECORD, {});
};

module.exports.record_adjust_data = function record_adjust_data(axios) {
  return axios.post(ENDPOINT_PATH.RECORD_ADJUST_DATA, {});
};

module.exports.send_gift_message = function send_gift_message(axios, data) {
  return axios.post(ENDPOINT_PATH.SEND_GIFT_MESSAGE, data);
};

module.exports.inapp_purchase_settings = function inapp_purchase_settings(
  axios
) {
  return axios.post(ENDPOINT_PATH.INAPP_PURCHASE_SETTINGS);
};

module.exports.promotion_inapp_purchase_settings =
  function promotion_inapp_purchase_settings(axios, data) {
    return axios.post(ENDPOINT_PATH.PROMOTION_INAPP_PURCHASE_SETTINGS, data);
  };

module.exports.payment_countries = function payment_countries(axios) {
  return axios.post(ENDPOINT_PATH.PAYMENT_COUNTRIES);
};

module.exports.get_banner = function get_banner(axios, data) {
  return axios.post(ENDPOINT_PATH.GET_BANNER, data);
};

module.exports.set_payment_country = function set_payment_country(axios, data) {
  return axios.post(ENDPOINT_PATH.SET_PAYMENT_COUNTRY, data);
};

module.exports.set_promotion_payment_country =
  function set_promotion_payment_country(axios, data) {
    return axios.post(ENDPOINT_PATH.SET_PROMOTION_PAYMENT_COUNTRY, data);
  };

module.exports.payermax_payment = function payermax_payment(axios, data) {
  return axios.post(ENDPOINT_PATH.PAYERMAX_PAYMENT, data);
};

module.exports.rapyd_payment = function rapyd_payment(axios, data) {
  return axios.post(ENDPOINT_PATH.RAPYD_PAYMENT, data);
};

module.exports.razer_payment = function (axios, data) {
  return axios.post(ENDPOINT_PATH.RAZER_PAYMENT, data);
};

module.exports.retrieve_payment_status = function (axios, data) {
  return axios.post(ENDPOINT_PATH.RETRIEVE_PAYMENT_STATUS, data);
};

module.exports.trustpay_payment = function (axios, data) {
  return axios.post(ENDPOINT_PATH.TRUSTPAY_PAYMENT, data);
};

module.exports.saved_cards = function (axios, data) {
  return axios.post(ENDPOINT_PATH.SAVED_CARDS, data);
};

module.exports.delete_saved_card = function (axios, data) {
  return axios.post(ENDPOINT_PATH.DELETE_SAVED_CARD, data);
};

module.exports.get_notifications = function get_notifications(axios, data) {
  return axios.post(ENDPOINT_PATH.GET_NOTIFICATIONS, data);
};

module.exports.user_entry = function user_entry(axios, data) {
  return axios.post(ENDPOINT_PATH.USER_ENTRY, data);
};

module.exports.active_viewers = function active_viewers(axios, data) {
  return axios.post(ENDPOINT_PATH.LIVESTREAM_ACTIVE_VIEWERS, data);
};

module.exports.connect_email = function connect_email(axios, data) {
  return axios.post(ENDPOINT_PATH.CONNECT_EMAIL, data);
};

module.exports.connect_google = function connect_google(axios, data) {
  return axios.post(ENDPOINT_PATH.CONNECT_GOOGLE, data);
};

module.exports.confirm_payment = function confirm_payment(axios, data) {
  return axios.post(ENDPOINT_PATH.CONFIRM_PAYMENT, {
    ...data,
    fbc: helpers.getCookie("_fbc"),
    fbp: helpers.getCookie("_fbp"),
    source_url: window.location.href,
  });
};

module.exports.reconfirm_payment = function reconfirm_payment(axios, data) {
  return axios.post(ENDPOINT_PATH.RECONFIRM_PAYMENT, {
    ...data,
  });
};

module.exports.create_payment_method = function create_payment_method(
  axios,
  data
) {
  return axios.post(ENDPOINT_PATH.CREATE_PAYMENT_METHOD, {
    ...data,
    fbc: helpers.getCookie("_fbc"),
    fbp: helpers.getCookie("_fbp"),
    source_url: window.location.href,
  });
};

module.exports.get_coin_reseller_accounts = function get_coin_reseller_accounts(
  axios,
  data
) {
  return axios.post(ENDPOINT_PATH.COIN_RESELLER_ACCOUNTS, data);
};

module.exports.get_active_promotion_banners =
  function get_active_promotion_banners(axios, data) {
    return axios.post(ENDPOINT_PATH.ACTIVE_PROMOTION_BANNERS, data);
  };

module.exports.paypal_payment = function (axios, data) {
  return axios.post(ENDPOINT_PATH.PAYPAL_PAYMENT, data);
};

module.exports.paypal_capture = function (axios, data) {
  return axios.post(ENDPOINT_PATH.PAYPAL_CAPTURE, data);
};
