export function vipAttributes(vip_state) {
  if (vip_state == 1) {
    return {
      image: require("@/assets/img/bronze-vip.svg"),
      style: "bg-bronze",
    };
  } else if (vip_state == 2) {
    return {
      image: require("@/assets/img/silver-vip.svg"),
      style: "bg-silver",
    };
  } else if (vip_state == 3) {
    return {
      image: require("@/assets/img/gold-vip.svg"),
      style: "bg-gold",
    };
  } else {
    return {
      image: null,
      style: "bg-primary",
    };
  }
}
