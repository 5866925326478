<template>
  <v-container class="home" :fluid="true">
    <DiscoverView />
  </v-container>
</template>
<style lang="scss"></style>
<script>
import DiscoverView from "@/views/Discover/DiscoverView.vue";
export default {
  name: "Home",
  components: {
    DiscoverView,
  },
  created() {},
};
</script>
