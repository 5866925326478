<template>
  <div>
    <v-dialog :value="isOpen" @input="onClose" max-width="400">
      <v-card class="flex">
        <v-card-title class="justify-center">
          {{ $t("payment.approval_request") }}
        </v-card-title>
        <v-card-text class="justify-center">
          {{ $t("payment.card_will_be_deleted") }}
        </v-card-text>
        <v-card-text>
          <v-btn
            class="confirmation-button is-approve-btn"
            rounded
            color="error"
            variant="outlined"
            block
            @click="onConfirm"
          >
            {{ $t("labels.remove") }}
          </v-btn>
        </v-card-text>
        <v-card-text>
          <v-btn
            class="confirmation-button is-cancel-btn"
            rounded
            color="white"
            variant="outlined"
            block
            @click="onClose"
          >
            {{ $t("labels.cancel") }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DeleteCardDialog",
  props: ["isOpen"],
  methods: {
    onClose() {
      this.$emit("close");
    },
    onConfirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style scoped lang="scss">
.confirmation-button {
  text-transform: unset !important;
  width: 60% !important;
  height: 55px !important;
}

.is-approve-btn {
  background-color: #dc2626 !important;
}

.is-cancel-btn {
  border: solid 1px #64748b !important;
}
</style>
