import { render, staticRenderFns } from "./MessageView.vue?vue&type=template&id=26f17ea0&scoped=true"
import script from "./MessageView.vue?vue&type=script&lang=js"
export * from "./MessageView.vue?vue&type=script&lang=js"
import style0 from "./MessageView.vue?vue&type=style&index=0&id=26f17ea0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26f17ea0",
  null
  
)

export default component.exports