<template>
  <div>
    <div class="columns is-multiline">
      <!-- GOOGLE PLAY PAYMENT -->
      <div
        v-if="this.googlePlayProduct"
        class="column is-12-mobile is-8-tablet is-offset-2-tablet is-4-desktop is-offset-4-desktop"
        @click="googlePlayPayment(googlePlayProduct.sku)"
      >
        <div class="general-container">
          <div class="coin-count">
            <img src="@/assets/img/coin.svg" alt="Coin Icon" />
            {{ this.googlePlayProduct.amount }}
          </div>
          <div class="price-container">
            <div class="current-price">{{ this.googlePlayProduct.pstr }}</div>
          </div>

          <button class="button custom-button green-button">
            <img src="@/assets/img/google-play-icon.svg" class="mr-2" />
            {{ $t("checkout.pay_with_google_play") }}
          </button>
        </div>
      </div>
      <!-- CREDIT CARD PAYMENT -->
      <div
        v-if="selectedProduct"
        class="column is-12-mobile is-8-tablet is-offset-2-tablet is-4-desktop is-offset-4-desktop"
      >
        <div class="general-container">
          <div class="coin-count">
            <img src="@/assets/img/coin.svg" alt="Coin Icon" />
            {{ selectedProduct.amount }}
          </div>
          <div class="price-container">
            <div
              class="old-price"
              v-if="selectedProduct.stripe_price.original_price"
            >
              {{
                currencies[selectedProduct.stripe_price.currency.toUpperCase()]
              }}{{ selectedProduct.stripe_price.original_price }}
            </div>
            <div class="current-price">
              {{
                currencies[selectedProduct.stripe_price.currency.toUpperCase()]
              }}{{ selectedProduct.stripe_price.price }}
            </div>
          </div>
          <CardItem
            v-if="selectedPaymentMethod"
            :cardData="selectedPaymentMethod"
            :key="selectedPaymentMethod.id"
            @detached="deletePaymentMethod"
            @removeError="removeError"
          />

          <button
            v-if="this.paymentMethods && this.paymentMethods.length > 0"
            @click="payWithMethod"
            class="button custom-button orange-button"
            :disabled="loading"
          >
            {{ $t("labels.buy") }}
            <img
              src="@/assets/img/loading.svg"
              alt="loading"
              class="loading"
              v-if="loading"
            />
          </button>
          <button
            v-else
            class="button custom-button orange-button"
            @click="goCardPayment"
          >
            <i class="far fa-credit-card mr-2"></i>
            {{ $t("checkout.buy_with_credit_card") }}
          </button>
          <div class="card-error" v-if="cardError">{{ this.cardError }}</div>
          <div
            class="checkout-badge"
            v-if="
              selectedProduct.stripe_price.original_price &&
              selectedProduct.stripe_price.discount
            "
          >
            %{{ selectedProduct.stripe_price.discount }}
          </div>
        </div>
      </div>

      <div
        v-if="this.paymentMethods && this.paymentMethods.length > 0"
        class="column is-12-mobile is-8-tablet is-offset-2-tablet is-4-desktop is-offset-4-desktop"
      >
        <div
          class="general-container mb-3"
          v-for="paymentMethod in paymentMethods.filter(
            (item) => item !== selectedPaymentMethod
          )"
          :key="paymentMethod.id"
        >
          <CardItem
            :cardData="paymentMethod"
            @detached="deletePaymentMethod"
            @removeError="removeError"
          />
        </div>
      </div>

      <div
        v-if="this.paymentMethods && this.paymentMethods.length > 0"
        class="column is-12-mobile is-8-tablet is-offset-2-tablet is-4-desktop is-offset-4-desktop"
        @click="goCardPayment"
      >
        <div class="general-container">
          <div class="card-number-container">
            <div class="card-number">
              <!-- <img src="@/assets/img/credit-card-icon.svg" alt="Credit Card Icon" /> -->
              <i class="far fa-credit-card mr-3"></i>
              {{ $t("checkout.buy_with_another_credit_card") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-overlay color="#fff" :value="showFullPageLoading">
      <v-progress-circular
        color="#333"
        :size="50"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<style lang="scss">
//Note: Needs to be scoped.
@import "@/components/Payment/Stripe/Checkout.scss";
</style>
<script>
"use strict";

import { mapState, mapActions } from "vuex";
import CardItem from "./CardItem.vue";

import { stripePaymentRequestData, stripeUuid } from "@/helper/index.js";
import {
  openCoinPurchaseEvent,
  selectProductEvent,
  paymentAttemptEvent,
  paymentSuccessEvent,
} from "@/helper/marketingevents.js";
import {
  paymentAttemptHybridEvent,
  paymentStartHybridEvent,
  paymentPageLoadedEvent,
} from "@/firebase_analytics";

const endpoints = require("@/api/endpoints");

export default {
  name: "Checkout",
  components: {
    CardItem,
  },
  data() {
    return {
      paymentMethods: null,
      loading: false,
      googlePlayProduct: null,
      cardError: null,
      showFullPageLoading: false,
    };
  },
  computed: {
    ...mapState({
      selectedProduct: (state) => state.payment.selectedProduct,
      selectedPaymentMethod: (state) => state.payment.selectedPaymentMethod,
      currencies: (state) => state.client.currencies,
      buyCoinBackground: (state) => state.client.buyCoinBackground,
      forterToken: (state) => state.client.forterToken,
      isHybridPage: (state) => state.client.isHybridPage,
      ownProfile: (state) => state.client.ownProfile,
      deviceId: (state) => state.client.deviceId,
    }),
  },
  beforeMount() {
    let platform = this.$route.query.platform;
    let SKU = this.$route.query.SKU ? this.$route.query.SKU : null;
    let PRC = this.$route.query.PRC;
    let CR = this.$route.query.CR;
    let CN = this.$route.query.CN;
    let PSTR = this.$route.query.PSTR;
    let flow_id = this.$route.query.PFI;
    let automatic = this.$route.query.automatic;
    let authToken = this.$route.query.auth_token;
    let deviceId = this.$route.query.device_id;

    this.setPlatform(platform != null ? platform : null);

    if (
      platform == "android" &&
      SKU &&
      PRC &&
      CR &&
      CN &&
      PSTR &&
      authToken &&
      deviceId
    ) {
      this.googlePlayProduct = {
        sku: SKU,
        price: PRC,
        currency: CR,
        amount: CN,
        pstr: PSTR,
        flow_id: flow_id,
        automatic: automatic,
        device_id: deviceId,
      };
    }
  },
  mounted() {
    window["backButtonTapped"] = () => {
      if (confirm(this.$t("labels.close_confirm"))) {
        this.hybridClose("close");
        return "close";
      }
      return "nothing";
    };
    this.$hybridapi("updateWindowSize", "medium");
    this.$hybridapi("showLoading");
    this.$hybridapi("updatePageTitle", this.$t("labels.do_payment"));
    if (this.buyCoinBackground !== "black-opac-bg") {
      this.$store.dispatch("client/setBuyCoinBackground", "white-bg");
    }

    if (this.googlePlayProduct) {
      // NOTE(baris): Initializing with Google Play price (and hybrid_method="google_play").
      this.$store.dispatch("payment/setHybridPaymentFlowInformation", {
        device_guid: this.googlePlayProduct.device_id,
        product_id: this.googlePlayProduct.sku,
        coins: this.googlePlayProduct.amount,
        price: this.googlePlayProduct.pstr, // NOTE(baris): using price string to match Android.
        page_source: "single_stripe",
        currency: this.googlePlayProduct.currency,
        is_new_card: 0,
        order_id: null,
        payment_type: null,
        hybrid_method: "google_play",
        flow_id: this.googlePlayProduct.flow_id,
      });
      this.getPaymentMethods(this.googlePlayProduct.sku);
    } else if (this.selectedProduct) {
      this.getPaymentMethods(this.selectedProduct.sku);
    } else {
      this.hybridClose("close");
      this.$store.dispatch("payment/setStripeActivePage", "coinSelect");
    }
  },
  methods: {
    ...mapActions(["setPlatform"]),
    ...mapActions("payment", [
      "stripePaymentWebsocket",
      "postHybridCloseChecks",
      "setSelectedPaymentMethod",
      "setHybridPaymentFlowInformationProps",
    ]),
    goCardPayment() {
      this.$store.dispatch("payment/setStripeActivePage", "cardPayment");
    },
    payWithMethod() {
      this.loading = true;

      if (!this.isHybridPage) {
        paymentAttemptEvent(
          this.deviceId,
          this.ownProfile.user.user_id,
          stripeUuid(),
          this.selectedProduct.sku,
          this.selectedProduct.stripe_price.amount,
          this.selectedProduct.stripe_price.price,
          this.selectedProduct.stripe_price.currency,
          0
        );
      } else {
        this.setHybridPaymentFlowInformationProps({
          hybrid_method: "stripe",
          product_id: this.selectedProduct.sku,
          coins: this.selectedProduct.stripe_price.amount,
          price: this.selectedProduct.stripe_price.price,
          currency: this.selectedProduct.stripe_price.currency,
          is_new_card: 0, // NOTE(baris): Just in case.
        });
        paymentAttemptHybridEvent(); // Hybrid Marketting Event
      }

      endpoints
        .confirm_payment(
          this.$axios,
          stripePaymentRequestData(
            this.selectedProduct.sku,
            this.selectedPaymentMethod.id,
            this.forterToken
          )
        )
        .then((payWithMethodResponse) => {
          this.confirmPaymentResponseHandler(payWithMethodResponse.data);
        })
        .catch((err) => {
          this.loading = false;

          if (err?.error?.message) this.cardError = err.error.message;
          else this.cardError = this.$t("errors.general_error");
        });
    },
    getPaymentMethods(productSku) {
      endpoints
        .payment_methods(this.$axios, { product_id: productSku })
        .then((paymentMethodResults) => {
          // This block has been moved to this section due to the speed problem in apps (websocket causes problems on Android devices).
          this.stripePaymentWebsocket(this.$hybrid_websocket_url).then(() => {
            // Marketing Event
            if (!this.isHybridPage) {
              paymentSuccessEvent(
                this.deviceId,
                this.ownProfile.user.user_id,
                stripeUuid(),
                this.selectedProduct.sku,
                this.selectedProduct.stripe_price.amount,
                this.selectedProduct.stripe_price.price,
                this.selectedProduct.stripe_price.currency,
                0
              );
            }
            this.loading = false;
            this.$hybridapi("hideLoading");
            sessionStorage.removeItem("uuid_stripe_payment");
            this.$store.dispatch("payment/setStripePaymentSucceeded", true);
            this.$store.dispatch(
              "payment/setStripeActivePage",
              "completePayment"
            );
            this.socketPaymentSucceeded = true;
          });

          if (this.isHybridPage) {
            this.setHybridPaymentFlowInformationProps({
              user_id: paymentMethodResults.data.user_id,
            });
          }
          if (this.isHybridPage && this.googlePlayProduct) {
            paymentPageLoadedEvent(
              paymentMethodResults.data.user_id,
              this.googlePlayProduct.device_id,
              "single_stripe",
              this.googlePlayProduct.automatic
            );
          }

          if (!paymentMethodResults.data.has_email) {
            this.$store.dispatch("payment/setStripeActivePage", "getEmail");
          }

          this.paymentMethods =
            paymentMethodResults.data.payment_methods.length > 0
              ? paymentMethodResults.data.payment_methods
              : null;
          this.$hybridapi("hideLoading");
          if (this.paymentMethods) {
            this.setSelectedPaymentMethod(this.paymentMethods[0]);
          }
          if (
            !paymentMethodResults.data.product ||
            !paymentMethodResults.data.product.stripe_price
          ) {
            this.hybridClose("close");
            this.$store.dispatch("payment/setStripeActivePage", "coinSelect");
            this.$router.push("checkout");
          }
          if (!this.isHybridPage) {
            //Marketing Event
            openCoinPurchaseEvent(
              productSku,
              paymentMethodResults.data.product.stripe_price.amount,
              paymentMethodResults.data.product.stripe_price.currency,
              paymentMethodResults.data.product.stripe_price.price
            );
            selectProductEvent(
              this.deviceId,
              stripeUuid(),
              this.ownProfile.user.user_id,
              productSku,
              this.selectedProduct.stripe_price.amount,
              this.selectedProduct.stripe_price.price,
              this.selectedProduct.stripe_price.currency
            );
          }
          this.$store.dispatch(
            "payment/setSelectedProduct",
            paymentMethodResults.data.product
          );
        });
    },
    deletePaymentMethod(paymentMethodId) {
      this.paymentMethods = this.paymentMethods.filter(
        (item) => item.id !== paymentMethodId
      );
      if (paymentMethodId === this.selectedPaymentMethod.id) {
        this.$store.dispatch(
          "payment/setSelectedPaymentMethod",
          this.paymentMethods[0]
        );
      }
    },
    googlePlayPayment(SKU) {
      this.$hybridapi("triggerPurchase", SKU);
      this.$hybridapi("showLoading");

      // NOTE(baris): Handling edge case that user tries google play payment after stripe fail.
      this.setHybridPaymentFlowInformationProps({
        hybrid_method: "google_play",
        product_id: this.googlePlayProduct.sku,
        coins: this.googlePlayProduct.amount,
        price: this.googlePlayProduct.pstr, // NOTE(baris): using price string to match Android.
        currency: this.googlePlayProduct.currency,
        payment_type: 2, // Hardcoded payment_provider enum for Google Play
        is_new_card: 0,
      });
      paymentStartHybridEvent();
    },
    removeError() {
      this.cardError = null;
    },
    confirmPaymentResponseHandler(payWithMethodResponse) {
      if (payWithMethodResponse.status == "succeeded") {
        sessionStorage.removeItem("uuid_stripe_payment");
        this.$store.dispatch("payment/setStripePaymentSucceeded", true);
        this.$store.dispatch("payment/setStripeActivePage", "completePayment");
      } else if (
        payWithMethodResponse.status == "requires_action" ||
        payWithMethodResponse.status == "requires_confirmation"
      ) {
        this.$stripe
          .handleCardAction(payWithMethodResponse.client_secret)
          .then((handleCardResponse) => {
            this.handleCardResponseHandler(
              handleCardResponse,
              payWithMethodResponse
            );
          });
      } else {
        this.loading = false;
      }
    },
    handleCardResponseHandler(handleCardResponse, payWithMethodResponse) {
      if (handleCardResponse.error) {
        this.loading = false;
        this.cardError = handleCardResponse.error.message;
        sessionStorage.removeItem("uuid_stripe_payment");
        stripeUuid();
      } else {
        endpoints
          .reconfirm_payment(this.$axios, {
            payment_id: payWithMethodResponse.payment_id,
          })
          .then((reConfirmResponse) => {
            this.reConfirmResponseHandler(reConfirmResponse.data);
          })
          .catch(() => {
            this.loading = false;
            this.cardError = this.$t("errors.general_error");
          });
      }
    },
    reConfirmResponseHandler(reConfirmResponse) {
      if (reConfirmResponse.error) {
        this.loading = false;
        this.cardError = reConfirmResponse.error.message;
      }
    },
    stripePaymentRequestData,
    hybridClose(exit_location = "close") {
      this.$hybridapi("close", exit_location);
      if (this.isHybridPage) this.postHybridCloseChecks();
    },
  },
};
</script>
