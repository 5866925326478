<template>
  <div class="live-stream-base">
    <div
      class="live-stream-general-container"
      :class="{
        show: (watchingStream && !streamStopped) || showAcceptPremium,
        'slide-out': nextStreamAnimation,
      }"
    >
      <div class="live-stream-top-container">
        <div
          class="live-stream-top"
          v-if="(watchingStream && !isFullScreen) || showAcceptPremium"
        >
          <div class="columns is-mobile">
            <div class="column is-half top-column">
              <div class="user" :class="userVipBorderClass">
                <div
                  class="user-image-container"
                  @click="openHalfProfile(selectedUser.user_id)"
                >
                  <user-image-circle :user="selectedUser" :size="'40px'" />
                </div>
                <div
                  class="user-name text-ellipsis"
                  @click="openHalfProfile(selectedUser.user_id)"
                >
                  <level-badge
                    v-if="selectedUser"
                    :user="selectedUser"
                  ></level-badge>
                  {{ selectedUser && selectedUser.name }}
                  <div
                    class="vip-container"
                    v-if="selectedUser && selectedUser.vip_state > 0"
                  >
                    <img
                      v-if="returnVipString(selectedUser.vip_state)"
                      :src="
                        require(`@/assets/img/${returnVipString(
                          selectedUser.vip_state
                        )}-vip.svg`)
                      "
                      class="vip-badge"
                    />
                  </div>
                </div>

                <v-btn
                  v-if="selectedUser && selectedUser.is_followed === false"
                  class="button-follow"
                  @click="clickFollowUser()"
                  :loading="isFollowLoading"
                  small
                >
                  <i class="fas fa-plus"></i>
                </v-btn>
              </div>
            </div>
            <div class="column is-half top-column top-gifter-container">
              <div class="top-gifter-wrapper">
                <div
                  v-for="(item, index) in topGifters.slice(0, 3)"
                  :key="`${index}${item.user_id}`"
                >
                  <div
                    v-if="item"
                    :class="`top-gifter top-gifters-${index}`"
                    @click="openHalfProfile(item.user_id)"
                  >
                    <user-image-circle :user="item" :size="'35px'" />
                    <div class="top-gifter-badge">
                      <img
                        :src="
                          require(`@/assets/img/top-gifter-${index + 1}.svg`)
                        "
                        :alt="$t('livestream.top_gifters_icon')"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <v-btn icon class="close-stream" @click="smartCloseToBack()">
                <i class="fas fa-times"></i>
              </v-btn>
            </div>
          </div>
        </div>
        <div
          class="live-stream-top-sub"
          v-if="(watchingStream && !isFullScreen) || showAcceptPremium"
        >
          <div class="headline-left-container mt-1">
            <v-btn @click="changeCardSettings()" class="viewer-count mr-2">
              <v-icon class="pl-1" left x-small>fa fa-eye</v-icon>
              {{ viewerCount }}
              <v-icon right x-small>fas fa-chevron-right</v-icon>
            </v-btn>
            <v-btn class="diamond-count" @click="openTopGifters()">
              <v-img
                width="12"
                src="@/assets/img/diamond-icon.svg"
                alt="Diamond count"
              />
              <div class="ml-2">
                {{ showThousund(diamondCount) }}
              </div>
              <v-icon right x-small>fas fa-chevron-right</v-icon>
            </v-btn>
          </div>
          <div class="stream-headline" v-if="activeStream" id="streamHeadline">
            {{ activeStream.headline }}
          </div>
        </div>
        <div class="live-stream-top-sub premium-top" v-if="livestreamType == 2">
          <div class="headline-left-container">
            <div class="premium-badge" v-if="!showAcceptPremium">
              <img src="@/assets/img/premium-key.svg" />
            </div>
          </div>
        </div>
      </div>
      <div class="live-stream-container">
        <img
          v-bind:src="require(`@/assets/img/live-tv-icon_${brandName}.svg`)"
          alt="live tv icon"
        />
      </div>
      <div v-if="activeGift">
        <div class="gift-container" :class="{ 'show-gift': showGiftClass }">
          <img
            class="profile-image"
            v-if="
              activeGift.profile_image && activeGift.profile_image.thumbnail_url
            "
            :src="activeGift.profile_image.thumbnail_url"
            alt="gifter profile image"
          />
          <div class="user-info text-ellipsis">
            <span class="name text-ellipsis">{{ activeGift.name }}</span>
            <span>{{ $t("livestream.sent") }}</span>
          </div>
          <div class="gift-image-container">
            <img
              class="gift-light"
              v-if="activeGift.gift.cost > 299"
              src="@/assets/img/gift-light.png"
            />
            <div class="gift-image">
              <img
                :src="activeGift.gift.image"
                :class="{ 'gift-pulse': activeGift.gift.cost > 299 }"
              />
            </div>
          </div>
          <div class="coin-count">
            <img src="@/assets/img/coin.svg" alt="coin" />
            {{ activeGift.gift.cost }}
          </div>
        </div>
      </div>

      <div
        class="vip-enter-container"
        :class="{ show: showVipClass }"
        v-if="activeVip"
      >
        <div class="vip-bg" v-if="activeVip.vip_state">
          <img
            v-if="returnVipString(activeVip.vip_state)"
            :src="
              require(`@/assets/img/${returnVipString(
                activeVip.vip_state
              )}_shine.png`)
            "
          />
        </div>
        <div class="vip-info">
          <img
            v-if="returnVipString(activeVip.vip_state)"
            :src="
              require(`@/assets/img/${returnVipString(
                activeVip.vip_state
              )}-vip.svg`)
            "
          />
          <span>{{ activeVip.name }}</span>
          <span v-if="activeVip.name">{{
            $t("livestream.vip_join_text")
          }}</span>
        </div>
      </div>

      <div v-if="showHalfProfile === true && !streamFinished && !streamStopped">
        <half-profile />
      </div>

      <VipMessage v-if="activeVipMessage" />

      <TopGifters
        v-if="showTopGifters === true && !streamFinished && !streamStopped"
      />
      <ViewerCard v-if="showViewerCard && !showHalfProfile" />
      <GuestCardMobile />
      <AcceptPremium />
      <v-row
        class="touch-container"
        v-if="isMobile()"
        v-touch="{
          up: () => nextStream(),
          end: (e) => toggleFullScreen(e),
        }"
      >
      </v-row>
      <div
        v-if="
          (watchingStream && isDesktop()) ||
          (!showHalfProfile &&
            !showViewerCard &&
            isMobile() &&
            watchingStream &&
            !isFullScreen &&
            !showTopGifters &&
            !showAcceptPremium)
        "
        class="stream-gradient-overlay"
      ></div>
    </div>

    <div
      class="live-stream-bottom-right"
      :class="{ 'slide-out-bottom': nextStreamAnimation }"
      v-if="
        (watchingStream && isDesktop()) ||
        (!showHalfProfile &&
          !showViewerCard &&
          isMobile() &&
          watchingStream &&
          !isFullScreen &&
          !showTopGifters)
      "
    >
      <v-btn icon color="white" class="report-link" @click="showDownloadApp()">
        <img src="@/assets/img/shield-info.svg" alt="Report Stream" />
        <!-- <i class="fa fa-times"></i> -->
      </v-btn>
      <a href="#" @click="openGift"
        ><img src="@/assets/img/gift-icon.svg" alt="Send Gift"
      /></a>
    </div>

    <div v-if="showSendGift">
      <div class="send-gift-overlay" v-if="isMobile()" @click="closeGift"></div>
      <div class="stream-send-gift-container">
        <SendGift
          v-if="
            (isDesktop() ||
              (!showHalfProfile &&
                !showViewerCard &&
                !showTopGifters &&
                isMobile())) &&
            !livestreamLoading &&
            watchingStream
          "
        />
      </div>
    </div>

    <Messages
      :activeStream="activeStream"
      :messages="messages"
      :selectedUser="selectedUser"
      :showDownloadApp="showDownloadApp"
      :class="{ 'slide-out-bottom': nextStreamAnimation }"
      v-on:disableFullScreen="(val) => (disableFullScreen = val)"
      v-if="
        (watchingStream && isDesktop()) ||
        (!showHalfProfile &&
          !showViewerCard &&
          isMobile() &&
          watchingStream &&
          !isFullScreen &&
          !showTopGifters &&
          !showAcceptPremium)
      "
    />

    <div
      class="live-stream-finished live-stream-screen"
      :style="{
        backgroundImage: 'url(' + profileImg + ')',
      }"
      v-if="streamFinished && !showAcceptPremium"
    >
      <v-btn icon class="close-stream" @click="smartCloseToBack()">
        <i class="fas fa-times"></i>
      </v-btn>
      <div class="overlay" v-if="this.selectedUser">
        <h2>{{ $t("discover_page.stream_end") }}</h2>
        <router-link
          v-if="selectedUser"
          :to="generateBase64Url('/profile/', selectedUser.user_id)"
        >
          <user-image-circle :user="selectedUser" :size="'150px'" />
        </router-link>
        <div class="button-container">
          <v-btn
            v-if="selectedUser && selectedUser.is_followed === false"
            class="follow-button"
            :loading="isFollowLoading"
            @click="clickFollowUser()"
          >
            <img
              class="follow-svg"
              :src="require(`@/assets/img/follow-icon_${brandName}.svg`)"
              alt="follow svg"
            />
            <span>{{ $t("half_profile_page.follow") }}</span>
          </v-btn>
          <v-btn
            v-if="selectedUser && selectedUser.is_followed === true"
            class="unfollow-button"
            :loading="isFollowLoading"
            @click="clickUnfollowUser()"
          >
            <img
              class="unfollow-svg"
              src="@/assets/img/unfollow.svg"
              alt="follow svg"
            />
            <span>{{ $t("half_profile_page.unfollow") }}</span>
          </v-btn>
          <a
            href="javascript:;"
            @click="checkLoginSendMessageClick(selectedUser.user_id)"
            class="follow-button"
          >
            {{ $t("discover_page.message_button") }}</a
          >
        </div>
      </div>
    </div>

    <div
      class="live-stream-stopped live-stream-screen"
      :style="{
        backgroundImage: 'url(' + profileImg + ')',
      }"
      v-if="
        streamStopped &&
        !streamFinished &&
        !showAcceptPremium &&
        !livestreamLoading
      "
    >
      <v-btn icon class="close-stream" @click="smartCloseToBack()">
        <i class="fas fa-times"></i>
      </v-btn>
      <div class="overlay">
        <h2>{{ $t("livestream.stopped") }}</h2>
      </div>
    </div>

    <div
      v-if="livestreamLoading && !streamStopped && !streamFinished"
      class="live-stream-loading live-stream-screen"
    >
      <div class="overlay">
        <i class="fas fa-circle-notch fa-spin"></i>
      </div>
    </div>

    <div
      class="live-stream-swipe-loading live-stream-screen"
      :class="{ 'slide-in': nextStreamAnimation }"
    >
      <div class="overlay">
        <i class="fas fa-circle-notch fa-spin"></i>
      </div>
    </div>

    <div class="preload-images" v-if="settings">
      <img
        v-for="gift in settings.gift"
        :src="gift.image"
        :key="gift.gift_id"
      />
      <img src="@/assets/img/gold_shine.png" alt="gold shine" />
      <img src="@/assets/img/silver_shine.png" alt="silver shine" />
      <img src="@/assets/img/bronze_shine.png" alt="bronze shine" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "LiveStream.scss";
</style>
<script>
import { mapState, mapActions } from "vuex";
import DownloadAppDialog from "@/components/DownloadAppDialog/DownloadAppDialog.vue";
import SendGift from "@/components/SendGift/SendGift.vue";
import AcceptPremium from "@/components/LiveStream/AcceptPremium/AcceptPremium.vue";
import { showThousund, returnVipString } from "@/helper/index.js";
import HalfProfile from "@/components/Profiles/HalfProfile/HalfProfile.vue";
import Messages from "@/components/Chat/Messages.vue";
import VipMessage from "@/components/Chat/VipMessage.vue";
import DefaultProfileImage from "@/assets/img/profile-image-default.svg";
import UserImageCircle from "@/components/Shared/UserImageCircle/UserImageCircle.vue";
import { FollowPageEnums } from "@/helper/enums.js";
import { generateBase64Url } from "@/helper/index.js";
import { decrypt, encrypt } from "@/helper/index.js";
import GuestCardMobile from "@/components/LiveStream/Cards/GuestCardMobile.vue";
import ViewerCard from "@/components/LiveStream/Cards/Viewers/ViewerCard.vue";
import TopGifters from "@/components/TopGifters/TopGifters.vue";
import LevelBadge from "@/components/Shared/LevelBadge/LevelBadge.vue";
import { openGiftsEvent } from "@/helper/marketingevents.js";
export default {
  name: "LiveStream",
  data() {
    return {
      DefaultProfileImage: DefaultProfileImage,
      isFullScreen: false,
      nextStreamAnimation: false,
      streamInfo: null,
      FollowPageEnums,
      disableFullScreen: false,
    };
  },
  computed: {
    ...mapState({
      history: (state) => state.history,
      activeStream: (state) => state.live.activeStream,
      watchingStream: (state) => state.live.watchingStream,
      streamFinished: (state) => state.live.streamFinished,
      streamStopped: (state) => state.live.streamStopped,
      showAcceptPremium: (state) => state.live.showAcceptPremium,
      messages: (state) => state.live.messages,
      topGifters: (state) => state.live.topGifters,
      selectedUser: (state) => state.live.selectedUser,
      userVipBorderClass: (state) => state.live.userVipBorderClass,
      settings: (state) => state.client.settings,
      guestStopped: (state) => state.live.guestStopped,
      diamondCount: (state) => state.live.diamondCount,
      viewerCount: (state) => state.live.viewerCount,
      showViewerCard: (state) => state.live.showViewerCard,
      showGiftClass: (state) => state.live.showGiftClass,
      showVipClass: (state) => state.live.showVipClass,
      showGuest: (state) => state.live.showGuest,
      activeVip: (state) => state.live.activeVip,
      activeGift: (state) => state.live.activeGift,
      activeGuest: (state) => state.live.activeGuest,
      showSendGift: (state) => state.live.showSendGift,
      showHalfProfile: (state) => state.client.showHalfProfile,
      showFullProfile: (state) => state.client.showFullProfile,
      livestreamType: (state) => state.live.livestreamType,
      livestreamLoading: (state) => state.live.livestreamLoading,
      isFollowLoading: (state) => state.client.isFollowLoading,
      discoverStreams: (state) => state.live.discoverStreams,
      webSocket: (state) => state.live.webSocket,
      showTopGifters: (state) => state.live.showTopGifters,
      brandName: (state) => state.brandName,
      activeVipMessage: (state) => state.live.activeVipMessage,
    }),
    profileImg() {
      return (
        (this.selectedUser?.profile_images?.length > 0 &&
          this.selectedUser.profile_images[0].thumbnail_url) ||
        DefaultProfileImage
      );
    },
  },
  components: {
    SendGift,
    HalfProfile,
    Messages,
    VipMessage,
    AcceptPremium,
    UserImageCircle,
    GuestCardMobile,
    ViewerCard,
    TopGifters,
    LevelBadge,
  },
  created() {
    this.connectChannel();
    // this.initializeAgoraClients();
    if (this.isDesktop()) {
      this.setShowSendGift(true);
    }
    this.setShowTopGifters(false);
  },
  destroyed() {
    this.$store.dispatch("live/leaveStream");
    window.removeEventListener("resize", this.handleResizeWindow);
  },
  watch: {
    webSocket() {
      this.connectChannel();
    },
    $route(to, from) {
      this.$store.dispatch("live/leaveStream");
      this.connectChannel();
    },
  },
  methods: {
    ...mapActions(["setHistory"]),
    ...mapActions("live", [
      "initializeAgoraClients",
      "giftInterval",
      "closeGuest",
      "closeStream",
      "leaveStream",
      "getDiscover",
      "toggleViewerCard",
      "setShowTopGifters",
      "closeGift",
      "setShowSendGift",
      "setLiveStreamSource",
    ]),
    ...mapActions("client", [
      "logout",
      "setShowHalfProfile",
      "followUser",
      "unfollowUser",
      "getUserMessageInfo",
      "checkLoginSendMessageClick",
      "openHalfProfile",
    ]),
    showThousund: showThousund,
    showDownloadApp() {
      this.$buefy.modal.open({
        parent: this,
        component: DownloadAppDialog,
        "has-modal-card": true,
        trapFocus: true,
      });
    },
    smartCloseToBack() {
      this.closeStream(true);
      let found = false;
      for (let i = this.history.length - 1; i >= 0; i--) {
        if (this.history[i].indexOf("/stream/") === -1) {
          found = true;
          const back = i + 1 - this.history.length;
          this.setHistory([...this.history.slice(0, i)]);
          this.$router.go(back);
          break;
        }
      }
      if (!found) {
        this.$router.push("/");
      }
    },
    generateBase64Url,
    connectChannel() {
      this.streamInfo = this.$route.params.streamInfo;
      this.streamInfo = decrypt(this.streamInfo);
      let userId = this.streamInfo.userId;
      let livestreamId = this.streamInfo.streamId;
      // Eğer socket varsa ve ready ise direk connectChannel çağırılır
      if (
        this.webSocket &&
        this.webSocket.readyState &&
        this.webSocket.readyState === 1
      ) {
        this.$store.dispatch("live/connectChannel", {
          livestreamId,
          userId,
        });
      } else if (this.webSocket) {
        // Eğer socket var ama ready değil ise ready olması beklenir.
        this.webSocket.onopen = () => {
          this.$store.dispatch("live/connectChannel", {
            livestreamId,
            userId,
          });
        };
      }
    },
    openGift() {
      if (this.streamInfo && this.showSendGift == false) {
        // Marketing Event
        openGiftsEvent(this.streamInfo.streamId, this.streamInfo.userId);
      }
      this.setShowSendGift(true);
    },
    clickFollowUser() {
      this.followUser({
        user_id: this.selectedUser.user_id,
        page_enum: FollowPageEnums.OTHER_PROFILE,
      });
    },
    clickUnfollowUser() {
      this.unfollowUser({
        user_id: this.selectedUser.user_id,
        page_enum: FollowPageEnums.OTHER_PROFILE,
      });
    },
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    toggleFullScreen(e) {
      if (e.touchstartY == e.touchendY && !this.disableFullScreen) {
        this.isFullScreen = !this.isFullScreen;
      }
    },
    nextStream() {
      if (this.discoverStreams.length > 1) {
        this.setLiveStreamSource("swipe");
        this.isFullScreen = false;
        this.nextStreamAnimation = true;

        let streamIndex = this.discoverStreams.findIndex(
          (stream) =>
            stream.stream_details.livestream_id == this.streamInfo.streamId
        );
        let nextIndex = streamIndex + 1;
        let encryptedId = null;
        if (nextIndex >= 0 && nextIndex <= this.discoverStreams.length - 1) {
          let cryptObj = {
            streamId:
              this.discoverStreams[nextIndex].stream_details.livestream_id,
            userId: this.discoverStreams[nextIndex].user.user_id,
          };
          encryptedId = encrypt(cryptObj);
        } else if (nextIndex === this.discoverStreams.length) {
          let cryptObj = {
            streamId: this.discoverStreams[0].stream_details.livestream_id,
            userId: this.discoverStreams[0].user.user_id,
          };
          encryptedId = encrypt(cryptObj);
        }

        setTimeout(() => {
          this.nextStreamAnimation = false;
          this.$router.push("/stream/" + encryptedId);
        }, 1400);
      }
    },
    openTopGifters() {
      if (this.showTopGifters) {
        this.setShowTopGifters(false);
      } else {
        if (this.showHalfProfile) {
          this.setShowHalfProfile(false);
        }
        if (this.showViewerCard) {
          this.toggleViewerCard();
        }
        this.setShowTopGifters({
          payload: true,
          livestream_id: this.selectedUser.livestream_id,
        });
      }
    },
    changeCardSettings() {
      if (this.showHalfProfile) {
        this.setShowHalfProfile(false);
      }
      if (this.showTopGifters) {
        this.setShowTopGifters(false);
      }
      this.toggleViewerCard();
    },

    returnVipString,
  },
  beforeDestroy() {
    this.setLiveStreamSource("");
  },
};
</script>
