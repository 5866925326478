<template>
  <div>
    <v-container
      v-if="
        !$route.path.includes('/leaderboard') &&
        !$route.path.includes('/notifications') &&
        !$route.path.includes('/my-level')
      "
      class="discover-container"
    >
      <v-row
        class="discover-container-row"
        v-if="!$vuetify.breakpoint.smAndDown"
      >
        <v-col>
          <h1 class="suggested-live-stream-header">
            <img
              alt="live tv icon"
              v-bind:src="require(`@/assets/img/live-tv-icon_${brandName}.svg`)"
            />
            {{ $t("discover_page.suggested_live_stream") }}
          </h1>
        </v-col>
      </v-row>
      <v-col class="discover-container-col">
        <!-- <discover-filter /> -->
        <!-- <country-filter-modal /> -->
        <v-row class="discover-container-stream-box">
          <v-card
            class="special-square-column discover-card"
            v-for="streamItem in discoverStreams"
            :key="
              streamItem &&
              streamItem.stream_details &&
              streamItem.stream_details.livestream_id
            "
          >
            <stream-card
              @clicked="setLiveStreamSource('discover')"
              :streamItem="streamItem"
            />
          </v-card>
        </v-row>
      </v-col>
      <infinite-loading @infinite="infiniteLoader" spinner="bubbles">
        <div slot="no-more" class="infinite-no-more">
          {{ $t("discover_page.infinite_no_more") }}
        </div>
        <div slot="no-results"></div>
      </infinite-loading>
    </v-container>
    <v-container v-else class="discover-container-else">
      <v-row>
        <v-col lg="12" md="12" sm="12" xs="12">
          <v-row>
            <div
              class="special-square-column"
              v-for="streamItem in discoverStreams"
              :key="
                streamItem &&
                streamItem.stream_details &&
                streamItem.stream_details.livestream_id
              "
            >
              <stream-card
                @clicked="setLiveStreamSource('leaderboard_feed')"
                :streamItem="streamItem"
              />
            </div>
          </v-row>
        </v-col>
      </v-row>
      <infinite-loading @infinite="infiniteLoader" spinner="bubbles">
        <div slot="no-more" class="infinite-no-more">
          {{ $t("discover_page.infinite_no_more") }}
        </div>
        <div slot="no-results"></div>
      </infinite-loading>
    </v-container>
  </div>
</template>
<style lang="scss" scoped>
@import "Discover.scss";
</style>
<script>
import { mapState, mapActions } from "vuex";
import InfiniteLoading from "vue-infinite-loading";

import StreamCard from "../Streams/StreamCard/StreamCard.vue";
// import DiscoverFilter from "./DiscoverFilter.vue";
// import CountryFilterModal from "./CountryFilterModal.vue";

export default {
  name: "Discover",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      discoverStreams: (state) => state.live.discoverStreams,
      settings: (state) => state.client.settings,
      nextItem: (state) => state.live.nextItem,
      registered: (state) => state.client.registered,
      loaderState: (state) => state.live.loaderState,
      brandName: (state) => state.brandName,
    }),
  },
  components: {
    InfiniteLoading,
    StreamCard,
    // DiscoverFilter,
    // CountryFilterModal,
  },
  created() {
    // this.initializeAgoraClients();
    this.setNextId(null);
  },
  mounted() {
    this.resetDiscover();
  },
  methods: {
    ...mapActions("live", [
      "initializeAgoraClients",
      "getDiscover",
      "resetDiscover",
      "setLoaderState",
      "setNextId",
      "setLiveStreamSource",
    ]),
    ...mapActions("client", ["logout", "getSettings"]),
    infiniteLoader($state) {
      if (!this.loaderState) this.setLoaderState($state);
      if (this.registered) this.getDiscover($state);
    },
  },
};
</script>
