import { render, staticRenderFns } from "./Checkout.vue?vue&type=template&id=5121a3a8"
import script from "./Checkout.vue?vue&type=script&lang=js"
export * from "./Checkout.vue?vue&type=script&lang=js"
import style0 from "./Checkout.vue?vue&type=style&index=0&id=5121a3a8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VOverlay,VProgressCircular})
