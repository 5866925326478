/* NOTE:
 * We need to send an event each time user enters a livestream (you can place this where livestream/enter endpoint is called.)
 */
export function enterStreamEvent(livestreamId) {
  if (window.fbq) {
    window.fbq("track", "ViewContent", {
      content_ids: [`livestream_${livestreamId}`],
      content_name: `livestream ${livestreamId}`,
    });
  }
  if (window.gtag) {
    window.gtag("event", "view_item", {
      items: [
        {
          item_id: `livestream_${livestreamId}`,
          item_name: `livestream ${livestreamId}`,
        },
      ],
    });
  }
}
/* NOTE:
 * We need to send one event user enters his 4th livestream (you can place this where livestream/enter endpoint is called.).
 * For this, we need make a counter, increment it every time user enters a livestream an trigger the event when it reaches 4.
 * It is importart that this event is published only at the 4th stream, only once per browser.
 * (We need to persist this counter to storage.)
 */
export function enter4thStreamEvent(livestreamId) {
  if (window.fbq) {
    window.fbq("track", "Search");
  }
  if (window.gtag) {
    window.gtag("event", "select_content", {
      item_id: `livestream_${livestreamId}`,
    });
  }
}

/* NOTE:
 * We need to send one event each time a user enters his payment method information to stripe.
 */
export function addPaymentInfoEvent(productId, amount, currencyCode, price) {
  if (window.fbq) {
    window.fbq("track", "AddPaymentInfo", {
      content_ids: [`coin_${productId}`],
      currency: currencyCode,
      value: price,
    });
  }
  if (window.gtag) {
    window.gtag("event", "add_payment_info", {
      items: [
        {
          item_id: `coin_${productId}`,
          item_name: `Coin ${amount}`,
          currency: currencyCode,
          price: price,
        },
      ],
      currency: currencyCode,
      value: price,
    });
  }
}

/* NOTE:
 * We need to send an event each time user opens the coin purchase page (page where we list all the coin packages).
 * Event should be triggered even if the page opens from a coin error automatically.
 *^If google does not accept the event this way (without currency and value), put “USD” to currency and 0.0 to value.-
 */
export function openCoinPurchasePageEvent() {
  if (window.fbq) {
    window.fbq("track", "AddToCart", {
      content_name: "Coin",
    });
  }

  if (window.gtag) {
    window.gtag("event", "add_to_cart", {
      items: [
        {
          item_id: "coins_page",
          item_name: "Coin",
        },
      ],
    });
  }
}

/* NOTE:
 * We need to send an event each time user selects a coin product from coin purchase page (page where we list all the coin packages).
 * Event should be triggered when user is forwarded to purchase.
 */
export function openCoinPurchaseEvent(productId, amount, currencyCode, price) {
  if (window.fbq) {
    window.fbq("track", "InitiateCheckout", {
      content_ids: [`coin_${productId}`],
      currency: currencyCode,
      value: price,
    });
  }
  if (window.gtag) {
    window.gtag("event", "begin_checkout", {
      items: [
        {
          item_id: `coin_${productId}`,
          item_name: `Coin ${amount}`,
          currency: currencyCode,
          price: price,
        },
      ],
      currency: currencyCode,
      value: price,
    });
  }
}

/* Note:
 * We need to send an event each time user opens the gifts in a livestream,
 * this should sent only when user voluntarily presses the gift button in the ui and open the gifts.
 */
export function openGiftsEvent(livestreamId, userId) {
  if (window.fbq) {
    window.fbq("track", "OPEN_GIFTS", {
      livestream_id: [`livestream_${livestreamId}`],
      user_id: `user_${userId}`,
    });
  }
  if (window.gtag) {
    window.gtag("event", "OPEN_GIFTS", {
      livestream_id: [`livestream_${livestreamId}`],
      user_id: `user_${userId}`,
    });
  }
}

/* Note:
 * We need to send an event each time user is directed to signup page.
 */
export function signupViewEvent(deviceId, flowId) {
  if (window.gtag) {
    window.gtag("event", "SIGNUP_FLOW", {
      stage: "view",
      device_guid: deviceId,
      flow_id: flowId,
    });
  }
}

/* Note:
 * We need to send an event each time user selects a login method.
 */
export function signupAttemptEvent(deviceId, flowId, method) {
  if (window.gtag) {
    window.gtag("event", "SIGNUP_FLOW", {
      stage: "attempt",
      method: method,
      device_guid: deviceId,
      flow_id: flowId,
    });
  }
}

/* Note:
 * We need to send an event each time user successfully completes the signup and creates an account (receives auth token).
 */
export function signupSuccessEvent(deviceId, flowId, userId, method) {
  if (window.gtag) {
    window.gtag("event", "SIGNUP_FLOW", {
      stage: "success",
      method: method,
      device_guid: deviceId,
      user_id: userId,
      flow_id: flowId,
    });
  }
}

/* Note:
 * We need to send an event each time user selects a coin product for purchase
 */
export function selectProductEvent(
  deviceId,
  flowId,
  userId,
  productId,
  coins,
  price,
  currency
) {
  if (window.gtag) {
    window.gtag("event", "PAYMENT_FLOW", {
      stage: "select_product",
      device_guid: deviceId,
      user_id: userId,
      flow_id: flowId,
      product_id: productId,
      coins: coins,
      price: price,
      currency: currency,
    });
  }
}

/* Note:
 * We need to send an event each time user starts a card creation,
 * either automatically for the first card, or manually for later cards.
 */
export function startCardCreationEvent(
  userId,
  deviceId,
  productId,
  coins,
  price,
  currency,
  flowId
) {
  if (window.gtag) {
    window.gtag("event", "PAYMENT_FLOW", {
      stage: "start_card_creation",
      device_guid: deviceId,
      user_id: userId,
      flow_id: flowId,
      product_id: productId,
      coins: coins,
      price: price,
      currency: currency,
    });
  }
}

/* Note:
 * We need to send an event each time user successfully creates a card
 * (either success for the first purchase, or manual additions for later cards)
 */
export function cardCreationSuccessEvent(
  userId,
  deviceId,
  productId,
  coins,
  price,
  currency,
  flowId
) {
  if (window.gtag) {
    window.gtag("event", "PAYMENT_FLOW", {
      stage: "card_creation_success",
      device_guid: deviceId,
      user_id: userId,
      flow_id: flowId,
      product_id: productId,
      coins: coins,
      price: price,
      currency: currency,
    });
  }
}

/* Note:
 * We need to send an event each time user tries to complete a payment
 * (either with a new card, or an existing card)
 */
export function paymentAttemptEvent(
  userId,
  deviceId,
  productId,
  coins,
  price,
  currency,
  flowId,
  isNew
) {
  if (window.gtag) {
    window.gtag("event", "PAYMENT_FLOW", {
      stage: "payment_attempt",
      device_guid: deviceId,
      user_id: userId,
      flow_id: flowId,
      product_id: productId,
      coins: coins,
      price: price,
      currency: currency,
      is_new_card: isNew,
    });
  }
}

/* Note:
 * We need to send an event each time user successfully creates finishes a payment.
 */
export function paymentSuccessEvent(
  userId,
  deviceId,
  productId,
  coins,
  price,
  currency,
  flowId,
  isNew
) {
  if (window.gtag) {
    window.gtag("event", "PAYMENT_FLOW", {
      stage: "payment_success",
      device_guid: deviceId,
      user_id: userId,
      flow_id: flowId,
      product_id: productId,
      coins: coins,
      price: price,
      currency: currency,
      is_new_card: isNew,
    });
  }
}

/* Note: LIVESTREAM_VIEW_FLOW
 * There are missing sources parameters for furture stories.
 * 'Sources' is a paremeter for LIVESTREAM_VIEW_FLOW events, 
 * that describe previous page before user joined to the stream. Example:(discover, following_feed, ect..)
 * '+' means added.
 * '-' means missing
 - livestream_finished_screen
 - conversation
 - push_notification
 + discover
 + following_feed
 + leaderboard_feed
 + notification_live_button
 + profile_live_button
 + leaderboard_diamonds_live_button
 + leaderboard_gift_senders_live_button
 + swipe
/*

/* Note:
 * We need to send an event each time user clicks on a livestream
 * from various places in the app. (also from push notification)
 */
export function liveStreamSelectEvent(
  deviceId,
  userId,
  livestreamId,
  ownerId,
  source,
  flowId
) {
  if (window.gtag) {
    window.gtag("event", "LIVESTREAM_VIEW_FLOW", {
      stage: "select",
      device_guid: deviceId,
      user_id: userId,
      livestream_id: livestreamId,
      owner_id: ownerId,
      source: source,
      flow_id: flowId,
    });
  }
}

/* Note:
 * We need to send an event each time user opens a livestream page
 * (do not confuse with Livestream Enter, if livestream is private and user is asked for a gift this event should still be sent)
 */
export function liveStreamViewPageEvent(
  deviceId,
  userId,
  livestreamId,
  ownerId,
  liveStreamType,
  entryGiftId,
  entryGiftCost,
  source,
  flowId
) {
  if (window.gtag) {
    window.gtag("event", "LIVESTREAM_VIEW_FLOW", {
      stage: "view_page",
      device_guid: deviceId,
      user_id: userId,
      livestream_id: livestreamId,
      owner_id: ownerId,
      livestream_type: liveStreamType,
      entry_gift_id: entryGiftId,
      entry_gift_cost: entryGiftCost,
      source: source,
      flow_id: flowId,
    });
  }
}

/* Note:
 * We need to send an event each time enters a livestream
 * (do not confuse with Livestream View Page, if livestream is private and user is
 * asked for a gift this event should not be sent)
 */
export function liveStreamEnterEvent(
  deviceId,
  userId,
  ownerId,
  livestreamType,
  source,
  flowId
) {
  if (window.gtag) {
    window.gtag("event", "LIVESTREAM_VIEW_FLOW", {
      stage: "enter",
      device_guid: deviceId,
      user_id: userId,
      owner_id: ownerId,
      livestream_type: livestreamType,
      source: source,
      flow_id: flowId,
    });
  }
}

/* Note:
 * We need to send an event each time user clicks on accept private livestream.
 * The result is not important, you should send this event when user clicks.
 */
export function liveStreamAcceptClickEvent(
  deviceId,
  userId,
  livestreamId,
  ownerId,
  entryGiftId,
  entryGiftCost,
  source,
  flowId
) {
  if (window.gtag) {
    window.gtag("event", "LIVESTREAM_VIEW_FLOW", {
      stage: "accept_click",
      device_guid: deviceId,
      user_id: userId,
      livestream_id: livestreamId,
      owner_id: ownerId,
      entry_gift_id: entryGiftId,
      entry_gift_cost: entryGiftCost,
      source: source,
      flow_id: flowId,
    });
  }
}

/* Note:
 * We need to send an event each time user accept private livestream is successful.
 */
export function liveStreamAcceptSuccessEvent(
  deviceId,
  userId,
  livestreamId,
  ownerId,
  entryGiftId,
  entryGiftCost,
  source,
  flowId
) {
  if (window.gtag) {
    window.gtag("event", "LIVESTREAM_VIEW_FLOW", {
      stage: "accept_success",
      device_guid: deviceId,
      user_id: userId,
      livestream_id: livestreamId,
      owner_id: ownerId,
      entry_gift_id: entryGiftId,
      entry_gift_cost: entryGiftCost,
      source: source,
      flow_id: flowId,
    });
  }
}

/* Note:
 * We need to send an event each time user accept private livestream is unsuccessful.
 */
export function liveStreamAcceptErrorEvent(
  deviceId,
  userId,
  livestreamId,
  ownerId,
  entryGiftId,
  entryGiftCost,
  source,
  flowId,
  errorCode
) {
  if (window.gtag) {
    window.gtag("event", "LIVESTREAM_VIEW_FLOW", {
      stage: "accept_error",
      device_guid: deviceId,
      user_id: userId,
      livestream_id: livestreamId,
      owner_id: ownerId,
      entry_gift_id: entryGiftId,
      entry_gift_cost: entryGiftCost,
      source: source,
      flow_id: flowId,
      error_code: errorCode,
    });
  }
}

/* Note:
 * We need to send an event each time user opens up a coin purchase page
 * (including automatic triggers, and overlays in a livestream).
 */
export function viewCoinPurchasePageEvent(
  userId,
  deviceId,
  pageName,
  automatic = 0
) {
  if (window.gtag) {
    window.gtag("event", "MISC_EVENT", {
      event_name: "view_coin_purchase_page",
      device_guid: deviceId,
      user_id: userId,
      page_name: pageName,
      automatic: automatic,
    });
  }
}
