var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(_vm.purchaseSettings)?_c('div',{staticClass:"top-container"},[_c('div',{staticClass:"top-left"},[_c('div',{staticClass:"back-button",on:{"click":_vm.closeHybridPage}},[_c('img',{attrs:{"src":require("@/assets/img/back-button_superlive.svg"),"alt":"back"}})]),_c('div',{class:["header-text", { 'header-text-ar': _vm.language === "ar" }]},[_vm._v(" "+_vm._s(_vm.$t("labels.do_payment"))+" ")])]),_c('div',{staticClass:"top-right"},[(_vm.purchaseSettings.is_payment_country_change_active)?_c('div',{staticClass:"country-select",on:{"click":_vm.toggleSelectCountry}},[_c('div',{staticClass:"img-container"},[_c('img',{attrs:{"src":require(("@/assets/img/flags/" + (_vm.countryCodeToFlagName(
                _vm.purchaseSettings.country
              )) + ".png")),"alt":"country-flag"}})]),_c('i',{staticClass:"fas fa-chevron-right"})]):_vm._e(),_c('div',{staticClass:"coins"},[_c('div',{staticClass:"coin-icon"},[_c('img',{attrs:{"src":require("@/assets/img/coin.svg"),"alt":"coin"}})]),_c('div',{staticClass:"coin-amount"},[_vm._v(" "+_vm._s(_vm.numberWithCommas(_vm.userCoinInfo.coins))+" ")])])])]):_vm._e(),(_vm.promotionBanners.length > 0)?_c('div',{staticClass:"banners"},[_c('v-card',{staticClass:"rounded-card"},[_c('v-carousel',{attrs:{"cycle":"","height":"120","hide-delimiter-background":"","interval":"4000"}},_vm._l((_vm.promotionBanners),function(promotionBanner,index){return _c('v-carousel-item',{key:("promotion-banner-" + index),attrs:{"src":promotionBanner.image_url},on:{"click":function($event){return _vm.goToPromotionBanner(index)}}})}),1)],1)],1):_vm._e(),(_vm.products != null)?_c('div',{staticClass:"products"},_vm._l((_vm.products),function(product,index){return _c('CoinProduct',{key:index,attrs:{"product":product},on:{"productSelected":function($event){return _vm.selectProduct(product)}}})}),1):_vm._e(),(
      _vm.paymentCountries && _vm.purchaseSettings.is_payment_country_change_active
    )?_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition"},model:{value:(_vm.showSelectCountry),callback:function ($$v) {_vm.showSelectCountry=$$v},expression:"showSelectCountry"}},[_c('v-card',{staticClass:"select-country-card"},[_c('div',{staticClass:"select-country-container"},[_c('div',{staticClass:"country-container-header my-2"},[_c('span',{staticClass:"header-text"},[_vm._v(" "+_vm._s(_vm.$t("payermax.select_country"))+" ")]),_c('button',{staticClass:"close-button",on:{"click":function($event){return _vm.toggleSelectCountry()}}},[_c('img',{attrs:{"width":"15","height":"15","src":require("@/assets/img/cancel-gray.svg"),"alt":"cancel"}})])]),_c('div',{staticClass:"search-input-container"},[_c('img',{attrs:{"src":require("@/assets/img/search-icon.svg"),"alt":"search-icon"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchCountryText),expression:"searchCountryText"}],staticClass:"search-input ml-2",attrs:{"type":"text","placeholder":_vm.$t('payermax.search_country'),"autocorrect":"off","autocomplete":"off"},domProps:{"value":(_vm.searchCountryText)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchCountryText=$event.target.value}}})]),_c('div',{staticClass:"mt-2 country-item-container"},_vm._l((_vm.filteredCountries),function(country,countryIndex){return _c('div',{key:countryIndex,staticClass:"country-item-card"},[_c('div',{class:[
                "country-item",
                {
                  'country-item-selected':
                    country &&
                    _vm.purchaseSettings &&
                    _vm.purchaseSettings.country === country.iso_code,
                } ],on:{"click":function($event){return _vm.selectCountry(country.iso_code)}}},[_c('img',{staticClass:"country-flag",attrs:{"src":require(("@/assets/img/flags/" + (_vm.countryCodeToFlagName(
                    country.iso_code
                  )) + ".png")),"alt":"country-flag"}}),_c('span',{staticClass:"country-name"},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetters(country.name))+" ")])])])}),0)])])],1):_vm._e(),(_vm.purchaseSettings)?_c('PaymentMethodBottomSheet',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedProduct !== null),expression:"selectedProduct !== null"}],ref:"paymentMethodBottomSheet",attrs:{"product":_vm.selectedProduct,"selected-offer-index":1,"privacy-policy-link":_vm.purchaseSettings.urls.privacy_policy,"terms-of-service-link":_vm.purchaseSettings.urls.terms_of_service,"support-user-id":_vm.purchaseSettings.support_user_id}}):_vm._e(),_c('v-dialog',{attrs:{"value":_vm.showPromotionExpiredPopUp,"transition":false,"animation":false}},[_c('v-card',{staticClass:"promotion-expired-wrapper"},[_c('div',{staticClass:"promotion-expired-container"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/img/ic-offer.svg"),"alt":_vm.$t('payermax.promotion_outdated_offer_popup_title')}})]),_c('div',{staticClass:"promotion-header"},[_vm._v(" "+_vm._s(_vm.$t("payermax.promotion_outdated_offer_popup_title"))+" ")]),_c('div',{staticClass:"promotion-info"},[_vm._v(" "+_vm._s(_vm.$t("payermax.promotion_outdated_offer_popup_description"))+" ")]),_c('button',{staticClass:"promotion-button",on:{"click":_vm.closePromotionExpired}},[_vm._v(" "+_vm._s(_vm.$t("labels.ok"))+" ")])])])],1),_c('v-overlay',{attrs:{"color":"#fff","value":_vm.showFullPageLoading}},[_c('v-progress-circular',{attrs:{"color":"#333","size":50,"indeterminate":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }