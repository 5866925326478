<template>
  <v-container class="remove-profile-img-container">
    <v-container
      class="remove-profile-bg-layer"
      @click="setShowRemoveImgModal(false)"
    ></v-container>
    <v-container class="remove-profile-img-wrapper">
      <v-container class="remove-title">
        <span>{{ $t("remove_profile_img_page.remove_title") }}</span>
      </v-container>
      <v-container class="remove-buttons-container">
        <v-btn
          rounded
          class="remove-button mb-3"
          @click="removeUploadedImage(uploadSelectedImgId)"
        >
          {{ $t("labels.remove") }}
        </v-btn>
        <v-btn
          rounded
          class="remove-cancel mb-3"
          @click="setShowRemoveImgModal(false)"
        >
          {{ $t("labels.cancel") }}
        </v-btn>
      </v-container>
    </v-container>
  </v-container>
</template>

<style lang="scss" scoped>
@import "RemoveImg.scss";
</style>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "RemoveImg",
  data() {
    return {};
  },

  computed: {
    ...mapState({
      showRemoveImgModal: (state) => state.client.showRemoveImgModal,
      uploadImageIds: (state) => state.client.uploadImageIds,
      uploadSelectedImgId: (state) => state.client.uploadSelectedImgId,
    }),
  },
  methods: {
    ...mapActions("client", [
      "setShowRemoveImgModal",
      "uploadProfileImages",
      "removeUploadedImage",
    ]),
  },
};
</script>
