<template>
  <v-container class="mylevel">
    <v-col cols="12">
      <div class="mylevel-header">{{ $t("mylevel.my_level") }}</div>
    </v-col>

    <v-col cols="12">
      <div class="mylevel-profile">
        <div class="user-info">
          {{ ownProfile.user.name }}
          <UserImageCircle :user="ownProfile && ownProfile.user" size="100px" />
        </div>
      </div>
    </v-col>

    <v-col cols="12">
      <div class="mylevel-progress">
        <div class="progress-container">
          <div class="progress-label">%{{ percentage }}</div>
          <div
            class="progress"
            v-bind:style="{
              width: percentage + '%',
            }"
          ></div>
        </div>
        <div class="progress-info">
          <div>Lv{{ ownProfile.user.leveling_progress.current_level }}</div>
          <div>
            {{ ownProfile.user.leveling_progress.current_exp }}/{{
              ownProfile.user.leveling_progress.target_exp
            }}
          </div>
        </div>
      </div>
    </v-col>

    <v-col cols="12">
      <div class="mylevel-info">
        <div class="info-header">
          {{ $t("mylevel.level_badges") }}
          <level-badge
            :user="{ level: 21, vip_state: 0 }"
            :fontSize="'14'"
          ></level-badge>
          <level-badge
            :user="{ level: 45, vip_state: 1 }"
            :fontSize="'14'"
          ></level-badge>
          <level-badge
            :user="{ level: 12, vip_state: 2 }"
            :fontSize="'14'"
          ></level-badge>
          <level-badge
            :user="{ level: 7, vip_state: 3 }"
            :fontSize="'14'"
          ></level-badge>
        </div>
        <p>
          {{ $t("mylevel.level_badges_info") }}
        </p>
        <div class="divider-header">
          {{ $t("mylevel.how_to_level_up") }}
        </div>
        <div class="level-description">
          <div class="item">
            <div class="item-icon">
              <img src="@/assets/img/level-coin.svg" />
            </div>
            <div class="info-header">
              {{ $t("mylevel.level_up_coin_header") }}
            </div>
            <div>
              {{ $t("mylevel.level_up_coin_info") }}
            </div>
          </div>
          <div class="item">
            <div class="item-icon">
              <img src="@/assets/img/level-gift.svg" />
            </div>
            <div class="info-header">
              {{ $t("mylevel.level_up_gift_header") }}
            </div>
            <div>
              {{ $t("mylevel.level_up_gift_info") }}
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </v-container>
</template>

<style lang="scss" scoped>
@import "MyLevel.scss";
</style>

<script>
import { mapState, mapActions } from "vuex";
import UserImageCircle from "@/components/Shared/UserImageCircle/UserImageCircle.vue";
import LevelBadge from "@/components/Shared/LevelBadge/LevelBadge.vue";

export default {
  name: "MyLevel",
  components: {
    UserImageCircle,
    LevelBadge,
  },
  data() {
    return {
      percentage: 0,
    };
  },
  computed: {
    ...mapState({
      brandName: (state) => state.brandName,
      ownProfile: (state) => state.client.ownProfile,
    }),
  },
  watch: {
    ownProfile() {
      if (this.ownProfile.user.leveling_progress.target_exp > 0) {
        this.percentage = (
          (this.ownProfile.user.leveling_progress.current_exp /
            this.ownProfile.user.leveling_progress.target_exp) *
          100
        ).toFixed(2);
      }
    },
  },
  methods: {
    ...mapActions("client", ["getNotifications"]),
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
