<template>
  <div>
    <div class="page-left-container">
      <DiscoverLeft />
    </div>
    <div class="page-right-profile-container">
      <full-profile />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "./ProfileView.scss";
@import "../Discover/DiscoverView.scss";
</style>
<script>
import DiscoverLeft from "@/components/Discover/DiscoverLeft.vue";
import FullProfile from "@/components/Profiles/FullProfile/FullProfile.vue";

export default {
  name: "ProfileView",
  components: {
    DiscoverLeft,
    FullProfile,
  },
};
</script>
