const logger = new (require("./logging").Logger)("storage");

const STORE_KEY_PREFIX = "slw:";

const STORE_KEYS = Object.freeze({
  RTC_ID: STORE_KEY_PREFIX + "rtc_id",
  AUTH_TOKEN: STORE_KEY_PREFIX + "auth_token",
  DEVICE_ID: STORE_KEY_PREFIX + "device_id",
  INSTALLATION_ID_KEY: STORE_KEY_PREFIX + "install_id",
  ADJUST_ADID: STORE_KEY_PREFIX + "adjust_adid",
  ADJUST_ATTRIBUTION_DATA: STORE_KEY_PREFIX + "adjust_attribution_data",
  ADJUST_WEB_UUID: STORE_KEY_PREFIX + "adjust_web_uuid",
  JOIN_STREAM_COUNT: STORE_KEY_PREFIX + "join_stream_count",
  // HYBRID_PAYMENT_FLOW_INFORMATION: STORE_KEY_PREFIX + "hybrid_payment_flow_information",
});
module.exports.STORE_KEYS = STORE_KEYS;

const dummyStoreMap = new Map();
const dummyStore = {
  getItem(key) {
    return dummyStoreMap.get(key);
  },
  setItem(key, value) {
    dummyStoreMap.set(key, value);
  },
  removeItem(key) {
    dummyStoreMap.delete(key);
  },
};

const TEST_KEY = "_test";

let store = window.localStorage;
try {
  store.setItem(TEST_KEY, 1);
  if (store.getItem(TEST_KEY) != 1) throw "store missing";
} catch (error) {
  logger.debugError("localStorage error", error);
  store = window.sessionStorage;
  try {
    store.setItem(TEST_KEY, 1);
    if (store.getItem(TEST_KEY) != 1) throw "store missing";
  } catch (error) {
    logger.debugError("sessionStorage error", error);
    store = dummyStore;
  }
}

module.exports.localStore = store;
