const { localStore, STORE_KEYS } = require("@/storage");
import { actions } from "./actions";
import currencyToSymbolMap from "currency-symbol-map/map";

// initial state ********************

const state = () => ({
  isHybridPage: false,
  isProd: false,
  axiosReady: false,
  showLoginModal: false,
  activeLoginComponent: "social-login",
  forterToken: "",
  ownProfile: {
    user: {
      coins: 0,
      leveling_progress: {
        current_exp: 0,
        current_level: 0,
        target_exp: 0,
      },
    },
  },
  authToken: localStore.getItem(STORE_KEYS.AUTH_TOKEN) || null,
  deviceId: localStore.getItem(STORE_KEYS.DEVICE_ID) || null,
  registered: false,
  errorMessage: null,
  emailLoginOrRegister: null,
  twitterConfig: {
    consumer: {
      key: "piDoeqxe5JyJkIkDJEgsWPiym",
      secret: "45DcQHxVzW0QBvtm0Sor0WCNPmYSGgrFIt2JO7jZ0JCyR92fBl",
    },
  },
  settings: null,
  currencies: currencyToSymbolMap,
  userCoinInfo: null,
  showHalfProfile: false,
  halfProfileUser: null,
  showFullProfile: false,
  userProfileInformation: null,
  selectedUserFollowers: null,
  selectedUserFollowings: null,
  userFollowingsStreams: null,
  userDirectMessageHistory: null,
  userDirectMessageInfo: null,
  userDirectMessageStatuses: [],
  showBuyCoinsModal: false,
  buyCoinBackground: "white-bg",
  showJoinNow: false,
  messageFromProfile: null,
  messageScreenError: null,
  unseenUserMessageCount: null,
  isFollowLoading: false,
  messageInfoForCoinCount: null,
  selectedUserFollowersMeta: null,
  selectedUserFollowingsMeta: null,
  leaderBoardList: null,
  leaderBoardUserSelf: null,
  leaderBoardGiftersList: null,
  leaderBoardGiftersUserSelf: null,
  isLeaderBoardShow: true,
  isGiftersLeaderBoardShow: false,
  isLeaderBoardLoading: null,
  isUserAnonymous: false,
  isShowUserAnonymous: null,
  isSendGiftMessageLoading: false,
  unseenNotification: [],
  unseenNotificationCount: null,
  unseenNotificationMeta: null,
  showEditProfile: false,
  isMessageStatusLoading: true,
  mobileChatActive: null,
  conversationId: null,
  conversationUser: null,
  showRemoveImgModal: false,
  uploadImageIds: [],
  isUploadImgLoading: false,
  uploadSelectedImgId: null,
});

// getters **************************

const getters = {
  isLoggedIn: (state) => {
    return !!state.authToken;
  },
  axiosReady: (state) => {
    return state.axiosReady;
  },
  errorMessage: (state) => {
    return state.errorMessage;
  },
  emailLoginOrRegister: (state) => {
    return state.emailLoginOrRegister;
  },
  twitterConfig: (state) => {
    return state.twitterConfig;
  },
  ownProfile: (state) => {
    return state.ownProfile;
  },
  isHybridPage: (state) => {
    return state.isHybridPage;
  },
  isProd: (state) => {
    return state.isProd;
  },
  settings: (state) => {
    return state.settings;
  },
  currencies: (state) => {
    return state.currencies;
  },
  selectedProduct: (state) => {
    return state.selectedProduct;
  },
  userCoinInfo: (state) => {
    return state.userCoinInfo;
  },
  activeLoginComponent: (state) => {
    return state.activeLoginComponent;
  },
  forterToken: (state) => {
    return state.forterToken;
  },
  userProfileInformation: (state) => {
    return state.userProfileInformation;
  },
  showLoginModal: (state) => {
    return state.showLoginModal;
  },
  selectedUserFollowers: (state) => {
    return state.selectedUserFollowers;
  },
  selectedUserFollowings: (state) => {
    return state.selectedUserFollowings;
  },
  userFollowingsStreams: (state) => {
    return state.userFollowingsStreams;
  },
  userDirectMessageHistory: (state) => {
    return state.userDirectMessageHistory;
  },
  userDirectMessageHistoryMessages: (state) => {
    if (
      state.userDirectMessageHistory &&
      state.userDirectMessageHistory.messages
    )
      return state.userDirectMessageHistory.messages;

    return [];
  },
  userDirectMessageInfo: (state) => {
    return state.userDirectMessageInfo;
  },
  userDirectMessageStatuses: (state) => {
    return state.userDirectMessageStatuses;
  },
  showBuyCoinsModal: (state) => {
    return state.showBuyCoinsModal;
  },
  buyCoinBackground: (state) => {
    return state.buyCoinBackground;
  },
  showJoinNow: (state) => {
    return state.showJoinNow;
  },
  halfProfileUser: (state) => {
    return state.halfProfileUser;
  },
  messageFromProfile: (state) => {
    return state.messageFromProfile;
  },
  unseenUserMessageCount: (state) => {
    return state.unseenUserMessageCount;
  },
  isFollowLoading: (state) => {
    return state.isFollowLoading;
  },
  messageInfoForCoinCount: (state) => {
    return state.messageInfoForCoinCount;
  },
  selectedUserFollowersMeta: (state) => {
    return state.selectedUserFollowersMeta;
  },
  selectedUserFollowingsMeta: (state) => {
    return state.selectedUserFollowingsMeta;
  },
  leaderBoardList: (state) => {
    return state.leaderBoardList;
  },
  leaderBoardUserSelf: (state) => {
    return state.leaderBoardUserSelf;
  },
  leaderBoardGiftersList: (state) => {
    return state.leaderBoardGiftersList;
  },
  leaderBoardGiftersUserSelf: (state) => {
    return state.leaderBoardGiftersUserSelf;
  },
  isLeaderBoardShow: (state) => {
    return state.isLeaderBoardShow;
  },
  isGiftersLeaderBoardShow: (state) => {
    return state.isGiftersLeaderBoardShow;
  },
  isLeaderBoardLoading: (state) => {
    return state.isLeaderBoardLoading;
  },
  isUserAnonymous: (state) => {
    return state.isUserAnonymous;
  },
  isShowUserAnonymous: (state) => {
    return state.isShowUserAnonymous;
  },
  isSendGiftMessageLoading: (state) => {
    return state.isSendGiftMessageLoading;
  },
  unseenNotification: (state) => {
    return state.unseenNotification;
  },
  unseenNotificationCount: (state) => {
    return state.unseenNotificationCount;
  },
  unseenNotificationMeta: (state) => {
    return state.unseenNotificationMeta;
  },
  showEditProfile: (state) => {
    return state.showEditProfile;
  },
  isMessageStatusLoading: (state) => {
    return state.isMessageStatusLoading;
  },
  mobileChatActive: (state) => {
    return state.mobileChatActive;
  },
  conversationId: (state) => {
    return state.conversationId;
  },
  conversationUser: (state) => {
    return state.conversationUser;
  },
  showRemoveImgModal: (state) => {
    return state.showRemoveImgModal;
  },
  uploadImageIds: (state) => {
    return state.uploadImageIds;
  },
  isUploadImgLoading: (state) => {
    return state.isUploadImgLoading;
  },
  uploadSelectedImgId: (state) => {
    return state.uploadSelectedImgId;
  },
};

// mutations ************************

const mutations = {
  setAuthToken(state, authToken) {
    state.authToken = authToken;
  },
  setDeviceId(state, deviceId) {
    state.deviceId = deviceId;
  },
  setRegistered(state, registered) {
    state.registered = registered;
  },
  setAxiosReady(state, axiosReady) {
    state.axiosReady = axiosReady;
  },
  setErrorMessage(state, errorMessage) {
    state.errorMessage = errorMessage;
  },
  setEmailLoginOrRegister(state, emailLoginOrRegister) {
    state.emailLoginOrRegister = emailLoginOrRegister;
  },
  setOwnProfile(state, ownProfile) {
    state.ownProfile = ownProfile;
  },
  setHybridPage(state, isHybridPage) {
    state.isHybridPage = isHybridPage;
  },
  setProd(state, isProd) {
    state.isProd = isProd;
  },
  setSettings(state, settings) {
    state.settings = settings;
  },
  setUserCoinInfo(state, userCoinInfo) {
    state.userCoinInfo = userCoinInfo;
  },
  setActiveLoginComponent(state, activeLoginComponent) {
    state.activeLoginComponent = activeLoginComponent;
  },
  setForterToken(state, forterToken) {
    state.forterToken = forterToken;
  },
  setShowHalfProfile(state, showHalfProfile) {
    state.showHalfProfile = showHalfProfile;
  },
  setHalfProfileUser(state, halfProfileUser) {
    state.halfProfileUser = halfProfileUser;
  },
  setShowFullProfile(state, showFullProfile) {
    state.showFullProfile = showFullProfile;
  },
  setUserProfileInformation(state, userProfileInformation) {
    state.userProfileInformation = userProfileInformation;
  },
  setShowLoginModal(state, showLoginModal) {
    state.showLoginModal = showLoginModal;
  },
  setSelectedUserFollowers(state, selectedUserFollowers) {
    state.selectedUserFollowers = selectedUserFollowers;
  },
  setSelectedUserFollowings(state, selectedUserFollowings) {
    state.selectedUserFollowings = selectedUserFollowings;
  },
  setUserFollowingsStreams(state, userFollowingsStreams) {
    state.userFollowingsStreams = userFollowingsStreams;
  },
  setUserDirectMessageHistory(state, userDirectMessageHistory) {
    state.userDirectMessageHistory = userDirectMessageHistory;
  },
  setUserDirectMessageInfo(state, userDirectMessageInfo) {
    state.userDirectMessageInfo = userDirectMessageInfo;
  },
  setUserDirectMessageStatuses(state, userDirectMessageStatuses) {
    state.userDirectMessageStatuses = userDirectMessageStatuses;
  },
  setShowBuyCoinsModal(state, showBuyCoinsModal) {
    state.showBuyCoinsModal = showBuyCoinsModal;
  },
  setBuyCoinBackground(state, buyCoinBackground) {
    state.buyCoinBackground = buyCoinBackground;
  },
  setShowJoinNow(state, showJoinNow) {
    state.showJoinNow = showJoinNow;
  },
  setMessageFromProfile(state, conversationInfo) {
    state.messageFromProfile = conversationInfo;
  },
  setMessageScreenError(state, error) {
    state.messageScreenError = error;
  },
  setUnseenUserMessageCount(state, unseenUserMessageCount) {
    state.unseenUserMessageCount = unseenUserMessageCount;
  },
  setIsFollowLoading(state, isFollowLoading) {
    state.isFollowLoading = isFollowLoading;
  },
  setMessageInfoForCoinCount(state, messageInfoForCoinCount) {
    state.messageInfoForCoinCount = messageInfoForCoinCount;
  },
  setSelectedUserFollowersMeta(state, selectedUserFollowersMeta) {
    state.selectedUserFollowersMeta = selectedUserFollowersMeta;
  },
  setSelectedUserFollowingsMeta(state, selectedUserFollowingsMeta) {
    state.selectedUserFollowingsMeta = selectedUserFollowingsMeta;
  },
  setLeaderBoardList(state, leaderBoardList) {
    state.leaderBoardList = leaderBoardList;
  },
  setLeaderBoardUserSelf(state, leaderBoardUserSelf) {
    state.leaderBoardUserSelf = leaderBoardUserSelf;
  },
  setLeaderBoardGiftersList(state, leaderBoardGiftersList) {
    state.leaderBoardGiftersList = leaderBoardGiftersList;
  },
  setLeaderBoardGiftersUserSelf(state, leaderBoardGiftersUserSelf) {
    state.leaderBoardGiftersUserSelf = leaderBoardGiftersUserSelf;
  },
  setIsLeaderBoardShow(state, payload) {
    state.isLeaderBoardShow = payload;
  },
  setIsGiftersLeaderBoardShow(state, payload) {
    state.isGiftersLeaderBoardShow = payload;
  },
  setIsLeaderBoardLoading(state, payload) {
    state.isLeaderBoardLoading = payload;
  },
  setIsUserAnonymous(state, payload) {
    state.isUserAnonymous = payload;
  },
  setIsShowUserAnonymous(state, payload) {
    state.isShowUserAnonymous = payload;
  },
  setIsSendGiftMessageLoading(state, payload) {
    state.isSendGiftMessageLoading = payload;
  },
  setUnseenNotification(state, payload) {
    state.unseenNotification = payload;
  },
  concatUnseenNotification(state, payload) {
    state.unseenNotification = state.unseenNotification.concat(payload);
  },
  setUnseenNotificationCount(state, payload) {
    state.unseenNotificationCount = payload;
  },
  setUnseenNotificationMeta(state, payload) {
    state.unseenNotificationMeta = payload;
  },
  setShowEditProfile(state, payload) {
    state.showEditProfile = payload;
  },
  setIsMessageStatusLoading(state, payload) {
    state.isMessageStatusLoading = payload;
  },
  setMobileChatActive(state, payload) {
    state.mobileChatActive = payload;
  },
  setConversationId(state, payload) {
    state.conversationId = payload;
  },
  setConversationUser(state, payload) {
    state.conversationUser = payload;
  },
  setShowRemoveImgModal(state, payload) {
    state.showRemoveImgModal = payload;
  },
  setUploadImageIds(state, payload) {
    state.uploadImageIds = payload;
  },
  setIsUploadImgLoading(state, payload) {
    state.isUploadImgLoading = payload;
  },
  setUploadSelectedImgId(state, payload) {
    state.uploadSelectedImgId = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
