<template>
  <div :class="{ 'hybrid-container': isHybridPage }"></div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CrazySale",
  computed: {
    ...mapState({
      isHybridPage: (state) => state.client.isHybridPage,
    }),
  },
  mounted() {
    this.$hybridapi("updatePageTitle", this.$t("labels.do_payment"));

    const redirect_url = this.$route.query.redirect_url;
    if (redirect_url !== null) {
      this.$hybridapi("hideLoading");
      window.location.href = redirect_url;
    } else {
      this.$toast.error(this.$t("errors.general_error"));
      this.hybridClose();
    }
  },
  methods: {
    hybridClose(exit_location = "close") {
      this.$hybridapi("close", exit_location);
      if (this.isHybridPage) this.postHybridCloseChecks();
    },
  },
};
</script>

<style scoped lang="scss"></style>
