<template>
  <div class="settings container is-fluid">
    <div class="settings-content">
      <h1>{{ $t("menu.settings") }}</h1>
      <!-- Temporarily removed this setting -->
      <!-- <div v-if="ownProfile">
        <input
          type="checkbox"
          @change="changeSensitive"
          v-model="ownProfile.user.is_sensitive_content_allowed"
          class="ios8-switch"
          id="checkbox-1"
        />
        <label class="switch-label" for="checkbox-1">{{
          $t("settings.sensitive_content")
        }}</label>
      </div>
      <p class="eighteen-info">{{ $t("labels.plus_eighteen") }}</p> -->
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "Settings.scss";
</style>
<script>
import { mapActions } from "vuex";
const endpoints = require("@/api/endpoints");

export default {
  name: "Settings",
  data() {
    return {
      ownProfile: null,
    };
  },
  mounted() {
    endpoints
      .own_profile(this.$axios)
      .then((profileResult) => {
        if (
          (profileResult.error && profileResult.error.code === "2") ||
          profileResult.status != 200
        ) {
          this.logout();
        }
        this.ownProfile = profileResult.data;
      })
      .catch((error) => {
        console.error("error: ", error);
        this.logout();
      });
  },
  methods: {
    ...mapActions("client", ["logout"]),
    changeSensitive() {
      endpoints.set_sensitive_content_allowance(this.$axios, {
        is_allowed: this.ownProfile.user.is_sensitive_content_allowed,
      });
    },
  },
};
</script>
