import { localStore, STORE_KEYS } from "@/storage";
import router from "@/router";
const endpoints = require("@/api/endpoints");
const helpers = require("../../../api/helpers");
import { getCookie, setCookie, generateUUID, signupUuid } from "@/helper";
import Adjust from "@adjustcom/adjust-web-sdk";
import { getClientParams } from "../../../api/client_params";
import {
  openCoinPurchasePageEvent,
  signupSuccessEvent,
  viewCoinPurchasePageEvent,
} from "@/helper/marketingevents.js";
export const actions = {
  setAuthToken({ commit }, authToken) {
    commit("setAuthToken", authToken);
  },
  setDeviceId({ commit }, deviceId) {
    commit("setDeviceId", deviceId);
  },
  setRegistered({ commit }, registered) {
    commit("setRegistered", registered);
  },
  setErrorMessage({ commit }, errorMessage) {
    commit("setErrorMessage", errorMessage);
  },
  setEmailLoginOrRegister({ commit }, payload) {
    commit("setEmailLoginOrRegister", payload);
  },
  setOwnProfile({ commit }, payload) {
    commit("setOwnProfile", payload);
  },
  setHybridPage({ commit }, payload) {
    commit("setHybridPage", payload);
  },
  setSettings({ commit }, payload) {
    commit("setSettings", payload);
  },
  setUserCoinInfo({ commit }, payload) {
    commit("setUserCoinInfo", payload);
  },
  setShowLoginModal({ commit }, showLoginModal) {
    commit("setShowLoginModal", showLoginModal);
  },
  setActiveLoginComponent({ commit }, activeLoginComponent) {
    commit("setActiveLoginComponent", activeLoginComponent);
  },
  setShowHalfProfile({ commit, state }, userId) {
    if (
      (state.showHalfProfile &&
        userId &&
        userId !== state.halfProfileUser?.user_id) ||
      (!state.showHalfProfile && userId)
    ) {
      if (userId !== state.halfProfileUser?.user_id) {
        endpoints
          .user_profile(this._vm.$axios, {
            user_id: userId,
          })
          .then(async (userResponse) => {
            // EF TODO: Neden bu hata durumunda profile modalı kapatıp toaster göstermek yerine anasayfaya yönlendirdik?
            if (userResponse.data.error && userResponse.data.error.code == 9) {
              router.push("/");
              return false;
            }
            const selectedHalfProfileUser = userResponse.data.user;
            commit("setHalfProfileUser", selectedHalfProfileUser);
            commit("setShowHalfProfile", true);
          });
      } else {
        commit("setShowHalfProfile", true);
      }
    } else {
      commit("setShowHalfProfile", false);
    }
  },
  setShowFullProfile({ commit }, payload) {
    commit("setShowFullProfile", payload);
  },
  setUserProfileInformation({ commit }, payload) {
    commit("setUserProfileInformation", payload);
  },
  facebookPixelInit({ state }) {
    if (state.deviceId && process.env.VUE_APP_FB_PIXEL_ID) {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      window.fbq("init", process.env.VUE_APP_FB_PIXEL_ID, {
        external_id: state.deviceId,
      });
      window.fbq("track", "PageView");
    }
  },
  adjustInit({ dispatch }) {
    const _this = this;
    Adjust.initSdk({
      appToken: process.env.VUE_APP_ADJUST_TOKEN,
      environment: process.env.VUE_APP_ADJUST_ENV,
      logLevel: process.env.VUE_APP_ADJUST_LOG_LEVEL,
      attributionCallback: function (e, attribution) {
        // update client params
        localStore.setItem(STORE_KEYS.ADJUST_ADID, attribution.adid);
        const { state, ...attrs } = attribution;
        localStore.setItem(
          STORE_KEYS.ADJUST_ATTRIBUTION_DATA,
          JSON.stringify(attrs)
        );

        let openRequest = indexedDB.open("adjust-sdk", 1);
        openRequest.onsuccess = function () {
          let db = openRequest.result;
          let transaction = db.transaction("as", "readwrite");
          let webUuidStore = transaction.objectStore("as");
          const request = webUuidStore.getAll();
          request.onsuccess = function () {
            if (request.result !== undefined) {
              const webUuid = request.result[0].u;
              localStore.setItem(STORE_KEYS.ADJUST_WEB_UUID, webUuid);
            }
            getClientParams(true);
            dispatch("recordAdjustData");
            db.close();
          };
        };
        openRequest.onerror = function () {
          console.error("Error", openRequest.error);
          getClientParams(true);
          dispatch("recordAdjustData");
        };
      },
    });
  },
  recordAdjustData() {
    endpoints.record_adjust_data(this._vm.$axios).then((res) => {
      if (!res) {
        console.error(
          "There occurs a problem setting adjust callback attributions to the server."
        );
      }
    });
  },
  logout({ commit, dispatch }) {
    localStore.removeItem(STORE_KEYS.AUTH_TOKEN);
    commit("setAuthToken", null);
    commit("setSettings", null);
    commit("setOwnProfile", null);
    commit("setShowJoinNow", false);
    dispatch("live/closeStream", null, { root: true });
    location.reload();
  },
  postLogin(
    { commit, dispatch, state, rootState },
    { authToken, redirectUrl, loginFrom }
  ) {
    helpers.postLogin(this._vm.$axios, authToken).then(() => {
      commit("setAuthToken", authToken);

      dispatch("getSettings", true).then(
        (res) => {
          if (
            state.webSocket &&
            state.webSocket.readyState &&
            state.webSocket.readyState === 1
          ) {
            dispatch("live/websocketAuth", authToken, { root: true });
          } else {
            dispatch("live/connectWebsocket", null, { root: true }).then(() => {
              dispatch("live/websocketAuth", authToken, { root: true });
            });
          }

          dispatch("getOwnProfile", loginFrom);
          if (state.showLoginModal) {
            commit("setShowLoginModal", false);
          } else if (redirectUrl) {
            router.push(redirectUrl);
          }
        },
        (err) => {
          console.error("settings err: ", err);
        }
      );
    });
  },
  getSettings({ commit, state, dispatch }, forceToReload = false) {
    return new Promise((resolve, reject) => {
      if (state.settings && !forceToReload) {
        resolve(state.settings);
      }
      endpoints.settings(this._vm.$axios).then((settingsResult) => {
        if (
          (settingsResult.error && settingsResult.error.code === "2") ||
          settingsResult.status == 400
        ) {
          dispatch("logout");
          reject();
        } else {
          const appId = settingsResult.data.agora_app_id;
          dispatch(
            "live/initializeAgoraClients",
            { app_id: appId },
            { root: true }
          );
          commit("setSettings", settingsResult.data);
          commit("setUserCoinInfo", settingsResult.data.coin_info);
          resolve(settingsResult.data);
        }
      });
    });
  },
  getOwnProfile({ commit, rootState, state, dispatch }, loginFrom = false) {
    endpoints.own_profile(this._vm.$axios).then((profileResult) => {
      commit("setOwnProfile", profileResult.data);
      if (loginFrom) {
        // Marketing Event
        signupSuccessEvent(
          state.deviceId,
          signupUuid(),
          profileResult.data.user.user_id,
          loginFrom
        );
      }

      if (profileResult.data.extra_information_required) {
        router.push("/onboarding");
      } else {
        // EF: Eğer aktif yayın yapan kullanıcı giriş yaptıysa yayın izlemeyi sonlandır.
        if (
          rootState.live.selectedUser &&
          rootState.live.selectedUser.user_id === state.ownProfile.user.user_id
        ) {
          dispatch("live/closeStream", null, { root: true });
        } else if (rootState.live.selectedUser && rootState.live.livestreamId) {
          // EF: Aktif yayın izlenmekteyse yayına yeniden giriş yap
          dispatch(
            "live/connectChannel",
            {
              livestreamId: rootState.live.livestreamId,
              selectedUser: rootState.live.selectedUser,
            },
            { root: true }
          );
        }
      }
    });
  },
  checkLoggedInByModal({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (!state.authToken) {
        reject();
        commit("setShowLoginModal", true);
      } else {
        resolve();
      }
    });
  },
  followUser({ dispatch, commit }, { user_id, page_enum }) {
    commit("setIsFollowLoading", true);
    endpoints
      .follow_user(this._vm.$axios, { user_id, page_enum })
      .then((followResult) => {
        if (followResult.data.error && followResult.data.error.code === "1") {
          commit("setShowLoginModal", true);
          commit("setIsFollowLoading", false);
        }
        if (followResult) {
          dispatch("getSelectedUser", { user_id });
          commit("setIsFollowLoading", false);
        }
      });
  },
  unfollowUser({ dispatch, commit }, { user_id, page_enum }) {
    commit("setIsFollowLoading", true);
    endpoints
      .unfollow_user(this._vm.$axios, { user_id, page_enum })
      .then((unfollowResult) => {
        if (unfollowResult) {
          dispatch("getSelectedUser", { user_id });
          commit("setIsFollowLoading", false);
        }
      });
  },
  notificationsFollowActions(
    { commit, state, dispatch },
    { user_id, page_enum, type }
  ) {
    const endpoint =
      type === "follow" ? endpoints.follow_user : endpoints.unfollow_user;
    endpoint(this._vm.$axios, { user_id, page_enum }).then((result) => {
      if (result.data && result.data.success) {
        dispatch("getNotifications", { next: null });
      }
    });
  },
  leaderboardFollowActions(
    { state, dispatch, commit },
    { user_id, page_enum, type, leaderboard_type, leaderboard_list_type }
  ) {
    const isGifterType = leaderboard_list_type === "gifter";
    // select endpoint
    const endpoint =
      type === "follow" ? endpoints.follow_user : endpoints.unfollow_user;

    // select leaderboard State
    const tempListElements = isGifterType
      ? state.leaderBoardGiftersList
      : state.leaderBoardList;

    // select update board State
    const boardSetAction = isGifterType
      ? "setLeaderBoardGiftersList"
      : "setLeaderBoardList";

    // select dispatch Action
    const dispatchAction = isGifterType
      ? "getGiftersLeaderBoard"
      : "getLeaderBoard";

    // buesiness Logic
    const tempList = [...tempListElements];
    const changeIndex = tempList.findIndex(
      (item) =>
        item && item.user && item.user.user_id && item.user.user_id === user_id
    );

    tempList[changeIndex].user.isFollowLoading = true;
    commit(boardSetAction, tempList);
    endpoint(this._vm.$axios, { user_id, page_enum }).then((result) => {
      if (result.data && result.data.success) {
        dispatch(dispatchAction, { leaderboard_type, isPageShouldLoad: false });
      }
    });
  },
  getSelectedUser({ commit }, { user_id }) {
    endpoints
      .user_profile(this._vm.$axios, {
        user_id: user_id,
      })
      .then(async (userResponse) => {
        if (userResponse.data.error && userResponse.data.error.code == 9) {
          router.push("/");
          return false;
        }
        const selectedUser = userResponse.data.user;
        commit("live/setSelectedUser", selectedUser, { root: true });
        commit("setHalfProfileUser", selectedUser);
      });
  },
  resetSelectedUser({ commit }) {
    commit("live/setSelectedUser", null, { root: true });
  },
  getSelectedUserFollowers({ commit }, { user_id }) {
    endpoints
      .user_followers(this._vm.$axios, {
        user_id: user_id,
      })
      .then((userFollowerResponse) => {
        const selectedUserFollowers = userFollowerResponse.data.items;
        commit("setSelectedUserFollowers", selectedUserFollowers);
        commit(
          "setSelectedUserFollowersMeta",
          userFollowerResponse.data.meta.next
        );
      });
  },
  getNextSelectedUserFollowers({ commit, state }, { user_id, next }) {
    endpoints
      .user_followers(this._vm.$axios, {
        user_id: user_id,
        next: next,
      })
      .then((userNextFollowerResponse) => {
        const selectedUserNextFollowers = userNextFollowerResponse.data.items;
        commit("setSelectedUserFollowers", [
          ...state.selectedUserFollowers,
          ...selectedUserNextFollowers,
        ]);
        commit(
          "setSelectedUserFollowersMeta",
          userNextFollowerResponse.data.meta.next
        );
      });
  },
  getSelectedUserFollowings({ commit }, { user_id }) {
    endpoints
      .user_followings(this._vm.$axios, {
        user_id: user_id,
      })
      .then((userFollowingsResponse) => {
        const selectedUserFollowings = userFollowingsResponse.data.items;
        commit("setSelectedUserFollowings", selectedUserFollowings);
        commit(
          "setSelectedUserFollowingsMeta",
          userFollowingsResponse.data.meta.next
        );
      });
  },
  getNextSelectedUserFollowings({ commit, state }, { user_id, next }) {
    endpoints
      .user_followings(this._vm.$axios, {
        user_id: user_id,
        next: next,
      })
      .then((userNextFollowingsResponse) => {
        const selectedUserNextFollowings =
          userNextFollowingsResponse.data.items;
        commit("setSelectedUserFollowings", [
          ...state.selectedUserFollowings,
          ...selectedUserNextFollowings,
        ]);
        commit(
          "setSelectedUserFollowingsMeta",
          userNextFollowingsResponse.data.meta.next
        );
      });
  },
  getUserFollowingsStreams({ commit }, { user_id }) {
    endpoints
      .followings_streams(this._vm.$axios, {
        user_id: user_id,
      })
      .then((userFollowingsStreamResponse) => {
        const userFollowingsStreams = userFollowingsStreamResponse.data.items;
        commit("setUserFollowingsStreams", userFollowingsStreams);
      });
  },
  getUserMessageInfo({ commit, dispatch }, { user_id }) {
    endpoints
      .message_info(this._vm.$axios, {
        user_id: user_id,
      })
      .then((response) => {
        dispatch("live/closeGift", null, { root: true });
        commit("setMessageFromProfile", response.data.conversation);
      });
  },
  getMessageInfoForCoinCount({ commit }, { user_id }) {
    endpoints
      .message_info(this._vm.$axios, {
        user_id: user_id,
      })
      .then((response) => {
        commit("setMessageInfoForCoinCount", response.data.conversation);
      });
  },
  resetUserMessageInfo({ commit }) {
    commit("setMessageFromProfile", null);
  },
  getUserDirectMessageHistory(
    { state, commit, dispatch },
    { type, conversation_id, user, update, next }
  ) {
    let params = { conversation_id };
    if (type === "incoming") {
      params.update = update;
    } else if (type === "old") {
      params.next = next;
    }
    endpoints
      .message_history(this._vm.$axios, params)
      .then((userDirectMessageHistoryResponse) => {
        let messages = [...userDirectMessageHistoryResponse.data.messages];
        if (type === "incoming") {
          messages = [
            ...userDirectMessageHistoryResponse.data.messages,
            ...state.userDirectMessageHistory.messages,
          ];
        } else if (type === "old") {
          messages = [
            ...state.userDirectMessageHistory.messages,
            ...userDirectMessageHistoryResponse.data.messages,
          ];
        }

        const userDirectMessageHistory = {
          user,
          conversation_id,
          updateKey: userDirectMessageHistoryResponse.data.meta.update,
          nextKey: userDirectMessageHistoryResponse.data.meta.next,
          messages: [...messages],
        };
        commit("setUserDirectMessageHistory", { ...userDirectMessageHistory });
        commit("setMessageScreenError", null);
        dispatch("getMessageInfoForCoinCount", { user_id: user.user_id });
        commit("setIsMessageStatusLoading", false);
      });
  },

  getUserDirectMessageStatuses({ commit }, next) {
    endpoints
      .message_statuses(this._vm.$axios, {
        next: next,
      })
      .then((userDirectMessageStatusesResponse) => {
        const userDirectMessageStatuses =
          userDirectMessageStatusesResponse.data.conversation_statuses;
        commit("setUserDirectMessageStatuses", [...userDirectMessageStatuses]);
        commit("setIsMessageStatusLoading", false);
      });
  },
  resetUserDirectMessageStatuses({ commit }) {
    commit("setUserDirectMessageHistory", null);
  },
  addToConversationStatuses({ state, commit, dispatch }, payload) {
    const tempState = state.userDirectMessageStatuses;

    const alreadyExistConversationIndex = tempState.findIndex(
      (item) => item.conversation_id === payload.conversation_id
    );

    if (alreadyExistConversationIndex === -1) {
      tempState.push(payload);
    } else {
      tempState[alreadyExistConversationIndex] = payload;
    }

    if (
      state.userDirectMessageHistory !== null &&
      payload.conversation_id === state.userDirectMessageHistory.conversation_id
    )
      dispatch("getUserDirectMessageHistory", {
        conversation_id: payload.conversation_id,
        update: state.userDirectMessageHistory.updateKey,
        user: state.userDirectMessageHistory.user,
        type: "incoming",
      });

    commit("setUserDirectMessageStatuses", [...tempState]);
  },
  getUserDirectMessageInfo({ commit }, conversation_id) {
    endpoints
      .message_info(this._vm.$axios, {
        conversation_id: conversation_id,
      })
      .then((userDirectMessageInfoResponse) => {
        const userDirectMessageInfo = userDirectMessageInfoResponse.data.item;
        commit("setUserDirectMessageInfo", userDirectMessageInfo);
      });
  },
  postUserTextMessage(
    { state, commit, dispatch },
    { conversation_id, text, dev_payload }
  ) {
    endpoints
      .send_text_message(this._vm.$axios, {
        conversation_id,
        text,
        dev_payload,
      })
      .then((response) => {
        if (response.data.error) {
          commit("setMessageScreenError", response.data.error.message);
          dispatch("getUserDirectMessageStatuses");
          return;
        }

        let newMessages = [response.data.message];
        if (
          state.userDirectMessageHistory &&
          state.userDirectMessageHistory.messages
        ) {
          dispatch("getUserDirectMessageStatuses");
          newMessages = [
            response.data.message,
            ...state.userDirectMessageHistory.messages,
          ];
        }
        dispatch("getUserDirectMessageStatuses");
        commit("setUserDirectMessageHistory", {
          ...state.userDirectMessageHistory,
          messages: newMessages,
        });
        commit("setMessageScreenError", null);
      });
  },
  sendMessageGift({ state, dispatch, commit }, gift) {
    commit("setIsSendGiftMessageLoading", true);
    const conversation_id = state.userDirectMessageHistory.conversation_id;
    if (!conversation_id) {
      return;
    }
    const dev_payload = generateUUID().replace("-", "").slice(0, 20);
    endpoints
      .send_gift_message(this._vm.$axios, {
        gift_id: gift.gift_id,
        conversation_id,
        dev_payload,
        guid: generateUUID(),
        initial_coins: 0,
      })
      .then((response) => {
        if (response.data.error) {
          commit("setIsSendGiftMessageLoading", false);
          commit("setMessageScreenError", response.data.error.message);
          dispatch("getUserDirectMessageStatuses");
          return;
        }

        let newMessages = [response.data.message];
        if (
          state.userDirectMessageHistory &&
          state.userDirectMessageHistory.messages
        ) {
          newMessages = [
            response.data.message,
            ...state.userDirectMessageHistory.messages,
          ];
        }
        commit("setIsSendGiftMessageLoading", false);
        dispatch("getUserDirectMessageStatuses");
        commit("setUserDirectMessageHistory", {
          ...state.userDirectMessageHistory,
          messages: newMessages,
        });
        commit("setMessageScreenError", null);
      });
  },
  setShowBuyCoinsModal(
    { rootState, state, commit },
    {
      showBuyCoinsModal,
      buyCoinBackground = "white-bg",
      pageName, // Possible values: “full_screen”, “livestream_overlay”, “multi_payment”(Will be added)
      isAutomatic = 0, // Check it pops up automaticly or not. For Automatic pop up -> isAutomatic = 1
    }
  ) {
    if (!state.authToken && router.app._route.path !== "/login") {
      commit("setShowLoginModal", true);
    } else {
      if (showBuyCoinsModal == true) {
        openCoinPurchasePageEvent(); //Marketing Event
        viewCoinPurchasePageEvent(
          rootState.client.ownProfile.user.user_id,
          rootState.client.deviceId,
          pageName,
          isAutomatic
        ); //Marketing Event
      }
      rootState.payment.stripeActivePage = "coinSelect";
      commit("setShowJoinNow", false);
      commit("setBuyCoinBackground", buyCoinBackground);
      commit("setShowBuyCoinsModal", showBuyCoinsModal);
    }
  },
  setBuyCoinBackground({ commit }, buyCoinBackground = "white-bg") {
    commit("setBuyCoinBackground", buyCoinBackground);
  },
  setShowJoinNow({ commit }, showJoinNow) {
    commit("setShowBuyCoinsModal", false);
    commit("setShowJoinNow", showJoinNow);
  },
  setHalfProfileUser({ commit }, payload) {
    commit("setHalfProfileUser", payload);
  },
  getLeaderBoard({ commit }, { leaderboard_type, isPageShouldLoad }) {
    commit(
      "setIsLeaderBoardLoading",
      isPageShouldLoad === undefined ? true : isPageShouldLoad
    );
    commit("setIsLeaderBoardShow", true);
    commit("setIsGiftersLeaderBoardShow", false);
    endpoints
      .leaderboard(this._vm.$axios, { leaderboard_type })
      .then((leaderBoardResponse) => {
        if (leaderBoardResponse.data) {
          const leaderBoardList = leaderBoardResponse.data.items;
          commit("setLeaderBoardList", leaderBoardList);
          const leaderBoardUserSelf = leaderBoardResponse.data.own_user;
          commit("setLeaderBoardUserSelf", leaderBoardUserSelf);
        }
        commit("setIsLeaderBoardLoading", false);
      });
  },
  getGiftersLeaderBoard({ commit }, { leaderboard_type, isPageShouldLoad }) {
    commit(
      "setIsLeaderBoardShow",
      isPageShouldLoad === undefined ? true : isPageShouldLoad
    );
    commit("setIsGiftersLeaderBoardShow", true);
    commit("setIsLeaderBoardShow", false);
    endpoints
      .gifters_leaderboard(this._vm.$axios, { leaderboard_type })
      .then((leaderBoardGiftersResponse) => {
        if (leaderBoardGiftersResponse.data) {
          const leaderBoardGiftersList = leaderBoardGiftersResponse.data.items;
          commit("setLeaderBoardGiftersList", leaderBoardGiftersList);
          const leaderBoardGiftersUserSelf =
            leaderBoardGiftersResponse.data.own_user;
          commit("setLeaderBoardGiftersUserSelf", leaderBoardGiftersUserSelf);
          commit(
            "setIsShowUserAnonymous",
            leaderBoardGiftersUserSelf.anonymous_on_gifters_leaderboard
          );
        }
        commit("setIsLeaderBoardLoading", false);
      });
  },
  setUserGifterLeaderboardAnonymous(
    { commit, dispatch },
    { is_anonymous, leaderboard_type }
  ) {
    endpoints
      .gifters_leaderboard_anonymous(this._vm.$axios, { is_anonymous })
      .then((anonymousResponse) => {
        if (is_anonymous === true) {
          commit("setIsUserAnonymous", anonymousResponse);
        } else {
          commit("setIsUserAnonymous", anonymousResponse);
        }
        dispatch("getGiftersLeaderBoard", {
          leaderboard_type: leaderboard_type,
        });
      });
  },
  getNotifications({ commit, state }, { next }) {
    endpoints
      .get_notifications(this._vm.$axios, { next: next })
      .then((notificationResponse) => {
        commit(
          next == null ? "setUnseenNotification" : "concatUnseenNotification",
          notificationResponse.data.notifications
        );
        commit(
          "setUnseenNotificationMeta",
          notificationResponse.data.meta.next
        );
      });
  },
  editProfile({ commit }, payload) {
    commit("setShowEditProfile", payload);
  },
  getOwnProfileForEdit({ commit }) {
    endpoints.own_profile(this._vm.$axios).then((profileResult) => {
      commit("setOwnProfile", profileResult.data);
    });
  },
  //Dont use checkLoginSendMessageClick when route is /login
  checkLoginSendMessageClick({ commit, state, dispatch }, user_id) {
    if (!state.authToken) {
      commit("setShowLoginModal", true);
    } else {
      dispatch("setConversationIdProfile", null);
      dispatch("getUserMessageInfo", { user_id });
      router.push("/messages");
    }
  },
  setMobileChatActive({ commit }, payload) {
    commit("setMobileChatActive", payload);
  },
  setConversationIdProfile({ commit }, payload) {
    commit("setConversationId", payload);
  },
  setConversationUser({ commit }, payload) {
    commit("setConversationUser", payload);
  },
  openHalfProfile({ commit, rootState, dispatch }, userId) {
    if (rootState.live.showTopGifters) {
      commit("live/setShowTopGifters", false, { root: true });
    }
    if (rootState.live.showViewerCard) {
      commit("live/setShowViewerCard", false, { root: true });
    }
    dispatch("setShowHalfProfile", userId);
  },
  setShowRemoveImgModal({ commit }, payload) {
    commit("setShowRemoveImgModal", payload);
  },
  setUploadImageIds({ commit }, imageIds) {
    commit("setUploadImageIds", imageIds);
  },
  uploadProfileImages({ commit, state, dispatch }, fileObject) {
    commit("setIsUploadImgLoading", true);
    const reader = new FileReader();
    reader.onload = (e) => {
      endpoints
        .upload_image(this._vm.$axios, {
          profile_image: e.target.result.split(",")[1],
        })
        .then((imageUploadResponse) => {
          commit("setIsUploadImgLoading", false);
          commit("setUploadImageIds", [
            ...state.uploadImageIds,
            {
              image_id: imageUploadResponse.data.id,
              thumbnail_url: e.target.result,
            },
          ]);
        })
        .catch((err) => {
          this.$toast.error(err);
        });
    };
    reader.readAsDataURL(fileObject);
  },
  updateProfileDetails(
    { commit, state, dispatch },
    { uploadedName, uploadedBio, uploadedUserId }
  ) {
    endpoints
      .update_user(this._vm.$axios, {
        name: uploadedName,
        bio: uploadedBio,
        image_ids: state.uploadImageIds.map((item) => item.image_id),
      })
      .then(() => {
        dispatch("getOwnProfileForEdit");
        dispatch("getSelectedUser", { user_id: uploadedUserId });
        dispatch("editProfile", false);
      });
  },
  removeUploadedImage({ commit, state, dispatch }, payload) {
    commit("setUploadImageIds", [
      ...state.uploadImageIds.filter((item) => item.image_id !== payload),
    ]);
    dispatch("setShowRemoveImgModal", false);
  },
  setUploadSelectedImgId({ commit }, payload) {
    commit("setUploadSelectedImgId", payload);
  },
};
