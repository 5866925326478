<template>
  <div
    class="payermax-credit-card-container pt-0"
    :class="{ 'hybrid-container': isHybridPage }"
  >
    <div class="promotion-top" v-if="isPromotionPayment">
      <div class="user-coin-circle">
        <div class="user-coin">
          <img
            class="coin-svg"
            src="@/assets/img/coin.svg"
            :alt="$t('general.coin_icon')"
          />
          <span class="coin-amount">
            {{ formatCoin(userCoinInfo.coin_info.coins) }}
          </span>
        </div>
      </div>
      <div class="purchase-header">
        {{ $t("menu.purchase") }}
      </div>
      <div class="close-right">
        <div class="close">
          <i class="fas fa-times" @click="closeHybridPage"></i>
        </div>
      </div>
    </div>
    <div class="hybrid-section">
      <div class="section-header">
        <div>
          {{ $t("payermax.order_details") }}
        </div>
        <div>
          <div class="close" v-if="!isPromotionPayment">
            <i class="fas fa-times" @click="closeCreditCard"></i>
          </div>
        </div>
      </div>
      <div class="order-amount-container" v-if="isPromotionPayment">
        <div class="order-amount-label">
          {{ $t("payermax.amount_of_coin") }}
        </div>
        <div class="price">
          <img
            class="diamond-icon"
            src="@/assets/img/coin.svg"
            :alt="$t('general.coin_icon')"
          />
          {{ selectedProduct.amount }}
        </div>
      </div>
      <div class="order-price-container">
        <div class="order-price-label">
          {{ $t("payermax.order_total") }}
        </div>
        <div class="price">
          {{ currencies[selectedProduct.stripe_price.currency.toUpperCase()]
          }}{{ selectedProduct.stripe_price.price }}
        </div>
      </div>
    </div>
    <div class="hybrid-section">
      <div class="section-process mt-1">
        {{ $t("payermax.payment_details") }}
      </div>
      <div class="ma-0 pa-0 mt-1">
        <img
          class="pr-1"
          :src="require(`@/assets/img/mastercard-icon-2.svg`)"
        />
        <img class="pr-1" :src="require(`@/assets/img/visa-icon.svg`)" />
        <img
          class="pre-show-card"
          :src="require(`@/assets/img/amex-icon.svg`)"
        />
      </div>
      <v-container>
        <v-row class="ma-0">
          <v-col cols="12 pt-2">
            <label class="uppercase small-font-size" for="card-holder-name"
              >{{ $t("labels.card_holder_name")
              }}<span class="asterix">*</span></label
            >
            <div
              :class="
                'input-container ' +
                (validation.firstError('cardHolder') ? 'has-error ' : '') +
                (submitted && !validation.firstError('cardHolder')
                  ? 'has-no-error '
                  : '')
              "
            >
              <input
                name="cardHolderName"
                v-model="cardHolder"
                :placeholder="$t('labels.card_holder_name')"
                tabindex="1"
                v-on:input="handleInputChange('cardHolder')"
                v-on:keypress="handleFocusable($event)"
              />
            </div>
          </v-col>
          <v-col cols="12">
            <label for="card-number" class="card-info uppercase"
              >{{ $t("labels.card_number")
              }}<span class="asterix">*</span></label
            >
            <div
              class="input-container"
              :class="
                stripeSettings.errors.cardNumber ||
                stripeErrorField === 'cardNumber'
                  ? 'error-border'
                  : stripeSettings.complete.cardNumber
                  ? 'success-border'
                  : ''
              "
            >
              <div id="card-number"></div>
              <span
                v-if="
                  stripeSettings.brand &&
                  stripeSettings.brand != 'unknown' &&
                  stripeSettings.errors.cardNumber == null
                "
                class="brand-icon-container"
              >
                <img
                  class="brand-icon"
                  :src="
                    require(`@/assets/img/${stripeSettings.brand}-icon.svg`)
                  "
                />
              </span>
              <span
                class="input-error-container"
                v-else-if="
                  stripeSettings.errors.cardNumber &&
                  stripeSettings.errors.cardNumber != 'unknown'
                "
              >
                <img :src="require(`@/assets/img/credit-card-error.svg`)" />
              </span>
            </div>
          </v-col>
          <v-col cols="6 pr-2">
            <label
              class="text-ellipsis card-expiry uppercase small-font-size"
              for="card-expiry"
              >{{ $t("labels.expire_date")
              }}<span class="asterix">*</span></label
            >
            <div
              class="input-container"
              :class="
                stripeSettings.errors.cardExpiry ||
                stripeErrorField === 'cardExpiry'
                  ? 'error-border'
                  : stripeSettings.complete.cardExpiry
                  ? 'success-border'
                  : ''
              "
            >
              <div id="card-expiry"></div>
              <span
                class="input-error-container"
                v-if="stripeSettings.errors.cardExpiry"
              >
                <img :src="require(`@/assets/img/credit-card-error.svg`)" />
              </span>
            </div>
          </v-col>
          <v-col cols="6 pl-2">
            <label for="card-cvc"
              >{{ $t("labels.card_cvc") }}<span class="asterix">*</span></label
            >
            <div
              class="input-container"
              :class="
                stripeSettings.errors.cardCvc || stripeErrorField === 'cardCvc'
                  ? 'error-border'
                  : stripeSettings.complete.cardCvc
                  ? 'success-border'
                  : ''
              "
            >
              <div id="card-cvc"></div>
              <span
                class="input-error-container"
                v-if="stripeSettings.errors.cardCvc"
              >
                <img :src="require(`@/assets/img/credit-card-error.svg`)" />
              </span>
            </div>
          </v-col>
          <v-col cols="12">
            <div
              class="card-error"
              v-if="
                cardError ||
                validation.firstError('cardHolder') ||
                stripeSettings.errors.cardNumber ||
                stripeSettings.errors.cardCvc ||
                stripeSettings.errors.cardExpiry
              "
            >
              <div v-if="validation.firstError('cardHolder')">
                {{
                  $t("labels.card_holder_name") +
                  ": " +
                  validation.firstError("cardHolder")
                }}
              </div>
              <div v-if="cardError">
                {{ cardError }}
              </div>
              <div v-if="stripeSettings.errors.cardNumber">
                {{
                  $t("labels.card_number") +
                  ": " +
                  stripeSettings.errors.cardNumber
                }}
              </div>
              <div v-if="stripeSettings.errors.cardExpiry">
                {{
                  $t("labels.expire_date") +
                  ": " +
                  stripeSettings.errors.cardExpiry
                }}
              </div>
              <div v-if="stripeSettings.errors.cardCvc">
                {{
                  $t("labels.card_cvc") + ": " + stripeSettings.errors.cardCvc
                }}
              </div>
            </div>

            <div class="pay-button-container">
              <button
                @click="newCardPayment"
                class="green-button mt-1"
                :disabled="
                  validation.firstError('cardHolder') ||
                  stripeSettings.errors.cardNumber ||
                  stripeSettings.errors.cardExpiry ||
                  stripeSettings.errors.cardCvc ||
                  stripeSettings.payButtonDisable ||
                  !stripeSettings.complete.cardCvc ||
                  !stripeSettings.complete.cardNumber ||
                  !stripeSettings.complete.cardExpiry ||
                  loading
                "
              >
                {{ $t("payermax.confirm_payment") }}
                {{
                  currencies[
                    selectedProduct.stripe_price.currency.toUpperCase()
                  ]
                }}{{ selectedProduct.stripe_price.price }}
                <img
                  src="@/assets/img/loading.svg"
                  alt="loading"
                  class="loading"
                  v-if="loading"
                />
              </button>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="security-footer">
      <img
        class="lock-svg"
        src="@/assets/img/lock.svg"
        :alt="$t('payermax.stripe_secure_and_protected_payment')"
      />
      <span class="security-text">
        {{ $t("payermax.stripe_secure_and_protected_payment") }}
      </span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/components/Payment/Payermax/PayermaxCreditCard.scss";
</style>
<script>
"use strict";

import { Logger } from "@/logging";
import { mapState, mapActions } from "vuex";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
import {
  stripePaymentRequestData,
  generatePaymentUUID,
  getPaymentUUID,
  clearPaymentUUID,
  formatCoin,
} from "@/helper/index.js";
import { PayermexErrorCodes } from "@/helper/enums.js";
const logger = new Logger("PayermaxCreditCard");
export default {
  name: "PayermaxCreditCard",
  components: {},
  data() {
    return {
      loading: false,
      submitted: false,
      paymentMethodId: null,
      cardHolder: null,
      cardError: null,
      stripeErrorField: null, // From backend error response.
      stripeSettings: {
        // clientSecret: null,
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null,
        brand: null,
        payButtonDisable: true,
        errors: {
          cardNumber: null,
          cardExpiry: null,
          cardCvc: null,
          // cardError: null,
        },
        complete: {
          cardNumber: false,
          cardExpiry: false,
          cardCvc: false,
        },
      },
    };
  },
  beforeMount() {
    if (!this.selectedProduct) {
      if (this.isPromotionPayment) {
        window.location.href = decodeURIComponent(this.promotionReferrer);
      } else {
        this.$router.push("/payermax");
      }
    }

    // NOTE(stripe-client-payment-create): leftover test code
    // window["testVue"] = this;
  },
  mounted() {
    window["backButtonTapped"] = () => {
      if (this.isPromotionPayment) {
        this.$hybridapi("showLoading");
        window.location.href = decodeURIComponent(this.promotionReferrer);
      } else if (confirm(this.$t("labels.close_confirm"))) {
        this.hybridClose("close");
        return "close";
      }
      return "nothing";
    };

    this.$hybridapi("updateWindowSize", "full");
    this.$hybridapi("updatePageTitle", this.$t("labels.do_payment"));
    this.$hybridapi("hideLoading");

    this.setStripeSettings();

    this.stripePaymentWebsocket(this.$hybrid_websocket_url).then(() => {
      this.loading = false;
      this.$hybridapi("hideLoading");
      clearPaymentUUID();
      this.socketPaymentSucceeded = true;
      this.goComplete();
    });
  },
  computed: {
    ...mapState({
      isHybridPage: (state) => state.client.isHybridPage,
      selectedProduct: (state) => state.payment.selectedProduct,
      currencies: (state) => state.client.currencies,
      forterToken: (state) => state.client.forterToken,
      userCoinInfo: (state) => state.client.userCoinInfo,
      isPromotionPayment: (state) => state.payment.isPromotionPayment,
      promotionReferrer: (state) => state.payment.promotionReferrer,
      platform: (state) => state.platform,
      buyCoinBackground: (state) => state.client.buyCoinBackground,
    }),
    stripeElements() {
      return this.$stripe.elements({
        fonts: [
          {
            // integrate your font into stripe
            family: "Avenir",
            cssSrc: "https://fonts.googleapis.com/css?family=Avenir:400,500",
          },
        ],
      });
    },
  },
  methods: {
    ...mapActions("payment", [
      "stripePaymentWebsocket",
      "postHybridCloseChecks",
      "stripePayment",
      "reConfirmPayment",
      "goComplete",
    ]),
    setStripeSettings() {
      let style = {
        base: {
          color: "#000",
          fontFamily: "Montserrat",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#ccc",
          },
        },
        invalid: {
          color: "#000", //error font color
          iconColor: "#32325d",
        },
      };
      if (this.buyCoinBackground == "black-opac-bg") {
        style = {
          base: {
            color: "#ffffff",
            fontFamily: "Montserrat",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#ccc",
            },
          },
          invalid: {
            color: "#ff0000",
            iconColor: "#ff0000",
          },
        };
      }

      this.stripeSettings.cardNumber = this.stripeElements.create(
        "cardNumber",
        { style }
      );
      this.stripeSettings.cardNumber.mount("#card-number");
      this.stripeSettings.cardExpiry = this.stripeElements.create(
        "cardExpiry",
        { style }
      );
      this.stripeSettings.cardExpiry.mount("#card-expiry");
      this.stripeSettings.cardCvc = this.stripeElements.create("cardCvc", {
        style,
      });
      this.stripeSettings.cardCvc.mount("#card-cvc");

      //Card Number
      this.stripeSettings.cardNumber.on("change", (cardChangeEvent) => {
        // Reset saved paymentMethodId on change.
        this.paymentMethodId = null;

        // Clear error on change.
        if (this.stripeErrorField == "cardNumber") {
          this.stripeErrorField = null;
          this.cardError = null;
        } else if (this.stripeErrorField == null) {
          this.cardError = null;
        }

        this.stripeSettings.complete.cardNumber = cardChangeEvent.complete;

        if (cardChangeEvent.brand) {
          this.stripeSettings.brand = cardChangeEvent.brand;
        }

        //Handle if any error occured
        if (cardChangeEvent.error) {
          this.stripeSettings.errors.cardNumber = cardChangeEvent.error.message;
        } else {
          this.stripeSettings.errors.cardNumber = null;
        }

        this.stripeSettings.payButtonDisable = cardChangeEvent.empty;
      });

      //Card CVC
      this.stripeSettings.cardCvc.on("change", (cardChangeEvent) => {
        // Reset saved paymentMethodId on change.
        this.paymentMethodId = null;

        // Clear error on change.
        if (this.stripeErrorField == "cardCvc") {
          this.stripeErrorField = null;
          this.cardError = null;
        } else if (this.stripeErrorField == null) {
          this.cardError = null;
        }

        this.stripeSettings.complete.cardCvc = cardChangeEvent.complete;

        //Handle if any error occured
        if (cardChangeEvent.error) {
          this.stripeSettings.errors.cardCvc = cardChangeEvent.error.message;
        } else {
          this.stripeSettings.errors.cardCvc = null;
        }
      });

      //Card Expiry
      this.stripeSettings.cardExpiry.on("change", (cardChangeEvent) => {
        // Reset saved paymentMethodId on change.
        this.paymentMethodId = null;

        // Clear error on change.
        if (this.stripeErrorField == "cardExpiry") {
          this.stripeErrorField = null;
          this.cardError = null;
        } else if (this.stripeErrorField == null) {
          this.cardError = null;
        }

        this.stripeSettings.complete.cardExpiry = cardChangeEvent.complete;

        if (cardChangeEvent.brand) {
          this.stripeSettings.brand = cardChangeEvent.brand;
        }

        //Handle if any error occured
        if (cardChangeEvent.error) {
          this.stripeSettings.errors.cardExpiry = cardChangeEvent.error.message;
        } else {
          this.stripeSettings.errors.cardExpiry = null;
        }
      });
    },
    newCardPayment() {
      this.submitted = true;
      this.$validate().then((success) => {
        if (success) {
          this.loading = true;
          if (this.paymentMethodId != null) {
            logger.debug("paymentMethodId reuse", this.paymentMethodId);
            // Retry case
            this.cardPayment();
          } else {
            // New payment method case
            const paymentMethodData = {
              type: "card",
              card: this.stripeSettings.cardNumber,
              billing_details: {
                name: this.cardHolder,
              },
              metadata: {
                // NOTE(stripe-client-payment-create): Client don't have access to "bin".
                initial_os_type: this.platform,
              },
            };
            this.$stripe
              .createPaymentMethod(paymentMethodData)
              .then((result) => {
                if (result.error) {
                  logger.debug("createPaymentMethod error", result);
                  this.loading = false;
                  this.submitted = false;
                  this.handleStripeError(result);
                } else if (result.paymentMethod) {
                  logger.debug("createPaymentMethod success", result);
                  this.paymentMethodId = result.paymentMethod.id;
                  logger.debug("paymentMethodId set", this.paymentMethodId);

                  // Generate new UUID when payment method changes.
                  generatePaymentUUID();

                  this.cardPayment();
                } else {
                  logger.error("createPaymentMethod unknown fail", result);
                  this.loading = false;
                  this.submitted = false;
                  this.cardError = this.$t("errors.general_error");
                }
              })
              .catch((err) => {
                this.handleStripeError(err);
                this.loading = false;
              });
          }
        } else {
          this.submitted = false;
          logger.error("newCardPayment failed validate");
        }
      });
    },
    //
    cardPayment() {
      this.loading = true;
      this.stripePayment(
        stripePaymentRequestData(
          this.selectedProduct.sku,
          this.paymentMethodId,
          getPaymentUUID(),
          this.forterToken
        )
      )
        .then((payWithMethodResponse) => {
          this.createPaymentMethodResponseHandler(payWithMethodResponse);
        })
        .catch((error) => {
          // Network error or server fail
          logger.debugError("stripePayment failed", error);
          this.cardError = this.$t("errors.general_error");
          this.loading = false;
        });
    },
    createPaymentMethodResponseHandler(payWithMethodResponse) {
      // NOTE: Backend doesn't attach method to user until successful purchase. Stripe doesn't allow non-attached method id to be used twice.
      this.paymentMethodId = null;

      if (payWithMethodResponse.error) {
        // Error response from server
        logger.debugError(
          "createPaymentMethodResponseHandler error",
          payWithMethodResponse.error
        );
        this.loading = false;
        if (
          payWithMethodResponse.error.code ===
          PayermexErrorCodes.IDEMPOTENCY_KEY_ERROR
        ) {
          clearPaymentUUID();
          if (this.promotionReferrer != null) {
            this.$hybridapi("showLoading");
            window.location.href = decodeURIComponent(this.promotionReferrer);
          } else {
            this.$hybridapi("hideLoading");
            this.$router.push("/payermax");
          }
        } else if (
          payWithMethodResponse.error.code ===
          PayermexErrorCodes.CREDIT_CARD_INFO_ERROR
        ) {
          this.handleStripeError(payWithMethodResponse);
          // NOTE(purchase-bug-fix-pass): Not touching the wait logic here. Assuming business call.
          setTimeout(() => {
            clearPaymentUUID();
            if (this.promotionReferrer != null) {
              this.$hybridapi("showLoading");
              window.location.href = decodeURIComponent(this.promotionReferrer);
            } else {
              this.$hybridapi("hideLoading");
              this.$router.push("/payermax");
            }
          }, 5000);
        } else {
          // Generate new UUID on error.
          generatePaymentUUID();

          this.handleStripeError(payWithMethodResponse);
        }
      } else {
        // Success response from server
        if (payWithMethodResponse.status == "succeeded") {
          this.loading = false;
          // Clear Payment UUID on success.
          clearPaymentUUID();
          this.goComplete();
        } else if (
          payWithMethodResponse.status == "requires_action" ||
          payWithMethodResponse.status == "requires_confirmation"
        ) {
          // Generate new UUID for next attempt.
          generatePaymentUUID();

          this.$stripe
            .handleCardAction(payWithMethodResponse.client_secret)
            .then((handleCardResponse) => {
              this.handleCardResponseHandler(
                handleCardResponse,
                payWithMethodResponse
              );
            });
        } else {
          logger.debugError(
            "createPaymentMethodResponseHandler unexpected case",
            payWithMethodResponse
          );
          this.cardError = this.$t("errors.general_error");
          this.loading = false;
        }
      }
    },
    handleCardResponseHandler(handleCardResponse, payWithMethodResponse) {
      if (handleCardResponse.error) {
        this.loading = false;
        this.handleStripeError(handleCardResponse);
      } else {
        this.reConfirmPayment({
          payment_id: payWithMethodResponse.payment_id,
        })
          .then((reConfirmResponse) => {
            this.reConfirmResponseHandler(reConfirmResponse);
          })
          .catch((error) => {
            // Network error or server fail
            logger.debugError("reConfirmPayment failed", error);
            this.cardError = this.$t("errors.general_error");
            this.loading = false;
          });
      }
    },
    reConfirmResponseHandler(reConfirmResponse) {
      if (reConfirmResponse.error) {
        // Error response from server
        logger.debugError(
          "reConfirmResponseHandler error",
          reConfirmResponse.error
        );
        this.loading = false;
        this.handleStripeError(reConfirmResponse);
      } else {
        // Success response from server
        logger.debug("reConfirmResponseHandler success", reConfirmResponse);
        // NOTE(purchase-bug-fix-pass): Not calling goComplete here for some backend reason. Websocket should trigger before response anyway.
      }
    },
    handleStripeError(res) {
      // NOTE(stripe-client-payment-create): Keeping stripeErrorField logic just in case. Error should happen before at createPaymentMethod.
      // NOTE: Both backend error codes (number enum) and stripe error codes (string enum) are handled here. Not ideal. Stripe sdk should handle errors before here.
      // https://stripe.com/docs/error-codes#incorrect-number
      const code = res.error.code;
      if (
        code === 1010 ||
        code === "invalid_number" ||
        code === "incorrect_number"
      ) {
        this.stripeErrorField = "cardNumber";
      } else if (
        code === 1011 ||
        code === "invalid_expiry_month" ||
        code === "invalid_expiry_year" ||
        code === "expired_card"
      ) {
        this.stripeErrorField = "cardExpiry";
      } else if (
        code === 1012 ||
        code === "incorrect_cvc" ||
        code === "invalid_cvc"
      ) {
        this.stripeErrorField = "cardCvc";
      } else {
        this.stripeErrorField = null;
      }

      if (res.error && res.error.message) {
        this.cardError = res.error.message;
      } else {
        this.cardError = this.$t("errors.general_error");
      }
    },
    handleInputChange(inputName) {
      if (inputName === "cardHolder") {
        // Reset saved paymentMethodId on change.
        this.paymentMethodId = null;

        // Clear error on change.
        if (this.stripeErrorField == null) {
          this.cardError = null;
        }
      } else {
        logger.debugError(
          "handleInputChange called with unknown inputName",
          inputName
        );
      }
    },
    handleFocusable(event) {
      // Detect Enter Keypress
      if (event.keyCode == 13) {
        this.moveNext(event.srcElement);
      }
    },
    moveNext(elem) {
      let tidx = +elem.getAttribute("tabindex") + 1,
        elems = document.getElementsByTagName("input");

      for (var i = elems.length; i--; ) {
        let tidx2 = elems[i].getAttribute("tabindex");
        if (tidx2 == tidx) {
          elems[i].focus();
        } else {
          elems[i].blur();
        }
      }
    },
    closeHybridPage() {
      this.$hybridapi("showLoading");
      window.location.href = decodeURIComponent(this.promotionReferrer);
    },
    closeCreditCard() {
      this.$hybridapi("showLoading");
      this.hybridClose("close");
    },
    formatCoin,
    hybridClose(exit_location = "close") {
      this.$hybridapi("close", exit_location);
      if (this.isHybridPage) this.postHybridCloseChecks();
    },
  },
  validators: {
    cardHolder: function (value) {
      return Validator.value(value).required().minLength(2);
    },
  },
};
</script>
