// All about live stream
import { actions } from "./actions.js";
import { StreamType, DiscoverFilterTypeEnum } from "@/helper/enums.js";

// initial state ********************

const state = () => ({
  webSocket: null,
  heartbeatState: "general",
  rtcClient: null,
  rtmClient: null,
  rtmChannel: null,
  remoteAudioTracks: [],
  livestreamId: "",
  livestreamType: StreamType.PUBLIC,
  livestreamLoading: false,
  activeStream: null,
  watchingStream: false,
  streamFinished: false,
  streamStopped: false,
  messages: [],
  messagesScroll: false,
  gifts: [],
  vipMessages: [],
  vips: [],
  joinedUser: {
    name: "",
    vipState: 0,
  },
  discoverStreams: [],
  loaderState: null,
  topGifters: [],
  selectedUser: null,
  userVipBorderClass: "",
  guestStopped: false,
  diamondCount: 0,
  viewerCount: 0,
  nextId: "first",
  showGiftClass: false,
  showVipClass: false,
  showVipMessageClass: false,
  showGuest: false,
  activeVip: null,
  activeVipMessage: null,
  activeGift: null,
  activeGuest: null,
  options: {
    appId: "6d79a10d9ea04d5f9e10f451191c96c2",
    role: "audience",
    channel: "demo_channel_name",
    token: null,
  },
  showSendGift: false,
  showAcceptPremium: false,
  premiumInfo: {
    is_invited: false,
    livestream_id: 0,
    private_livestream_gift: {
      cost: 0,
      gift_id: 0,
      image: "",
      name: "",
    },
    rating_info: null,
    refund_available: false,
  },
  discoverFilterType: DiscoverFilterTypeEnum.POPULAR,
  discoverCountries: [],
  showCountryFilterModal: false,
  activeViewers: {
    count: 0,
    count_info: {
      total_viewers: 0,
      vip_viewers: 0,
    },
    viewers: [],
    agora_events: [],
    meta: {
      next: null,
    },
  },
  showViewerCard: false,
  showTopGifters: false,
  topGiftersList: null,
  topGiftersListMetaNext: null,
  joinStreamCount: 0,
  liveStreamSource: "",
  liveStreamFlowId: 0,
});

// getters **************************

const getters = {
  rtcClient: (state) => {
    return state.rtcClient;
  },
  rtmClient: (state) => {
    return state.rtmClient;
  },
  rtmChannel: (state) => {
    return state.rtmChannel;
  },
  remoteAudioTracks: (state) => {
    return state.remoteAudioTracks;
  },
  webSocket: (state) => {
    return state.webSocket;
  },
  livestreamId: (state) => {
    return state.livestreamId;
  },
  activeStream: (state) => {
    return state.activeStream;
  },
  watchingStream: (state) => {
    return state.watchingStream;
  },
  streamFinished: (state) => {
    return state.streamFinished;
  },
  streamStopped: (state) => {
    return state.streamStopped;
  },
  messages: (state) => {
    return state.messages;
  },
  messagesScroll: (state) => {
    return state.messagesScroll;
  },
  gifts: (state) => {
    return state.gifts;
  },
  vipMessages: (state) => {
    return state.vipMessages;
  },
  vips: (state) => {
    return state.vips;
  },
  joinedUser: (state) => {
    return state.joinedUser;
  },
  discoverStreams: (state) => {
    return state.discoverStreams;
  },
  loaderState: (state) => {
    return state.loaderState;
  },
  topGifters: (state) => {
    return state.topGifters;
  },
  selectedUser: (state) => {
    return state.selectedUser;
  },
  userVipBorderClass: (state) => {
    return state.userVipBorderClass;
  },
  guestStopped: (state) => {
    return state.guestStopped;
  },
  diamondCount: (state) => {
    return state.diamondCount;
  },
  viewerCount: (state) => {
    return state.viewerCount;
  },
  heartbeatState: (state) => {
    return state.heartbeatState;
  },
  nextId: (state) => {
    return state.nextId;
  },
  showGiftClass: (state) => {
    return state.showGiftClass;
  },
  showVipMessageClass: (state) => {
    return state.showVipMessageClass;
  },
  showVipClass: (state) => {
    return state.showVipClass;
  },
  showGuest: (state) => {
    return state.showGuest;
  },
  activeVip: (state) => {
    return state.activeVip;
  },
  activeGift: (state) => {
    return state.activeGift;
  },
  activeGuest: (state) => {
    return state.activeGuest;
  },
  showSendGift: (state) => {
    return state.showSendGift;
  },
  showAcceptPremium: (state) => {
    return state.showAcceptPremium;
  },
  premiumInfo: (state) => {
    return state.premiumInfo;
  },
  discoverFilterType: (state) => {
    return state.discoverFilterType;
  },
  discoverCountries: (state) => {
    return state.discoverCountries;
  },
  showCountryFilterModal: (state) => {
    return state.showCountryFilterModal;
  },
  activeViewers: (state) => {
    return state.activeViewers;
  },
  showTopGifters: (state) => {
    return state.showTopGifters;
  },
  topGiftersList: (state) => {
    return state.topGiftersList;
  },
  topGiftersListMetaNext: (state) => {
    return state.topGiftersListMetaNext;
  },
  joinStreamCount: (state) => {
    return state.joinStreamCount;
  },
  liveStreamSource: (state) => {
    return state.liveStreamSource;
  },
  liveStreamFlowId: (state) => {
    return state.liveStreamFlowId;
  },
};

// mutations ************************

const mutations = {
  setRtcClient(state, rtcClient) {
    state.rtcClient = rtcClient;
  },
  setRtmClient(state, rtmClient) {
    state.rtmClient = rtmClient;
  },
  setRtmChannel(state, rtmChannel) {
    state.rtmChannel = rtmChannel;
  },
  setRemoteAudioTracks(state, remoteAudioTracks) {
    state.remoteAudioTracks = remoteAudioTracks;
  },
  setWebSocket(state, webSocket) {
    state.webSocket = webSocket;
  },
  setLivestreamId(state, livestreamId) {
    state.livestreamId = livestreamId;
  },
  setLivestreamType(state, livestreamType) {
    state.livestreamType = livestreamType;
  },
  setLivestreamLoading(state, livestreamLoading) {
    state.livestreamLoading = livestreamLoading;
  },
  setActiveStream(state, activeStream) {
    state.activeStream = activeStream;
  },
  setWatchingStream(state, watchingStream) {
    state.watchingStream = watchingStream;
  },
  setStreamFinished(state, streamFinished) {
    state.streamFinished = streamFinished;
  },
  setStreamStopped(state, streamStopped) {
    state.streamStopped = streamStopped;
  },
  setMessages(state, messages) {
    state.messages = messages;
  },
  addMessage(state, message) {
    state.messages.push(message);
    state.messagesScroll = true;
  },
  setMessagesScroll(state, messagesScroll) {
    state.messagesScroll = messagesScroll;
  },
  setGifts(state, gifts) {
    state.gifts = gifts;
  },
  addGift(state, gift) {
    state.gifts.push(gift);
  },
  addVipMessage(state, message) {
    state.vipMessages.push(message);
  },
  shiftGifts(state) {
    state.gifts.shift();
  },
  shiftVipMessages(state) {
    state.vipMessages.shift();
  },
  setVips(state, vips) {
    state.vips = vips;
  },
  addVip(state, vip) {
    state.vips.push(vip);
  },
  shiftVips(state) {
    state.vips.shift();
  },
  setJoinedUser(state, joinedUser) {
    state.joinedUser = joinedUser;
  },
  setDiscoverStreams(state, discoverStreams) {
    state.discoverStreams = discoverStreams;
  },
  setLoaderState(state, loaderState) {
    state.loaderState = loaderState;
  },
  addDiscoverStreams(state, discoverStreams) {
    state.discoverStreams.push(...discoverStreams);
  },
  setTopGifters(state, topGifters) {
    state.topGifters = topGifters;
  },
  setSelectedUser(state, selectedUser) {
    state.selectedUser = selectedUser;
    if (selectedUser?.vip_state == 3) {
      state.userVipBorderClass = "gold";
    } else if (selectedUser?.vip_state == 2) {
      state.userVipBorderClass = "silver";
    } else if (selectedUser?.vip_state == 1) {
      state.userVipBorderClass = "bronze";
    }
  },
  setGuestStopped(state, guestStopped) {
    state.guestStopped = guestStopped;
  },
  setDiamondCount(state, diamondCount) {
    state.diamondCount = diamondCount;
  },
  setViewerCount(state, viewerCount) {
    state.viewerCount = viewerCount;
  },
  setHeartbeatState(state, heartbeatState) {
    state.heartbeatState = heartbeatState;
  },
  setNextId(state, nextId) {
    state.nextId = nextId;
  },
  setShowGiftClass(state, showGiftClass) {
    state.showGiftClass = showGiftClass;
  },
  setShowVipMessageClass(state, showVipMessageClass) {
    state.showVipMessageClass = showVipMessageClass;
  },
  setShowVipClass(state, showVipClass) {
    state.showVipClass = showVipClass;
  },
  setShowGuest(state, showGuest) {
    state.showGuest = showGuest;
  },
  setActiveVip(state, activeVip) {
    state.activeVip = activeVip;
  },
  setActiveGift(state, activeGift) {
    state.activeGift = activeGift;
  },
  setActiveVipMessage(state, activeVipMessage) {
    state.activeVipMessage = activeVipMessage;
  },
  setActiveGuest(state, activeGuest) {
    state.activeGuest = activeGuest;
  },
  setShowSendGift(state, showSendGift) {
    state.showSendGift = showSendGift;
  },
  setShowAcceptPremium(state, showAcceptPremium) {
    state.showAcceptPremium = showAcceptPremium;
  },
  setPremiumInfo(state, premiumInfo) {
    state.premiumInfo = premiumInfo;
  },
  setDiscoverFilterType(state, discoverFilterType) {
    state.discoverFilterType = discoverFilterType;
  },
  setDiscoverCountries(state, discoverCountries) {
    state.discoverCountries = discoverCountries;
  },
  setShowCountryFilterModal(state, showCountryFilterModal) {
    state.showCountryFilterModal = showCountryFilterModal;
  },
  setActiveViewers(state, activeViewers) {
    state.activeViewers = activeViewers.data;
  },
  setShowViewerCard(state, showViewerCard) {
    state.showViewerCard = showViewerCard;
  },
  toggleViewerCard(state) {
    state.showViewerCard = !state.showViewerCard;
  },
  setShowTopGifters(state, payload) {
    state.showTopGifters = payload;
  },
  setTopGiftersList(state, payload) {
    state.topGiftersList = payload;
  },
  setTopGiftersListMetaNext(state, payload) {
    state.topGiftersListMetaNext = payload;
  },
  addJoinStreamCount(state) {
    state.joinStreamCount += 1;
  },
  restoreJoinStreamCount(state, payload) {
    state.joinStreamCount = payload;
  },
  setLiveStreamSource(state, payload) {
    state.liveStreamSource = payload;
  },
  setLiveStreamFlowId(state, payload) {
    state.liveStreamFlowId = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
