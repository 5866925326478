var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("message-container " + (_vm.mobileChatActive && 'message-container-box'))},[(
      _vm.userDirectMessageStatuses &&
      _vm.userDirectMessageStatuses.length > 0 &&
      _vm.isMessageStatusLoading === false
    )?_c('div',{class:("message-box " + (_vm.mobileChatActive && 'mobile-user-passive'))},[_c('div',{staticClass:"message-box-title"},[_c('div',{staticClass:"back-button-container"},[_c('button',{staticClass:"back-button-header",on:{"click":_vm.goBack}},[_c('img',{staticClass:"chat-header-back-button",attrs:{"src":require(("@/assets/img/back-button_" + _vm.brandName + ".svg")),"alt":_vm.$t('general.back_button')}})])]),_c('div',{staticClass:"message-title-span"},[_c('span',{staticClass:"message-title-content"},[_vm._v(" "+_vm._s(_vm.$t("messages_page.messages"))+" ")])])]),_vm._l((_vm.userDirectMessageStatuses),function(item,index){return _c('div',{key:(index + "+" + (item.timestamp)),class:_vm.activeId === item.conversation_id
          ? 'user-message active'
          : 'user-message',on:{"click":function($event){return _vm.getUserMessages(item.conversation_id, item.user)}}},[(item.user && item.user.user_id === '1')?_c('div',{staticClass:"message-img"},[_c('img',{staticClass:"mod-live-img",attrs:{"src":require(("@/assets/img/livesupport_" + _vm.brandName + ".svg"))}})]):(item.user && item.user.user_id === 'moderation')?_c('div',{staticClass:"message-img"},[_c('img',{staticClass:"mod-live-img",attrs:{"src":require(("@/assets/img/moderation_" + _vm.brandName + ".svg"))}})]):_c('div',{staticClass:"message-img"},[_c('user-image-circle',{attrs:{"user":item.user,"size":"50px","border":""}})],1),_c('div',{staticClass:"single-user"},[_c('div',{staticClass:"user-content"},[_c('div',{staticClass:"user-level-name-wrapper"},[_c('level-badge',{attrs:{"user":item.user,"fontSize":'14'}}),_c('span',{staticClass:"user-name"},[_vm._v(_vm._s(item.user.name))])],1),_c('span',{staticClass:"user-message-date-span"},[_vm._v(_vm._s(_vm.getWalltime(item.timestamp)))])]),_c('div',{staticClass:"user-message-date"},[_c('span',{class:item.unseen_count !== 0 ? 'has-unseen' : 'user-message-preview'},[_vm._v(_vm._s(item.last_message_text))]),(item.unseen_count > 0)?_c('span',{class:item.unseen_count !== _vm.unseenUserMessageCount
                ? 'unseen-count-badge none'
                : 'unseen-count-badge'},[_vm._v(" "+_vm._s(_vm.unseenUserMessageCount))]):_vm._e()])])])})],2):(_vm.isMessageStatusLoading === true)?_c('div',{staticClass:"message-loader-container"},[_vm._m(0)]):_c('div',{class:("message-box empty " + (_vm.mobileChatActive && 'mobile-user-passive'))},[_c('img',{attrs:{"src":require(("@/assets/img/empty-message-box_" + _vm.brandName + ".svg"))}}),_c('span',{staticClass:"empty-info"},[_vm._v(" "+_vm._s(_vm.$t("messages_page.empty_box"))+" ")])]),(_vm.userDirectMessageHistory)?_c('div',{class:("chat-container " + (_vm.mobileChatActive && 'mobile-chat-active'))},[_c('div',{staticClass:"chat-about"},[_c('span',{staticClass:"message-back-button-wrapper"},[_c('button',{staticClass:"message-back-button",on:{"click":_vm.backToList}},[_c('img',{staticClass:"chat-header-back-button",attrs:{"src":require(("@/assets/img/back-button_" + _vm.brandName + ".svg")),"alt":_vm.$t('general.back_button')}})])]),_c('div',{staticClass:"chat-user-img"},[(
            _vm.userDirectMessageHistory &&
            _vm.isModMessage !== 'moderation' &&
            _vm.isModMessage !== '1'
          )?_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.fullProfile(_vm.userDirectMessageHistory.conversation_id)}}},[_c('user-image-circle',{attrs:{"user":_vm.userDirectMessageHistory.user,"size":"40px","border":""}})],1):_vm._e(),(_vm.isModMessage === '1')?_c('div',{staticClass:"message-img"},[_c('img',{staticClass:"mod-live-img",attrs:{"src":require(("@/assets/img/livesupport_" + _vm.brandName + ".svg"))}})]):_vm._e(),(_vm.isModMessage === 'moderation')?_c('div',{staticClass:"message-img"},[_c('img',{staticClass:"mod-live-img",attrs:{"src":require(("@/assets/img/moderation_" + _vm.brandName + ".svg"))}})]):_vm._e()],1),_c('div',{staticClass:"chat-user-content"},[_c('span',{staticClass:"chat-user-name"},[_vm._v(" "+_vm._s(_vm.userDirectMessageHistory.user.name))])])]),_c('div',{staticClass:"chat-history"},[(
          _vm.messageInfoForCoinCount !== null &&
          _vm.messageInfoForCoinCount.user.is_followed === false &&
          _vm.messageInfoForCoinCount.exists === false
        )?_c('div',{staticClass:"is-following-container"},[_c('i',{staticClass:"is-following-icon fas fa-user-times"}),_c('span',{staticClass:"is-following-content"},[_vm._v(_vm._s(_vm.$t("messages_page.is_following")))])]):_vm._e(),_c('ul',{staticClass:"chat-history-ul",on:{"&scroll":function($event){return _vm.handleScroll.apply(null, arguments)}}},[(_vm.messageScreenError)?_c('li',[_c('span',{staticClass:"message other-message-box"},[_vm._v(" "+_vm._s(_vm.messageScreenError)+" ")])]):_vm._l((_vm.userMessages),function(item,index){return _c('li',{key:("message" + (item.message_id) + index),class:("message " + (item.owner_type === 3 ? 'other-message-box' : 'my-message-box') + "\n            " + (item.type === _vm.ConversationMessageDataEnums.AUDIO ? 'audio' : '') + "\n            " + (item.type === _vm.ConversationMessageDataEnums.PHOTO ? 'photo' : '') + "\n            " + (item.type === _vm.ConversationMessageDataEnums.VIDEO ? 'video' : '') + "\n            " + (item.type === _vm.ConversationMessageDataEnums.GIFT ? 'gift' : ''))},[_c('span',{class:("message " + (item.owner_type === 3
                ? 'other-message float-left'
                : 'message-last float-right'))},[(item.data && item.data.gift)?_c('img',{staticClass:"gift-img",attrs:{"src":item.data.gift.image,"alt":_vm.$t('general.gift_img')}}):_vm._e(),(item.type === _vm.ConversationMessageDataEnums.PHOTO)?_c('img',{staticClass:"photo-message",attrs:{"src":item.data.file_url,"alt":_vm.$t('general.photo_message')}}):_vm._e(),(item.type === _vm.ConversationMessageDataEnums.AUDIO)?_c('audio',{staticClass:"audio-message",attrs:{"controls":""}},[_c('source',{attrs:{"src":item.data.file_url,"type":"audio/mp4"}})]):_vm._e(),(item.type === _vm.ConversationMessageDataEnums.VIDEO)?_c('video',{staticClass:"video-message",attrs:{"width":"320","height":"240","controls":""}},[_c('source',{attrs:{"src":item.data.file_url,"type":"video/mp4"}})]):_vm._e(),_vm._v(" "+_vm._s(item.data.text)+" ")])])})],2)]),(_vm.isModMessage !== 'moderation')?_c('div',{staticClass:"keyboard-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.messageText),expression:"messageText"}],staticClass:"keyboard-text-area",attrs:{"type":"textarea","maxlength":"500","placeholder":_vm.messageInfoForCoinCount && _vm.messageInfoForCoinCount.initial_coin_cost
            ? _vm.messageInfoForCoinCount.initial_coin_cost + ' coins/message'
            : ''},domProps:{"value":(_vm.messageText)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendMessage(
            _vm.userDirectMessageHistory.conversation_id,
            _vm.messageText,
            _vm.userDirectMessageHistory.user.user_id
          )},function($event){return _vm.focus()}],"focus":_vm.inputFocus,"input":function($event){if($event.target.composing){ return; }_vm.messageText=$event.target.value}}}),(this.isTyping === true || _vm.isModMessage === '1')?_c('div',{staticClass:"send-message-button-container"},[_c('button',{staticClass:"send-message-button"},[_c('img',{staticClass:"button-svg",attrs:{"src":require(("@/assets/img/send-message_" + _vm.brandName + ".svg")),"alt":_vm.$t('general.send_message_button')},on:{"click":function($event){return _vm.sendMessage(
                _vm.userDirectMessageHistory.conversation_id,
                _vm.messageText,
                _vm.userDirectMessageHistory.user.user_id
              )}}})])]):_vm._e(),(
          this.isTyping === false &&
          _vm.showSendGift === true &&
          _vm.isModMessage !== '1'
        )?_c('v-btn',{staticClass:"send-gift-button",attrs:{"fab":""},on:{"click":_vm.closeGift}},[_c('img',{staticClass:"send-gift-button-img",attrs:{"src":require("@/assets/img/gift-icon.svg"),"alt":_vm.$t('general.send_gift_button')}})]):_vm._e(),(
          this.isTyping === false &&
          _vm.showSendGift === false &&
          _vm.isModMessage !== '1'
        )?_c('v-btn',{staticClass:"send-gift-button-passive",attrs:{"fab":""},on:{"click":_vm.openGift}},[_c('img',{staticClass:"send-gift-button-passive-img",attrs:{"src":require("@/assets/img/gift-box-passive.svg"),"alt":_vm.$t('general.send_gift_button')}})]):_vm._e()],1):_vm._e(),(
        (_vm.showSendGift &&
          _vm.isDesktop() &&
          _vm.isSendGiftMessageLoading === false &&
          _vm.isModMessage !== 'moderation' &&
          _vm.isModMessage !== '1') ||
        (_vm.isMobile() &&
          _vm.showSendGift &&
          _vm.isSendGiftMessageLoading === false &&
          _vm.isModMessage !== 'moderation' &&
          _vm.isModMessage !== '1')
      )?_c('SendGift',{staticClass:"message-gift-container",attrs:{"dividerCount":"16"}}):_vm._e(),(_vm.isSendGiftMessageLoading === true)?_c('div',{staticClass:"live-stream-loading live-stream-screen"},[_vm._m(1)]):_vm._e()],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-box-loader"},[_c('i',{staticClass:"fas fa-circle-notch fa-spin"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overlay"},[_c('i',{staticClass:"fas fa-circle-notch fa-spin"})])}]

export { render, staticRenderFns }