<template>
  <div
    class="container is-fluid payermax-container pt-0"
    :class="{ 'hybrid-container': isHybridPage }"
  >
    <div class="promotion-top" v-if="fromPromotion && !isPromotionPayment">
      <div></div>
      <div class="purchase-header">
        {{ $t("menu.purchase") }}
      </div>
      <div class="close-right">
        <div class="close">
          <i class="fas fa-times" @click="closeHybridPage"></i>
        </div>
      </div>
    </div>

    <div
      class="hybrid-section user-section"
      v-if="purchaseSettings && !isPromotionPayment"
    >
      <div class="user-info">
        <user-image-circle
          class="user-profile-img"
          :user="purchaseSettings.user"
          size="63px"
        />
        <div class="user-name-and-id">
          <span class="user-name">{{ purchaseSettings.user.name }}</span>
          <span class="user-id">ID:{{ purchaseSettings.user.shared_id }}</span>
          <span class="user-coin" v-if="userCoinInfo">
            {{ $t("payermax.multi_payment_my_coins") }}:<img
              src="@/assets/img/coin.svg"
              :alt="$t('general.coin_icon')"
            />{{ formatCoin(userCoinInfo.coins) }}</span
          >
        </div>
      </div>
      <div
        class="country-select"
        @click="toggleSelectCountry"
        v-if="purchaseSettings.is_payment_country_change_active"
      >
        <img
          :src="
            require(`@/assets/img/flags/${countryCodeToFlagName(
              purchaseSettings.country
            )}.png`)
          "
          class="country-flag"
        />
        <i class="fas fa-chevron-down"></i>
      </div>
    </div>

    <div class="hybrid-section coin-select-section" v-if="!isPromotionPayment">
      <div class="section-header-payermax">
        <span class="header-badge">1</span>
        {{ $t("payermax.choose_coin_package") }}
      </div>
      <v-container class="mt-2">
        <v-row class="coins-container">
          <v-col cols="4" v-for="(product, index) in products" :key="index">
            <div
              class="coin-item"
              :class="{ active: index == selectedProduct }"
              @click="changeSelectedProduct(index)"
            >
              <img :src="product.icon_url" />
              {{ product.amount }}
              <div class="badge-container">
                <div
                  class="coin-badge"
                  v-if="product.tag == ProductTag.BEST_TAG"
                >
                  <img src="@/assets/img/flame_on_badge.svg" />
                  {{ $t("payermax.hot_tag") }}
                </div>
                <div
                  class="coin-badge for-new-user"
                  v-if="product.tag == ProductTag.NEW_USER_TAG"
                >
                  {{ $t("payermax.for_new_users") }}
                </div>
                <div
                  class="coin-badge"
                  v-if="
                    product.tag == ProductTag.DISCOUNT_TAG &&
                    !isNaN(getDiscountInformation(product))
                  "
                >
                  %{{ getDiscountInformation(product) }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div
      :class="['promotion-top', { 'is-ios': isIOS }]"
      v-if="purchaseSettings && isPromotionPayment"
    >
      <div class="user-coin-circle">
        <div class="user-coin">
          <img
            class="coin-svg"
            src="@/assets/img/coin.svg"
            :alt="$t('general.coin_icon')"
          />
          <span class="coin-amount">
            {{ formatCoin(purchaseSettings.coin_info.coins) }}
          </span>
        </div>
      </div>
      <span class="purchase-header">
        {{ $t("menu.purchase") }}
      </span>
      <div class="country-select-n-close">
        <div class="country-select" @click="toggleSelectCountry">
          <img
            :src="
              require(`@/assets/img/flags/${countryCodeToFlagName(
                purchaseSettings.country
              )}.png`)
            "
            class="country-flag"
          />
          <i class="fas fa-chevron-down"></i>
        </div>
        <div class="close">
          <i class="fas fa-times" @click="closeHybridPage"></i>
        </div>
      </div>
    </div>

    <div class="hybrid-section" v-if="isPromotionPayment">
      <div class="section-header">
        {{ $t("payermax.order_details") }}
      </div>
      <div class="order-amount-container">
        <div class="order-amount-label">
          {{ $t("payermax.amount_of_coin") }}
        </div>
        <div class="price" v-if="purchaseSettings && selectedProduct">
          <img
            class="diamond-icon"
            src="@/assets/img/coin.svg"
            :alt="$t('general.coin_icon')"
          />
          {{
            purchaseSettings.coin_info.multi_payment_coin_offer.products[
              selectedProduct
            ].amount
          }}
        </div>
      </div>
      <div class="order-price-container">
        <div class="order-price-label">{{ $t("payermax.order_total") }}</div>
        <div class="price" v-if="paymentMethods">
          {{ currencies[paymentMethods[0].payment_price.currency.toUpperCase()]
          }}{{ paymentMethods[0].payment_price.price }}
        </div>
      </div>
    </div>

    <div class="hybrid-section coin-select-section">
      <div class="section-header-payermax pb-0 pt-0">
        <span class="header-badge" v-if="!isPromotionPayment">2</span>
        {{ $t("payermax.choose_payment_method") }}
      </div>
      <v-container class="mt-1">
        <v-row class="payment-methods-container">
          <v-col
            cols="12"
            v-for="(payment_method, index) in paymentMethods"
            :key="index"
          >
            <div
              class="payment-method"
              @click="selectPaymentMethod(payment_method)"
            >
              <div class="payment-method-info">
                <div>
                  <img
                    :src="payment_method.icon_url"
                    v-if="payment_method.icon_url"
                  />
                </div>
                <div class="payment-method-name">
                  {{ payment_method.name }}
                  <div
                    class="payment-method-sub-text"
                    v-if="payment_method.sub_text"
                  >
                    {{ payment_method.sub_text }}
                  </div>
                </div>
                <span
                  class="discount-badge"
                  v-if="
                    payment_method &&
                    payment_method.is_available &&
                    payment_method.payment_price != null &&
                    payment_method.payment_price.discount > 0
                  "
                  >%{{ payment_method.payment_price.discount }}</span
                >
              </div>
              <div class="payment-method-price">
                <template
                  v-if="
                    payment_method.is_available &&
                    payment_method.payment_price != null
                  "
                >
                  <span
                    class="old-price"
                    v-if="
                      payment_method &&
                      payment_method.is_available &&
                      payment_method.payment_price.discount > 0
                    "
                  >
                    {{
                      currencies[
                        payment_method.payment_price.currency.toUpperCase()
                      ]
                    }}
                    {{ payment_method.payment_price.original_price }}
                  </span>
                  <span class="new-price">
                    {{
                      currencies[
                        payment_method.payment_price.currency.toUpperCase()
                      ]
                    }}
                    {{ payment_method.payment_price.price }}
                  </span>
                </template>
                <span
                  v-else-if="
                    payment_method.payment_provider === PaymentProvider.DEALER
                  "
                >
                  <!-- NOTE: if payment provider is dealer, price will not be shown -->
                  <span class="go-forward-icon">
                    <img src="@/assets/img/go-forward-button.svg" />
                  </span>
                </span>
                <span class="disabled" v-else>
                  {{ $t("payermax.unable_package") }}
                </span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" v-if="purchaseSettings">
            <div class="payment-method-footer">
              <button class="customer-service" @click="openCustomerService">
                <img
                  src="@/assets/img/headphone-icon.svg"
                  alt="Customer Service"
                />
                <span>{{ $t("payermax.customer_service") }}</span>
              </button>
              <div class="privacy-link">
                <span
                  class="link"
                  @click="openLink(purchaseSettings.urls.terms_of_service)"
                  >{{ $t("menu.terms_of_service") }}</span
                >
                <span class="link-seperator">&</span>
                <span
                  class="link"
                  @click="openLink(purchaseSettings.urls.privacy_policy)"
                  >{{ $t("menu.privacy_policy") }}</span
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-dialog
      v-model="showSelectCountry"
      v-if="paymentCountries"
      transition="dialog-bottom-transition"
    >
      <v-card class="select-country-card">
        <div class="select-country-container">
          <div class="country-container-header my-2">
            <span class="header-text">
              {{ $t("payermax.select_country") }}
            </span>
            <button class="close-button" @click="toggleSelectCountry()">
              <img width="15" height="15" src="@/assets/img/cancel-blue.svg" />
            </button>
          </div>
          <div class="search-input-container">
            <img src="@/assets/img/search-icon.svg" />
            <input
              type="text"
              class="search-input ml-2"
              :placeholder="$t('payermax.search_country')"
              v-model="searchCountryText"
              autocorrect="off"
              autocomplete="off"
            />
          </div>
          <div class="mt-2 country-item-container">
            <div
              class="country-item-card"
              v-for="(country, countryIndex) in filteredCountries"
              :key="countryIndex"
            >
              <div
                class="country-item"
                @click="selectCountry(country.iso_code)"
              >
                <img
                  :src="
                    require(`@/assets/img/flags/${countryCodeToFlagName(
                      country.iso_code
                    )}.png`)
                  "
                  class="country-flag"
                />
                <span class="country-name">
                  {{ capitalizeFirstLetters(country.name) }}
                </span>
                <img
                  src="@/assets/img/country-select-thick.svg"
                  v-if="
                    country &&
                    purchaseSettings &&
                    purchaseSettings.country === country.iso_code
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      :value="showPromotionExpired"
      :transition="false"
      :animation="false"
    >
      <v-card class="promotion-expired-wrapper">
        <div class="promotion-expired-container">
          <div>
            <img
              src="@/assets/img/ic-offer.svg"
              :alt="$t('payermax.promotion_outdated_offer_popup_title')"
            />
          </div>
          <div class="promotion-header">
            {{ $t("payermax.promotion_outdated_offer_popup_title") }}
          </div>
          <div class="promotion-info">
            {{ $t("payermax.promotion_outdated_offer_popup_description") }}
          </div>
          <button class="promotion-button" @click="closePromotionExpired">
            {{ $t("labels.ok") }}
          </button>
        </div>
      </v-card>
    </v-dialog>
    <v-overlay color="#fff" :value="isFullLoading">
      <v-progress-circular color="#333" :size="50" indeterminate />
    </v-overlay>
    <SavedCardsBottomSheet
      ref="savedCardsBottomSheet"
      :data="savedPaymentTokens"
      :currency="currency"
      :price="price"
      :provider="
        selectedPaymentMethod != null
          ? selectedPaymentMethod.payment_provider
          : null
      "
      :coin-amount="coinAmount"
      :show-full-page-loading="isSavedPaymentTokenLoading"
      :show-save-card-permission="
        isSaveCardPermissionAvailable(selectedPaymentMethod)
      "
      :show-add-card="isAddCardOptionAvailable(selectedPaymentMethod)"
      @add-card="onAddCard"
      @delete-card="onDeleteCard"
      @pay="onSavedCardPay"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "@/components/Payment/Payermax/Payermax.scss";
</style>

<script>
import { mapState, mapActions } from "vuex";
import { Logger } from "@/logging";
import UserImageCircle from "@/components/Shared/UserImageCircle/UserImageCircle.vue";
import { generatePaymentUUID, formatCoin } from "@/helper/index.js";
import { countryCodeToFlagName } from "@/helper/countryCodeToFlagName.js";
import { PaymentProvider, ProductTag } from "@/helper/enums.js";
import { localStore, STORE_KEYS } from "@/storage";
import SavedCardsBottomSheet from "@/components/Payment/SavedCard/SavedCardsBottomSheet.vue";
import PaymentTokenOperationMixin from "@/components/Payment/Payermax/PaymentMethodBottomSheet/PaymentTokenOperationMixin";
import PaymentMethodProceedMixin from "@/components/Payment/Payermax/PaymentMethodBottomSheet/PaymentMethodProceedMixin";

const logger = new Logger("Payermax");

/** @import  {Payment} from '@/components/Payment/types/payment' */

/**
 * @mixes PaymentMethodProceedMixin
 * @mixes PaymentTokenOperationMixin
 */
export default {
  name: "Payermax",
  components: { SavedCardsBottomSheet, UserImageCircle },
  mixins: [PaymentTokenOperationMixin, PaymentMethodProceedMixin],
  data() {
    return {
      PaymentProvider,
      ProductTag,
      paymentCountries: null,
      purchaseSettings: null,
      products: null,
      selectedProduct: null,
      paymentMethods: null,
      showSelectCountry: false,
      selectedStripeProduct: null,
      bannerId: null,
      bannerData: null,
      onBackClose: false,
      isConfirmOpened: false,
      isIOS: false,
      searchCountryText: null,
      nativeLoadingRestoreValue: true,
      selectedPaymentMethod: null,
    };
  },
  computed: {
    ...mapState({
      isHybridPage: (state) => state.client.isHybridPage,
      currencies: (state) => state.client.currencies,
      deviceId: (state) => state.client.deviceId,
      userCoinInfo: (state) => state.client.userCoinInfo,
      showPromotionExpired: (state) => state.payment.showPromotionExpired,
      isPromotionPayment: (state) => state.payment.isPromotionPayment,
      promotionReferrer: (state) => state.payment.promotionReferrer,
      fromPromotion: (state) => state.payment.fromPromotion,
    }),
    filteredCountries: function () {
      if (this.searchCountryText) {
        return this.paymentCountries.filter((country) =>
          country.name
            .toLowerCase()
            .includes(this.searchCountryText.toLowerCase())
        );
      } else {
        return this.paymentCountries;
      }
    },
    /**
     * @returns {number}
     */
    coinAmount() {
      if (this.selectedPaymentMethod != null) {
        return (
          this.selectedPaymentMethod.payment_price.overriden_coin_amount ??
          this.products[this.selectedProduct].amount
        );
      }

      return 0;
    },
    /** @returns {string|null} */
    currency() {
      if (
        this.selectedPaymentMethod != null &&
        this.selectedPaymentMethod.payment_price != null
      ) {
        const currency =
          this.selectedPaymentMethod.payment_price.currency.toUpperCase();
        const symbol = this.currencies[currency];

        return symbol ?? currency;
      }

      return null;
    },
    /** @returns {number|null} */
    price() {
      return this.selectedPaymentMethod != null &&
        this.selectedPaymentMethod.payment_price != null
        ? this.selectedPaymentMethod.payment_price.price
        : null;
    },
  },
  watch: {
    userCoinInfo() {
      if (this.bannerId) {
        // Do not update. Using promotion products.
      } else if (
        this.userCoinInfo.multi_payment_coin_offer &&
        this.userCoinInfo.multi_payment_coin_offer.products
      ) {
        this.products = this.userCoinInfo.multi_payment_coin_offer.products;
        this.changeSelectedProduct(
          this.userCoinInfo.multi_payment_coin_offer.selected_offer_index
        );
      }
    },
    showPromotionExpired(newVal, oldVal) {
      if (this.showPromotionExpired) {
        this.bannerId = null;
        this.getInappPurchaseSettings();
      }
      if (newVal == false && oldVal == true) {
        this.onBackClose = true;
      }
    },
  },
  mounted() {
    this.isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    const platform = this.$route.query.platform;
    this.setPlatform(platform != null ? platform : null);

    this.bannerId = this.$route.query.banner_id;
    if (this.bannerId) {
      this.setIsPromotionPayment(true);
      this.setFromPromotion(true);
      this.setPromotionReferrer(this.$route.query.referrer);
      window["backButtonTapped"] = () => {
        if (this.showPromotionExpired) {
          this.closePromotionExpired();
          return "nothing";
        } else if (this.onBackClose) {
          this.hybridClose("close");
        } else {
          this.$hybridapi("showLoading");
          this.nativeLoadingRestoreValue = true;
        }
      };
    } else {
      window["backButtonTapped"] = () => {
        if (this.isConfirmOpened) {
          return "nothing";
        }

        if (this.showPromotionExpired) {
          this.closePromotionExpired();
        } else if (this.onBackClose) {
          this.hybridClose("close");
        } else {
          this.isConfirmOpened = true;
          if (confirm(this.$t("labels.close_confirm"))) {
            this.hybridClose("close");
            return "close";
          } else {
            this.isConfirmOpened = false;
          }
        }
        return "nothing";
      };
    }
    this.$hybridapi("updateWindowSize", "full");
    if (this.bannerId) {
      this.$hybridapi("updatePageTitle", " ");
      this.$hybridapi("updateTopBar", "hide");
      this.$hybridapi("hideBackButton", "hide");
      Promise.all([this.getInappPurchaseSettings(), this.getBannerData()]).then(
        ([successSettings, successBanner]) => {
          if (successSettings && successBanner) {
            this.$hybridapi("loadSuccess", null);
            this.$hybridapi("hideLoading");
            this.nativeLoadingRestoreValue = false;
          }
        }
      );
    } else {
      this.$hybridapi("updateTopBar", "show", 255, 255, 255);
      if (this.isIOS) {
        this.$hybridapi("showOnlyNavBackButton");
      }
      this.$hybridapi("updatePageTitle", this.$t("labels.do_payment"));
      const button_enum = "info";
      const action_enum = "hide";
      this.$hybridapi("updateButton", button_enum, action_enum);
      this.getInappPurchaseSettings().then((success) => {
        if (success) {
          this.$hybridapi("loadSuccess", null);
          this.$hybridapi("hideLoading");
          this.nativeLoadingRestoreValue = false;
        }
      });
    }

    window["preloadUpdate"] = (updateData) => {
      logger.debug("preloadUpdate updateData", updateData);
      if (typeof updateData == "string") {
        updateData = JSON.parse(updateData);
      }
      const {
        coin_info,
        // bdi,
        // app_build,
        // platform,
        // automatic,
        device_id,
        auth_token,
        // lang,
        // user_agent,
      } = updateData;

      if (
        coin_info != null &&
        coin_info.coins != null &&
        coin_info.coins != this.userCoinInfo.coins
      ) {
        logger.debug("preloadUpdate: Updating user coins");
        this.$store.dispatch("client/setUserCoinInfo", {
          ...this.userCoinInfo,
          coins: coin_info.coins,
        });
      }

      const old_auth_token = localStore.getItem(STORE_KEYS.AUTH_TOKEN);
      const old_device_id = localStore.getItem(STORE_KEYS.DEVICE_ID);
      if (
        (old_auth_token == null && auth_token != null) ||
        (old_auth_token == null && auth_token != null)
      ) {
        logger.error(
          "preloadUpdate missing auth_token or device_id received:",
          updateData,
          old_auth_token,
          old_device_id
        );
      } else if (old_auth_token != auth_token || old_device_id != device_id) {
        logger.error(
          "preloadUpdate different auth_token or device_id received:",
          updateData,
          old_auth_token,
          old_device_id
        );
      }

      if (this.nativeLoadingRestoreValue) {
        logger.debug("preloadUpdate still loading data");
        this.$hybridapi("showLoading");
      } else {
        logger.debug("preloadUpdate will hide loading again");
        this.$hybridapi("hideLoading");
      }

      return true;
    };
  },
  methods: {
    ...mapActions(["setPlatform"]),
    ...mapActions("payment", [
      "payermaxSelectCountry",
      "promotionSelectCountry",
      "payermaxInappPurchaseSettings",
      "promotionInappPurchaseSettings",
      "payermaxPaymentCountries",
      "payermaxPayment",
      "coinUpdateWebsocket",
      "postHybridCloseChecks",
      "getBanner",
      "promotionBannerInterval",
      "setIsPromotionPayment",
      "setShowPromotionExpired",
      "setPromotionReferrer",
      "setFromPromotion",
    ]),
    listenToCoinUpdateEvents() {
      this.coinUpdateWebsocket(this.$hybrid_websocket_url);
    },
    /**
     * Returns Promise<boolean> (if success)
     */
    getInappPurchaseSettings() {
      if (this.bannerId) {
        return this.promotionInappPurchaseSettings({ banner_id: this.bannerId })
          .then((purchaseSettingsResponse) => {
            if (purchaseSettingsResponse.error) {
              // Error response from server
              logger.debugError(
                "promotionInappPurchaseSettings error",
                purchaseSettingsResponse.error
              );
              this.$toast.error(
                purchaseSettingsResponse.error.message ||
                  this.$t("errors.general_error")
              );
              return false;
            } else {
              // Success response from server
              this.purchaseSettings = purchaseSettingsResponse;

              this.purchaseSettings.coin_info =
                purchaseSettingsResponse.promotion_products_coin_info;

              const selectedProductSku = this.$route.query.product_id;

              if (selectedProductSku) {
                let selectedProductIndex =
                  this.purchaseSettings.promotion_products_coin_info.multi_payment_coin_offer.products
                    .map((object) => object.sku)
                    .indexOf(selectedProductSku);
                this.products =
                  this.purchaseSettings.promotion_products_coin_info.multi_payment_coin_offer.products;
                if (selectedProductIndex >= 0)
                  this.changeSelectedProduct(selectedProductIndex);
              }
              delete this.purchaseSettings.promotion_products_coin_info;

              this.listenToCoinUpdateEvents();
              this.$store.dispatch(
                "client/setUserCoinInfo",
                this.purchaseSettings
              );
              return true;
            }
          })
          .catch((error) => {
            // Network error or server fail
            logger.debugError("promotionInappPurchaseSettings failed", error);
            this.$toast.error(this.$t("errors.general_error"));
            return false;
          });
      } else {
        return this.payermaxInappPurchaseSettings()
          .then((purchaseSettingsResponse) => {
            if (purchaseSettingsResponse.error) {
              // Error response from server
              logger.debugError(
                "payermaxInappPurchaseSettings error",
                purchaseSettingsResponse.error
              );
              this.$toast.error(
                purchaseSettingsResponse.error.message ||
                  this.$t("errors.general_error")
              );
              return false;
            } else {
              // Success response from server
              this.purchaseSettings = purchaseSettingsResponse;
              if (purchaseSettingsResponse) {
                this.listenToCoinUpdateEvents();
                this.$store.dispatch(
                  "client/setUserCoinInfo",
                  this.purchaseSettings.coin_info
                );
              }
              return true;
            }
          })
          .catch((error) => {
            // Network error or server fail
            logger.debugError("payermaxInappPurchaseSettings failed", error);
            this.$toast.error(this.$t("errors.general_error"));
            return false;
          });
      }
    },
    getPaymentCountries() {
      return this.payermaxPaymentCountries()
        .then((paymentCountriesResponse) => {
          if (paymentCountriesResponse.error) {
            // Error response from server
            logger.debugError(
              "payermaxPaymentCountries error",
              paymentCountriesResponse.error
            );
            return false;
          } else {
            // Success response from server
            let paymentCountriesTemp = [];
            paymentCountriesResponse.items.forEach((countriesItem) =>
              countriesItem.countries.forEach((countryItem) => {
                paymentCountriesTemp.push(countryItem);
              })
            );
            paymentCountriesTemp.sort((a, b) => a.name.localeCompare(b.name));
            this.paymentCountries = paymentCountriesTemp;
            return true;
          }
        })
        .catch((error) => {
          // Network error or server fail
          logger.debugError("payermaxPaymentCountries failed", error);
          return false;
        });
    },
    /**
     * Returns Promise<boolean> (if success)
     */
    getBannerData() {
      return this.getBanner(this.bannerId)
        .then((bannerDataResponse) => {
          if (bannerDataResponse.error) {
            // Error response from server
            logger.debugError("getBanner error", bannerDataResponse.error);
            this.$toast.error(
              bannerDataResponse.error.message ||
                this.$t("errors.general_error")
            );
            return false;
          } else {
            // Success response from server
            if (bannerDataResponse.banner.end_time - Date.now() <= 0) {
              // Promo expired case
              this.setIsPromotionPayment(false);
              this.setShowPromotionExpired(true);
              this.$router.push("/payermax");
            } else {
              this.bannerData = bannerDataResponse;
              this.promotionBannerInterval(this.bannerData);
            }
            return true;
          }
        })
        .catch((error) => {
          // Network error or server fail
          logger.debugError("getBanner failed", error);
          this.$toast.error(this.$t("errors.general_error"));
          return false;
        });
    },
    capitalizeFirstLetters(string) {
      const language = window.TheURLSearchParams.get("lang");
      if (string) {
        let changedString = string.toLocaleLowerCase(language);
        let newStringArray = [];
        changedString.split(" ").forEach((splittedItem) => {
          newStringArray.push(
            splittedItem.charAt(0).toLocaleUpperCase(language) +
              splittedItem.substring(1)
          );
        });
        return newStringArray.join(" ");
      }
      return "";
    },
    changeSelectedProduct(index) {
      let selectedProductTemp = this.selectedProduct;
      this.selectedProduct = index;
      this.paymentMethods = this.products[this.selectedProduct].payment_types;
      this.selectedStripeProduct = {
        sku: this.products[this.selectedProduct].sku,
        amount: this.products[this.selectedProduct].amount,
        stripe_price: {},
      };

      if (selectedProductTemp != index) {
        // Generate uuid for payment attempt at product select.
        generatePaymentUUID();
      }
    },
    /**
     * @param {Payment.PaymentMethod} paymentMethod
     */
    async selectPaymentMethod(paymentMethod) {
      if (!this.isFullLoading && paymentMethod.is_available) {
        this.selectedPaymentMethod = paymentMethod;
        this.isFullLoading = true;

        if (paymentMethod.payable_with_saved_card) {
          await this.getSavedPaymentTokens(paymentMethod.payment_provider);
        }

        this.isFullLoading = false;
        await this.onProceedPayment(
          this.products[this.selectedProduct],
          paymentMethod,
          null,
          this.bannerId
        );
      }
    },
    toggleSelectCountry() {
      this.searchCountryText = null;

      if (this.paymentCountries == null) {
        this.isFullLoading = true;
        this.getPaymentCountries().then((success) => {
          this.isFullLoading = false;
          if (success) {
            this.showSelectCountry = true;
          } else {
            this.$toast.error(this.$t("errors.general_error"));
          }
        });
      } else {
        this.showSelectCountry = !this.showSelectCountry;
      }
    },
    selectCountry(country) {
      this.isFullLoading = true;
      this.showSelectCountry = false;
      if (this.bannerId) {
        let promotionCountryData = {
          country: country,
          banner_id: this.bannerId,
        };
        this.promotionSelectCountry(promotionCountryData)
          .then((selectCountryResponse) => {
            this.isFullLoading = false;
            if (selectCountryResponse.error) {
              // Error response from server
              logger.debugError(
                "promotionSelectCountry error",
                selectCountryResponse.error
              );
              this.$toast.error(
                selectCountryResponse.error.message ||
                  this.$t("errors.general_error")
              );
              this.showSelectCountry = true;
            } else {
              // Success response from server
              this.purchaseSettings = selectCountryResponse;
              this.purchaseSettings.coin_info =
                selectCountryResponse.promotion_products_coin_info;
              const selectedProductSku = this.$route.query.product_id;

              if (selectedProductSku) {
                let selectedProductIndex =
                  this.purchaseSettings.promotion_products_coin_info.multi_payment_coin_offer.products
                    .map((object) => object.sku)
                    .indexOf(selectedProductSku);
                this.products =
                  this.purchaseSettings.promotion_products_coin_info.multi_payment_coin_offer.products;
                if (selectedProductIndex >= 0)
                  this.changeSelectedProduct(selectedProductIndex);
              }
              delete this.purchaseSettings.promotion_products_coin_info;
              // NOTE(purchase-bug-fix-pass): Might be a good idea to call "client/setUserCoinInfo" here. Watch for userCoinInfo would need to be updated first. Not fixing for now.
            }
          })
          .catch((error) => {
            // Network error or server fail
            logger.debugError("promotionSelectCountry failed", error);
            this.$toast.error(this.$t("errors.general_error"));
            this.isFullLoading = false;
            this.showSelectCountry = true;
          });
      } else {
        this.payermaxSelectCountry(country)
          .then((selectCountryResponse) => {
            this.isFullLoading = false;
            if (selectCountryResponse.error) {
              // Error response from server
              logger.debugError(
                "payermaxSelectCountry error",
                selectCountryResponse.error
              );
              this.$toast.error(
                selectCountryResponse.error.message ||
                  this.$t("errors.general_error")
              );
              this.showSelectCountry = true;
            } else {
              // Success response from server
              this.purchaseSettings = selectCountryResponse;
              this.$store.dispatch(
                "client/setUserCoinInfo",
                selectCountryResponse.coin_info
              );
            }
          })
          .catch((error) => {
            // Network error or server fail
            logger.debugError("payermaxSelectCountry failed", error);
            this.$toast.error(this.$t("errors.general_error"));
            this.isFullLoading = false;
            this.showSelectCountry = true;
          });
      }
    },
    openCustomerService() {
      this.$hybridapi("openNativeConversation", 1);
    },
    countryCodeToFlagName,
    closeHybridPage() {
      if (
        this.bannerData &&
        this.bannerData.banner.end_time - Date.now() <= 0
      ) {
        this.hybridClose("close");
      } else if (
        this.promotionReferrer &&
        this.bannerData &&
        this.bannerData.banner.end_time - Date.now() > 0
      ) {
        this.$hybridapi("showLoading");
        this.nativeLoadingRestoreValue = true;
        window.location.href = decodeURIComponent(this.promotionReferrer);
      } else {
        this.hybridClose("close");
      }
    },
    closePromotionExpired() {
      this.$store.dispatch("payment/setShowPromotionExpired", false);
    },
    openLink(link) {
      this.$hybridapi("openLinkInBrowser", link);
    },
    getDiscountInformation(product) {
      let discount = NaN;
      if (
        product &&
        product.payment_types &&
        product.payment_types.length > 0
      ) {
        product.payment_types.forEach((paymentType) => {
          if (
            paymentType &&
            paymentType.payment_price &&
            paymentType.payment_price.discount &&
            isNaN(discount)
          ) {
            discount = parseInt(paymentType.payment_price.discount);
            // If first multi payment discount is in use, dont let the discount tag.
            if (discount == 55) discount = NaN;
          }
        });
      }
      return discount;
    },
    formatCoin,
    hybridClose(exit_location = "close") {
      this.$hybridapi("close", exit_location);
      if (this.isHybridPage) this.postHybridCloseChecks();
    },
    /**
     * @param {Payment.SavedPaymentToken | Payment.OlderSavedPaymentToken} savedPaymentToken
     */
    async onDeleteCard(savedPaymentToken) {
      await this.proceedDeleteCard(
        savedPaymentToken,
        this.selectedPaymentMethod.payment_provider
      );
    },
    /**
     * @param {Payment.SavedPaymentToken | Payment.OlderSavedPaymentToken | null} savedPaymentToken
     */
    async onSavedCardPay(savedPaymentToken) {
      await this.proceedSavedCardPay(
        this.products[this.selectedProduct].sku,
        savedPaymentToken,
        this.selectedPaymentMethod,
        this.bannerId
      );
    },
    /**
     * @param {boolean} isSaveCard
     */
    async onAddCard(isSaveCard) {
      await this.proceedAddCard(
        this.products[this.selectedProduct].sku,
        this.selectedPaymentMethod,
        isSaveCard,
        this.bannerId
      );
    },
  },
};
</script>
