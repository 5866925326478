<template>
  <div>
    <div class="columns is-multiline is-mobile">
      <!-- CREDIT CARD PAYMENT -->
      <div
        v-if="this.selectedProduct"
        class="column card-payment-container is-12-mobile is-8-tablet is-offset-2-tablet is-4-desktop is-offset-4-desktop"
      >
        <div class="coin-count">
          <img src="@/assets/img/coin.svg" alt="Coin Icon" />
          {{ this.selectedProduct.amount }}
        </div>
        <div class="price-container">
          <div
            class="old-price"
            v-if="selectedProduct.stripe_price.original_price"
          >
            {{ currencies[selectedProduct.stripe_price.currency.toUpperCase()]
            }}{{ selectedProduct.stripe_price.original_price }}
          </div>
          <div class="current-price">
            {{
              currencies[
                this.selectedProduct.stripe_price.currency.toUpperCase()
              ]
            }}{{ this.selectedProduct.stripe_price.price }}
          </div>
          <div
            class="checkout-badge"
            v-if="
              selectedProduct.stripe_price.original_price &&
              selectedProduct.stripe_price.discount
            "
          >
            %{{ selectedProduct.stripe_price.discount }}
          </div>
        </div>
        <div class="general-container">
          <div class="columns mb-0">
            <div class="column is-12 credit-card-container">
              <label for="cardNumber">{{ $t("labels.card_number") }}</label>

              <the-mask
                mask="#### #### #### ####"
                v-model="cardNumber"
                id="cardNumber"
                type="tel"
                :masked="true"
                v-bind:class="{
                  'input-container': true,
                  error:
                    validation.firstError('cardNumber') ||
                    stripeErrorField === 'cardNumber',
                }"
                :placeholder="$t('labels.card_number')"
                v-on:input="handleInputChange('cardNumber')"
              ></the-mask>

              <div
                class="validation-error-message m-0 p-0"
                v-if="validation.firstError('cardNumber')"
              >
                {{ validation.firstError("cardNumber") }}
              </div>
            </div>
          </div>
          <div class="columns is-mobile mb-0">
            <div class="column is-6 credit-card-container">
              <label class="text-ellipsis" for="cardExpiry">{{
                $t("labels.expire_date")
              }}</label>

              <the-mask
                mask="##/##"
                v-model="cardExpiry"
                id="cardExpiry"
                type="tel"
                :masked="true"
                v-bind:class="{
                  'input-container': true,
                  error:
                    validation.firstError('cardExpiry') ||
                    stripeErrorField === 'cardExpiry',
                }"
                :placeholder="$t('labels.expire_date')"
                v-on:input="handleInputChange('cardExpiry')"
              ></the-mask>

              <div
                class="validation-error-message m-0 p-0"
                v-if="validation.firstError('cardExpiry')"
              >
                {{ validation.firstError("cardExpiry") }}
              </div>
            </div>
            <div class="column is-6 credit-card-container">
              <label for="cardCvc">{{ $t("labels.card_cvc") }}</label>

              <the-mask
                mask="####"
                v-model="cardCvc"
                id="cardCvc"
                type="tel"
                :masked="true"
                v-bind:class="{
                  'input-container': true,
                  error:
                    validation.firstError('cardCvc') ||
                    stripeErrorField === 'cardCvc',
                }"
                :placeholder="$t('labels.card_cvc')"
                v-on:input="handleInputChange('cardCvc')"
              ></the-mask>

              <div
                class="validation-error-message m-0 p-0"
                v-if="validation.firstError('cardCvc')"
              >
                {{ validation.firstError("cardCvc") }}
              </div>
            </div>
          </div>
          <div class="columns mb-0">
            <div class="column is-12 credit-card-container">
              <label for="cardHolder">{{
                $t("labels.card_holder_name")
              }}</label>

              <input
                type="text"
                name="cardHolderName"
                v-model="cardHolder"
                id="cardHolder"
                v-bind:class="{
                  'input-container': true,
                  error:
                    validation.firstError('cardHolder') ||
                    stripeErrorField === 'cardHolder',
                }"
                :placeholder="$t('labels.card_holder_name')"
                v-on:input="handleInputChange('cardHolder')"
              />

              <div
                class="validation-error-message m-0 p-0"
                v-if="validation.firstError('cardHolder')"
              >
                {{ validation.firstError("cardHolder") }}
              </div>
            </div>
          </div>
          <button
            @click="cardPayment"
            class="button custom-button orange-button"
            :disabled="
              validation.firstError('cardHolder') ||
              validation.firstError('cardCvc') ||
              validation.firstError('cardExpiry') ||
              validation.firstError('cardNumber') ||
              loading
            "
          >
            {{ $t("labels.pay") }}
            <img
              src="@/assets/img/loading.svg"
              alt="loading"
              class="loading"
              v-if="loading"
            />
          </button>
          <div class="card-error" v-if="cardError">{{ this.cardError }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "@/components/Payment/Stripe/CreditCardPayment.scss";
</style>
<script>
"use strict";

import { mapState, mapActions } from "vuex";
import {
  createPaymentMethodData,
  stripePaymentRequestData,
} from "@/helper/index.js";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;

import {
  addPaymentInfoEvent,
  startCardCreationEvent,
  cardCreationSuccessEvent,
  paymentAttemptEvent,
  paymentSuccessEvent,
} from "@/helper/marketingevents.js";
import { stripeUuid } from "../../../helper";
import { TheMask } from "vue-the-mask";
import {
  cardCreationSuccessHybridEvent,
  paymentAttemptHybridEvent,
  startCardCreationHybridEvent,
} from "@/firebase_analytics";

const endpoints = require("@/api/endpoints");

// NOTE: Credit card logic changed. Did not update this deprecated file. See PayermaxCreditCard.vue for more info.

export default {
  name: "CreditCardPayment",
  components: { TheMask },
  data() {
    return {
      cardError: null,
      clientSecret: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      cardHolder: null,
      brand: null,
      socketPaymentSucceeded: false,
      loading: false,
      paymentMethodId: null,
      savedCard: null,
      submitted: false,
      stripeErrorField: "",
    };
  },
  computed: {
    ...mapState({
      selectedProduct: (state) => state.payment.selectedProduct,
      currencies: (state) => state.client.currencies,
      buyCoinBackground: (state) => state.client.buyCoinBackground,
      forterToken: (state) => state.client.forterToken,
      isHybridPage: (state) => state.client.isHybridPage,
      ownProfile: (state) => state.client.ownProfile,
      deviceId: (state) => state.client.deviceId,
    }),
  },
  beforeMount() {
    if (!this.selectedProduct) {
      this.$store.dispatch("payment/setStripeActivePage", "coinSelect");
    }
  },
  mounted() {
    window["backButtonTapped"] = () => {
      if (confirm(this.$t("labels.close_confirm"))) {
        this.hybridClose("close");
        return "close";
      }
      return "nothing";
    };
    this.$hybridapi("updateWindowSize", "full");
    this.$hybridapi("updatePageTitle", this.$t("labels.do_payment"));
    this.$hybridapi("hideLoading");

    this.stripePaymentWebsocket(this.$hybrid_websocket_url).then(() => {
      //  Marketing Event
      if (!this.isHybridPage) {
        paymentSuccessEvent(
          this.deviceId,
          this.ownProfile.user.user_id,
          stripeUuid(),
          this.selectedProduct.sku,
          this.selectedProduct.stripe_price.amount,
          this.selectedProduct.stripe_price.price,
          this.selectedProduct.stripe_price.currency,
          1
        );
      }
      this.loading = false;
      this.$hybridapi("hideLoading");
      sessionStorage.removeItem("uuid_stripe_payment");
      this.$store.dispatch("payment/setStripePaymentSucceeded", true);
      this.$store.dispatch("payment/setStripeActivePage", "completePayment");
      this.socketPaymentSucceeded = true;
    });

    if (!this.isHybridPage) {
      // Marketing Event
      startCardCreationEvent(
        this.deviceId,
        this.ownProfile.user.user_id,
        stripeUuid(),
        this.selectedProduct.sku,
        this.selectedProduct.stripe_price.amount,
        this.selectedProduct.stripe_price.price,
        this.selectedProduct.stripe_price.currency
      );
    } else {
      this.setHybridPaymentFlowInformationProps({
        is_new_card: 1,
        hybrid_method: "stripe",
        product_id: this.selectedProduct.sku,
        coins: this.selectedProduct.stripe_price.amount,
        price: this.selectedProduct.stripe_price.price,
        currency: this.selectedProduct.stripe_price.currency,
      });
      startCardCreationHybridEvent();
    }
  },
  methods: {
    ...mapActions("payment", [
      "stripePaymentWebsocket",
      "postHybridCloseChecks",
      "setHybridPaymentFlowInformationProps",
    ]),
    cardPayment() {
      if (this.isHybridPage) {
        paymentAttemptHybridEvent(); // Hybrid Marketting Event
      }
      this.$validate().then((success) => {
        if (success) {
          if (
            !this.isHybridPage &&
            this.selectedProduct &&
            this.selectedProduct.stripe_price
          ) {
            //Marketing Event
            addPaymentInfoEvent(
              this.selectedProduct.sku,
              this.selectedProduct.stripe_price.amount,
              this.selectedProduct.stripe_price.currency,
              this.selectedProduct.stripe_price.price
            );

            paymentAttemptEvent(
              this.deviceId,
              this.ownProfile.user.user_id,
              stripeUuid(),
              this.selectedProduct.sku,
              this.selectedProduct.stripe_price.amount,
              this.selectedProduct.stripe_price.price,
              this.selectedProduct.stripe_price.currency,
              1
            );
          }
          this.loading = true;
          let cardInfo = {
            name: this.cardHolder,
            number: this.cardNumber.replace(/ +/g, ""),
            exp_month: Number(this.cardExpiry.replace("/", "").substring(0, 2)),
            exp_year: Number(this.cardExpiry.replace("/", "").substring(2, 5)),
            cvc: this.cardCvc,
          };

          if (
            this.paymentMethodId &&
            cardInfo.name == this.savedCard.name &&
            cardInfo.number == this.savedCard.number &&
            cardInfo.exp_month == this.savedCard.exp_month &&
            cardInfo.exp_year == this.savedCard.exp_year &&
            cardInfo.cvc == this.savedCard.cvc
          ) {
            endpoints
              .confirm_payment(
                this.$axios,
                stripePaymentRequestData(
                  this.selectedProduct.sku,
                  this.paymentMethodId,
                  this.forterToken
                )
              )
              .then((payWithMethodResponse) => {
                this.createPaymentResponseHandler(payWithMethodResponse.data);
              })
              .catch((err) => {
                this.loading = false;
                this.handleStripeError(err);
              });
          } else {
            this.savedCard = cardInfo;
            endpoints
              .create_payment_method(
                this.$axios,
                createPaymentMethodData(
                  this.selectedProduct.sku,
                  cardInfo,
                  this.forterToken
                )
              )
              .then((createPaymentMethodResponse) => {
                this.createPaymentResponseHandler(
                  createPaymentMethodResponse.data
                );
              })
              .catch((err) => {
                this.loading = false;
                this.handleStripeError(err);
              });
          }
        }
      });
    },
    createPaymentResponseHandler(payWithMethodResponse) {
      if (payWithMethodResponse.status == "succeeded") {
        this.sendCardCreationSuccess();
        sessionStorage.removeItem("uuid_stripe_payment");
        this.$store.dispatch("payment/setStripePaymentSucceeded", true);
        this.$store.dispatch("payment/setStripeActivePage", "completePayment");
      } else if (
        payWithMethodResponse.status == "requires_action" ||
        payWithMethodResponse.status == "requires_confirmation"
      ) {
        this.$stripe
          .handleCardAction(payWithMethodResponse.client_secret)
          .then((handleCardResponse) => {
            this.handleCardResponseHandler(
              handleCardResponse,
              payWithMethodResponse
            );
          });
      } else {
        if (payWithMethodResponse.error) {
          this.handleStripeError(payWithMethodResponse);
        }
        this.loading = false;
        //Marketing Event
        addPaymentInfoEvent(
          this.selectedProduct.sku,
          this.selectedProduct.stripe_price.amount,
          this.selectedProduct.stripe_price.currency,
          this.selectedProduct.stripe_price.price
        );
      }
    },
    handleCardResponseHandler(handleCardResponse, payWithMethodResponse) {
      if (handleCardResponse.error) {
        this.loading = false;
        this.handleStripeError(handleCardResponse);
        sessionStorage.removeItem("uuid_stripe_payment");
        stripeUuid();
        this.paymentMethodId = handleCardResponse.error.payment_method.id;
      } else {
        this.sendCardCreationSuccess();
        endpoints
          .reconfirm_payment(this.$axios, {
            payment_id: payWithMethodResponse.payment_id,
          })
          .then((reConfirmResponse) => {
            this.reConfirmResponseHandler(reConfirmResponse.data);
          })
          .catch((err) => {
            this.loading = false;
            this.handleStripeError(err);
          });
      }
    },
    reConfirmResponseHandler(reConfirmResponse) {
      if (reConfirmResponse && reConfirmResponse.error) {
        this.loading = false;
        this.handleStripeError(reConfirmResponse);
      }
    },
    sendCardCreationSuccess() {
      if (!this.isHybridPage) {
        // Marketing Event
        cardCreationSuccessEvent(
          this.deviceId,
          this.ownProfile.user.user_id,
          stripeUuid(),
          this.selectedProduct.sku,
          this.selectedProduct.stripe_price.amount,
          this.selectedProduct.stripe_price.price,
          this.selectedProduct.stripe_price.currency
        );
      } else {
        cardCreationSuccessHybridEvent();
      }
    },
    handleStripeError(res) {
      this.stripeErrorField = "";
      if (res?.error?.code === 1010) {
        this.stripeErrorField = "cardNumber";
      } else if (res?.error?.code === 1011) {
        this.stripeErrorField = "cardExpiry";
      } else if (res?.error?.code === 1012) {
        this.stripeErrorField = "cardCvc";
      }
      if (res?.error?.message) {
        this.cardError = res.error.message;
      } else {
        this.cardError = this.$t("errors.general_error");
      }
    },
    handleInputChange(inputName) {
      if (inputName === this.stripeErrorField) {
        this.stripeErrorField = "";
        this.cardError = "";
      }
    },
    createPaymentMethodData,
    stripePaymentRequestData,
    hybridClose(exit_location = "close") {
      this.$hybridapi("close", exit_location);
      if (this.isHybridPage) this.postHybridCloseChecks();
    },
  },
  validators: {
    cardHolder: function (value) {
      return Validator.value(value).required();
    },
    cardNumber: function (value) {
      if (value) {
        return Validator.value(value.replace(/ +/g, "")).required().length(16);
      } else {
        return Validator.value(value).required();
      }
    },
    cardExpiry: function (value) {
      if (value) {
        return Validator.value(value.replace("/", "")).required().length(4);
      } else {
        return Validator.value(value).required();
      }
    },
    cardCvc: function (value) {
      return Validator.value(value).required().lengthBetween(3, 4);
    },
  },
};
</script>
