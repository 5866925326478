<template>
  <div class="live-stream-messages-container">
    <div class="messages" id="LiveMessages" ref="liveMessagesDiv">
      <div v-if="activeStream" class="rule-message">
        {{ activeStream.initial_stream_message }}
      </div>
      <div
        class="message-container"
        v-for="(message, index) in messages"
        :key="index"
      >
        <div class="message" :class="[returnVipString(message.vip_state)]">
          <template
            v-if="message.type && message.type == 'livestream_new_follower'"
          >
            <level-badge :user="message"></level-badge>
            <span
              class="name"
              :class="[returnVipString(message.vip_state)]"
              @click="openHalfProfile(message.user_id)"
              >{{ message.name }}</span
            ><span class="new-follower-label">
              {{ $t("labels.new_follower") }} </span
            >👋
          </template>
          <template v-else>
            <span class="camera" v-if="selectedUser.user_id == message.user_id"
              ><i class="fas fa-video"></i
            ></span>
            <level-badge :user="message"></level-badge>
            <v-btn
              class="name no-uppercase chat-btn-padding"
              @click="openHalfProfile(message.user_id)"
              :class="[returnVipString(message.vip_state)]"
              text
            >
              {{ message.name }}
            </v-btn>
            <span class="vip-badge" v-if="message.vip_state > 0">
              <img
                v-if="returnVipString(message.vip_state)"
                :src="
                  require(`@/assets/img/${returnVipString(
                    message.vip_state
                  )}-vip.svg`)
                "
              />
            </span>
            <span v-html="message.text"></span>
          </template>
        </div>
      </div>
    </div>
    <div class="follow-message" v-if="showFollowMessage">
      <v-btn
        v-if="selectedUser !== null && selectedUser.is_followed === true"
        class="follow-icon-svg"
        :loading="isFollowLoading"
        :disabled="isFollowLoading"
        @click="clickUnfollowUser(selectedUser.user_id)"
        depressed
        rounded
        small
      >
        <img
          src="@/assets/img/unfollow-icon.svg"
          :alt="$t('general.unfollow_icon')"
        />
      </v-btn>
      <v-btn
        v-else-if="selectedUser !== null && selectedUser.is_followed === false"
        class="follow-icon-svg"
        :loading="isFollowLoading"
        :disabled="isFollowLoading"
        @click="clickFollowUser(selectedUser.user_id)"
        rounded
        small
      >
        <img
          :src="require(`@/assets/img/follow-icon_${brandName}.svg`)"
          :alt="$t('general.follow_icon')"
        />
      </v-btn>
      {{ $t("labels.follow") }}
      {{ selectedUser.name }}
      <user-image-circle :user="selectedUser" :size="'25px'" />
    </div>
    <div class="joined-message" v-if="joinedUser">
      <div class="message" :class="[returnVipString(joinedUser.vip_state)]">
        <level-badge :user="joinedUser"></level-badge>
        <span
          class="name"
          @click="openHalfProfile(joinedUser.user_id)"
          :class="[returnVipString(joinedUser.vip_state)]"
          >{{ joinedUser.name }}</span
        >
        <span class="vip-badge" v-if="joinedUser.vip_state > 0">
          <img
            v-if="returnVipString(joinedUser.vip_state)"
            :src="
              require(`@/assets/img/${returnVipString(
                joinedUser.vip_state
              )}-vip.svg`)
            "
          />
        </span>
        {{ $t("livestream.join_the_stream") }}
      </div>
    </div>
    <send-message
      v-on:disableFullScreen="(val) => $emit('disableFullScreen', val)"
    />
  </div>
</template>
<style lang="scss" scoped>
@import "Messages.scss";
</style>
<script>
import SendMessage from "@/components/Chat/SendMessage.vue";
import { mapState, mapActions } from "vuex";
import LevelBadge from "@/components/Shared/LevelBadge/LevelBadge.vue";
import UserImageCircle from "@/components/Shared/UserImageCircle/UserImageCircle.vue";
import { FollowPageEnums } from "@/helper/enums.js";
import { returnVipString } from "@/helper/index.js";

export default {
  name: "Messages",
  props: ["activeStream", "messages", "selectedUser"],
  components: {
    SendMessage,
    LevelBadge,
    UserImageCircle,
  },
  mounted() {
    if (this.selectedUser !== null && this.selectedUser.is_followed === false) {
      setTimeout(() => {
        this.showFollowMessage = true;
      }, 3000);
    }
  },
  updated() {
    if (this.messagesScroll) {
      this.$refs.liveMessagesDiv.scrollTop =
        this.$refs.liveMessagesDiv.scrollHeight;
      this.$store.dispatch("live/setMessagesScroll", false);
    }
  },
  data() {
    return {
      showFollowMessage: false,
    };
  },
  computed: {
    ...mapState({
      messagesScroll: (state) => state.live.messagesScroll,
      brandName: (state) => state.brandName,
      isFollowLoading: (state) => state.client.isFollowLoading,
      joinedUser: (state) => state.live.joinedUser,
    }),
  },
  methods: {
    ...mapActions("client", ["openHalfProfile", "followUser", "unfollowUser"]),
    showDownloadApp() {
      this.$emit("showDownloadApp");
    },
    clickFollowUser() {
      this.followUser({
        user_id: this.selectedUser.user_id,
        page_enum: FollowPageEnums.OTHER_PROFILE,
      });
    },
    clickUnfollowUser() {
      this.unfollowUser({
        user_id: this.selectedUser.user_id,
        page_enum: FollowPageEnums.OTHER_PROFILE,
      });
    },
    returnVipString,
  },
};
</script>
