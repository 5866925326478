<template>
  <div>
    <div class="page-right-container">
      <user-followings />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "FollowingsView.scss";
</style>
<script>
import UserFollowings from "@/components/Follow/UserFollowings/UserFollowings.vue";

export default {
  name: "FollowingsView",
  components: {
    UserFollowings,
  },
};
</script>
