<template>
  <div>
    <!--
    <div class="page-left-container">
      <DiscoverLeft />
    </div>
    -->
    <div class="page-right-container">
      <Discover />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "DiscoverView.scss";
</style>
<script>
import Discover from "@/components/Discover/Discover.vue";
// import DiscoverLeft from "@/components/Discover/DiscoverLeft.vue";

export default {
  name: "DiscoverView",
  components: {
    Discover,
    // DiscoverLeft
  },
};
</script>
