const endpoints = require("@/api/endpoints");
import AgoraRTC from "agora-rtc-sdk-ng";
import AgoraRTM from "agora-rtm-sdk";
import EmptyProfile from "@/assets/img/empty_profile.png";
import coinIcon from "@/assets/img/coin.svg";
import i18n from "@/i18n.js";
import router from "@/router";
import {
  enterStreamEvent,
  enter4thStreamEvent,
  liveStreamSelectEvent,
  liveStreamViewPageEvent,
  liveStreamEnterEvent,
  liveStreamAcceptClickEvent,
  liveStreamAcceptSuccessEvent,
  liveStreamAcceptErrorEvent,
} from "@/helper/marketingevents.js";
import { DiscoverFilterTypeEnum } from "@/helper/enums.js";
import mockSocket from "@/mocks/index";
import {
  isElementOverflowing,
  wrapContentsInMarquee,
  generateRandomStringId,
  generateUUID,
} from "@/helper";
const { localStore, STORE_KEYS } = require("@/storage");

let webSocketPromise = null;

export const actions = {
  setRtmChannel({ commit }, rtmChannel) {
    commit("setRtmChannel", rtmChannel);
  },
  setLivestreamId({ commit }, payload) {
    commit("setLivestreamId", payload);
  },
  setMessages({ commit }, payload) {
    commit("setMessages", payload);
  },
  setLoaderState({ commit }, payload) {
    commit("setLoaderState", payload);
  },
  setMessagesScroll({ commit }, payload) {
    commit("setMessagesScroll", payload);
  },
  addMessage({ commit }, payload) {
    commit("addMessage", payload);
  },
  addGift({ commit }, gift) {
    commit("addGift", gift);

    const msgText =
      i18n.t("livestream.i_sent_gift") +
      ' <span style="color:goldenrod;">' +
      gift.gift.name +
      "</span> " +
      '<img src="' +
      gift.gift.image +
      '" style="width:16px; vertical-align:middle;"/> <img src="' +
      coinIcon +
      '" style="vertical-align:middle" class="message-coin" /> <span style="color:goldenrod;">' +
      gift.gift.cost +
      "</span>";

    const message = {
      vip: gift.vip,
      vip_state: gift.vip_state,
      name: gift.name,
      user_id: gift.user_id,
      text: msgText,
    };

    commit("addMessage", message);
  },
  addDiscoverStreams({ commit }, discoverStreams) {
    if (discoverStreams) commit("addDiscoverStreams", discoverStreams);
  },
  setNextId({ commit }, nextId) {
    commit("setNextId", nextId);
  },
  initializeAgoraClients({ commit, state }, payload) {
    if (!state.rtcClient) {
      AgoraRTC.setLogLevel(4);
      const rtcClient = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
      const rtmClient = AgoraRTM.createInstance(payload.app_id, {
        enableLogUpload: false,
        logFilter: AgoraRTM.LOG_FILTER_OFF,
      });
      rtcClient.setClientRole(state.options.role);

      commit("setRtcClient", rtcClient);
      commit("setRtmClient", rtmClient);
    }
  },
  getDiscover({ state, commit, dispatch, rootState }, infiniteLoaderState) {
    if (rootState.client.axiosReady) {
      endpoints
        .discover(
          this._vm.$axios,
          {
            next: state.nextId == "first" ? null : state.nextId,
          },
          state.discoverFilterType
        )
        .then((discoverResult) => {
          if (infiniteLoaderState) infiniteLoaderState.loaded();

          // IF USER LOGGED OUT FOR SOME REASON
          if (
            discoverResult.data.error &&
            discoverResult.data.error.code === "2"
          ) {
            dispatch("logout");
          }

          const { next } = discoverResult.data.meta;
          commit("setNextId", next);

          if (infiniteLoaderState) {
            commit("addDiscoverStreams", discoverResult.data.items);
            if (!next) {
              infiniteLoaderState.complete();
            }
          } else {
            commit("setDiscoverStreams", discoverResult.data.items);
          }
        });
    }
  },
  resetDiscover({ commit }) {
    commit("setDiscoverStreams", []);
  },
  connectChannel(
    { commit, dispatch, state, rootState },
    { livestreamId, userId, premiumGift, isPremiumAccepted = false }
  ) {
    // Marketing Event: START
    if (isPremiumAccepted == false) {
      let liveStreamFlowId = generateUUID();
      commit("setLiveStreamFlowId", liveStreamFlowId);
      liveStreamSelectEvent(
        rootState.client.deviceId,
        rootState.client.ownProfile.user.user_id,
        livestreamId,
        userId,
        state.liveStreamSource,
        liveStreamFlowId
      );
    }
    // Marketing Event: END
    dispatch("leaveStream");
    commit("setLivestreamLoading", true);
    commit("setStreamFinished", false);
    commit("setStreamStopped", false);

    endpoints
      .livestream_retrieve(this._vm.$axios, { livestream_id: livestreamId })
      .then(async (livestreamResponse) => {
        if (
          livestreamResponse.data.error &&
          livestreamResponse.data.error.code == 3
        ) {
          router.push("/");
          commit("setLivestreamLoading", false);
          return false;
        }
        //Kicked user configuration
        else if (
          livestreamResponse.data.error &&
          livestreamResponse.data.error.code == 9
        ) {
          this._vm.$toast.error(i18n.t("livestream.kicked_by_streamer"));
          router.push("/");
          commit("setLivestreamLoading", false);
          return false;
        }

        const stream = livestreamResponse.data.stream_details;
        commit("setLivestreamId", livestreamId);
        commit("setLivestreamType", stream.type);

        if (stream.guests.length > 0) {
          // todo: MULTIPLE GUEST için burası değiştirilecek.
          commit("setActiveGuest", stream.guests[0]);
        }
        commit("setActiveStream", { ...stream, livestream_id: livestreamId });
        commit("setSelectedUser", livestreamResponse.data.user);
        if (state.selectedUser?.user_id !== userId) {
          endpoints
            .user_profile(this._vm.$axios, {
              user_id: state.selectedUser.user_id,
            })
            .then(async (userResponse) => {
              if (
                userResponse.data.error &&
                userResponse.data.error.code == 9
              ) {
                router.push("/");
                commit("setLivestreamLoading", false);
                return false;
              }
              let selectedUser = userResponse.data.user;
              commit("setSelectedUser", selectedUser);
              commit("client/setShowJoinNow", false, { root: true });
              if (!(selectedUser?.profile_images?.length > 0)) {
                selectedUser.profile_images = [{ thumbnail_url: EmptyProfile }];
              }
            });
        }

        commit("setDiamondCount", stream.diamond_count);
        commit("setViewerCount", stream.viewer_count);
        if (!stream.finished_at) {
          //Marketing Event: Start
          if (isPremiumAccepted == false) {
            liveStreamViewPageEvent(
              rootState.client.deviceId,
              rootState.client.ownProfile.user.user_id,
              state.livestreamId,
              state.selectedUser.user_id,
              stream.type,
              stream.private_livestream_gift
                ? stream.private_livestream_gift.gift_id
                : 0,
              stream.private_livestream_gift
                ? stream.private_livestream_gift.cost
                : 0,
              state.liveStreamSource,
              state.liveStreamFlowId
            );
          }
          //Marketing Event: End

          // CHECK PRIVATE STREAM private_livestream_gift
          // Yayın tipi 2 (2 = premium) ve user accepted değil ise premium yayına giriş modal'ı açılır.
          if (stream.type == 2 && !stream.is_accepted) {
            commit("setPremiumInfo", {
              ...stream,
              livestream_id: livestreamId,
            });
            commit("setLivestreamLoading", false);
            commit("setShowAcceptPremium", true);
          } else {
            await dispatch("joinLiveStream", {
              livestreamId,
              stream,
              premiumGift,
              isPremiumAccepted,
            });
          }
        } else {
          dispatch("streamFinishedScreen");
        }
      });
  },
  async joinLiveStream(
    { commit, state, rootState, dispatch },
    { livestreamId, stream, premiumGift = [], isPremiumAccepted = false }
  ) {
    if (isPremiumAccepted == false) {
      // Marketing Event: Start
      liveStreamEnterEvent(
        rootState.client.deviceId,
        rootState.client.ownProfile.user.user_id,
        state.livestreamId,
        state.selectedUser.user_id,
        state.liveStreamSource,
        state.liveStreamFlowId
      );
      //Marketing Event: End
    }
    // ENTER LIVESTREAM FOR SOCKET
    const livestreamEnterData = JSON.stringify({
      id: generateRandomStringId(),
      action: "enter_livestream",
      data: { livestream_id: livestreamId },
    });
    state.webSocket.send(livestreamEnterData);
    commit("setHeartbeatState", "livestream:" + livestreamId);
    commit("setRemoteAudioTracks", []);

    // GET TOP GIFTERS
    endpoints
      .get_topgifters(this._vm.$axios, { livestream_id: livestreamId })
      .then(async (topGifterResponse) => {
        const topGifters = [];
        topGifterResponse.data.gifters.forEach((gifter) => {
          topGifters.push(gifter.user);
        });
        commit("setTopGifters", topGifters);
      });

    // JOIN AGORA CHANNEL
    try {
      await state.rtcClient.join(
        rootState.client.settings.agora_app_id,
        stream.channel_id,
        stream.agora_channel_token,
        stream.agora_id
      );
    } catch (e) {
      commit("setLivestreamLoading", false);
      dispatch("leaveStream");
      this._vm.$toast.error(e.message);
    }
    // AGORA RTM CONNECTION FOR MESSAGES
    endpoints
      .livestream_enter(this._vm.$axios, { livestream_id: livestreamId })
      .then((response) => {
        const liveStreamEnterEvents = response.data.agora_events;

        const rtmUid =
          rootState.client.ownProfile.user.user_id || rootState.client.deviceId;

        const rtmOptions = {
          // todo: user login değilken token null geliyor, burası güncellenecek
          token: stream.agora_rtm_token,
          uid: rtmUid,
        };
        state.rtmClient.login(rtmOptions).then(async () => {
          const rtmChannel = state.rtmClient.createChannel(stream.channel_id);
          await rtmChannel.join();
          dispatch("setRtmChannel", rtmChannel);

          // When user join rtm channel, start interval for gift and vip enter events
          dispatch("giftInterval");
          dispatch("vipMessageInterval");
          dispatch("vipEnterInterval");

          const allAgoraEvents = [...liveStreamEnterEvents, ...premiumGift];

          allAgoraEvents.length > 0 &&
            allAgoraEvents.forEach((item) => {
              const rtmChannelMessage = {
                messageType: "TEXT",
                text: JSON.stringify(item),
              };
              state.rtmChannel
                .sendMessage(rtmChannelMessage)
                .then((sendResult) => {
                  dispatch("handleChannelMessages", rtmChannelMessage);
                })
                .catch((error) => {
                  console.error("error: ", error);
                });
            });

          state.rtmChannel.on("ChannelMessage", (message) => {
            dispatch("handleChannelMessages", message);
          });
        });
        enterStreamEvent(livestreamId); //Marketting Event
        dispatch("addJoinStreamCount", livestreamId);
      });

    state.rtcClient.on("user-published", async (user, mediaType) => {
      // BEFORE VIDEO STARTED SET WATCHING STATUS
      commit("setWatchingStream", true);
      commit("setStreamStopped", false);
      commit("setLivestreamLoading", false);

      // SUBSCRIBE AGORA VIDEO AND SET VIDEO AND AUDIO IN PAGE
      await state.rtcClient.subscribe(user, mediaType);
      if (mediaType === "video") {
        const remoteVideoTrack = user.videoTrack;
        const playerContainer = document.createElement("div");
        playerContainer.id = user.uid.toString();
        playerContainer.style.width = "100%";
        playerContainer.style.height = "100%";

        if (user.uid == 1) {
          // uid 1 ise ana yayıncı değil ise guest
          const streamContainer = document.getElementsByClassName(
            "live-stream-container"
          );
          streamContainer[0].innerHTML = "";
          streamContainer[0].append(playerContainer);
          remoteVideoTrack.play(playerContainer);

          // FOR HEADLINE MARQUEE
          var element = document.getElementById("streamHeadline");
          if (isElementOverflowing(element)) {
            wrapContentsInMarquee(element);
          }
        } else {
          // GUEST User Section
          commit("setShowGuest", true);
          commit("setGuestStopped", false);
          const guestContainer = document.getElementsByClassName("guest-inner");
          guestContainer[0].innerHTML = "";
          guestContainer[0].append(playerContainer);
          remoteVideoTrack.play(playerContainer);
        }
      }
      if (mediaType === "audio") {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
        remoteAudioTrack.setVolume(100);
        commit("setRemoteAudioTracks", [
          ...state.remoteAudioTracks,
          remoteAudioTrack,
        ]);
      }
    });
    state.rtcClient.on("user-unpublished", async (user) => {
      // WHEN THE STREAM STOPPED
      if (user.uid == 1) {
        commit("setLivestreamLoading", false);
        dispatch("stopStream");
      } else {
        commit("setGuestStopped", true);
      }
    });
  },
  resetLiveStream({ commit }) {
    commit("setGifts", []);
    commit("setVips", []);
    commit("setMessages", []);
    commit("setActiveGuest", null);
    commit("setJoinedUser", null);
    commit("setActiveStream", null);
    commit("setDiamondCount", 0);
    commit("setViewerCount", 0);
    commit("setHeartbeatState", "general");
    commit("setTopGifters", []);
    commit("setShowAcceptPremium", false);
    commit("setPremiumInfo", {
      is_invited: false,
      livestream_id: 0,
      private_livestream_gift: {
        cost: 0,
        gift_id: 0,
        image: "",
        name: "",
      },
      rating_info: null,
      refund_available: false,
    });
  },
  leaveStream({ state, dispatch }) {
    dispatch("resetLiveStream");
    dispatch("client/setShowHalfProfile", false, { root: true });
    dispatch("doLeaveStream");
    if (state.showViewerCard == true) dispatch("toggleViewerCard");
  },
  doLeaveStream({ state }) {
    state.rtcClient?.leave();
    if (state.liveStreamId) {
      endpoints
        .livestream_exit(this._vm.$axios, {
          livestream_id: state.liveStreamId,
        })
        .then((response) => {
          if (
            state.rtmChannel !== null &&
            response.data &&
            response.data.agora_events
          ) {
            response.data.agora_events.forEach((item) => {
              state.rtmChannel
                .sendMessage({
                  messageType: "TEXT",
                  text: JSON.stringify(item),
                })
                .then(() => {})
                .catch((err) => console.error("exit error: ", err));
            });
          }
        });
    } else {
      state.rtmClient?.logout();
    }
  },
  stopStream({ commit }) {
    commit("setStreamStopped", true);
    // commit("setWatchingStream", false);
    commit("setShowGuest", false);
  },
  streamFinishedScreen({ commit }) {
    commit("setStreamStopped", false);
    commit("setStreamFinished", true);
    commit("setShowGuest", false);
    commit("setWatchingStream", false);
  },
  closeStream({ commit, dispatch }, withoutBack = false) {
    dispatch("leaveStream");
    commit("setStreamFinished", false);
    commit("setStreamStopped", false);
    commit("setWatchingStream", false);
    if (!withoutBack) router.go(-1);
  },
  closeGuest({ commit }) {
    commit("setShowGuest", false);
  },
  handleChannelMessages({ dispatch }, message) {
    let messageContent = JSON.parse(message.text);
    messageContent.data = JSON.parse(messageContent.data);
    dispatch("handleChannelMessageContent", messageContent);
  },
  handleChannelMessageContent({ commit, dispatch }, messageContent) {
    if (messageContent.type == 1) {
      // TEXT
      commit("addMessage", messageContent.data);
      if (messageContent.data.highlight) {
        commit("addVipMessage", messageContent.data);
      }
    } else if (messageContent.type == 2) {
      // VIP ENTER
      commit("addVip", messageContent.data);
    } else if (messageContent.type == 3) {
      // GIFT
      const gift = messageContent.data;
      dispatch("addGift", gift);
    } else if (messageContent.type == 4) {
      // TOP GIFTER UPDATE
      commit("setTopGifters", messageContent.data.gifters);
    } else if (messageContent.type == 5) {
      // VIEWER COUNT
      commit("setViewerCount", messageContent.data.viewers);
    } else if (messageContent.type == 6) {
      // DIAMOND COUNT
      commit("setDiamondCount", messageContent.data.diamonds);
    } else if (messageContent.type == 7) {
      // ARRIVAL MESSAGE
      commit("setJoinedUser", messageContent.data);
    }
  },
  websocketAuth({ rootState, state }, authToken) {
    let randomId = Math.random();
    randomId = randomId.toString();
    state.webSocket.send(
      JSON.stringify({
        id: randomId,
        action: "auth",
        data: { token: authToken },
      })
    );
  },
  connectWebsocket({ state, rootState, commit, dispatch }) {
    // WEBSOCKET START
    let webSocket = null;
    if (webSocketPromise != null) return webSocketPromise;

    const { heartbeat, url } = rootState.client.settings.websocket;

    const webSocketParams =
      "?" +
      "device=" +
      rootState.client.deviceId +
      (rootState.client.authToken ? "&auth=" + rootState.client.authToken : "");

    if (
      ["development", "test"].includes(process.env.NODE_ENV) &&
      process.env.VUE_APP_WITH_MOCK == "true"
    ) {
      webSocket = new WebSocket(
        "wss://demo.piesocket.com/v3/channel_1?api_key=VCXCEuvhGcBDP7XhiJJUDvR1e1D3eiVjgZ9VRiaV&device=test_123&auth=test_123"
      );
    } else {
      webSocket = new WebSocket(url + webSocketParams);
    }

    webSocket.onmessage = (event) => {
      let data = null;
      if (process.env.VUE_APP_WITH_MOCK == "true") {
        data = mockSocket;
        setTimeout(() => {
          dispatch("handleWebSocketMessage", data);
        }, 5000);
      } else {
        const socketMessage = JSON.parse(event.data);
        dispatch("handleWebSocketMessage", socketMessage);
      }
    };

    const heartbeatInterval = setInterval(() => {
      if (webSocket.readyState === 1) {
        let randomId = Math.random();
        randomId = randomId.toString();
        webSocket.send(
          JSON.stringify({
            id: randomId,
            action: "heartbeat",
            data: { state: state.heartbeatState },
          })
        );
      } else if (webSocket.readyState === 3) {
        clearInterval(heartbeatInterval);
      }
    }, heartbeat * 1000);

    webSocket.onclose = () => {
      webSocketPromise = null;
      dispatch("connectWebsocket");
    };

    webSocketPromise = new Promise((resolve) => {
      webSocket.onopen = () => {
        resolve();
      };
    });

    commit("setWebSocket", webSocket);
    return webSocketPromise;
  },
  handleWebSocketMessage({ state, dispatch, commit }, socketMessage) {
    // IF MESSAGE BELOW MY ACTIVE STREAM
    if (
      state.activeStream &&
      socketMessage.data &&
      socketMessage.data.livestream_id &&
      socketMessage.data.livestream_id == state.activeStream.livestream_id
    ) {
      // LIVESTREAM ENDED
      if (socketMessage.type == "livestream_ended") {
        dispatch("streamFinishedScreen");
      }
      // TOP GIFTERS UPDATE
      else if (socketMessage.type == "livestream_top_gifters_update") {
        commit("setTopGifters", socketMessage.data.gifters);
      }
      // LIVESTREAM VIEWERS UPDATE
      else if (socketMessage.type == "livestream_viewers_update") {
        commit("setViewerCount", socketMessage.data.viewers);
      }
      // DIAMONDS UPDATE
      else if (socketMessage.type == "livestream_diamonds_update") {
        commit("setDiamondCount", socketMessage.data.diamonds);
      }
      // LIVESTREAM MESSAGE SENT
      else if (socketMessage.type == "livestream_message_sent") {
        commit("addMessage", socketMessage.data);
      }
      // LIVESTREAM GIFT SENT
      else if (socketMessage.type == "livestream_gift_sent") {
        const gift = socketMessage.data;
        dispatch("addGift", gift);
      }
      // LIVESTREAM ARRIVAL MESSAGE
      else if (socketMessage.type == "livestream_arrival_message") {
        commit("setJoinedUser", socketMessage.data);
      }
      // LIVESTREAM VIP ENTER
      else if (socketMessage.type == "livestream_vip_enter") {
        commit("addVip", socketMessage.data);
      }
      // LIVESTREAM GUEST JOIN
      else if (socketMessage.type == "livestream_guest_join") {
        commit("setActiveGuest", socketMessage.data.guest);
      }
      // LIVESTREAM GUEST LEAVE
      else if (socketMessage.type == "livestream_guest_leave") {
        commit("setActiveGuest", null);
      }
      // LIVESTREAM TURNED PREMUIM - PRIVATE
      else if (socketMessage.type == "livestream_turned_private") {
        commit("setPremiumInfo", socketMessage.data);
        commit("setShowAcceptPremium", true);
      }
      // LIVESTREAM KICKED
      else if (socketMessage.type == "livestream_kicked") {
        dispatch("streamFinishedScreen");
        this._vm.$toast.error(i18n.t("livestream.kicked_by_streamer"));
      }
      //LIVESTREAM MUTE
      else if (socketMessage.type == "livestream_muted") {
        this._vm.$toast.error(i18n.t("livestream.muted_by_streamer"));
      }
      //LIVESTREAM UNMUTE
      else if (socketMessage.type == "livestream_unmuted") {
        this._vm.$toast.success(i18n.t("livestream.unmuted_by_streamer"));
      }
      // LIVESTREAM PRIVATE REFUND
      else if (socketMessage.type == "livestream_private_refund") {
        this._vm.$toast.success(i18n.t("livestream.refund_info_message"));
      } else if (socketMessage.type == "livestream_new_follower") {
        socketMessage.data.type = "livestream_new_follower";
        commit("addMessage", socketMessage.data);
      }
    }
    if (socketMessage.data) {
      if (socketMessage.type == "conversation_message_received") {
        dispatch("conversationHandler", socketMessage.data);
      } else if (socketMessage.type == "general_unseen_message") {
        commit("client/setUnseenUserMessageCount", socketMessage.data.count, {
          root: true,
        });
      } else if (socketMessage.type == "general_unseen_notifications") {
        commit("client/setUnseenNotificationCount", socketMessage.data.count, {
          root: true,
        });
      }
    }
  },
  conversationHandler({ dispatch }, socketMessageData) {
    dispatch(
      "client/addToConversationStatuses",
      socketMessageData.conversation_status,
      { root: true }
    );
  },
  giftInterval({ state, commit }) {
    setInterval(() => {
      if (!state.activeGift && state.gifts.length > 0) {
        let activeGift = state.gifts[0];
        if (!activeGift.profile_image) {
          activeGift.profile_image = {};
          activeGift.profile_image.thumbnail_url = EmptyProfile;
        }
        commit("setActiveGift", activeGift);
        commit("shiftGifts");
        setTimeout(() => {
          commit("setShowGiftClass", true);
        }, 100);
        setTimeout(() => {
          commit("setShowGiftClass", false);
          setTimeout(() => {
            commit("setActiveGift", null);
          }, 100);
        }, 1000);
      }
    }, 1000);
  },
  vipMessageInterval({ state, commit }) {
    setInterval(() => {
      if (!state.activeVipMessage && state.vipMessages.length > 0) {
        let activeVipMessage = state.vipMessages[0];
        if (!activeVipMessage.profile_image) {
          if (activeVipMessage.picture_url) {
            activeVipMessage.profile_image = {
              thumbnail_url: activeVipMessage.picture_url,
            };
          } else {
            activeVipMessage.profile_image = {
              thumbnail_url: EmptyProfile,
            };
          }
        }
        commit("setActiveVipMessage", activeVipMessage);
        let vipMessageIntervalTime =
          state.vipMessages.length == 1 ? 6000 : 2500;
        commit("shiftVipMessages");
        setTimeout(() => {
          commit("setShowVipMessageClass", true);
        }, 100);
        setTimeout(() => {
          commit("setShowVipMessageClass", false);
          setTimeout(() => {
            commit("setActiveVipMessage", null);
          }, 100);
        }, vipMessageIntervalTime);
      }
    }, 1000);
  },
  vipEnterInterval({ state, commit }) {
    setInterval(() => {
      if (!state.activeVip && state.vips.length > 0) {
        commit("setActiveVip", state.vips[0]);
        commit("shiftVips");
        commit("setShowVipClass", true);
        setTimeout(() => {
          commit("setShowVipClass", false);
          commit("setActiveVip", null);
        }, 2000);
      }
    }, 1000);
  },
  setShowSendGift({ commit }, payload) {
    commit("setShowSendGift", payload);
  },
  sendGift({ rootState, state, dispatch }, gift) {
    if (gift) {
      let coinCount =
        rootState.client.userCoinInfo && rootState.client.userCoinInfo.coins
          ? rootState.client.userCoinInfo.coins
          : 0;
      if (gift.cost > coinCount) {
        this._vm.$toast.warning(i18n.t("errors.not_enough_coin"));
      } else {
        let sendGiftRequestData = {
          guid: generateUUID(),
          livestream_id: state.livestreamId,
          gift_id: gift.gift_id,
        };
        endpoints
          .send_gift(this._vm.$axios, sendGiftRequestData)
          .then((sendGiftResponse) => {
            if (sendGiftResponse.data.success) {
              if (
                sendGiftResponse.data.agora_events &&
                sendGiftResponse.data.agora_events.length > 0
              ) {
                let giftBody = {
                  text: JSON.stringify(sendGiftResponse.data.agora_events[0]),
                };
                state.rtmChannel
                  .sendMessage(giftBody)
                  .then(() => {
                    let sendGiftData = JSON.parse(
                      sendGiftResponse.data.agora_events[0].data
                    );
                    dispatch("addGift", sendGiftData);
                  })
                  .catch((error) => {
                    this._vm.$toast.error(
                      "An error occurred while sending the message."
                    );
                  });
              }
              dispatch(
                "client/setUserCoinInfo",
                sendGiftResponse.data.coin_info,
                { root: true }
              );
            }
          });
      }
    }
  },
  closeGift({ dispatch }) {
    dispatch("setShowSendGift", false);
  },
  cancelAcceptPremium({ dispatch }) {
    dispatch("leaveStream", false);
    router.go(-1);
  },
  acceptAndEnterPremium({ state, rootState, dispatch }, premiumGift) {
    // Marketing Event: Start
    liveStreamAcceptClickEvent(
      rootState.client.deviceId,
      rootState.client.ownProfile.user.user_id,
      state.livestreamId,
      state.selectedUser.user_id,
      premiumGift.gift_id,
      premiumGift.cost,
      state.liveStreamSource,
      state.liveStreamFlowId
    );
    //Marketing Event: End
    endpoints
      .accept_private(this._vm.$axios, {
        livestream_id: state.premiumInfo.livestream_id,
      })
      .then((acceptPrivateRes) => {
        if (acceptPrivateRes.data.error) {
          this._vm.$toast.error(acceptPrivateRes.data.error.message);
          dispatch("leaveStream");
          // Marketing Event: Start
          liveStreamAcceptErrorEvent(
            rootState.client.deviceId,
            rootState.client.ownProfile.user.user_id,
            state.livestreamId,
            state.selectedUser.user_id,
            premiumGift.gift_id,
            premiumGift.cost,
            state.liveStreamSource,
            state.liveStreamFlowId,
            acceptPrivateRes.data.error.code
          );
          //Marketing Event: End
          router.go(-1);
        } else {
          const agoraEvents = acceptPrivateRes.data.agora_events;
          dispatch("connectChannel", {
            livestreamId: state.livestreamId,
            userId: state.selectedUser.user_id,
            premiumGift: premiumGift,
            isPremiumAccepted: true,
          });
          // Marketing Event: Start
          liveStreamAcceptSuccessEvent(
            rootState.client.deviceId,
            rootState.client.ownProfile.user.user_id,
            state.livestreamId,
            state.selectedUser.user_id,
            premiumGift.gift_id,
            premiumGift.cost,
            state.liveStreamSource,
            state.liveStreamFlowId
          );
          //Marketing Event: End
        }
      });
  },
  setDiscoverFilterType({ commit, dispatch, rootState }, payload) {
    if (
      payload === DiscoverFilterTypeEnum.COUNTRY &&
      rootState.client.axiosReady
    ) {
      dispatch("getDiscoverCountries");
    }
    commit("setDiscoverFilterType", payload);
    dispatch("getDiscover");
  },
  getDiscoverCountries({ commit }) {
    endpoints
      .discover_countries(this._vm.$axios)
      .then((discoverCountriesResult) => {
        commit("setDiscoverCountries", discoverCountriesResult.data.items);
      });
  },
  setShowCountryFilterModal({ commit, dispatch }, payload) {
    dispatch("getDiscoverCountries");
    commit("setShowCountryFilterModal", payload);
  },
  setDiscoverCountries({ commit }, payload) {
    commit("setDiscoverCountries", payload);
  },
  getActiveViewers({ commit }, livestream_id) {
    endpoints
      .active_viewers(this._vm.$axios, {
        livestream_id: livestream_id,
      })
      .then((activeViewers) => {
        commit("setActiveViewers", activeViewers);
      });
  },
  toggleViewerCard({ commit }) {
    commit("toggleViewerCard");
  },
  setShowTopGifters({ commit }, { payload, livestream_id, next }) {
    if (livestream_id) {
      endpoints
        .get_topgifters(this._vm.$axios, {
          livestream_id: livestream_id,
          next: next,
        })
        .then((topGifterResponse) => {
          commit("setTopGiftersList", topGifterResponse.data);
          commit("setTopGiftersListMetaNext", topGifterResponse.data.meta.next);
        });
      commit("setShowTopGifters", payload);
    } else {
      commit("setShowTopGifters", payload);
    }
  },
  browserStateCheckBindings({ dispatch, state }) {
    var browserHidden = false;
    const browserStateChangeAction = (newBrowserHidden) => {
      browserHidden = newBrowserHidden;
      const volume = newBrowserHidden ? 0 : 100;

      if (state.remoteAudioTracks[0])
        state.remoteAudioTracks[0].setVolume(volume);
      if (state.remoteAudioTracks[1])
        state.remoteAudioTracks[1].setVolume(volume);
      if (state.remoteAudioTracks[2])
        state.remoteAudioTracks[2].setVolume(volume);
      if (state.remoteAudioTracks[3])
        state.remoteAudioTracks[3].setVolume(volume);
      if (state.remoteAudioTracks[4])
        state.remoteAudioTracks[4].setVolume(volume);
    };

    function addEvent(obj, evType, fn, isCapturing) {
      var objName;
      if (obj === window) {
        objName = "window";
      } else if (obj === document) {
        objName = "document";
      } else if (obj && obj.id) {
        objName = obj.id;
      } else {
        objName = "unknown";
      }
      if (isCapturing == null) isCapturing = false;
      if (obj.addEventListener) {
        // Firefox
        obj.addEventListener(evType, fn, isCapturing);
        return true;
      } else if (obj.attachEvent) {
        // MSIE
        var r = obj.attachEvent("on" + evType, fn);
        return r;
      } else {
        return false;
      }
    }

    // register to the W3C Page Visibility API
    var hidden = null;
    var visibilityChange = null;
    if (typeof document.mozHidden !== "undefined") {
      hidden = "mozHidden";
      visibilityChange = "mozvisibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    } else if (document.hidden !== hidden) {
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    }
    if (hidden != null && visibilityChange != null) {
      addEvent(document, visibilityChange, function (event) {
        browserStateChangeAction(document[hidden]);
      });
    }

    addEvent(document, "pageshow", function (event) {
      browserStateChangeAction(false);
    });
    addEvent(document, "pagehide", function (event) {
      browserStateChangeAction(true);
    });
    addEvent(window, "pageshow", function (event) {
      browserStateChangeAction(false);
    });
    addEvent(window, "pagehide", function (event) {
      browserStateChangeAction(true);
    });
    addEvent(window, "blur", function (event) {
      browserStateChangeAction(true);
    });
    addEvent(window, "focus", function (event) {
      browserStateChangeAction(false);
    });
    addEvent(window, "visibilitychange", function (event) {
      browserStateChangeAction(document.hidden);
    });
  },
  muteStream({ state }, muted) {
    if (state.remoteAudioTracks.length > 0) {
      if (muted) {
        state.remoteAudioTracks.forEach((remoteAudioTrack) =>
          remoteAudioTrack.setVolume(0)
        );
      } else {
        state.remoteAudioTracks.forEach((remoteAudioTrack) =>
          remoteAudioTrack.setVolume(100)
        );
      }
    }
  },
  addJoinStreamCount({ state, commit }, livestreamId) {
    if (state.joinStreamCount == 3) {
      enter4thStreamEvent(livestreamId); //Marketting Event
    }
    localStore.setItem(STORE_KEYS.JOIN_STREAM_COUNT, state.joinStreamCount + 1);
    commit("addJoinStreamCount");
  },
  setLiveStreamSource({ commit }, payload) {
    commit("setLiveStreamSource", payload);
  },
  setLiveStreamFlowId({ commit }, payload) {
    commit("setLiveStreamFlow", payload);
  },
};
