<template>
  <div>
    <template v-if="unsupportedStripe">
      <div class="unsupported-stripe">
        {{ $t("errors.unsupported_stripe") }}
      </div>
    </template>
    <template v-if="!unsupportedStripe && settings">
      <div class="coin-select-header">
        <div class="coin-select-title">
          {{ $t("menu.purchase") }}
        </div>
        <div class="coin-count">
          <img src="@/assets/img/coin.svg" alt="Coin Icon" />
          {{ Number(userCoinInfo.coins).toLocaleString() }}
        </div>
      </div>
      <div class="columns is-mobile is-multiline">
        <div
          v-for="coinItem in settings.coin_info.coin_offer.products"
          :key="coinItem.sku"
          class="column is-one-quarter-desktop is-one-third-tablet is-half-mobile"
        >
          <div class="coin-select-container" @click="selectProduct(coinItem)">
            <img :src="coinItem.icon_url" :alt="coinItem.amount + ' Coin'" />
            <div class="coin">{{ coinItem.amount }}</div>
            <div class="price-container">
              <div
                class="old-price"
                v-if="
                  coinItem.stripe_price && coinItem.stripe_price.original_price
                "
              >
                {{ currencies[coinItem.stripe_price.currency.toUpperCase()]
                }}{{ coinItem.stripe_price.original_price }}
              </div>
              <div class="price" v-if="coinItem.stripe_price">
                {{ currencies[coinItem.stripe_price.currency.toUpperCase()]
                }}{{ coinItem.stripe_price.price }}
              </div>
            </div>
            <div
              class="badge orange-bg"
              :class="{
                'right-badge': checkDiscount(coinItem),
              }"
              v-if="coinItem.tag == 1"
            >
              <img src="@/assets/img/fire.svg" alt="Fire Icon" />
              HOT
            </div>
            <div
              class="badge purple-bg"
              v-if="coinItem.stripe_price && coinItem.stripe_price.discount"
            >
              %{{ coinItem.stripe_price.discount }}
            </div>
          </div>
        </div>
        <!--
            <div class="column is-one-quarter-desktop is-one-third-tablet is-half-mobile">
                <div class="coin-select-container" @click="testPlayProduct">
                    <img src="@/assets/img/google-play-icon.svg" alt="Google Play Icon" />
                    Test google product
                </div>
            </div>
            -->
      </div>
    </template>
  </div>
</template>
<style lang="scss">
@import "@/components/Payment/Stripe/CoinSelect.scss";
</style>
<script>
"use strict";

import { mapState, mapActions } from "vuex";

const endpoints = require("@/api/endpoints");

export default {
  name: "CoinSelect",
  data() {
    return {
      coinInfo: null,
      unsupportedStripe: false,
      // testAuthToken:false,
      // testDeviceId:false
    };
  },
  computed: {
    ...mapState({
      isHybridPage: (state) => state.client.isHybridPage,
      settings: (state) => state.client.settings,
      currencies: (state) => state.client.currencies,
      userCoinInfo: (state) => state.client.userCoinInfo,
    }),
  },
  mounted() {
    // If close button tapped on hybrid popup
    window["backButtonTapped"] = () => {
      this.hybridClose(null);
      return "close";
    };

    this.$hybridapi("updateWindowSize", "full");
    // this.$store.dispatch("client/setHybridPage", true);

    this.$store.dispatch("payment/setStripePaymentSucceeded", false);
    sessionStorage.removeItem("uuid_stripe_payment");
    let platform = this.$route.query.platform;

    this.setPlatform(platform != null ? platform : null);

    if (platform !== "android" && platform !== "ios") {
      // Query parametre ile direk checkout'a gelindiğinde bu componentin mounted'ı tetikleniyor
      // eğer android veya ios'dan geldi ise bu api call'u hybrid'e atıldığı için hata veriyor
      // onu engellemek için check eklendi.
      this.getSettings().then((settingsResponse) => {
        this.coinInfo = settingsResponse.coin_info;
        // If stripe_price prop coming null this means stripe not supported on this country
        this.coinInfo.coin_offer.products.forEach((element) => {
          if (!element.stripe_price) {
            this.unsupportedStripe = true;
          }
        });
      });
    }
  },
  methods: {
    ...mapActions(["setPlatform"]),
    ...mapActions("client", ["getSettings"]),
    ...mapActions("payment", ["postHybridCloseChecks"]),
    selectProduct(product) {
      this.$store.dispatch("payment/setSelectedProduct", product);
      this.$store.dispatch("payment/setStripeActivePage", "checkout");
    },
    checkDiscount(coinItem) {
      return coinItem?.stripe_price?.discount;
    },
    hybridClose(exit_location = "close") {
      this.$hybridapi("close", exit_location);
      if (this.isHybridPage) this.postHybridCloseChecks();
    },
  },
};
</script>
