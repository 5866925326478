<template>
  <span
    class="user-level-badge"
    :class="[returnVipString(user.vip_state)]"
    v-bind:style="{ 'font-size': fontSize ? fontSize : '12px' }"
    v-if="
      user &&
      ((user.level && user.level > 1) ||
        (user.leveling_progress && user.leveling_progress.current_level > 1)) &&
      user.user_id !== '1'
    "
  >
    {{
      user.level ||
      (user.leveling_progress && user.leveling_progress.current_level)
    }}
  </span>
</template>

<script>
import { returnVipString } from "@/helper/index.js";
export default {
  name: "LevelBadge",
  props: {
    /**
     * User object cannot be null
     * if null, show nothing
     */
    user: {
      type: Object,
    },
    /**
     * Font size of badge
     */
    fontSize: {
      type: String,
    },
  },
  methods: {
    returnVipString,
  },
};
</script>

<style lang="scss" scoped>
@import "LevelBadge.scss";
</style>
