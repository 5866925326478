<template>
  <div>
    <vue-bottom-sheet-vue2
      custom-class="bs-wrap"
      ref="sheet"
      :z-index="1001"
      :can-swipe="canSwipe"
    >
      <template #header>
        <div class="bs-header">
          <div class="bs-header-title">
            {{ $t("payment.purchase_coins") }}
          </div>
          <div class="bs-header-container">
            <div class="bs-coins-img">
              <img src="@/assets/img/payment/coin-icon.svg" alt="Coins" />
            </div>
            <div class="bs-coins-content">{{ coinAmount }}</div>
          </div>
        </div>
      </template>
      <template #default>
        <div class="bs-body" id="container" @scroll="onScroll">
          <div v-if="isSavedPaymentTokenAvailable" class="bs-body-title">
            {{ $t("payment.saved_cards") }}
          </div>
          <div class="bs-body-container">
            <div
              class="bs-payment-token"
              v-for="paymentToken in data"
              :key="paymentToken.id"
              @click="selected = paymentToken"
            >
              <PaymentToken
                :payment-token="paymentToken"
                :active="
                  selected != null &&
                  (paymentToken.id != null
                    ? selected.id === paymentToken.id
                    : selected.card_id === paymentToken.card_id)
                "
                @delete="onCardDeleteRequest(paymentToken)"
              />
            </div>
            <div
              v-if="showAddCard"
              class="bs-payment-token-add"
              @click="onAddCard"
            >
              <div class="bs-payment-token-add-container">
                <div class="bs-payment-token-add-icon">
                  <img
                    :src="require(`@/assets/img/payment/card-icon.svg`)"
                    :alt="
                      isPaymentProviderSupportAccountOverCard
                        ? 'Add Account'
                        : 'Add Card'
                    "
                  />
                </div>
                <div class="bs-payment-token-add-text">
                  {{
                    isPaymentProviderSupportAccountOverCard
                      ? $t("payment.add_account")
                      : $t("payment.add_card")
                  }}
                </div>
              </div>
            </div>
            <div
              v-if="showSaveCardPermission"
              class="bs-payment-token-permission"
            >
              <div class="bs-payment-token-permission-checkbox">
                <input
                  v-model="isSaveCard"
                  type="checkbox"
                  checked
                  id="checkbox"
                />
                <label for="checkbox"></label>
              </div>
              <div
                class="bs-payment-token-permission-text"
                @click="isSaveCard = !isSaveCard"
              >
                {{ $t("payment.card_saving_agreement") }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="bs-footer">
          <div class="btn-pay" @click="$emit('pay', selected)">
            <div>
              {{ currency }}
              {{ price }}
              {{ $t("labels.do_payment") }}
            </div>
          </div>
        </div>
      </template>
    </vue-bottom-sheet-vue2>
    <DeleteDialog
      :is-open="isCardDeleteRequested"
      @close="isCardDeleteRequested = false"
      @confirm="onDeleteCard"
    />
    <v-overlay
      :style="{ 'z-index': 1002 }"
      color="#fff"
      :value="showFullPageLoading"
    >
      <v-progress-circular color="#333" :size="50" indeterminate />
    </v-overlay>
  </div>
</template>

<script>
import VueBottomSheetVue2 from "@webzlodimir/vue-bottom-sheet-vue2";
import { PaymentProvider } from "@/helper/enums";
import PaymentToken from "./PaymentToken.vue";
import DeleteDialog from "./DeleteDialog.vue";

/** @import { Payment } from '@/components/Payment/types/payment' */

/**
 * @typedef ReactiveData
 * @property {boolean} canSwipe
 * @property {boolean} isSaveCard
 * @property {Payment.SavedPaymentToken | Payment.OlderSavedPaymentToken} selected
 * @property {Payment.SavedPaymentToken | Payment.OlderSavedPaymentToken} pendingToDeleteToken
 */

/**
 * @typedef Props
 * @property {Array<Payment.SavedPaymentToken | Payment.OlderSavedPaymentToken>} data
 * @property {string} currency
 * @property {number} coinAmount
 * @property {number} price
 * @property {PaymentProvider|null} provider
 * @property {boolean} showAddCard
 * @property {boolean} showSaveCardPermission
 * @property {boolean} showFullPageLoading
 */

/** @typedef {import('vue') & ReactiveData & Props} Component */

export default {
  name: "SavedCardsBottomSheet",
  props: {
    data: {
      type: Array,
      require: true,
    },
    currency: {
      type: String,
      require: true,
    },
    coinAmount: {
      type: Number,
      require: true,
    },
    price: {
      type: Number,
      require: true,
    },
    showAddCard: {
      type: Boolean,
      default: false,
    },
    showSaveCardPermission: {
      type: Boolean,
      default: false,
    },
    showFullPageLoading: {
      type: Boolean,
      default: false,
    },
    provider: {
      type: Number,
      default: null,
    },
  },
  components: {
    DeleteDialog,
    PaymentToken,
    VueBottomSheetVue2,
  },
  data() {
    return {
      canSwipe: true,
      isSaveCard: true,
      selected: null,
      pendingToDeleteToken: null,
    };
  },
  computed: {
    isPaymentProviderSupportAccountOverCard() {
      return this.provider == PaymentProvider.PAYPAL;
    },
    isSavedPaymentTokenAvailable() {
      return this.data.length > 0;
    },
    isCardDeleteRequested: {
      get() {
        return this.pendingToDeleteToken != null;
      },
      set(value) {
        if (!value) {
          this.pendingToDeleteToken = null;
        }
      },
    },
  },
  created() {
    if (this.data.length > 0) {
      this.selected = this.data[0];
    }
  },
  methods: {
    onScroll() {
      this.canSwipe = false;
    },
    onScrollEnd() {
      this.canSwipe = true;
    },
    /**
     * @this Component
     * @param {Payment.SavedPaymentToken | Payment.OlderSavedPaymentToken} token
     */
    onCardDeleteRequest(token) {
      this.pendingToDeleteToken = token;
    },
    /**
     * @this Component
     */
    onAddCard() {
      this.$emit("add-card", this.isSaveCard);
    },
    open() {
      this.$refs.sheet.open();
    },
    /**
     * @this Component
     */
    onDeleteCard() {
      this.$emit("delete-card", this.pendingToDeleteToken);
      this.pendingToDeleteToken = null;

      const remaining = this.data.filter(
        (it) =>
          (it.is_newer_version && it.id != this.selected.id) ||
          (!it.is_newer_version && it.card_id != this.selected.card_id)
      );

      if (remaining.length > 0) {
        this.selected = remaining[0];
      } else {
        this.selected = null;
      }
    },
  },
  mounted() {
    document.getElementById("container").addEventListener("touchend", () => {
      this.$emit("scroll-end");
    });
  },
};
</script>

<style scoped lang="scss">
@import "./SavedCardsBottomSheet.scss";
</style>
