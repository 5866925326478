<template>
  <div class="livestream-left">
    <!-- <button @click="goBack" class="back-button">
      <i class="fas fa-chevron-left"></i>
    </button> -->
    <v-btn icon color="white" class="back-button" @click="goBack()">
      <i class="fas fa-chevron-left"></i>
    </v-btn>
    <GuestCard />
  </div>
</template>
<style lang="scss" scoped>
@import "LivestreamLeft.scss";
</style>
<script>
import GuestCard from "@/components/LiveStream/Cards/GuestCard.vue";
export default {
  name: "LivestreamLeft",
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  components: {
    GuestCard,
  },
};
</script>
