<template>
  <div>
    <img src="@/assets/img/delete-accounts-spl.png" />
  </div>
</template>
<script>
export default {
  name: "DeleteAccountInfo",
};
</script>
