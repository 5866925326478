import { mapActions } from "vuex";
import { Logger } from "@/logging";
import { PaymentProvider } from "@/helper/enums";
import PaymentMethodProceedMixin from "./PaymentMethodProceedMixin";

const logger = new Logger("PaymentTokenOperationMixin");

/** @import Vue from 'vue' */
/** @import  {Payment} from '@/components/Payment/types/payment' */

/**
 * @typedef ReactiveData
 * @property {boolean} isSavedPaymentTokenLoading
 * @property {Array<Payment.SavedPaymentToken>|Array<Payment.OlderSavedPaymentToken>} savedPaymentTokens
 */

const SAVED_PAYMENT_TOKEN_SUPPORTED_PROVIDERS = new Set([
  PaymentProvider.TRUSTPAY,
  PaymentProvider.PAYERMAX,
  PaymentProvider.PAYPAL,
]);

/**
 * @mixin
 */
export default {
  mixins: [PaymentMethodProceedMixin],
  /**
   * @returns {ReactiveData}
   */
  data() {
    return {
      isSavedPaymentTokenLoading: false,
      savedPaymentTokens: [],
    };
  },
  methods: {
    ...mapActions("payment", ["deleteSavedCard", "getSavedCards"]),
    /**
     * @this ReactiveData
     * @param {Payment.PaymentMethod|null} paymentMethod
     * @returns {boolean}
     */
    isAddCardOptionAvailable(paymentMethod) {
      if (paymentMethod == null) {
        return false;
      } else if (paymentMethod.payment_provider == PaymentProvider.PAYERMAX) {
        return true;
      } else if (
        [PaymentProvider.PAYPAL, PaymentProvider.TRUSTPAY].includes(
          paymentMethod.payment_provider
        )
      ) {
        return (
          this.savedPaymentTokens != null && this.savedPaymentTokens.length < 5
        );
      } else {
        return false;
      }
    },
    /**
     * @param {Payment.PaymentMethod|null} paymentMethod
     * @returns {boolean}
     */
    isSaveCardPermissionAvailable(paymentMethod) {
      return (
        paymentMethod != null &&
        [PaymentProvider.TRUSTPAY, PaymentProvider.PAYPAL].includes(
          paymentMethod.payment_provider
        )
      );
    },
    /**
     * NOTE: After usage of SavedPaymentToken, OlderSavedPaymentToken will be unused
     *
     * @overload
     * @deprecated
     * @param {Payment.OlderSavedPaymentToken} savedPaymentToken
     * @param {PaymentProvider} provider
     */ /**
     * @overload
     * @param {Payment.SavedPaymentToken} savedPaymentToken
     * @param {null} [provider=null]
     */ /**
     * @this {Vue & ReactiveData}
     * @param {Payment.OlderSavedPaymentToken | Payment.SavedPaymentToken} savedPaymentToken
     * @param {PaymentProvider|null} [provider=null]
     */
    async proceedDeleteCard(savedPaymentToken, provider = null) {
      if (!this.isSavedPaymentTokenLoading) {
        this.isSavedPaymentTokenLoading = true;

        /** @type {object} */
        let payload;
        let index = -1;

        if (savedPaymentToken.is_newer_version) {
          index = this.savedPaymentTokens.findIndex(
            (it) => it.id == savedPaymentToken.id
          );
          payload = {
            payment_token_id: savedPaymentToken.id,
            provider: savedPaymentToken.provider,
          };
        } else {
          index = this.savedPaymentTokens.findIndex(
            (it) => it.card_id == savedPaymentToken.card_id
          );
          payload = {
            card_id: savedPaymentToken.card_id,
            provider,
          };
        }

        try {
          await this.deleteSavedCard(payload);
          if (index !== -1) {
            this.savedPaymentTokens.splice(index, 1);
          }
        } catch (e) {
          logger.debugError("Delete card failed with payload", payload, e);
          this.$toast.error(this.$t("errors.general_error"));
        } finally {
          this.isSavedPaymentTokenLoading = false;
        }
      }
    },
    /**
     * NOTE: After usage of SavedPaymentToken, OlderSavedPaymentToken will be unused
     *
     * @overload
     * @deprecated
     * @param {string} sku
     * @param {Payment.OlderSavedPaymentToken | null} savedPaymentToken
     * @param {Payment.PaymentMethod} paymentMethod
     * @param {PaymentProvider} provider
     * @param {string|null} [bannerId=null]
     */ /**
     * @overload
     * @param {string} sku
     * @param {Payment.SavedPaymentToken | null} savedPaymentToken
     * @param {Payment.PaymentMethod} paymentMethod
     * @param {null} [provider=null]
     * @param {string|null} [bannerId=null]
     */ /**
     * @this {Vue & ReactiveData}
     * @param {string} sku
     * @param {Payment.OlderSavedPaymentToken | Payment.SavedPaymentToken | null} savedPaymentToken
     * @param {Payment.PaymentMethod} paymentMethod
     * @param {PaymentProvider|null} [provider=null]
     * @param {string|null} [bannerId=null]
     */
    async proceedSavedCardPay(
      sku,
      savedPaymentToken,
      paymentMethod,
      bannerId = null
    ) {
      if (!this.isSavedPaymentTokenLoading) {
        this.isSavedPaymentTokenLoading = true;
        let isRedirectingAvailable = false;

        let savedPaymentTokenId = null;
        if (savedPaymentToken != null) {
          savedPaymentTokenId = savedPaymentToken.is_newer_version
            ? savedPaymentToken.id
            : savedPaymentToken.card_id;
        }

        if (paymentMethod.payment_provider == PaymentProvider.PAYERMAX) {
          isRedirectingAvailable = await this.proceedWithPayermax(
            sku,
            paymentMethod,
            savedPaymentTokenId,
            bannerId
          );
        } else if (paymentMethod.payment_provider == PaymentProvider.TRUSTPAY) {
          isRedirectingAvailable = await this.proceedWithTrustPay(
            sku,
            savedPaymentTokenId,
            false,
            bannerId
          );
        } else if (paymentMethod.payment_provider == PaymentProvider.PAYPAL) {
          isRedirectingAvailable = await this.proceedWithPayPal(
            sku,
            savedPaymentTokenId,
            false,
            bannerId
          );
        } else {
          throw Error(
            `Provider ${paymentMethod.payment_provider} proceedSavedCardPay method is not implemented`
          );
        }

        if (!isRedirectingAvailable) {
          this.isSavedPaymentTokenLoading = false;
        }
      }
    },
    /**
     * @this {Vue & ReactiveData}
     * @param {string} sku
     * @param {Payment.PaymentMethod} paymentMethod
     * @param {boolean} [isSavePaymentTokenPermission=false]
     * @param {string|null} [bannerId=null]
     */
    async proceedAddCard(
      sku,
      paymentMethod,
      isSavePaymentTokenPermission = false,
      bannerId = null
    ) {
      if (!this.isSavedPaymentTokenLoading) {
        this.isSavedPaymentTokenLoading = true;
        let isRedirectingAvailable = false;

        if (paymentMethod.payment_provider == PaymentProvider.TRUSTPAY) {
          isRedirectingAvailable = await this.proceedWithTrustPay(
            sku,
            null,
            isSavePaymentTokenPermission,
            bannerId
          );
        } else if (paymentMethod.payment_provider == PaymentProvider.PAYERMAX) {
          isRedirectingAvailable = await this.proceedWithPayermax(
            sku,
            paymentMethod,
            null,
            bannerId
          );
        } else if (paymentMethod.payment_provider == PaymentProvider.PAYPAL) {
          isRedirectingAvailable = await this.proceedWithPayPal(
            sku,
            null,
            isSavePaymentTokenPermission,
            bannerId
          );
        } else {
          throw Error(
            `Provider ${paymentMethod.payment_provider} proceedAddCard method is not implemented`
          );
        }

        if (!isRedirectingAvailable) {
          this.isSavedPaymentTokenLoading = false;
        }
      }
    },
    clearSavedPaymentTokens() {
      this.savedPaymentTokens = [];
    },
    /**
     * @param {PaymentProvider} provider
     */
    async getSavedPaymentTokens(provider) {
      if (SAVED_PAYMENT_TOKEN_SUPPORTED_PROVIDERS.has(provider)) {
        try {
          const response = await this.getSavedCards({ provider });
          if (response.cards != null) {
            this.savedPaymentTokens = response.cards.map((it) => ({
              ...it,
              is_newer_version: false,
            }));
          } else if (response.data != null) {
            this.savedPaymentTokens = response.data.map((it) => ({
              ...it,
              is_newer_version: true,
            }));
          }
        } catch (e) {
          logger.error(
            "Failed to retrieve saved payment tokens for the provider:",
            provider,
            e
          );
        }
      }
    },
  },
};
