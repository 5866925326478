<template>
  <v-container
    class="followers-container"
    v-if="isLiveStreamActive(userFollowingsStreams)"
  >
    <v-row class="item-rows-title">
      <v-col class="item-cols-title" mb-12>
        <h1 class="suggested-live-stream-header">
          {{ $t("followings_stream_page.stream_title") }}
        </h1>
      </v-col>
    </v-row>
    <v-row class="item-rows">
      <div
        class="special-square-column"
        v-for="user in userFollowingsStreams"
        :key="`user${user.user.user_id}`"
      >
        <stream-card
          @clicked="setLiveStreamSource('following_feed')"
          :streamItem="user"
        />
      </div>
    </v-row>
  </v-container>
  <v-container v-else class="followers-wrapper-empty">
    <v-row class="followers-empty-row">
      <v-col class="followers-empty-col">
        <v-card class="item-cards pa-2" outlined tile>
          <img
            class="item-empty-icon"
            :src="require(`@/assets/img/empty-followings_${brandName}.svg`)"
            :alt="$t('general.empty_profile_svg')"
          />
          <span class="card-empty-span-title">{{
            $t("user_followings_page.no_stream_title")
          }}</span>
          <span class="card-empty-span">{{
            $t("user_followings_page.no_stream_content")
          }}</span>
          <router-link to="/">
            <v-btn class="button-discovery" rounded color="primary" dark>
              {{ $t("user_followings_page.no_stream_button") }}
            </v-btn>
          </router-link>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss" scoped>
@import "FollowingsStream.scss";
</style>
<script>
import { mapState, mapActions } from "vuex";
import { showThousund } from "@/helper/index.js";
import StreamCard from "@/components/Streams/StreamCard/StreamCard.vue";

export default {
  name: "FollowingsStream",
  components: {
    StreamCard,
  },
  computed: {
    ...mapState({
      userFollowingsStreams: (state) => state.client.userFollowingsStreams,
      ownProfile: (state) => state.client.ownProfile,
      brandName: (state) => state.brandName,
    }),
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions("client", ["logout", "getUserFollowingsStreams"]),
    ...mapActions("live", ["setLiveStreamSource"]),
    showThousund: showThousund,
    getData() {
      this.getUserFollowingsStreams({ user_id: this.ownProfile.user.user_id });
    },
    isLiveStreamActive(userFollowingsStreams) {
      return userFollowingsStreams !== null
        ? userFollowingsStreams.filter((item) => {
            return item.user.livestream_id !== null;
          }).length > 0
        : false;
    },
  },
};
</script>
