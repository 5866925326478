<template>
  <div
    class="payermax-container payermax-get-email-container"
    :class="{ 'hybrid-container': isHybridPage }"
  >
    <div class="hybrid-section">
      <div class="section-header">
        <div>
          {{ $t("payermax.order_details") }}
        </div>
        <div>
          <div class="close-get-email-page">
            <i class="fas fa-times" @click="closeHybridPage"></i>
          </div>
        </div>
      </div>
      <div class="order-price-container">
        <div class="order-price-label">{{ $t("payermax.order_total") }}</div>
        <div class="price">
          {{ currencies[selectedProduct.stripe_price.currency.toUpperCase()]
          }}{{ selectedProduct.stripe_price.price }}
        </div>
      </div>
    </div>
    <div class="hybrid-section">
      <v-container>
        <v-row>
          <v-col cols="12">
            <label>{{ $t("labels.email") }}*</label>
            <div
              :class="
                'input-container ' +
                (validation.firstError('email') ? 'has-error ' : '') +
                (submitted && !validation.firstError('email')
                  ? 'has-no-error '
                  : '')
              "
            >
              <input
                name="email"
                id="email"
                v-model="email"
                :placeholder="$t('labels.email')"
              />
            </div>
            <div
              class="validation-error-message m-0 p-0"
              v-if="validation.firstError('email')"
            >
              {{ validation.firstError("email") }}
            </div>
            <button
              @click="connectEmail"
              class="green-button"
              :disabled="loading || !email"
              style="margin-top: 20px"
            >
              {{ $t("labels.continue") }}
              <img
                src="@/assets/img/loading.svg"
                alt="loading"
                class="loading"
                v-if="loading"
              />
            </button>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="google-login-section">
      <v-col cols="12" class="pt-0" v-if="!isHybridPage">
        <div class="or-divider">{{ $t("login_page.or") }}</div>
        <div id="googleSignIn" class="google-sign-in btn-sign-in"></div>
      </v-col>
      <v-col cols="12">
        <div class="card-error" v-if="connectError">
          <img src="@/assets/img/payermax-exclamation.svg" />
          {{ this.connectError }}
        </div>
      </v-col>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/components/Payment/Payermax/Payermax.scss";
</style>
<script>
"use strict";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
import { mapState, mapActions } from "vuex";

const endpoints = require("@/api/endpoints");

export default {
  name: "PayermaxGetEmail",
  data() {
    return {
      loading: false,
      connectError: null,
      email: null,
      submitted: false,
    };
  },
  computed: {
    ...mapState({
      isHybridPage: (state) => state.client.isHybridPage,
      selectedProduct: (state) => state.payment.selectedProduct,
      currencies: (state) => state.client.currencies,
      isPromotionPayment: (state) => state.payment.isPromotionPayment,
      promotionReferrer: (state) => state.payment.promotionReferrer,
    }),
  },
  validators: {
    email: function (value) {
      return Validator.value(value).required().email();
    },
  },
  mounted() {
    window["backButtonTapped"] = () => {
      if (confirm(this.$t("labels.close_confirm"))) {
        this.hybridClose("close");
        return "close";
      }
      return "nothing";
    };
    this.$hybridapi("updateWindowSize", "full");
    this.$hybridapi("hideLoading");
    this.$hybridapi("updatePageTitle", this.$t("labels.do_payment"));

    if (!this.selectedProduct) {
      this.hybridClose("close");
      this.$store.dispatch("payment/setStripeActivePage", "coinSelect");
    }
    //google sign-in
    this.$gAuth.load(this.onGoogleSignInSuccess, this.onGoogleSignInFail, 200);
  },
  methods: {
    ...mapActions("payment", ["postHybridCloseChecks"]),
    connectEmail() {
      this.submitted = true;
      this.$validate().then((success) => {
        if (success) {
          this.loading = true;
          endpoints
            .connect_email(this.$axios, { email: this.email })
            .then((connectEmailResponse) => {
              connectEmailResponse = connectEmailResponse.data;
              this.loading = false;
              if (connectEmailResponse.error) {
                this.connectError = connectEmailResponse.error.message;
              } else if (connectEmailResponse.success) {
                this.$router.push("/payermax/checkout");
              }
            })
            .catch(() => {
              this.loading = false;
              this.connectError = this.$t("errors.general_error");
            });
        }
      });
    },
    closeHybridPage() {
      this.$hybridapi("showLoading");
      if (this.isPromotionPayment) {
        window.location.href = decodeURIComponent(this.promotionReferrer);
      } else {
        this.$router.push("/payermax");
      }
    },
    removeError() {
      this.connectError = null;
    },
    onGoogleSignInSuccess(response) {
      let google_id_token = response.credential;
      endpoints
        .connect_google(this.$axios, { token: google_id_token })
        .then((googleConnectResponse) => {
          this.loading = false;
          googleConnectResponse = googleConnectResponse.data;
          if (googleConnectResponse.error) {
            this.connectError = googleConnectResponse.error.message;
          } else if (googleConnectResponse.success) {
            //  Successfully connected
            this.$router.push("/payermax/checkout");
          }
        })
        .catch(() => {
          this.loading = false;
          this.connectError = this.$i18n.t("errors.general_error");
        });
    },
    onGoogleSignInFail() {
      this.loading = false;
      this.connectError = this.$i18n.t("errors.general_error");
    },
    hybridClose(exit_location = "close") {
      this.$hybridapi("close", exit_location);
      if (this.isHybridPage) this.postHybridCloseChecks();
    },
  },
};
</script>

<style lang="scss" scoped>
.section-header {
  justify-content: space-between;

  .close-get-email-page {
    display: flex;
    width: 20px;
    justify-content: center;
  }
}
</style>
