import Vue from "vue";
import VueI18n from "vue-i18n";
// For datepicker language
import { Settings } from "luxon";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

let defaultLang = window.navigator.language
  ? window.navigator.language.split("-")[0]
  : "en";

const { TheURLSearchParams } = window;
const paramLang = TheURLSearchParams?.get("lang");

if (paramLang) {
  defaultLang = paramLang;
}

// For datepicker language
Settings.defaultLocale = defaultLang;
document.documentElement.setAttribute("lang", defaultLang);

const i18n = new VueI18n({
  locale: defaultLang,
  fallbackLocale: "en",
  messages: loadLocaleMessages(),
});

export default i18n;
