// @ts-check

const axios = require("axios").default;
const mocks = require("../mocks/index").default;

const client_params_module = require("./client_params");

/**
 * @typedef ApiAxiosInstanceOptions
 * @property {string} [base_url=null]
 * @property {string} [device_id=null]
 * @property {string} [auth_token=null]
 * @property {Object<string, *>} [base_data=null]
 */

/**
 * @param {ApiAxiosInstanceOptions} options
 */
function getAxiosInstance(options) {
  const { base_url, device_id, auth_token } = options;

  let common_headers = {};
  if (auth_token != null) {
    common_headers["Authorization"] = `Token ${auth_token}`;
  }
  if (device_id != null) {
    common_headers["Device-ID"] = device_id;
  }
  this.common_headers = common_headers;

  const instance = axios.create({
    baseURL: base_url,
    headers: {
      // NOTE(baris): Adding auth and device_id headers here.
      common: this.common_headers,
      post: {
        "Content-Type": "application/json",
      },
    },
    transformRequest: [
      function (data) {
        // "Do whatever you want to transform the data" - Axios

        // NOTE(baris): Adding request base data here.
        return Object.assign(
          {
            // Base data is calculated here
            client_params: client_params_module.getClientParams(false),
          },
          data
        );
      },
      // @ts-ignore
      ...axios.defaults.transformRequest,
    ],
    validateStatus: function (status) {
      return status != null && status >= 200 && status < 500;
    },
  });

  // ...
  if (
    process.env.NODE_ENV === ("development" || "test") &&
    process.env.VUE_APP_WITH_MOCK &&
    process.env.VUE_APP_WITH_MOCK === "true"
  ) {
    const responseHandler = (response) => {
      const route = response.config.url?.replace(response.config.baseURL, "");
      if (mocks[route]) {
        return {
          ...response,
          data: mocks[route],
        };
      }
      return response;
    };
    instance.interceptors.response.use(responseHandler, responseHandler);
  }

  return instance;
}
module.exports.getAxiosInstance = getAxiosInstance;

/**
 * @param {import('axios').AxiosInstance} instance
 * @param {string|null} device_id
 */
function updateAxiosInstanceDeviceID(instance, device_id) {
  if (device_id != null) {
    instance.defaults.headers.common["Device-ID"] = device_id;
  } else {
    delete instance.defaults.headers.common["Device-ID"];
  }
}
module.exports.updateAxiosInstanceDeviceID = updateAxiosInstanceDeviceID;

/**
 * @param {import('axios').AxiosInstance} instance
 * @param {string|null} auth_token
 */
function updateAxiosInstanceAuthToken(instance, auth_token) {
  if (auth_token) {
    instance.defaults.headers.common["Authorization"] = `Token ${auth_token}`;
  } else {
    delete instance.defaults.headers.common["Authorization"];
  }
}
module.exports.updateAxiosInstanceAuthToken = updateAxiosInstanceAuthToken;
