<template>
  <div>
    <div class="columns is-multiline">
      <div
        v-if="this.selectedProduct"
        class="column is-12-mobile is-8-tablet is-offset-2-tablet is-4-desktop is-offset-4-desktop"
      >
        <div class="coin-count">
          <img src="@/assets/img/coin.svg" alt="Coin Icon" />
          {{ this.selectedProduct.amount }}
        </div>
        <div class="price-container">
          <div
            class="old-price"
            v-if="selectedProduct.stripe_price.original_price"
          >
            {{ currencies[selectedProduct.stripe_price.currency.toUpperCase()]
            }}{{ selectedProduct.stripe_price.original_price }}
          </div>
          <div class="current-price">
            {{
              currencies[
                this.selectedProduct.stripe_price.currency.toUpperCase()
              ]
            }}{{ this.selectedProduct.stripe_price.price }}
          </div>
        </div>

        <div class="status-container">
          <template v-if="stripePaymentSucceeded">
            <div class="status success"><i class="fas fa-check"></i></div>
            <div>{{ $t("checkout.payment_success") }}</div>
          </template>
          <template v-else>
            <div class="status error"><i class="fas fa-times"></i></div>
            <div>{{ $t("checkout.payment_failed") }}</div>
          </template>
          <div class="redirect">{{ $t("labels.redirecting") }}</div>
          <div class="mt-3"><img src="@/assets/img/loading.svg" /></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "@/components/Payment/Stripe/CompletePayment.scss";
</style>
<script>
"use strict";
import { paymentSuccessHybridEvent } from "@/firebase_analytics";
import { mapState, mapActions } from "vuex";

export default {
  name: "CompletePayment",
  data() {
    return {
      success: false,
      timer: null,
    };
  },
  computed: {
    ...mapState({
      stripePaymentSucceeded: (state) => state.payment.stripePaymentSucceeded,
      selectedProduct: (state) => state.payment.selectedProduct,
      currencies: (state) => state.client.currencies,
      showBuyCoinsModal: (state) => !!state.client.showBuyCoinsModal,
      buyCoinBackground: (state) => state.client.buyCoinBackground,
      isHybridPage: (state) => state.client.isHybridPage,
    }),
  },
  beforeMount() {
    if (!this.selectedProduct) {
      this.$store.dispatch("payment/setStripeActivePage", "coinSelect");
    }
  },
  mounted() {
    if (this.stripePaymentSucceeded && this.isHybridPage)
      paymentSuccessHybridEvent();
    // If close button tapped on hybrid popup
    window["backButtonTapped"] = () => {
      // do nothing bec. it's closing after 3 min automatically
      return "nothing";
    };

    // this.$store.dispatch("client/setHybridPage", true);
    this.$hybridapi("updatePageTitle", this.$t("labels.do_payment"));
    this.$hybridapi("updateWindowSize", "medium");
    // this.$hybridapi("hideLoading");
    this.timer = setTimeout(() => {
      // HYBRID API CLOSE CALL
      this.$store.dispatch("client/setShowBuyCoinsModal", {
        showBuyCoinsModal: false,
        buyCoinBackground: this.buyCoinBackground,
      });
      // This timeout added for web
      setTimeout(() => {
        this.$store.dispatch("payment/setStripePaymentSucceeded", false);
        this.$store.dispatch("payment/setSelectedProduct", null);
        this.$store.dispatch("payment/setSelectedPaymentMethod", null);
        this.hybridClose("close");
        this.$store.dispatch("payment/setStripeActivePage", "coinSelect");
      }, 500);
    }, 5000);
  },
  destroyed() {
    this.timer = null;
  },
  methods: {
    ...mapActions("payment", ["postHybridCloseChecks"]),
    hybridClose(exit_location = "close") {
      this.$hybridapi("close", exit_location);
      if (this.isHybridPage) this.postHybridCloseChecks();
    },
  },
};
</script>
