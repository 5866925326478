import hybridapi from "./hybridapi";

/**
 * @deprecated
 * @param {string} event_name
 * @param {Object<string, string|number>} event_data
 */
function sendHybridFirebaseEvent(event_name, event_data) {
  if (event_name && event_data) {
    let event_data_json = JSON.stringify(event_data);
    hybridapi("sendFirebaseEvent", event_name, event_data_json);
  }
}

// NOTE(purchase-bug-fix-pass): Deprecated hybrid payment flow events. Was missing updates and missing event triggers.
// function getHybridPaymentFlowInformation() {
//   return require("./store").default.state.payment.hybridPaymentFlowInformation;
// }

/**
 * Note:
 * We need to send an event each time user selects a coin product for purchase.
 * This is only for meant the multi-payment page.
 * @deprecated
 */
export function selectProductHybridEvent() {
  // NOTE(purchase-bug-fix-pass): Deprecated hybrid payment flow events. Was missing updates and missing event triggers.
  throw "Deprecated";
  // const { user_id, device_guid, product_id, coins, price, currency, flow_id } =
  //   getHybridPaymentFlowInformation();
  // sendHybridFirebaseEvent("PAYMENT_FLOW", {
  //   stage: "select_product",
  //   user_id,
  //   device_guid,
  //   product_id,
  //   coins,
  //   price,
  //   currency,
  //   flow_id,
  // });
}

/**
 * Note:
 * We need to send an event each time user starts a card creation,
 * either automatically for the first card, or manually for later cards.
 * This is only for stripe card payments.
 * @deprecated
 */
export function startCardCreationHybridEvent() {
  // NOTE(purchase-bug-fix-pass): Deprecated hybrid payment flow events. Was missing updates and missing event triggers.
  throw "Deprecated";
  // const {
  //   user_id,
  //   device_guid,
  //   product_id,
  //   coins,
  //   price,
  //   currency,
  //   page_source,
  //   flow_id,
  // } = getHybridPaymentFlowInformation();
  // sendHybridFirebaseEvent("PAYMENT_FLOW", {
  //   stage: "start_card_creation",
  //   user_id,
  //   device_guid,
  //   product_id,
  //   coins,
  //   price,
  //   currency,
  //   page_source, //“single_stripe”, “multi_payment”
  //   flow_id,
  // });
}

/**
 * Note:
 * We need to send an event each time user successfully creates a card
 * (either success for the first purchase, or manual additions for later cards).
 * This is only for stripe card payments.
 * @deprecated
 */
export function cardCreationSuccessHybridEvent() {
  // NOTE(purchase-bug-fix-pass): Deprecated hybrid payment flow events. Was missing updates and missing event triggers.
  throw "Deprecated";
  // const {
  //   user_id,
  //   device_guid,
  //   product_id,
  //   coins,
  //   price,
  //   currency,
  //   page_source,
  //   flow_id,
  // } = getHybridPaymentFlowInformation();
  // sendHybridFirebaseEvent("PAYMENT_FLOW", {
  //   stage: "card_creation_success",
  //   user_id,
  //   device_guid,
  //   product_id,
  //   coins,
  //   price,
  //   currency,
  //   page_source,
  //   flow_id,
  // });
}

/**
 * Note:
 * We need to send an event each time user tries to complete a payment
 * (either with a new card, or an existing card).
 * This is only for stripe card payments.
 * @deprecated
 */
export function paymentAttemptHybridEvent() {
  // NOTE(purchase-bug-fix-pass): Deprecated hybrid payment flow events. Was missing updates and missing event triggers.
  throw "Deprecated";
  // const {
  //   user_id,
  //   device_guid,
  //   product_id,
  //   coins,
  //   price,
  //   currency,
  //   page_source,
  //   flow_id,
  //   is_new_card,
  // } = getHybridPaymentFlowInformation();
  // sendHybridFirebaseEvent("PAYMENT_FLOW", {
  //   stage: "payment_attempt",
  //   user_id,
  //   device_guid,
  //   product_id,
  //   coins,
  //   price,
  //   currency,
  //   page_source,
  //   flow_id,
  //   is_new_card,
  // });
}

/**
 * Note:
 * We need to send an event each time user tries to start
 * a payment that forwards to a third party or google_play.
 * This is only meant payment methods that forward outside of the hybrid page.
 * @deprecated
 */
export function paymentStartHybridEvent() {
  // NOTE(purchase-bug-fix-pass): Deprecated hybrid payment flow events. Was missing updates and missing event triggers.
  throw "Deprecated";
  // const {
  //   user_id,
  //   device_guid,
  //   product_id,
  //   coins,
  //   price,
  //   currency,
  //   page_source,
  //   flow_id,
  //   is_new_card,
  //   payment_type,
  //   order_id,
  // } = getHybridPaymentFlowInformation();
  // sendHybridFirebaseEvent("PAYMENT_FLOW", {
  //   stage: "payment_start",
  //   user_id,
  //   device_guid,
  //   product_id,
  //   coins,
  //   price,
  //   currency,
  //   page_source,
  //   flow_id,
  //   is_new_card,
  //   payment_type,
  //   order_id,
  // });
}

/**
 * Note:
 * We need to send an event each time user successfully creates finishes a payment.
 * This is both for stripe and payment method that forward to a third party.
 * @deprecated
 */
export function paymentSuccessHybridEvent() {
  // NOTE(purchase-bug-fix-pass): Deprecated hybrid payment flow events. Was missing updates and missing event triggers.
  throw "Deprecated";
  // const {
  //   user_id,
  //   device_guid,
  //   product_id,
  //   coins,
  //   price,
  //   currency,
  //   page_source,
  //   flow_id,
  //   is_new_card,
  //   hybrid_method,
  // } = getHybridPaymentFlowInformation();
  // sendHybridFirebaseEvent("PAYMENT_FLOW", {
  //   stage: "payment_success_hybrid",
  //   user_id,
  //   device_guid,
  //   product_id,
  //   coins,
  //   price,
  //   currency,
  //   page_source,
  //   flow_id,
  //   is_new_card,
  //   hybrid_method,
  // });
}

/**
 * Note:
 * We need to send an event each time user opens up a coin purchase page
 * (including automatic triggers, and overlays in a livestream).
 * @deprecated
 */
export function paymentPageLoadedEvent(
  userId,
  deviceId,
  pageName,
  automatic = 0
) {
  // NOTE(purchase-bug-fix-pass): Deprecated hybrid payment load event. Was sent at wrong place.
  throw "Deprecated";
  // sendHybridFirebaseEvent("MISC_EVENT", {
  //   event_name: "payment_page_loaded",
  //   device_guid: deviceId,
  //   user_id: userId,
  //   page_name: pageName,
  //   automatic: automatic,
  // });
}

/**
 * Note:
 * We need to send an event each time user opens up a coin purchase page
 * (including automatic triggers, and overlays in a livestream).
 * @deprecated
 */
export function viewCoinPurchasePageHybritEvent(
  userId,
  deviceId,
  pageName,
  automatic = 0
) {
  // NOTE(purchase-bug-fix-pass): Deprecated hybrid payment view event. Was sent at wrong place in some cases.
  throw "Deprecated";
  // sendHybridFirebaseEvent("MISC_EVENT", {
  //   event_name: "view_coin_purchase_page",
  //   device_guid: deviceId,
  //   user_id: userId,
  //   page_name: pageName,
  //   automatic: automatic,
  // });
}
