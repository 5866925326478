<template>
  <div
    class="payermax-checkout-container pt-0"
    :class="{ 'hybrid-container': isHybridPage }"
  >
    <div class="promotion-top" v-if="isPromotionPayment">
      <div class="user-coin-circle">
        <div class="user-coin">
          <img
            class="coin-svg"
            src="@/assets/img/coin.svg"
            :alt="$t('general.coin_icon')"
          />
          <span class="coin-amount">
            {{ formatCoin(userCoinInfo.coin_info.coins) }}
          </span>
        </div>
      </div>
      <div class="purchase-header">
        {{ $t("menu.purchase") }}
      </div>
      <div class="close-right">
        <div class="close">
          <i class="fas fa-times" @click="closeHybridPage"></i>
        </div>
      </div>
    </div>
    <div class="hybrid-section">
      <div class="section-header">
        <div>{{ $t("payermax.order_details") }}</div>
        <div class="close" v-if="!isPromotionPayment">
          <i class="fas fa-times" @click="closeCreditCard"></i>
        </div>
      </div>
      <div class="order-amount-container" v-if="isPromotionPayment">
        <div class="order-amount-label">
          {{ $t("payermax.amount_of_coin") }}
        </div>
        <div class="price">
          <img
            class="diamond-icon"
            src="@/assets/img/coin.svg"
            :alt="$t('general.coin_icon')"
          />
          {{ selectedProduct.amount }}
        </div>
      </div>
      <div class="order-price-container">
        <div class="order-price-label">{{ $t("payermax.order_total") }}</div>
        <div
          class="price"
          v-if="
            selectedProduct &&
            selectedProduct.stripe_price &&
            selectedProduct.stripe_price.currency
          "
        >
          {{ currencies[selectedProduct.stripe_price.currency.toUpperCase()]
          }}{{ selectedProduct.stripe_price.price }}
        </div>
      </div>
    </div>
    <div class="hybrid-section mt-4">
      <div class="section-cards">{{ $t("payermax.payment_details") }}</div>
      <div class="card-item-container">
        <payermax-card-item
          :cardData="paymentMethod"
          :selectedPaymentMethod="selectedPaymentMethod"
          @onRemove="(val) => (onRemove = val)"
          @detached="deletePaymentMethod"
          @removeError="removeError"
          v-for="paymentMethod in paymentMethods"
          :key="paymentMethod.id"
        ></payermax-card-item>
      </div>
      <div class="pay-with-another-card" @click="goCardPayment">
        <img src="@/assets/img/plus-icon.svg" />
        {{ $t("checkout.buy_with_another_credit_card") }}
      </div>
      <div class="pay-button-container">
        <button
          v-if="
            this.paymentMethods &&
            this.paymentMethods.length > 0 &&
            selectedProduct &&
            selectedProduct.stripe_price &&
            selectedProduct.stripe_price.currency
          "
          @click="payWithMethod"
          class="green-button"
          :disabled="loading || onRemove"
        >
          {{ $t("payermax.confirm_payment") }}
          {{ currencies[selectedProduct.stripe_price.currency.toUpperCase()]
          }}{{ selectedProduct.stripe_price.price }}
          <img
            src="@/assets/img/loading.svg"
            alt="loading"
            class="loading"
            v-if="loading"
          />
        </button>
        <div class="card-error" v-if="cardError">{{ cardError }}</div>
      </div>
    </div>
    <div class="security-footer">
      <img
        class="lock-svg"
        src="@/assets/img/lock.svg"
        :alt="$t('payermax.stripe_secure_and_protected_payment')"
      />
      <span class="security-text">
        {{ $t("payermax.stripe_secure_and_protected_payment") }}
      </span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/components/Payment/Payermax/PayermaxCheckout.scss";
</style>
<script>
"use strict";

import { Logger } from "@/logging";
import { mapState, mapActions } from "vuex";
import PayermaxCardItem from "./PayermaxCardItem.vue";
import {
  stripePaymentRequestData,
  generatePaymentUUID,
  getPaymentUUID,
  clearPaymentUUID,
  formatCoin,
} from "@/helper/index.js";
const logger = new Logger("PayermaxCheckout");
export default {
  name: "PayermaxCheckout",
  components: {
    PayermaxCardItem,
  },
  data() {
    return {
      paymentMethods: null,
      loading: false,
      cardError: null,
      onRemove: false,
    };
  },
  computed: {
    ...mapState({
      isHybridPage: (state) => state.client.isHybridPage,
      selectedProduct: (state) => state.payment.selectedProduct,
      selectedPaymentMethod: (state) => state.payment.selectedPaymentMethod,
      currencies: (state) => state.client.currencies,
      forterToken: (state) => state.client.forterToken,
      userCoinInfo: (state) => state.client.userCoinInfo,
      isPromotionPayment: (state) => state.payment.isPromotionPayment,
      promotionReferrer: (state) => state.payment.promotionReferrer,
    }),
  },
  mounted() {
    window.scrollTo(0, 0);
    window["backButtonTapped"] = () => {
      if (this.isPromotionPayment) {
        this.$hybridapi("showLoading");
        window.location.href = decodeURIComponent(this.promotionReferrer);
      } else if (confirm(this.$t("labels.close_confirm"))) {
        this.hybridClose("close");
        return "close";
      }
      return "nothing";
    };
    this.$hybridapi("updateWindowSize", "full");
    this.$hybridapi("showLoading");
    this.$hybridapi("updatePageTitle", this.$t("labels.do_payment"));
    this.$store.dispatch("client/setBuyCoinBackground", "white-bg");
    // Hız sorunu için buraya taşındı
    if (this.selectedProduct) {
      this.getPaymentMethods(this.selectedProduct.sku);
    } else {
      this.hybridClose("close");
      this.$router.push("/payermax");
    }
  },
  methods: {
    ...mapActions("payment", [
      "stripePaymentWebsocket",
      "postHybridCloseChecks",
      "setSelectedPaymentMethod",
      "stripePayment",
      "stripePaymentMethods",
      "reConfirmPayment",
      "goComplete",
    ]),
    goCardPayment() {
      this.$router.push({
        path: "/payermax/credit-card",
      });
    },
    payWithMethod() {
      this.loading = true;
      this.stripePayment(
        stripePaymentRequestData(
          this.selectedProduct.sku,
          this.selectedPaymentMethod.id,
          getPaymentUUID(),
          this.forterToken
        )
      )
        .then((payWithMethodResponse) => {
          this.loading = false;
          if (payWithMethodResponse.error) {
            // Generate new UUID for next attempt on error from server.
            generatePaymentUUID();

            // Error response from server
            logger.debugError(
              "stripePayment error",
              payWithMethodResponse.error
            );
            this.cardError =
              payWithMethodResponse.error.message ||
              this.$t("errors.general_error");
          } else {
            // Success response from server
            if (payWithMethodResponse.status == "succeeded") {
              // Clear Payment UUID on success.
              clearPaymentUUID();
              this.goComplete();
            } else if (
              payWithMethodResponse.status == "requires_action" ||
              payWithMethodResponse.status == "requires_confirmation"
            ) {
              // Generate new UUID for next attempt.
              generatePaymentUUID();

              this.$stripe
                .handleCardAction(payWithMethodResponse.client_secret)
                .then((handleCardResponse) => {
                  if (handleCardResponse.error) {
                    this.loading = false;
                    this.cardError = handleCardResponse.error.message;
                  } else {
                    this.reConfirmPayment({
                      payment_id: payWithMethodResponse.payment_id,
                    })
                      .then((reConfirmResponse) => {
                        if (reConfirmResponse.error) {
                          // Error response from server
                          logger.debugError(
                            "reConfirmPayment error",
                            reConfirmResponse.error
                          );
                          this.loading = false;
                          this.cardError =
                            reConfirmResponse.error.message ||
                            this.$t("errors.general_error");
                        } else {
                          // Success response from server
                          logger.debug(
                            "reConfirmPayment success",
                            reConfirmResponse
                          );
                          // NOTE(purchase-bug-fix-pass): Not calling goComplete here for some backend reason. Websocket should trigger before response anyway.
                        }
                      })
                      .catch((error) => {
                        // Network error or server fail
                        logger.debugError("reConfirmPayment failed", error);
                        this.cardError = this.$t("errors.general_error");
                        this.loading = false;
                      });
                  }
                });
            } else {
              logger.debugError(
                "stripePayment unexpected case",
                payWithMethodResponse
              );
              this.cardError = this.$t("errors.general_error");
            }
          }
        })
        .catch((error) => {
          // Network error or server fail
          logger.debugError("stripePayment failed", error);
          this.cardError = this.$t("errors.general_error");
          this.loading = false;
        });
    },
    getPaymentMethods(productSku) {
      this.stripePaymentMethods(productSku)
        .then((paymentMethodResults) => {
          this.$hybridapi("hideLoading");
          if (paymentMethodResults.error) {
            // Error response from server
            logger.debugError(
              "stripePaymentMethods error",
              paymentMethodResults.error
            );
            this.cardError =
              paymentMethodResults.error.message ||
              this.$t("errors.general_error");
          } else {
            // Success response from server
            // Hız sorunu için buraya taşındı
            this.stripePaymentWebsocket(this.$hybrid_websocket_url).then(() => {
              this.loading = false;
              this.$hybridapi("hideLoading");
              // Clear Payment UUID after payment success.
              clearPaymentUUID();
              this.goComplete();
            });
            if (!paymentMethodResults.has_email) {
              this.$router.push("/payermax/email");
            } else {
              this.paymentMethods =
                paymentMethodResults.payment_methods.length > 0
                  ? paymentMethodResults.payment_methods
                  : null;
              if (this.paymentMethods) {
                this.setSelectedPaymentMethod(this.paymentMethods[0]);
              } else {
                this.$router.push("/payermax/credit-card");
              }
            }
          }
        })
        .catch((error) => {
          // Network error or server fail
          logger.debugError("stripePaymentMethods failed", error);
          this.cardError = this.$t("errors.general_error");
          this.$hybridapi("hideLoading");
        });
    },
    deletePaymentMethod(paymentMethodId) {
      this.paymentMethods = this.paymentMethods.filter(
        (item) => item.id !== paymentMethodId
      );
      if (paymentMethodId === this.selectedPaymentMethod.id) {
        // Generate new UUID for next attempt when method changes.
        generatePaymentUUID();

        this.$store.dispatch(
          "payment/setSelectedPaymentMethod",
          this.paymentMethods[0]
        );
      }
    },
    removeError() {
      this.cardError = null;
    },
    closeHybridPage() {
      this.$hybridapi("showLoading");
      window.location.href = decodeURIComponent(this.promotionReferrer);
    },
    closeCreditCard() {
      this.$hybridapi("showLoading");
      this.hybridClose("close");
    },
    formatCoin,
    hybridClose(exit_location = "close") {
      this.$hybridapi("close", exit_location);
      if (this.isHybridPage) this.postHybridCloseChecks();
    },
  },
};
</script>
