<template>
  <v-dialog
    v-model="showBuyCoinsModal"
    fullscreen
    hide-overlay
    :no-click-animation="true"
    :persistent="true"
    transition="dialog-bottom-transition"
    content-class="buy-coins-modal"
  >
    <v-card :class="buyCoinBackground">
      <div class="header">
        <div></div>
        <div>
          <v-btn
            color="primary"
            :dark="buyCoinBackground == 'black-opac-bg'"
            icon
            @click="closeBuyCoins"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <Payment />
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
@import "BuyCoinsModal.scss";
</style>
<script>
import { mapState } from "vuex";
import Payment from "@/components/Payment/Payment.vue";
export default {
  name: "BuyCoinsModalView",
  components: { Payment },
  computed: {
    ...mapState({
      showBuyCoinsModal: (state) => !!state.client.showBuyCoinsModal,
      buyCoinBackground: (state) => state.client.buyCoinBackground,
    }),
  },
  methods: {
    closeBuyCoins() {
      this.$store.dispatch("client/setShowBuyCoinsModal", {
        showBuyCoinsModal: false,
        buyCoinBackground: this.buyCoinBackground,
      });
      setTimeout(() => {
        this.$store.dispatch("payment/setStripePaymentSucceeded", false);
        this.$store.dispatch("payment/setSelectedProduct", null);
        this.$store.dispatch("payment/setSelectedPaymentMethod", null);
        this.$store.dispatch("payment/setStripeActivePage", "coinSelect");
      }, 500);
    },
  },
};
</script>
