<template>
  <div
    :class="`message-container ${mobileChatActive && 'message-container-box'}`"
  >
    <div
      v-if="
        userDirectMessageStatuses &&
        userDirectMessageStatuses.length > 0 &&
        isMessageStatusLoading === false
      "
      :class="`message-box ${mobileChatActive && 'mobile-user-passive'}`"
    >
      <div class="message-box-title">
        <div class="back-button-container">
          <button class="back-button-header" @click="goBack">
            <img
              class="chat-header-back-button"
              :src="require(`@/assets/img/back-button_${brandName}.svg`)"
              :alt="$t('general.back_button')"
            />
          </button>
        </div>
        <div class="message-title-span">
          <span class="message-title-content">
            {{ $t("messages_page.messages") }}
          </span>
        </div>
      </div>
      <div
        v-for="(item, index) in userDirectMessageStatuses"
        :key="`${index}+${item.timestamp}`"
        :class="
          activeId === item.conversation_id
            ? 'user-message active'
            : 'user-message'
        "
        @click="getUserMessages(item.conversation_id, item.user)"
      >
        <div v-if="item.user && item.user.user_id === '1'" class="message-img">
          <img
            class="mod-live-img"
            :src="require(`@/assets/img/livesupport_${brandName}.svg`)"
          />
        </div>
        <div
          v-else-if="item.user && item.user.user_id === 'moderation'"
          class="message-img"
        >
          <img
            class="mod-live-img"
            :src="require(`@/assets/img/moderation_${brandName}.svg`)"
          />
        </div>
        <div v-else class="message-img">
          <user-image-circle :user="item.user" size="50px" border="" />
        </div>
        <div class="single-user">
          <div class="user-content">
            <div class="user-level-name-wrapper">
              <level-badge :user="item.user" :fontSize="'14'"></level-badge>
              <span class="user-name">{{ item.user.name }}</span>
            </div>
            <span class="user-message-date-span">{{
              getWalltime(item.timestamp)
            }}</span>
          </div>
          <div class="user-message-date">
            <span
              :class="
                item.unseen_count !== 0 ? 'has-unseen' : 'user-message-preview'
              "
              >{{ item.last_message_text }}</span
            >
            <span
              :class="
                item.unseen_count !== unseenUserMessageCount
                  ? 'unseen-count-badge none'
                  : 'unseen-count-badge'
              "
              v-if="item.unseen_count > 0"
            >
              {{ unseenUserMessageCount }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="isMessageStatusLoading === true"
      class="message-loader-container"
    >
      <div class="message-box-loader">
        <i class="fas fa-circle-notch fa-spin"></i>
      </div>
    </div>
    <div
      v-else
      :class="`message-box empty ${mobileChatActive && 'mobile-user-passive'}`"
    >
      <img :src="require(`@/assets/img/empty-message-box_${brandName}.svg`)" />
      <span class="empty-info"> {{ $t("messages_page.empty_box") }} </span>
    </div>

    <div
      v-if="userDirectMessageHistory"
      :class="`chat-container ${mobileChatActive && 'mobile-chat-active'}`"
    >
      <div class="chat-about">
        <span class="message-back-button-wrapper">
          <button class="message-back-button" @click="backToList">
            <img
              class="chat-header-back-button"
              :src="require(`@/assets/img/back-button_${brandName}.svg`)"
              :alt="$t('general.back_button')"
            />
          </button>
        </span>
        <div class="chat-user-img">
          <v-btn
            depressed
            v-if="
              userDirectMessageHistory &&
              isModMessage !== 'moderation' &&
              isModMessage !== '1'
            "
            @click="fullProfile(userDirectMessageHistory.conversation_id)"
          >
            <user-image-circle
              :user="userDirectMessageHistory.user"
              size="40px"
              border=""
            />
          </v-btn>
          <div v-if="isModMessage === '1'" class="message-img">
            <img
              class="mod-live-img"
              :src="require(`@/assets/img/livesupport_${brandName}.svg`)"
            />
          </div>
          <div v-if="isModMessage === 'moderation'" class="message-img">
            <img
              class="mod-live-img"
              :src="require(`@/assets/img/moderation_${brandName}.svg`)"
            />
          </div>
        </div>
        <div class="chat-user-content">
          <span class="chat-user-name">
            {{ userDirectMessageHistory.user.name }}</span
          >
        </div>
      </div>

      <div class="chat-history">
        <div
          class="is-following-container"
          v-if="
            messageInfoForCoinCount !== null &&
            messageInfoForCoinCount.user.is_followed === false &&
            messageInfoForCoinCount.exists === false
          "
        >
          <i class="is-following-icon fas fa-user-times"></i>
          <span class="is-following-content">{{
            $t("messages_page.is_following")
          }}</span>
        </div>
        <ul class="chat-history-ul" v-on:scroll.passive="handleScroll">
          <li v-if="messageScreenError">
            <span class="message other-message-box">
              {{ messageScreenError }}
            </span>
          </li>
          <li
            v-else
            v-for="(item, index) in userMessages"
            :key="`message${item.message_id}${index}`"
            :class="`message ${
              item.owner_type === 3 ? 'other-message-box' : 'my-message-box'
            }
              ${item.type === ConversationMessageDataEnums.AUDIO ? 'audio' : ''}
              ${item.type === ConversationMessageDataEnums.PHOTO ? 'photo' : ''}
              ${item.type === ConversationMessageDataEnums.VIDEO ? 'video' : ''}
              ${item.type === ConversationMessageDataEnums.GIFT ? 'gift' : ''}`"
          >
            <span
              :class="`message ${
                item.owner_type === 3
                  ? 'other-message float-left'
                  : 'message-last float-right'
              }`"
            >
              <img
                class="gift-img"
                v-if="item.data && item.data.gift"
                :src="item.data.gift.image"
                :alt="$t('general.gift_img')"
              />
              <img
                v-if="item.type === ConversationMessageDataEnums.PHOTO"
                class="photo-message"
                :src="item.data.file_url"
                :alt="$t('general.photo_message')"
              />

              <audio
                class="audio-message"
                v-if="item.type === ConversationMessageDataEnums.AUDIO"
                controls
              >
                <source :src="item.data.file_url" type="audio/mp4" />
              </audio>
              <video
                v-if="item.type === ConversationMessageDataEnums.VIDEO"
                class="video-message"
                width="320"
                height="240"
                controls
              >
                <source :src="item.data.file_url" type="video/mp4" />
              </video>
              {{ item.data.text }}
            </span>
          </li>
        </ul>
      </div>
      <div class="keyboard-input" v-if="isModMessage !== 'moderation'">
        <input
          class="keyboard-text-area"
          type="textarea"
          maxlength="500"
          v-model="messageText"
          :placeholder="
            messageInfoForCoinCount && messageInfoForCoinCount.initial_coin_cost
              ? messageInfoForCoinCount.initial_coin_cost + ' coins/message'
              : ''
          "
          v-on:keyup.enter="
            sendMessage(
              userDirectMessageHistory.conversation_id,
              messageText,
              userDirectMessageHistory.user.user_id
            )
          "
          v-on:keyup="focus()"
          v-on:focus="inputFocus"
        />
        <div
          v-if="this.isTyping === true || isModMessage === '1'"
          class="send-message-button-container"
        >
          <button class="send-message-button">
            <img
              class="button-svg"
              :src="require(`@/assets/img/send-message_${brandName}.svg`)"
              :alt="$t('general.send_message_button')"
              @click="
                sendMessage(
                  userDirectMessageHistory.conversation_id,
                  messageText,
                  userDirectMessageHistory.user.user_id
                )
              "
            />
          </button>
        </div>
        <v-btn
          class="send-gift-button"
          fab
          v-if="
            this.isTyping === false &&
            showSendGift === true &&
            isModMessage !== '1'
          "
          @click="closeGift"
        >
          <img
            class="send-gift-button-img"
            src="@/assets/img/gift-icon.svg"
            :alt="$t('general.send_gift_button')"
          />
        </v-btn>
        <v-btn
          v-if="
            this.isTyping === false &&
            showSendGift === false &&
            isModMessage !== '1'
          "
          class="send-gift-button-passive"
          fab
          @click="openGift"
        >
          <img
            class="send-gift-button-passive-img"
            src="@/assets/img/gift-box-passive.svg"
            :alt="$t('general.send_gift_button')"
          />
        </v-btn>
      </div>
      <SendGift
        class="message-gift-container"
        v-if="
          (showSendGift &&
            isDesktop() &&
            isSendGiftMessageLoading === false &&
            isModMessage !== 'moderation' &&
            isModMessage !== '1') ||
          (isMobile() &&
            showSendGift &&
            isSendGiftMessageLoading === false &&
            isModMessage !== 'moderation' &&
            isModMessage !== '1')
        "
        dividerCount="16"
      />
      <div
        v-if="isSendGiftMessageLoading === true"
        class="live-stream-loading live-stream-screen"
      >
        <div class="overlay">
          <i class="fas fa-circle-notch fa-spin"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "Message.scss";
</style>
<script>
import { mapState, mapActions } from "vuex";
import UserImageCircle from "@/components/Shared/UserImageCircle/UserImageCircle.vue";
import { generateUUID } from "@/helper";
import moment from "moment";
import { generateBase64Url } from "@/helper/index.js";
import SendGift from "@/components/SendGift/SendGift.vue";
import { ConversationMessageDataEnums } from "@/helper/enums.js";
import LevelBadge from "@/components/Shared/LevelBadge/LevelBadge.vue";

export default {
  name: "Message",
  data() {
    return {
      activeId: null,
      liveSupportInfos: null,
      messageText: "",
      lastOldRequest: 0,
      unseenCounter: null,
      isTyping: false,
      ConversationMessageDataEnums,
      isModMessage: null,
    };
  },
  components: {
    SendGift,
    UserImageCircle,
    LevelBadge,
  },
  computed: {
    ...mapState({
      userMessages: (state) => {
        if (
          state.client.userDirectMessageHistory &&
          state.client.userDirectMessageHistory.messages
        )
          return state.client.userDirectMessageHistory.messages;

        return [];
      },
      messageScreenError: (state) => state.client.messageScreenError,
      messageFromProfile: (state) => state.client.messageFromProfile,
      userDirectMessageHistory: (state) =>
        state.client.userDirectMessageHistory,
      userDirectMessageInfo: (state) => state.client.userDirectMessageInfo,
      userDirectMessageStatuses: (state) =>
        state.client.userDirectMessageStatuses,
      unseenUserMessageCount: (state) => state.client.unseenUserMessageCount,
      messageInfoForCoinCount: (state) => state.client.messageInfoForCoinCount,
      showSendGift: (state) => state.live.showSendGift,
      isSendGiftMessageLoading: (state) =>
        state.client.isSendGiftMessageLoading,
      brandName: (state) => state.brandName,
      isMessageStatusLoading: (state) => state.client.isMessageStatusLoading,
      mobileChatActive: (state) => state.client.mobileChatActive,
      conversationId: (state) => state.client.conversationId,
      conversationUser: (state) => state.client.conversationUser,
      selectedUser: (state) => state.live.selectedUser,
    }),
  },
  watch: {
    messageFromProfile(newValue) {
      if (newValue) {
        this.getUserMessages(newValue.conversation_id, newValue.user);
      }
    },
  },
  created() {
    if (this.conversationId !== null && this.conversationUser !== null) {
      this.getUserDirectMessageHistory({
        conversation_id: this.conversationId,
        user: this.conversationUser,
        type: "initial",
      });
      this.setMobileChatActive(true);
    }
  },
  mounted() {
    this.getData();
    if (this.conversationId === null && this.mobileChatActive === true) {
      this.setMobileChatActive(false);
    }
  },
  beforeDestroy() {
    this.resetUserDirectMessageStatuses();
  },
  methods: {
    ...mapActions("client", [
      "logout",
      "getUserDirectMessageHistory",
      "getUserDirectMessageStatuses",
      "getUserDirectMessageInfo",
      "postUserTextMessage",
      "resetUserMessageInfo",
      "resetUserDirectMessageStatuses",
      "getUserMessageInfo",
      "setConversationIdProfile",
      "setConversationUser",
      "setMobileChatActive",
    ]),
    ...mapActions("live", ["closeGift"]),
    generateBase64Url,
    handleScroll(event) {
      if (
        event.target.scrollTop -
          (-event.target.scrollHeight + event.target.offsetHeight) <
          100 &&
        this.userDirectMessageHistory.nextKey !== null
      ) {
        this.lastOldRequest = Date.now();
        this.getUserDirectMessageHistory({
          conversation_id: this.userDirectMessageHistory.conversation_id,
          user: this.userDirectMessageHistory.user,
          next: this.userDirectMessageHistory.nextKey,
          type: "old",
        });
      }
    },
    getData() {
      this.getUserDirectMessageStatuses();
    },
    getWalltime(endTime) {
      const now = moment(new Date()); //todays date
      const end = moment(endTime);
      const duration = moment.duration(now.diff(end));
      const days = duration.asDays();
      if (days < 1) {
        return moment(endTime).local().format("HH:mm");
      } else if (days < 7) {
        return moment(endTime).local().format("dddd");
      } else {
        return moment(endTime).format("DD.MM.YYYY");
      }
    },
    getUserMessages(conversation_id, user) {
      this.setMobileChatActive(true);
      this.messageText = "";
      this.activeId = conversation_id;
      this.isModMessage = user.user_id;
      this.getUserDirectMessageHistory({
        conversation_id,
        user: user,
        type: "initial",
      });
      this.resetUserDirectMessageStatuses();
    },
    backToList() {
      this.getUserDirectMessageStatuses();
      this.setMobileChatActive(false);
    },
    startFrom(arr, idx) {
      return arr.slice(idx);
    },
    sendMessage(conversation_id, messageText) {
      if (messageText.trim().length === 0) return;
      let uuid = generateUUID();
      uuid = uuid.replace("-", "").slice(0, 20);
      this.postUserTextMessage({
        conversation_id,
        text: messageText,
        dev_payload: uuid,
      });
      this.messageText = "";
      this.getUserDirectMessageStatuses();
    },
    goBack() {
      this.$router.go(-1);
    },
    isDesktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.xs
      );
    },
    openGift() {
      this.$store.dispatch("live/setShowSendGift", true);
    },
    focus() {
      this.$store.dispatch("live/setShowSendGift", false);
      this.isTyping = true;
      if (this.messageText.length < 1) {
        // this.$store.dispatch("live/setShowSendGift", true);
        this.isTyping = false;
      }
    },
    fullProfile(conversation_id) {
      this.setConversationIdProfile(conversation_id);
      this.setConversationUser({ ...this.userDirectMessageHistory.user });
      const profileUrl = this.generateBase64Url(
        "/profile/",
        this.userDirectMessageHistory.user.user_id
      );
      this.$router.push(profileUrl);
    },
    inputFocus(e) {
      if (navigator.userAgent.indexOf("iPhone OS 15") != -1) {
        setTimeout(() => {
          window.scrollTo(0, 270);
        }, 250);
      }
    },
  },
};
</script>
