const countries = [
  {
    code: "AD",
    phone_code: "376",
    name: "Andorra",
    flag_icon: "iti-flag ad",
    local_name: null,
  },
  {
    code: "AE",
    phone_code: "971",
    name: "United Arab Emirates",
    flag_icon: "iti-flag ae",
    local_name: "‫الإمارات العربية المتحدة‬‎",
  },
  {
    code: "AF",
    phone_code: "93",
    name: "Afghanistan",
    flag_icon: "iti-flag af",
    local_name: "‫افغانستان‬‎",
  },
  {
    code: "AG",
    phone_code: "1268",
    name: "Antigua and Barbuda",
    flag_icon: "iti-flag ag",
    local_name: null,
  },
  {
    code: "AI",
    phone_code: "1264",
    name: "Anguilla",
    flag_icon: "iti-flag ai",
    local_name: null,
  },
  {
    code: "AL",
    phone_code: "355",
    name: "Albania",
    flag_icon: "iti-flag al",
    local_name: "Shqipëri",
  },
  {
    code: "AM",
    phone_code: "374",
    name: "Armenia",
    flag_icon: "iti-flag am",
    local_name: "Հայաստան",
  },
  {
    code: "AO",
    phone_code: "244",
    name: "Angola",
    flag_icon: "iti-flag ao",
    local_name: null,
  },
  {
    code: "AR",
    phone_code: "54",
    name: "Argentina",
    flag_icon: "iti-flag ar",
    local_name: null,
  },
  {
    code: "AS",
    phone_code: "1684",
    name: "American Samoa",
    flag_icon: "iti-flag as",
    local_name: null,
  },
  {
    code: "AT",
    phone_code: "43",
    name: "Austria",
    flag_icon: "iti-flag at",
    local_name: "Österreich",
  },
  {
    code: "AU",
    phone_code: "61",
    name: "Australia",
    flag_icon: "iti-flag au",
    local_name: null,
  },
  {
    code: "AW",
    phone_code: "297",
    name: "Aruba",
    flag_icon: "iti-flag aw",
    local_name: null,
  },
  {
    code: "AX",
    phone_code: "358",
    name: "Åland Islands",
    flag_icon: "iti-flag ax",
    local_name: null,
  },
  {
    code: "AZ",
    phone_code: "994",
    name: "Azerbaijan",
    flag_icon: "iti-flag az",
    local_name: "Azərbaycan",
  },
  {
    code: "BA",
    phone_code: "387",
    name: "Bosnia and Herzegovina",
    flag_icon: "iti-flag ba",
    local_name: "Босна и Херцеговина",
  },
  {
    code: "BB",
    phone_code: "1246",
    name: "Barbados",
    flag_icon: "iti-flag bb",
    local_name: null,
  },
  {
    code: "BD",
    phone_code: "880",
    name: "Bangladesh",
    flag_icon: "iti-flag bd",
    local_name: "বাংলাদেশ",
  },
  {
    code: "BE",
    phone_code: "32",
    name: "Belgium",
    flag_icon: "iti-flag be",
    local_name: "België",
  },
  {
    code: "BF",
    phone_code: "226",
    name: "Burkina Faso",
    flag_icon: "iti-flag bf",
    local_name: null,
  },
  {
    code: "BG",
    phone_code: "359",
    name: "Bulgaria",
    flag_icon: "iti-flag bg",
    local_name: "България",
  },
  {
    code: "BH",
    phone_code: "973",
    name: "Bahrain",
    flag_icon: "iti-flag bh",
    local_name: "‫البحرين‬‎",
  },
  {
    code: "BI",
    phone_code: "257",
    name: "Burundi",
    flag_icon: "iti-flag bi",
    local_name: "Uburundi",
  },
  {
    code: "BJ",
    phone_code: "229",
    name: "Benin",
    flag_icon: "iti-flag bj",
    local_name: "Bénin",
  },
  {
    code: "BL",
    phone_code: "590",
    name: "Saint Barthélemy",
    flag_icon: "iti-flag bl",
    local_name: null,
  },
  {
    code: "BM",
    phone_code: "1441",
    name: "Bermuda",
    flag_icon: "iti-flag bm",
    local_name: null,
  },
  {
    code: "BN",
    phone_code: "673",
    name: "Brunei",
    flag_icon: "iti-flag bn",
    local_name: null,
  },
  {
    code: "BO",
    phone_code: "591",
    name: "Bolivia",
    flag_icon: "iti-flag bo",
    local_name: null,
  },
  {
    code: "BQ",
    phone_code: "599",
    name: "Caribbean Netherlands",
    flag_icon: "iti-flag bq",
    local_name: null,
  },
  {
    code: "BR",
    phone_code: "55",
    name: "Brazil",
    flag_icon: "iti-flag br",
    local_name: "Brasil",
  },
  {
    code: "BS",
    phone_code: "1242",
    name: "Bahamas",
    flag_icon: "iti-flag bs",
    local_name: null,
  },
  {
    code: "BT",
    phone_code: "975",
    name: "Bhutan",
    flag_icon: "iti-flag bt",
    local_name: "འབྲུག",
  },
  {
    code: "BW",
    phone_code: "267",
    name: "Botswana",
    flag_icon: "iti-flag bw",
    local_name: null,
  },
  {
    code: "BY",
    phone_code: "375",
    name: "Belarus",
    flag_icon: "iti-flag by",
    local_name: "Беларусь",
  },
  {
    code: "BZ",
    phone_code: "501",
    name: "Belize",
    flag_icon: "iti-flag bz",
    local_name: null,
  },
  {
    code: "CA",
    phone_code: "1",
    name: "Canada",
    flag_icon: "iti-flag ca",
    local_name: null,
  },
  {
    code: "CC",
    phone_code: "61",
    name: "Cocos (Keeling) Islands",
    flag_icon: "iti-flag cc",
    local_name: null,
  },
  {
    code: "CD",
    phone_code: "243",
    name: "Congo (DRC)",
    flag_icon: "iti-flag cd",
    local_name: "Jamhuri ya Kcodeemokrasia ya Kongo",
  },
  {
    code: "CF",
    phone_code: "236",
    name: "Central African Republic",
    flag_icon: "iti-flag cf",
    local_name: "République centrafricaine",
  },
  {
    code: "CG",
    phone_code: "242",
    name: "Congo (Republic)",
    flag_icon: "iti-flag cg",
    local_name: "Congo-Brazzaville",
  },
  {
    code: "CH",
    phone_code: "41",
    name: "Switzerland",
    flag_icon: "iti-flag ch",
    local_name: "Schweiz",
  },
  {
    code: "CI",
    phone_code: "225",
    name: "Côte d’Ivoire",
    flag_icon: "iti-flag ci",
    local_name: null,
  },
  {
    code: "CK",
    phone_code: "682",
    name: "Cook Islands",
    flag_icon: "iti-flag ck",
    local_name: null,
  },
  {
    code: "CL",
    phone_code: "56",
    name: "Chile",
    flag_icon: "iti-flag cl",
    local_name: null,
  },
  {
    code: "CM",
    phone_code: "237",
    name: "Cameroon",
    flag_icon: "iti-flag cm",
    local_name: "Cameroun",
  },
  {
    code: "CN",
    phone_code: "86",
    name: "China",
    flag_icon: "iti-flag cn",
    local_name: "中国",
  },
  {
    code: "CO",
    phone_code: "57",
    name: "Colombia",
    flag_icon: "iti-flag co",
    local_name: null,
  },
  {
    code: "CR",
    phone_code: "506",
    name: "Costa Rica",
    flag_icon: "iti-flag cr",
    local_name: null,
  },
  {
    code: "CU",
    phone_code: "53",
    name: "Cuba",
    flag_icon: "iti-flag cu",
    local_name: null,
  },
  {
    code: "CV",
    phone_code: "238",
    name: "Cape Verde",
    flag_icon: "iti-flag cv",
    local_name: "Kabu Verdi",
  },
  {
    code: "CW",
    phone_code: "599",
    name: "Curaçao",
    flag_icon: "iti-flag cw",
    local_name: null,
  },
  {
    code: "CX",
    phone_code: "61",
    name: "Christmas Island",
    flag_icon: "iti-flag cx",
    local_name: null,
  },
  {
    code: "CY",
    phone_code: "357",
    name: "Cyprus",
    flag_icon: "iti-flag cy",
    local_name: "Κύπρος",
  },
  {
    code: "CZ",
    phone_code: "420",
    name: "Czech Republic",
    flag_icon: "iti-flag cz",
    local_name: "Česká republika",
  },
  {
    code: "DE",
    phone_code: "49",
    name: "Germany",
    flag_icon: "iti-flag de",
    local_name: "Deutschland",
  },
  {
    code: "DJ",
    phone_code: "253",
    name: "Djibouti",
    flag_icon: "iti-flag dj",
    local_name: null,
  },
  {
    code: "DK",
    phone_code: "45",
    name: "Denmark",
    flag_icon: "iti-flag dk",
    local_name: "Danmark",
  },
  {
    code: "DM",
    phone_code: "1767",
    name: "Dominica",
    flag_icon: "iti-flag dm",
    local_name: null,
  },
  {
    code: "DO",
    phone_code: "1",
    name: "Dominican Republic",
    flag_icon: "iti-flag do",
    local_name: "República Dominicana",
  },
  {
    code: "DZ",
    phone_code: "213",
    name: "Algeria",
    flag_icon: "iti-flag dz",
    local_name: "‫الجزائر‬‎",
  },
  {
    code: "EC",
    phone_code: "593",
    name: "Ecuador",
    flag_icon: "iti-flag ec",
    local_name: null,
  },
  {
    code: "EE",
    phone_code: "372",
    name: "Estonia",
    flag_icon: "iti-flag ee",
    local_name: "Eesti",
  },
  {
    code: "EG",
    phone_code: "20",
    name: "Egypt",
    flag_icon: "iti-flag eg",
    local_name: "‫مصر‬‎",
  },
  {
    code: "EH",
    phone_code: "212",
    name: "Western Sahara",
    flag_icon: "iti-flag eh",
    local_name: "‫الصحراء الغربية‬‎",
  },
  {
    code: "ER",
    phone_code: "291",
    name: "Eritrea",
    flag_icon: "iti-flag er",
    local_name: null,
  },
  {
    code: "ES",
    phone_code: "34",
    name: "Spain",
    flag_icon: "iti-flag es",
    local_name: "España",
  },
  {
    code: "ET",
    phone_code: "251",
    name: "Ethiopia",
    flag_icon: "iti-flag et",
    local_name: null,
  },
  {
    code: "FI",
    phone_code: "358",
    name: "Finland",
    flag_icon: "iti-flag fi",
    local_name: "Suomi",
  },
  {
    code: "FJ",
    phone_code: "679",
    name: "Fiji",
    flag_icon: "iti-flag fj",
    local_name: null,
  },
  {
    code: "FK",
    phone_code: "500",
    name: "Falkland Islands",
    flag_icon: "iti-flag fk",
    local_name: "Islas Malvinas",
  },
  {
    code: "FM",
    phone_code: "691",
    name: "Micronesia",
    flag_icon: "iti-flag fm",
    local_name: null,
  },
  {
    code: "FO",
    phone_code: "298",
    name: "Faroe Islands",
    flag_icon: "iti-flag fo",
    local_name: "Føroyar",
  },
  {
    code: "FR",
    phone_code: "33",
    name: "France",
    flag_icon: "iti-flag fr",
    local_name: null,
  },
  {
    code: "GA",
    phone_code: "241",
    name: "Gabon",
    flag_icon: "iti-flag ga",
    local_name: null,
  },
  {
    code: "GB",
    phone_code: "44",
    name: "United Kingdom",
    flag_icon: "iti-flag gb",
    local_name: null,
  },
  {
    code: "GD",
    phone_code: "1473",
    name: "Grenada",
    flag_icon: "iti-flag gd",
    local_name: null,
  },
  {
    code: "GE",
    phone_code: "995",
    name: "Georgia",
    flag_icon: "iti-flag ge",
    local_name: "საქართველო",
  },
  {
    code: "GF",
    phone_code: "594",
    name: "French Guiana",
    flag_icon: "iti-flag gf",
    local_name: "Guyane française",
  },
  {
    code: "GG",
    phone_code: "44",
    name: "Guernsey",
    flag_icon: "iti-flag gg",
    local_name: null,
  },
  {
    code: "GH",
    phone_code: "233",
    name: "Ghana",
    flag_icon: "iti-flag gh",
    local_name: "Gaana",
  },
  {
    code: "GI",
    phone_code: "350",
    name: "Gibraltar",
    flag_icon: "iti-flag gi",
    local_name: null,
  },
  {
    code: "GL",
    phone_code: "299",
    name: "Greenland",
    flag_icon: "iti-flag gl",
    local_name: "Kalaallit Nunaat",
  },
  {
    code: "GM",
    phone_code: "220",
    name: "Gambia",
    flag_icon: "iti-flag gm",
    local_name: null,
  },
  {
    code: "GN",
    phone_code: "224",
    name: "Guinea",
    flag_icon: "iti-flag gn",
    local_name: "Guinée",
  },
  {
    code: "GP",
    phone_code: "590",
    name: "Guadeloupe",
    flag_icon: "iti-flag gp",
    local_name: null,
  },
  {
    code: "GQ",
    phone_code: "240",
    name: "Equatorial Guinea",
    flag_icon: "iti-flag gq",
    local_name: "Guinea Ecuatorial",
  },
  {
    code: "GR",
    phone_code: "30",
    name: "Greece",
    flag_icon: "iti-flag gr",
    local_name: "Ελλάδα",
  },
  {
    code: "GT",
    phone_code: "502",
    name: "Guatemala",
    flag_icon: "iti-flag gt",
    local_name: null,
  },
  {
    code: "GU",
    phone_code: "1671",
    name: "Guam",
    flag_icon: "iti-flag gu",
    local_name: null,
  },
  {
    code: "GW",
    phone_code: "245",
    name: "Guinea-Bissau",
    flag_icon: "iti-flag gw",
    local_name: "Guiné Bissau",
  },
  {
    code: "GY",
    phone_code: "592",
    name: "Guyana",
    flag_icon: "iti-flag gy",
    local_name: null,
  },
  {
    code: "HK",
    phone_code: "852",
    name: "Hong Kong",
    flag_icon: "iti-flag hk",
    local_name: "香港",
  },
  {
    code: "HN",
    phone_code: "504",
    name: "Honduras",
    flag_icon: "iti-flag hn",
    local_name: null,
  },
  {
    code: "HR",
    phone_code: "385",
    name: "Croatia",
    flag_icon: "iti-flag hr",
    local_name: "Hrvatska",
  },
  {
    code: "HT",
    phone_code: "509",
    name: "Haiti",
    flag_icon: "iti-flag ht",
    local_name: null,
  },
  {
    code: "HU",
    phone_code: "36",
    name: "Hungary",
    flag_icon: "iti-flag hu",
    local_name: "Magyarország",
  },
  {
    code: "ID",
    phone_code: "62",
    name: "Indonesia",
    flag_icon: "iti-flag code",
    local_name: null,
  },
  {
    code: "IE",
    phone_code: "353",
    name: "Ireland",
    flag_icon: "iti-flag ie",
    local_name: null,
  },
  {
    code: "IL",
    phone_code: "972",
    name: "Israel",
    flag_icon: "iti-flag il",
    local_name: "‫ישראל‬‎",
  },
  {
    code: "IM",
    phone_code: "44",
    name: "Isle of Man",
    flag_icon: "iti-flag im",
    local_name: null,
  },
  {
    code: "IN",
    phone_code: "91",
    name: "India",
    flag_icon: "iti-flag in",
    local_name: "भारत",
  },
  {
    code: "IO",
    phone_code: "246",
    name: "British Indian Ocean Territory",
    flag_icon: "iti-flag io",
    local_name: null,
  },
  {
    code: "IQ",
    phone_code: "964",
    name: "Iraq",
    flag_icon: "iti-flag iq",
    local_name: "‫العراق‬‎",
  },
  {
    code: "IR",
    phone_code: "98",
    name: "Iran",
    flag_icon: "iti-flag ir",
    local_name: "‫ایران‬‎",
  },
  {
    code: "IS",
    phone_code: "354",
    name: "Iceland",
    flag_icon: "iti-flag is",
    local_name: "Ísland",
  },
  {
    code: "IT",
    phone_code: "39",
    name: "Italy",
    flag_icon: "iti-flag it",
    local_name: "Italia",
  },
  {
    code: "JE",
    phone_code: "44",
    name: "Jersey",
    flag_icon: "iti-flag je",
    local_name: null,
  },
  {
    code: "JM",
    phone_code: "1876",
    name: "Jamaica",
    flag_icon: "iti-flag jm",
    local_name: null,
  },
  {
    code: "JO",
    phone_code: "962",
    name: "Jordan",
    flag_icon: "iti-flag jo",
    local_name: "‫الأردن‬‎",
  },
  {
    code: "JP",
    phone_code: "81",
    name: "Japan",
    flag_icon: "iti-flag jp",
    local_name: "日本",
  },
  {
    code: "KE",
    phone_code: "254",
    name: "Kenya",
    flag_icon: "iti-flag ke",
    local_name: null,
  },
  {
    code: "KG",
    phone_code: "996",
    name: "Kyrgyzstan",
    flag_icon: "iti-flag kg",
    local_name: "Кыргызстан",
  },
  {
    code: "KH",
    phone_code: "855",
    name: "Cambodia",
    flag_icon: "iti-flag kh",
    local_name: "កម្ពុជា",
  },
  {
    code: "KI",
    phone_code: "686",
    name: "Kiribati",
    flag_icon: "iti-flag ki",
    local_name: null,
  },
  {
    code: "KM",
    phone_code: "269",
    name: "Comoros",
    flag_icon: "iti-flag km",
    local_name: "‫جزر القمر‬‎",
  },
  {
    code: "KN",
    phone_code: "1869",
    name: "Saint Kitts and Nevis",
    flag_icon: "iti-flag kn",
    local_name: null,
  },
  {
    code: "KP",
    phone_code: "850",
    name: "North Korea",
    flag_icon: "iti-flag kp",
    local_name: "조선 민주주의 인민 공화국",
  },
  {
    code: "KR",
    phone_code: "82",
    name: "South Korea",
    flag_icon: "iti-flag kr",
    local_name: "대한민국",
  },
  {
    code: "KW",
    phone_code: "965",
    name: "Kuwait",
    flag_icon: "iti-flag kw",
    local_name: "‫الكويت‬‎",
  },
  {
    code: "KY",
    phone_code: "1345",
    name: "Cayman Islands",
    flag_icon: "iti-flag ky",
    local_name: null,
  },
  {
    code: "KZ",
    phone_code: "7",
    name: "Kazakhstan",
    flag_icon: "iti-flag kz",
    local_name: "Казахстан",
  },
  {
    code: "LA",
    phone_code: "856",
    name: "Laos",
    flag_icon: "iti-flag la",
    local_name: "ລາວ",
  },
  {
    code: "LB",
    phone_code: "961",
    name: "Lebanon",
    flag_icon: "iti-flag lb",
    local_name: "‫لبنان‬‎",
  },
  {
    code: "LC",
    phone_code: "1758",
    name: "Saint Lucia",
    flag_icon: "iti-flag lc",
    local_name: null,
  },
  {
    code: "LI",
    phone_code: "423",
    name: "Liechtenstein",
    flag_icon: "iti-flag li",
    local_name: null,
  },
  {
    code: "LK",
    phone_code: "94",
    name: "Sri Lanka",
    flag_icon: "iti-flag lk",
    local_name: "ශ්‍රී ලංකාව",
  },
  {
    code: "LR",
    phone_code: "231",
    name: "Liberia",
    flag_icon: "iti-flag lr",
    local_name: null,
  },
  {
    code: "LS",
    phone_code: "266",
    name: "Lesotho",
    flag_icon: "iti-flag ls",
    local_name: null,
  },
  {
    code: "LT",
    phone_code: "370",
    name: "Lithuania",
    flag_icon: "iti-flag lt",
    local_name: "Lietuva",
  },
  {
    code: "LU",
    phone_code: "352",
    name: "Luxembourg",
    flag_icon: "iti-flag lu",
    local_name: null,
  },
  {
    code: "LV",
    phone_code: "371",
    name: "Latvia",
    flag_icon: "iti-flag lv",
    local_name: "Latvija",
  },
  {
    code: "LY",
    phone_code: "218",
    name: "Libya",
    flag_icon: "iti-flag ly",
    local_name: "‫ليبيا‬‎",
  },
  {
    code: "MA",
    phone_code: "212",
    name: "Morocco",
    flag_icon: "iti-flag ma",
    local_name: "‫المغرب‬‎",
  },
  {
    code: "MC",
    phone_code: "377",
    name: "Monaco",
    flag_icon: "iti-flag mc",
    local_name: null,
  },
  {
    code: "MD",
    phone_code: "373",
    name: "Moldova",
    flag_icon: "iti-flag md",
    local_name: "Republica Moldova",
  },
  {
    code: "ME",
    phone_code: "382",
    name: "Montenegro",
    flag_icon: "iti-flag me",
    local_name: "Crna Gora",
  },
  {
    code: "MF",
    phone_code: "590",
    name: "Saint Martin",
    flag_icon: "iti-flag mf",
    local_name: "Saint-Martin partie française)",
  },
  {
    code: "MG",
    phone_code: "261",
    name: "Madagascar",
    flag_icon: "iti-flag mg",
    local_name: "Madagasikara",
  },
  {
    code: "MH",
    phone_code: "692",
    name: "Marshall Islands",
    flag_icon: "iti-flag mh",
    local_name: null,
  },
  {
    code: "MK",
    phone_code: "389",
    name: "Macedonia (FYROM)",
    flag_icon: "iti-flag mk",
    local_name: "Македонија",
  },
  {
    code: "ML",
    phone_code: "223",
    name: "Mali",
    flag_icon: "iti-flag ml",
    local_name: null,
  },
  {
    code: "MM",
    phone_code: "95",
    name: "Myanmar (Burma)",
    flag_icon: "iti-flag mm",
    local_name: "မြန်မာ",
  },
  {
    code: "MN",
    phone_code: "976",
    name: "Mongolia",
    flag_icon: "iti-flag mn",
    local_name: "Монгол",
  },
  {
    code: "MO",
    phone_code: "853",
    name: "Macau",
    flag_icon: "iti-flag mo",
    local_name: "澳門",
  },
  {
    code: "MP",
    phone_code: "1670",
    name: "Northern Mariana Islands",
    flag_icon: "iti-flag mp",
    local_name: null,
  },
  {
    code: "MQ",
    phone_code: "596",
    name: "Martinique",
    flag_icon: "iti-flag mq",
    local_name: null,
  },
  {
    code: "MR",
    phone_code: "222",
    name: "Mauritania",
    flag_icon: "iti-flag mr",
    local_name: "‫موريتانيا‬‎",
  },
  {
    code: "MS",
    phone_code: "1664",
    name: "Montserrat",
    flag_icon: "iti-flag ms",
    local_name: null,
  },
  {
    code: "MT",
    phone_code: "356",
    name: "Malta",
    flag_icon: "iti-flag mt",
    local_name: null,
  },
  {
    code: "MU",
    phone_code: "230",
    name: "Mauritius",
    flag_icon: "iti-flag mu",
    local_name: "Moris",
  },
  {
    code: "MV",
    phone_code: "960",
    name: "Maldives",
    flag_icon: "iti-flag mv",
    local_name: null,
  },
  {
    code: "MW",
    phone_code: "265",
    name: "Malawi",
    flag_icon: "iti-flag mw",
    local_name: null,
  },
  {
    code: "MX",
    phone_code: "52",
    name: "Mexico",
    flag_icon: "iti-flag mx",
    local_name: "México",
  },
  {
    code: "MY",
    phone_code: "60",
    name: "Malaysia",
    flag_icon: "iti-flag my",
    local_name: null,
  },
  {
    code: "MZ",
    phone_code: "258",
    name: "Mozambique",
    flag_icon: "iti-flag mz",
    local_name: "Moçambique",
  },
  {
    code: "NA",
    phone_code: "264",
    name: "Namibia",
    flag_icon: "iti-flag na",
    local_name: "Namibië",
  },
  {
    code: "NC",
    phone_code: "687",
    name: "New Caledonia",
    flag_icon: "iti-flag nc",
    local_name: "Nouvelle-Calédonie",
  },
  {
    code: "NE",
    phone_code: "227",
    name: "Niger",
    flag_icon: "iti-flag ne",
    local_name: "Nijar",
  },
  {
    code: "NF",
    phone_code: "672",
    name: "Norfolk Island",
    flag_icon: "iti-flag nf",
    local_name: null,
  },
  {
    code: "NG",
    phone_code: "234",
    name: "Nigeria",
    flag_icon: "iti-flag ng",
    local_name: null,
  },
  {
    code: "NI",
    phone_code: "505",
    name: "Nicaragua",
    flag_icon: "iti-flag ni",
    local_name: null,
  },
  {
    code: "NL",
    phone_code: "31",
    name: "Netherlands",
    flag_icon: "iti-flag nl",
    local_name: "Nederland",
  },
  {
    code: "NO",
    phone_code: "47",
    name: "Norway",
    flag_icon: "iti-flag no",
    local_name: "Norge",
  },
  {
    code: "NP",
    phone_code: "977",
    name: "Nepal",
    flag_icon: "iti-flag np",
    local_name: "नेपाल",
  },
  {
    code: "NR",
    phone_code: "674",
    name: "Nauru",
    flag_icon: "iti-flag nr",
    local_name: null,
  },
  {
    code: "NU",
    phone_code: "683",
    name: "Niue",
    flag_icon: "iti-flag nu",
    local_name: null,
  },
  {
    code: "NZ",
    phone_code: "64",
    name: "New Zealand",
    flag_icon: "iti-flag nz",
    local_name: null,
  },
  {
    code: "OM",
    phone_code: "968",
    name: "Oman",
    flag_icon: "iti-flag om",
    local_name: "‫عُمان‬‎",
  },
  {
    code: "PA",
    phone_code: "507",
    name: "Panama",
    flag_icon: "iti-flag pa",
    local_name: "Panamá",
  },
  {
    code: "PE",
    phone_code: "51",
    name: "Peru",
    flag_icon: "iti-flag pe",
    local_name: "Perú",
  },
  {
    code: "PF",
    phone_code: "689",
    name: "French Polynesia",
    flag_icon: "iti-flag pf",
    local_name: "Polynésie française",
  },
  {
    code: "PG",
    phone_code: "675",
    name: "Papua New Guinea",
    flag_icon: "iti-flag pg",
    local_name: null,
  },
  {
    code: "PH",
    phone_code: "63",
    name: "Philippines",
    flag_icon: "iti-flag ph",
    local_name: null,
  },
  {
    code: "PK",
    phone_code: "92",
    name: "Pakistan",
    flag_icon: "iti-flag pk",
    local_name: "‫پاکستان‬‎",
  },
  {
    code: "PL",
    phone_code: "48",
    name: "Poland",
    flag_icon: "iti-flag pl",
    local_name: "Polska",
  },
  {
    code: "PM",
    phone_code: "508",
    name: "Saint Pierre and Miquelon",
    flag_icon: "iti-flag pm",
    local_name: "Saint-Pierre-et-Miquelon",
  },
  {
    code: "PR",
    phone_code: "1",
    name: "Puerto Rico",
    flag_icon: "iti-flag pr",
    local_name: null,
  },
  {
    code: "PS",
    phone_code: "970",
    name: "Palestine",
    flag_icon: "iti-flag ps",
    local_name: "‫فلسطين‬‎",
  },
  {
    code: "PT",
    phone_code: "351",
    name: "Portugal",
    flag_icon: "iti-flag pt",
    local_name: null,
  },
  {
    code: "PW",
    phone_code: "680",
    name: "Palau",
    flag_icon: "iti-flag pw",
    local_name: null,
  },
  {
    code: "PY",
    phone_code: "595",
    name: "Paraguay",
    flag_icon: "iti-flag py",
    local_name: null,
  },
  {
    code: "QA",
    phone_code: "974",
    name: "Qatar",
    flag_icon: "iti-flag qa",
    local_name: "‫قطر‬‎",
  },
  {
    code: "RE",
    phone_code: "262",
    name: "Réunion",
    flag_icon: "iti-flag re",
    local_name: "La Réunion",
  },
  {
    code: "RO",
    phone_code: "40",
    name: "Romania",
    flag_icon: "iti-flag ro",
    local_name: "România",
  },
  {
    code: "RS",
    phone_code: "381",
    name: "Serbia",
    flag_icon: "iti-flag rs",
    local_name: "Србија",
  },
  {
    code: "RU",
    phone_code: "7",
    name: "Russia",
    flag_icon: "iti-flag ru",
    local_name: "Россия",
  },
  {
    code: "RW",
    phone_code: "250",
    name: "Rwanda",
    flag_icon: "iti-flag rw",
    local_name: null,
  },
  {
    code: "SA",
    phone_code: "966",
    name: "Saudi Arabia",
    flag_icon: "iti-flag sa",
    local_name: "‫المملكة العربية السعودية‬‎",
  },
  {
    code: "SB",
    phone_code: "677",
    name: "Solomon Islands",
    flag_icon: "iti-flag sb",
    local_name: null,
  },
  {
    code: "SC",
    phone_code: "248",
    name: "Seychelles",
    flag_icon: "iti-flag sc",
    local_name: null,
  },
  {
    code: "SD",
    phone_code: "249",
    name: "Sudan",
    flag_icon: "iti-flag sd",
    local_name: "‫السودان‬‎",
  },
  {
    code: "SE",
    phone_code: "46",
    name: "Sweden",
    flag_icon: "iti-flag se",
    local_name: "Sverige",
  },
  {
    code: "SG",
    phone_code: "65",
    name: "Singapore",
    flag_icon: "iti-flag sg",
    local_name: null,
  },
  {
    code: "SH",
    phone_code: "290",
    name: "Saint Helena",
    flag_icon: "iti-flag sh",
    local_name: null,
  },
  {
    code: "SI",
    phone_code: "386",
    name: "Slovenia",
    flag_icon: "iti-flag si",
    local_name: "Slovenija",
  },
  {
    code: "SJ",
    phone_code: "47",
    name: "Svalbard and Jan Mayen",
    flag_icon: "iti-flag sj",
    local_name: null,
  },
  {
    code: "SK",
    phone_code: "421",
    name: "Slovakia",
    flag_icon: "iti-flag sk",
    local_name: "Slovensko",
  },
  {
    code: "SL",
    phone_code: "232",
    name: "Sierra Leone",
    flag_icon: "iti-flag sl",
    local_name: null,
  },
  {
    code: "SM",
    phone_code: "378",
    name: "San Marino",
    flag_icon: "iti-flag sm",
    local_name: null,
  },
  {
    code: "SN",
    phone_code: "221",
    name: "Senegal",
    flag_icon: "iti-flag sn",
    local_name: "Sénégal",
  },
  {
    code: "SO",
    phone_code: "252",
    name: "Somalia",
    flag_icon: "iti-flag so",
    local_name: "Soomaaliya",
  },
  {
    code: "SR",
    phone_code: "597",
    name: "Suriname",
    flag_icon: "iti-flag sr",
    local_name: null,
  },
  {
    code: "SS",
    phone_code: "211",
    name: "South Sudan",
    flag_icon: "iti-flag ss",
    local_name: "‫جنوب السودان‬‎",
  },
  {
    code: "ST",
    phone_code: "239",
    name: "São Tomé and Príncipe",
    flag_icon: "iti-flag st",
    local_name: "São Tomé e Príncipe",
  },
  {
    code: "SV",
    phone_code: "503",
    name: "El Salvador",
    flag_icon: "iti-flag sv",
    local_name: null,
  },
  {
    code: "SX",
    phone_code: "1721",
    name: "Sint Maarten",
    flag_icon: "iti-flag sx",
    local_name: null,
  },
  {
    code: "SY",
    phone_code: "963",
    name: "Syria",
    flag_icon: "iti-flag sy",
    local_name: "‫سوريا‬‎",
  },
  {
    code: "SZ",
    phone_code: "268",
    name: "Swaziland",
    flag_icon: "iti-flag sz",
    local_name: null,
  },
  {
    code: "TC",
    phone_code: "1649",
    name: "Turks and Caicos Islands",
    flag_icon: "iti-flag tc",
    local_name: null,
  },
  {
    code: "TD",
    phone_code: "235",
    name: "Chad",
    flag_icon: "iti-flag td",
    local_name: "Tchad",
  },
  {
    code: "TG",
    phone_code: "228",
    name: "Togo",
    flag_icon: "iti-flag tg",
    local_name: null,
  },
  {
    code: "TH",
    phone_code: "66",
    name: "Thailand",
    flag_icon: "iti-flag th",
    local_name: "ไทย",
  },
  {
    code: "TJ",
    phone_code: "992",
    name: "Tajikistan",
    flag_icon: "iti-flag tj",
    local_name: null,
  },
  {
    code: "TK",
    phone_code: "690",
    name: "Tokelau",
    flag_icon: "iti-flag tk",
    local_name: null,
  },
  {
    code: "TL",
    phone_code: "670",
    name: "Timor-Leste",
    flag_icon: "iti-flag tl",
    local_name: null,
  },
  {
    code: "TM",
    phone_code: "993",
    name: "Turkmenistan",
    flag_icon: "iti-flag tm",
    local_name: null,
  },
  {
    code: "TN",
    phone_code: "216",
    name: "Tunisia",
    flag_icon: "iti-flag tn",
    local_name: "‫تونس‬‎",
  },
  {
    code: "TO",
    phone_code: "676",
    name: "Tonga",
    flag_icon: "iti-flag to",
    local_name: null,
  },
  {
    code: "TR",
    phone_code: "90",
    name: "Turkey",
    flag_icon: "iti-flag tr",
    local_name: "Türkiye",
  },
  {
    code: "TT",
    phone_code: "1868",
    name: "Trincodead and Tobago",
    flag_icon: "iti-flag tt",
    local_name: null,
  },
  {
    code: "TV",
    phone_code: "688",
    name: "Tuvalu",
    flag_icon: "iti-flag tv",
    local_name: null,
  },
  {
    code: "TW",
    phone_code: "886",
    name: "Taiwan",
    flag_icon: "iti-flag tw",
    local_name: "台灣",
  },
  {
    code: "TZ",
    phone_code: "255",
    name: "Tanzania",
    flag_icon: "iti-flag tz",
    local_name: null,
  },
  {
    code: "UA",
    phone_code: "380",
    name: "Ukraine",
    flag_icon: "iti-flag ua",
    local_name: "Україна",
  },
  {
    code: "UG",
    phone_code: "256",
    name: "Uganda",
    flag_icon: "iti-flag ug",
    local_name: null,
  },
  {
    code: "US",
    phone_code: "1",
    name: "United States",
    flag_icon: "iti-flag us",
    local_name: null,
  },
  {
    code: "UY",
    phone_code: "598",
    name: "Uruguay",
    flag_icon: "iti-flag uy",
    local_name: null,
  },
  {
    code: "UZ",
    phone_code: "998",
    name: "Uzbekistan",
    flag_icon: "iti-flag uz",
    local_name: "Oʻzbekiston",
  },
  {
    code: "VA",
    phone_code: "39",
    name: "Vatican City",
    flag_icon: "iti-flag va",
    local_name: "Città del Vaticano",
  },
  {
    code: "VC",
    phone_code: "1784",
    name: "Saint Vincent and the Grenadines",
    flag_icon: "iti-flag vc",
    local_name: null,
  },
  {
    code: "VE",
    phone_code: "58",
    name: "Venezuela",
    flag_icon: "iti-flag ve",
    local_name: null,
  },
  {
    code: "VG",
    phone_code: "1284",
    name: "British Virgin Islands",
    flag_icon: "iti-flag vg",
    local_name: null,
  },
  {
    code: "VI",
    phone_code: "1340",
    name: "U.S. Virgin Islands",
    flag_icon: "iti-flag vi",
    local_name: null,
  },
  {
    code: "VN",
    phone_code: "84",
    name: "Vietnam",
    flag_icon: "iti-flag vn",
    local_name: "Việt Nam",
  },
  {
    code: "VU",
    phone_code: "678",
    name: "Vanuatu",
    flag_icon: "iti-flag vu",
    local_name: null,
  },
  {
    code: "WF",
    phone_code: "681",
    name: "Wallis and Futuna",
    flag_icon: "iti-flag wf",
    local_name: "Wallis-et-Futuna",
  },
  {
    code: "WS",
    phone_code: "685",
    name: "Samoa",
    flag_icon: "iti-flag ws",
    local_name: null,
  },
  {
    code: "YE",
    phone_code: "967",
    name: "Yemen",
    flag_icon: "iti-flag ye",
    local_name: "‫اليمن‬‎",
  },
  {
    code: "YT",
    phone_code: "262",
    name: "Mayotte",
    flag_icon: "iti-flag yt",
    local_name: null,
  },
  {
    code: "ZA",
    phone_code: "27",
    name: "South Africa",
    flag_icon: "iti-flag za",
    local_name: null,
  },
  {
    code: "ZM",
    phone_code: "260",
    name: "Zambia",
    flag_icon: "iti-flag zm",
    local_name: null,
  },
  {
    code: "ZW",
    phone_code: "263",
    name: "Zimbabwe",
    flag_icon: "iti-flag zw",
    local_name: null,
  },
];

export default countries;
