import { Logger } from "./logging";
import { generateUUID } from "@/helper/index.js";

const logger = new Logger("paypal");

// One-time PayPal initialization control flag
window._isPayPalInitialized = false;

// PayPal Flow Identifier for the Page
const PAYPAL_PAGE_FLOW_ID = process.env.VUE_APP_PAYPAL_PAGE_FLOW_ID;
const PAYPAL_FRAUD_NET_ENV = process.env.VUE_APP_PAYPAL_FRAUD_NET_ENV;

/**
 * This function will load PayPal FraudNet configuration to the
 * page for fraud detection. Generated content for session will be passed
 * in PAYPAL-CLIENT-METADATA-ID header each request to the PayPal.
 *
 * @see {@link https://developer.paypal.com/docs/multiparty/checkout/apm/pay-upon-invoice/fraudnet/}
 */
function initialize() {
  if (window._isPayPalInitialized) {
    logger.warn("PayPal is already initialized skip initialization");
    return;
  }

  window._paypalSessionId = generateUUID().replace(/-/g, "");
  const config = {
    f: window._paypalSessionId,
    s: PAYPAL_PAGE_FLOW_ID,
  };

  if (PAYPAL_FRAUD_NET_ENV != "production") {
    config["sandbox"] = true;
  }

  let $el = document.createElement("script");
  $el.type = "application/json";
  $el.setAttribute("fncls", "fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99");
  $el.innerText = JSON.stringify(config);
  document.head.appendChild($el);

  $el = document.createElement("script");
  $el.type = "text/javascript";
  $el.src = "https://c.paypal.com/da/r/fb.js";
  document.head.appendChild($el);

  window._isPayPalInitialized = true;
}

/**
 * @returns {string|null}
 */
function getPayPalSessionId() {
  return window._paypalSessionId;
}

export default { initialize, getPayPalSessionId };
