/**
 * @typedef GoogleAuthConfig
 * @property {string} clientId
 * @property {string} scope
 * @property {string} prompt
 * @property {Array<string>} discoveryDocs
 */

/**
 * @typedef GoogleAuthOptions
 * @property {string} clientId
 * @property {string} [scope]
 * @property {string} [prompt]
 */

/**
 * @typedef GoogleAuthResponse
 * @property {string} clientId
 * @property {string} client_id
 * @property {string} credential
 * @property {string} select_by
 */

class GoogleAuth {
  URL = "https://accounts.google.com/gsi/client";

  /**
   * @returns {Promise<void>}
   */
  install() {
    return new Promise((resolve) => {
      const $script = document.createElement("script");
      const $head = document.getElementsByTagName("head")[0];
      window.onGoogleLibraryLoad = () => resolve();

      $script.src = this.URL;
      $head.appendChild($script);
    });
  }

  /**
   * @param {GoogleAuthConfig} config
   * @returns {Promise<void>}
   */
  initialize(config) {
    return this.install().then(() => {
      window.google.accounts.id.initialize({
        client_id: config.clientId,
        context: "signin",
        cancel_on_tap_outside: false,
        callback: (response) => {
          console.log("Response", response);
          if (this.onCallback != null) {
            this.onCallback(response);
          }
        },
      });
    });
  }

  /**
   * @param {(response: GoogleAuthResponse) => void} onCallback
   */
  setCallback(onCallback) {
    this.onCallback = onCallback;
  }

  /**
   * @param {HTMLDivElement} $el
   * @param {number} [width=400]
   */
  render($el, width = 400) {
    const options = {
      type: "standard",
      theme: "filled_blue",
      size: "large",
      text: "signin_with",
      shape: "pill",
      width,
    };

    window.google.accounts.id.renderButton($el, options);
  }
}

const GoogleAuthLoader = {
  $instance: new GoogleAuth(),
  /**
   * @param {GoogleAuthConfig} config
   * @returns {{load: () => void}}
   */
  initialize(config) {
    const promise = this.$instance.initialize(config);
    return {
      /**
       * @param {(response: GoogleAuthResponse) => void} onCallback
       * @param {() => void} onError
       * @param {number?} [width=null]
       */
      load: (onCallback, onError, width = null) => {
        promise
          .then(() => {
            const $el = document.getElementById("googleSignIn");
            this.$instance.setCallback(onCallback);

            if (width === null) {
              // Auto resizable google button will be used
              console.log("Width", $el.offsetWidth);
              this.$instance.render($el, $el.offsetWidth);
              window.onresize = () => {
                console.log("Resize", $el.offsetWidth);
                this.$instance.render($el, $el.offsetWidth);
              };
            } else {
              this.$instance.render($el, width);
            }
          })
          .catch((error) => {
            console.error("Failed to load Google Auth", error);
            onError();
          });
      },
    };
  },
};

/**
 * @param {import('vue').Vue} Vue
 * @param {GoogleAuthOptions} options
 */
export default function (Vue, options) {
  /** @type {GoogleAuthConfig?} */
  let config = null;

  if (typeof options === "object") {
    config = Object.assign(
      {
        prompt: "select_account",
        scope: "profile email",
        discoveryDocs: [
          "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
        ],
      },
      options
    );

    if (!config.clientId) {
      console.warn("clientId is required");
    }
  } else {
    console.warn("invalid option type. Object type accepted only");
  }

  Vue.prototype.$gAuth = GoogleAuthLoader.initialize(config);
}
