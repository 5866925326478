import { mapActions } from "vuex";

export default {
  computed: {
    isDeviceIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
  },
  methods: {
    ...mapActions("payment", ["postHybridCloseChecks"]),
    handleTopBar() {
      if (this.isDeviceIOS) {
        this.showTopBar();
      } else {
        this.hideTopBar();
      }
    },
    onHybridClose() {
      this.$hybridapi("close", "close");
      if (this.isHybridPage) this.postHybridCloseChecks();
    },
    showTopBar() {
      this.$hybridapi("updateTopBar", "show", 255, 255, 255);
      this.$hybridapi("updatePageTitle", this.$t("labels.do_payment"));
      this.$hybridapi("showOnlyNavBackButton");
    },
    hideTopBar() {
      this.$hybridapi("updatePageTitle", "");
      this.$hybridapi("updateTopBar", "hide", null, null, null);
      this.$hybridapi("hideBackButton", "hide");
    },
  },
};
