<template>
  <v-overlay
    :absolute="true"
    :value="showAcceptPremium"
    :opacity="0.75"
    class="ap-overlay"
    v-bind:class="getClass()"
    color="#000000"
  >
    <div class="b-radius gold-border" v-if="showAcceptPremium">
      <v-container class="b-radius ap-container">
        <v-row>
          <v-col>
            <img src="@/assets/img/premium-key.svg" alt="Premium" />
            <v-btn
              icon
              color="white"
              class="close-button"
              @click="cancelAcceptPremium()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="showFifteenSecInfo"
          :justify="'center'"
          no-gutters
          style="margin: 15px 0 0 0"
        >
          <v-col cols="10" :align-self="'center'" class="justify-center">
            <div class="fifteen-sec-info b-1">
              <v-icon small color="#9e9e9e">mdi-information-outline</v-icon>
              {{ $t("livestream.premium_enter_refund_info") }}
            </div>
          </v-col>
        </v-row>
        <v-row :justify="'center'" no-gutters style="margin: 15px 0 0 0">
          <v-col cols="12" :align-self="'center'" class="justify-center">
            <user-image-circle
              :user="selectedUser"
              :border="'2px solid #ffffff'"
              :size="'85px'"
            />
          </v-col>
          <v-col
            cols="12"
            :align-self="'center'"
            class="justify-center flex-column"
          >
            <div class="user-name" v-if="selectedUser">
              {{ selectedUser.name }}
            </div>
          </v-col>
          <v-col
            cols="12"
            :align-self="'center'"
            class="justify-center flex-column"
            v-if="isInvited"
          >
            <div class="invited-info">
              {{ $t("livestream.invited_to_stream_info") }}
            </div>
          </v-col>
        </v-row>
        <v-row :justify="'center'" no-gutters style="margin: 10px 0 0 0">
          <v-col cols="12" :align-self="'center'" class="justify-center">
            <div class="premium-alert">
              {{ $t("livestream.enter_private_stream_dialog_warning") }}
            </div>
          </v-col>
        </v-row>
        <v-row
          :justify="'center'"
          no-gutters
          style="margin: 10px 0 0 0"
          v-if="premiumGift"
        >
          <v-col cols="12" :align-self="'center'" class="justify-center">
            <img :src="premiumGift.image" class="gift-image" />
          </v-col>
          <v-col cols="12" :align-self="'center'" class="justify-center">
            <div class="gift-text">
              {{ premiumGift.name }} <img src="@/assets/img/coin.svg" />
              {{ premiumGift.cost }}
            </div>
          </v-col>
        </v-row>
        <v-row :justify="'center'">
          <v-col cols="11">
            <v-btn
              block
              color="primary"
              class="accept-private-btn"
              :loading="loading"
              :disabled="loading"
              @click="acceptAndEnter()"
            >
              <span v-if="isInvited">
                {{ $t("livestream.enter_private_livestream") }}
              </span>
              <span v-else>
                {{ $t("livestream.enter_private_livestream_with_pay") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-overlay>
</template>

<style lang="scss">
@import "AcceptPremium.scss";
</style>

<script>
import { mapState, mapActions } from "vuex";
import UserImageCircle from "@/components/Shared/UserImageCircle/UserImageCircle.vue";

export default {
  name: "AcceptPremium",
  components: {
    UserImageCircle,
  },
  computed: {
    ...mapState({
      showAcceptPremium: (state) => state.live.showAcceptPremium,
      selectedUser: (state) => state.live.selectedUser,
      showFifteenSecInfo: (state) => state.live.premiumInfo.refund_available,
      isInvited: (state) => state.live.premiumInfo.is_invited,
      premiumGift: (state) => state.live.premiumInfo.private_livestream_gift,
    }),
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions("live", ["cancelAcceptPremium", "acceptAndEnterPremium"]),
    acceptAndEnter() {
      this.loading = true;
      this.acceptAndEnterPremium(this.premiumGift);
    },
    getClass() {
      if (this.$vuetify.breakpoint.height < 645) return "allign-end";
      else {
        return "allign-center";
      }
    },
  },
};
</script>
