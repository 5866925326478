<template>
  <div
    class="main-div pt-0"
    :class="{ 'hybrid-container': isHybridPage }"
    id="scrollElement"
    @scroll="onScrollChange()"
  >
    <v-row class="item-rows-title" v-show="!isIOS">
      <v-col cols="4" class="item-cols-title" mb-3>
        <button class="back-button-header" @click="goBack()">
          <img
            src="@/assets/img/go-back-button.svg"
            :alt="$t('general.back_button')"
          />
        </button>
      </v-col>
      <v-col cols="4" class="item-cols-title" mb-3>
        <h1 class="coin-reseller-header">
          {{ $t("menu.coin_reseller") }}
        </h1>
      </v-col>
      <v-col cols="4" class="item-cols-title" mb-3>
        <button class="info-button-header" @click="openInfoModal()">
          <img src="@/assets/img/info-button.svg" />
        </button>
      </v-col>
    </v-row>
    <div :style="{ maxHeight: !isEmpty ? '100vh' : null }">
      <div
        v-show="isEmpty && !isTableLoading"
        class="has-text-centered has-text-info"
        style="font-size: x-large; margin: 20%"
      >
        <p class="mt-5 has-text-centered" style="color: black">
          {{ $t("coin_reseller.empty_page") }}
        </p>
      </div>
      <div>
        <table class="table" v-show="!isEmpty">
          <tbody>
            <tr
              v-for="rawAccountData in resellerAccountsData"
              :key="rawAccountData.coin_reseller_id"
              class="cursor-pointer"
              @click="selectReseller(rawAccountData.coin_reseller_user)"
            >
              <td class="td1">
                <div>
                  <div class="image-col">
                    <div class="country-img">
                      <div
                        class="iti-flag rounded-flag"
                        :class="
                          rawAccountData.coin_reseller_user.country.toLowerCase()
                        "
                      ></div>
                    </div>
                    <div class="profile-img">
                      <img
                        :src="
                          getProfileImage(rawAccountData.coin_reseller_user)
                        "
                        :alt="rawAccountData.coin_reseller_user.name"
                      />
                    </div>
                    <div class="online-status">
                      <div
                        :style="{
                          backgroundColor: rawAccountData.is_online
                            ? 'rgb(109, 212, 0)'
                            : 'rgb(182, 182, 182)',
                        }"
                        class="iti-flag rounded-online"
                      ></div>
                    </div>
                  </div>
                  <div class="user-info-col">
                    <div class="level-col">
                      <level-badge
                        :user="{
                          level: rawAccountData.coin_reseller_user.level,
                          vip_state:
                            rawAccountData.coin_reseller_user.vip_state,
                        }"
                        fontSize="12px"
                        class="level-badge"
                      ></level-badge>
                    </div>
                    <div class="name-col">
                      <div class="user-name">
                        {{
                          formatUserName(
                            rawAccountData.coin_reseller_user.name,
                            rawAccountData.coin_reseller_user.vip_state
                          )
                        }}
                      </div>
                    </div>
                    <div class="vip-badge-col">
                      <span
                        class="vip-badge"
                        v-if="rawAccountData.coin_reseller_user.vip_state > 0"
                      >
                        <img
                          v-if="
                            returnVipString(
                              rawAccountData.coin_reseller_user.vip_state
                            )
                          "
                          :src="
                            require(`@/assets/img/${returnVipString(
                              rawAccountData.coin_reseller_user.vip_state
                            )}-vip.svg`)
                          "
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </td>
              <td class="td2">
                <div class="coin-col">
                  <div v-if="showCoins" class="user-coin-icon">
                    <img src="@/assets/img/coin.svg" />
                  </div>
                  <div v-if="showCoins" class="user-coins">
                    {{ showThousund(rawAccountData.coin_balance, 1) }}
                  </div>
                  <div class="go-forward-icon">
                    <img src="@/assets/img/go-forward-button.svg" />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { ToastProgrammatic as Toast } from "buefy";
import OfficialDealerModal from "@/components/Payment/CoinReseller/OfficialDealerModal.vue";
import LevelBadge from "@/components/Shared/LevelBadge/LevelBadge.vue";
import DefaultProfileImage from "@/assets/img/profile-image-default.svg";
import { showThousund, returnVipString } from "@/helper/index.js";

const COIN_RESELLER_PAGINATION_LIMIT = 20;

export default {
  name: "CoinReseller",
  data() {
    return {
      nextCursor: null,
      resellerAccountsData: [],
      isTableLoading: false,
      recordsPerPage: COIN_RESELLER_PAGINATION_LIMIT,
      coinAmount: 0,
      isIOS: false,
      showCoins: false,
    };
  },
  components: { LevelBadge },
  computed: {
    ...mapState({
      isHybridPage: (state) => state.client.isHybridPage,
      deviceId: (state) => state.client.deviceId,
      selectedProduct: (state) => state.payment.selectedProduct,
    }),
    tableConfigs() {
      return {
        loading: this.isTableLoading,
      };
    },
    isEmpty() {
      return (
        this.resellerAccountsData == null ||
        this.resellerAccountsData.length === 0
      );
    },
  },
  methods: {
    ...mapActions(["setPlatform"]),
    ...mapActions("payment", ["getCoinResellerAccounts"]),
    showThousund,
    returnVipString,
    getResellerAccountItems() {
      const body = {
        min_coin_amount: this.coinAmount,
      };
      this.isTableLoading = true;
      this.getCoinResellerAccounts(body)
        .then((resellerAccountsResponse) => {
          resellerAccountsResponse.items.forEach((reseller) => {
            this.resellerAccountsData.push(reseller);
          });
          this.nextCursor = resellerAccountsResponse.meta.next;
          this.showCoins = resellerAccountsResponse.show_coins;
        })
        .catch((error) => {
          console.error(error);
          Toast.open({
            type: "is-danger",
            message: "Error occurred while retrieving data from API",
            position: "is-top",
          });
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },
    onScrollChange() {
      const scrollElement = document.getElementById("scrollElement");
      scrollElement.addEventListener("scroll", () => {
        if (
          scrollElement.offsetHeight + scrollElement.scrollTop >=
            scrollElement.scrollHeight - 5 &&
          this.isTableLoading == false &&
          this.nextCursor != null
        ) {
          this.fetchRemainingAccounts();
        }
      });
    },
    async fetchRemainingAccounts() {
      if (!this.isTableLoading && this.nextCursor) {
        this.isTableLoading = true;
        const next = this.nextCursor;

        this.retrieveAccounts(next);
      }
    },
    retrieveAccounts(next = null) {
      const body = {
        next: next,
        min_coin_amount: this.coinAmount,
      };
      this.getCoinResellerAccounts(body)
        .then((resellerAccountsResponse) => {
          resellerAccountsResponse.items.forEach((reseller) => {
            this.resellerAccountsData.push(reseller);
          });
          this.nextCursor = resellerAccountsResponse.meta.next;
          this.showCoins = resellerAccountsResponse.show_coins;
        })
        .catch((error) => {
          console.error(error);
          Toast.open({
            type: "is-danger",
            message: "Error occurred while retrieving data from API",
            position: "is-top",
          });
          this.isTableLoading = false;
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    openInfoModal() {
      this.$buefy.modal.open({
        parent: this,
        component: OfficialDealerModal,
        "has-modal-card": true,
        trapFocus: true,
        canCancel: ["escape", "outside"],
      });
    },
    getProfileImage(coin_reseller_user) {
      return (
        (coin_reseller_user.profile_picture &&
          coin_reseller_user.profile_picture.thumbnail_url) ||
        DefaultProfileImage
      );
    },
    selectReseller(coin_reseller_user) {
      let default_chat_message = this.$t("coin_reseller.default_chat_message");
      this.$hybridapi(
        "openDealerChat",
        coin_reseller_user.id.toString(),
        default_chat_message
      );
    },
    formatUserName(userName, vip_state) {
      let limit = vip_state > 0 ? 10 : 16;
      return userName.length < limit
        ? userName
        : userName.slice(0, limit) + "...";
    },
  },
  mounted() {
    this.isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    const platform = this.$route.query.platform;
    this.setPlatform(platform != null ? platform : null);

    window["backButtonTapped"] = () => {
      this.$store.dispatch("setDealerModalCloseStatus", true);
      return "go_back";
    };

    window["infoButtonTapped"] = () => {
      this.openInfoModal();
      return "nothing";
    };

    if (this.isIOS) {
      this.$hybridapi("updatePageTitle", this.$t("menu.coin_reseller"));
      this.$hybridapi("showOnlyNavBackButton");
      this.$hybridapi("updateTopBar", "show", 255, 255, 255);
    } else {
      this.$hybridapi("updatePageTitle", " ");
    }

    const button_enum = "info";
    const action_enum = "show";
    this.$hybridapi("updateButton", button_enum, action_enum);
    this.coinAmount = this.selectedProduct.amount;
    this.getResellerAccountItems();
    this.$hybridapi("hideLoading");
  },
};
</script>
<style lang="scss" scoped>
@import "./CoinReseller.scss";
</style>
