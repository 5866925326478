<template>
  <div class="columns is-multiline">
    <div class="back-button">
      <v-btn
        icon
        class="back-button-item"
        @click="setActiveLoginComponent('email-login-register')"
      >
        <v-icon class="fas fa-arrow-left fa-2x" />
      </v-btn>
    </div>
    <div class="column is-12">
      <h1 class="login-header">{{ $t("login_page.signup_with_email") }}</h1>
      <div
        class="sub-title"
        v-html="$t('login_page.enter_email_password')"
      ></div>
    </div>
    <div class="column is-12" :class="{ error: validation.hasError('email') }">
      <label for="email">{{ $t("labels.email") }}</label>
      <input
        type="text"
        class="input"
        placeholder="example@example.com"
        v-model="email"
      />
      <div class="validation-error-message">
        {{ validation.firstError("email") }}
      </div>
    </div>
    <div
      class="column is-12"
      :class="{ error: validation.hasError('password') }"
    >
      <label for="password">{{ $t("labels.password") }}</label>
      <input
        type="password"
        class="input"
        id="password"
        v-model="password"
        :placeholder="$t('labels.password')"
      />
      <div class="validation-error-message">
        {{ validation.firstError("password") }}
      </div>
    </div>
    <div class="column is-12" :class="{ error: validation.hasError('repeat') }">
      <label for="password-repeat">{{ $t("labels.repeat_password") }}</label>
      <input
        type="password"
        class="input"
        id="password-repeat"
        v-model="repeat"
        :placeholder="$t('labels.repeat_password')"
      />
      <div class="validation-error-message">
        {{ validation.firstError("repeat") }}
      </div>
    </div>
    <div class="column is-12">
      <br />
      <button
        class="sign-up-email special-button primary-button"
        :disabled="loading"
        @click="register"
      >
        {{ $t("general.continue") }}
      </button>
      <div class="lds-ellipsis" v-if="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="validation-error-message" v-if="registerError">
        {{ registerError }}
      </div>
    </div>
    <div style="text-align: center" class="column is-12">
      <button class="btn-link" @click="setActiveLoginComponent('email-login')">
        {{ $t("labels.login") }}
      </button>
    </div>
  </div>
</template>

<script>
"use strict";
import { mapActions } from "vuex";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;

const endpoints = require("../../../api/endpoints");
const helpers = require("../../../api/helpers");

export default {
  name: "EmailRegister",
  data: () => {
    return {
      email: "",
      password: "",
      repeat: "",
      loading: false,
      registerError: null,
    };
  },
  mounted() {},
  validators: {
    email: function (value) {
      return Validator.value(value).required().email();
    },
    password: function (value) {
      return Validator.value(value).required().minLength(8);
    },
    "repeat, password": function (repeat, password) {
      if (this.submitted || this.validation.isTouched("repeat")) {
        return Validator.value(repeat).required().match(password);
      }
    },
  },
  methods: {
    ...mapActions("client", ["setActiveLoginComponent"]),
    register() {
      this.registerError = false;
      let that = this;

      this.$validate().then(function (success) {
        if (success) {
          that.loading = true;
          let registerData = { email: that.email, password: that.password };
          endpoints
            .register_email(that.$axios, registerData)
            .then((emailRegisterResponse) => {
              emailRegisterResponse = emailRegisterResponse.data;
              that.loading = false;
              if (emailRegisterResponse.error) {
                that.registerError = that.$i18n.t(
                  "errors.register_error_" + emailRegisterResponse.error.code
                );
              } else if (emailRegisterResponse.existed) {
                that.registerError = that.$i18n.t(
                  "errors.register_existing_user"
                );
              } else {
                // USER LOGIN YAPILIP DISCOVER'A YONLENDIRILECEK
                that.$store.dispatch("client/postLogin", {
                  authToken: emailRegisterResponse.token,
                  redirectUrl: "/",
                  loginFrom: "email_register",
                });
              }
            });
        }
      });
    },
  },
};
</script>
