import { render, staticRenderFns } from "./LeaderBoardDiamond.vue?vue&type=template&id=f08b6270&scoped=true"
import script from "./LeaderBoardDiamond.vue?vue&type=script&lang=js"
export * from "./LeaderBoardDiamond.vue?vue&type=script&lang=js"
import style0 from "./LeaderBoardDiamond.vue?vue&type=style&index=0&id=f08b6270&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f08b6270",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
