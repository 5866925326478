<template>
  <v-dialog
    v-model="showLoginModal"
    width="600"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <div class="container">
        <Login />
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss"></style>
<script>
import Login from "@/components/Login/Login.vue";

export default {
  name: "LoginModalView",
  components: {
    Login,
  },
  computed: {
    showLoginModal: {
      get() {
        return this.$store.state.client.showLoginModal;
      },
      set(value) {
        this.$store.commit("client/setShowLoginModal", value);
      },
    },
  },
};
</script>
