<template>
  <div>
    <div class="page-left-container">
      <DiscoverLeft />
    </div>
    <div class="page-right-container page-message-container">
      <message />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "./MessageView.scss";
// Comment out for discover-left component
// @import "../Discover/DiscoverView.scss";
</style>
<script>
import Message from "@/components/Message/Message.vue";
import DiscoverLeft from "@/components/Discover/DiscoverLeft.vue";

export default {
  name: "MessageView",
  components: {
    Message,
    DiscoverLeft,
  },
};
</script>
