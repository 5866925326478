<template>
  <div>
    <div class="page-right-container page-followers-container">
      <user-followers />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "FollowersView.scss";
@import "../../Discover/DiscoverView.scss";
</style>
<script>
import UserFollowers from "@/components/Follow/UserFollowers/UserFollowers.vue";

export default {
  name: "FollowersView",
  components: {
    UserFollowers,
  },
};
</script>
