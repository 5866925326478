<template>
  <div
    class="user-image"
    v-bind:style="[sizeStyles, { 'background-image': 'url(' + imageUrl + ')' }]"
  ></div>
</template>

<script>
import DefaultProfileImage from "@/assets/img/profile-image-default.svg";

export default {
  name: "UserImageCircle",
  props: {
    /**
     * User object cannot be null
     * if null, default profile image shown
     */
    user: {
      type: Object,
    },
    /**
     * Width & height value of profile image with measurement type
     * if null, it will get 100%
     * sample: 45px, 80%
     */
    size: {
      type: String,
    },
    /**
     * border styling of profile image
     * sample: '1px solid #cccccc'
     */
    border: {
      type: String,
    },
  },
  computed: {
    imageUrl() {
      return (
        this.user?.profile_image?.thumbnail_url ||
        (this.user?.profile_images?.length > 0 &&
          this.user.profile_images[0].thumbnail_url) ||
        DefaultProfileImage
      );
    },
    sizeStyles() {
      const styles = {};
      if (this.size) {
        styles["width"] = this.size;
        styles["height"] = this.size;
      }
      if (this.border) {
        styles["border"] = this.border;
      }
      return styles;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  overflow: hidden;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
