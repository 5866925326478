import { render, staticRenderFns } from "./ViewerCard.vue?vue&type=template&id=ff398afa&scoped=true"
import script from "./ViewerCard.vue?vue&type=script&lang=js"
export * from "./ViewerCard.vue?vue&type=script&lang=js"
import style0 from "./ViewerCard.vue?vue&type=style&index=0&id=ff398afa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff398afa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBanner,VCard,VCol,VIcon,VProgressCircular,VRow})
