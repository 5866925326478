<template>
  <div class="diamond-board-container">
    <div class="diamond-board-date">
      <v-btn
        rounded
        small
        :class="
          activeTab === LeaderBoardEnums.DAILY
            ? 'date-button'
            : 'date-button-passive'
        "
        @click="getGiftersLeaderBoardDaily()"
      >
        {{ $t("leaderboard_page.daily") }}
      </v-btn>
      <v-btn
        rounded
        small
        :class="
          activeTab === LeaderBoardEnums.WEEKLY
            ? 'date-button'
            : 'date-button-passive'
        "
        @click="getGiftersLeaderBoardWeekly()"
      >
        {{ $t("leaderboard_page.weekly") }}
      </v-btn>
      <v-btn
        rounded
        small
        :class="
          activeTab === LeaderBoardEnums.DEFAULT
            ? 'date-button'
            : 'date-button-passive'
        "
        @click="getGiftersLeaderBoardAllTime()"
      >
        {{ $t("leaderboard_page.monthly") }}
      </v-btn>
    </div>
    <div v-if="this.isLeaderBoardLoading === false" class="divider">
      <div
        v-if="leaderBoardGiftersList !== null"
        class="leader-board-header-mobile"
      >
        <div
          :class="`leader-board-item-mobile mobile-item-${index}`"
          v-for="(item, index) in leaderBoardGiftersList.slice(0, 3)"
          :key="`${index}${item.diamonds}${item.user}`"
        >
          <div class="user-list">
            <div v-if="item.user !== null" class="user-content">
              <div v-if="index === 0" class="user-img-container">
                <div class="star-icon-container-1">
                  <img
                    class="icon-1"
                    src="@/assets/img/star-icon-leaderboard.svg"
                    :alt="$t('leaderboard_page.star_icon')"
                  />
                </div>
                <div class="star-icon-container-2">
                  <img
                    class="icon-2"
                    src="@/assets/img/star-icon-leaderboard.svg"
                    :alt="$t('leaderboard_page.star_icon')"
                  />
                </div>
                <img
                  class="user-crown"
                  src="@/assets/img/crown-number-one.svg"
                  :alt="$t('leaderboard_page.crown_one')"
                />
                <router-link
                  :to="generateBase64Url('/profile/', item.user.user_id)"
                  class="empty-user-one"
                >
                  <user-image-circle
                    class="user-profile-img"
                    :user="item.user"
                    size="100px"
                    border="4px solid #FFFB6D"
                  />
                </router-link>
              </div>
              <div v-else-if="index === 1" class="user-img-container">
                <div class="star-icon-container-3">
                  <img
                    class="icon-1"
                    src="@/assets/img/star-icon-leaderboard.svg"
                    :alt="$t('leaderboard_page.star_icon')"
                  />
                </div>
                <div class="star-icon-container-4">
                  <img
                    class="icon-1"
                    src="@/assets/img/star-icon-leaderboard.svg"
                    :alt="$t('leaderboard_page.star_icon')"
                  />
                </div>
                <div class="star-icon-container-5">
                  <img
                    class="icon-1"
                    src="@/assets/img/star-icon-leaderboard.svg"
                    :alt="$t('leaderboard_page.star_icon')"
                  />
                </div>
                <img
                  class="user-crown"
                  src="@/assets/img/crown-number-two.svg"
                  :alt="$t('leaderboard_page.crown_two')"
                />
                <router-link
                  :to="generateBase64Url('/profile/', item.user.user_id)"
                  class="empty-user-two"
                >
                  <user-image-circle
                    class="user-profile-img"
                    :user="item.user"
                    size="60px"
                    border="4px solid #E5E5E5"
                  />
                </router-link>
              </div>
              <div v-else-if="index === 2" class="user-img-container">
                <div class="star-icon-container-6">
                  <img
                    class="icon-1"
                    src="@/assets/img/star-icon-leaderboard.svg"
                    :alt="$t('leaderboard_page.star_icon')"
                  />
                </div>
                <div class="star-icon-container-7">
                  <img
                    class="icon-1"
                    src="@/assets/img/star-icon-leaderboard.svg"
                    :alt="$t('leaderboard_page.star_icon')"
                  />
                </div>
                <div class="star-icon-container-8">
                  <img
                    class="icon-1"
                    src="@/assets/img/star-icon-leaderboard.svg"
                    :alt="$t('leaderboard_page.star_icon')"
                  />
                </div>
                <img
                  class="user-crown"
                  src="@/assets/img/crown-number-three.svg"
                  :alt="$t('leaderboard_page.crown_three')"
                />
                <router-link
                  :to="generateBase64Url('/profile/', item.user.user_id)"
                  class="empty-user-two"
                >
                  <user-image-circle
                    class="user-profile-img"
                    :user="item.user"
                    size="60px"
                    border="4px solid #FD9B4D"
                  />
                </router-link>
              </div>
              <router-link
                v-if="item.user !== null && item.user.livestream_id !== null"
                :to="
                  '/stream/' +
                  getCryptedString(item.user.livestream_id, item.user.user_id)
                "
                class="livebutton"
              >
                <span
                  class="live-status-mobile"
                  @click="
                    setLiveStreamSource('leaderboard_gift_senders_live_button')
                  "
                >
                  <img
                    src="@/assets/img/live-status-icon-animation.gif"
                    :alt="$t('general.live_icon')"
                  />
                  {{ $t("labels.live") }}
                </span>
              </router-link>

              <div class="mobile-name-container">
                <div v-if="index === 0">
                  <img
                    class="special-order-number"
                    src="@/assets/img/number-one-icon.svg"
                    :alt="$t('leaderboard_page.numer_one_icon')"
                  />
                </div>
                <div v-else-if="index === 1">
                  <img
                    class="special-order-number"
                    src="@/assets/img/number-two-icon.svg"
                    :alt="$t('leaderboard_page.numer_two_icon')"
                  />
                </div>
                <div v-else-if="index === 2">
                  <img
                    class="special-order-number"
                    src="@/assets/img/number-three-icon.svg"
                    :alt="$t('leaderboard_page.numer_three_icon')"
                  />
                </div>
                <level-badge :user="item.user"></level-badge>
                <span class="user-name">
                  {{ item.user.name }}
                </span>
              </div>

              <div
                class="vip-user-badge"
                v-if="item.user !== null && item.user.vip_state > 0"
              >
                <span class="vip-badge">
                  <img
                    v-if="item.user.vip_state == 3"
                    src="@/assets/img/gold-vip.svg"
                    :alt="$t('general.vip_icon')"
                  />
                  <img
                    v-if="item.user.vip_state == 2"
                    src="@/assets/img/silver-vip.svg"
                    :alt="$t('general.vip_icon')"
                  />
                  <img
                    v-if="item.user.vip_state == 1"
                    src="@/assets/img/bronze-vip.svg"
                    :alt="$t('general.vip_icon')"
                  />
                </span>
              </div>

              <div class="diamond-container">
                <img
                  class="diamond-icon"
                  src="@/assets/img/coin.svg"
                  :alt="$t('general.coin_icon')"
                />
                <span class="diamond-count">
                  {{ showThousund(item.coins, 1) }}
                </span>
              </div>
              <div
                v-if="
                  item.user.user_id !== leaderBoardGiftersUserSelf.user.user_id
                "
                class="user-status-follow"
              >
                <v-btn
                  v-if="item.user.is_followed === true"
                  class="follow-icon-svg"
                  :loading="item.user.isFollowLoading"
                  :disabled="item.user.isFollowLoading"
                  @click="clickUnfollowUser(item.user.user_id)"
                  rounded
                  small
                >
                  <img
                    src="@/assets/img/unfollow-icon.svg"
                    :alt="$t('general.unfollow_icon')"
                  />
                </v-btn>
                <v-btn
                  v-else
                  class="follow-icon-svg"
                  :loading="item.user.isFollowLoading"
                  :disabled="item.user.isFollowLoading"
                  @click="clickFollowUser(item.user.user_id)"
                  rounded
                  small
                >
                  <img
                    :src="require(`@/assets/img/follow-icon_${brandName}.svg`)"
                    :alt="$t('general.follow_icon')"
                  />
                </v-btn>
              </div>
            </div>
            <div v-if="item.user === null" class="user-content">
              <div v-if="index === 0" class="user-img-container">
                <div class="star-icon-container-1">
                  <img
                    class="icon-1"
                    src="@/assets/img/star-icon-leaderboard.svg"
                    :alt="$t('leaderboard_page.star_icon')"
                  />
                </div>
                <div class="star-icon-container-2">
                  <img
                    class="icon-2"
                    src="@/assets/img/star-icon-leaderboard.svg"
                    :alt="$t('leaderboard_page.star_icon')"
                  />
                </div>
                <img
                  class="user-crown"
                  src="@/assets/img/crown-number-one.svg"
                  :alt="$t('leaderboard_page.crown_one')"
                />
                <user-image-circle
                  class="user-profile-img"
                  :user="item.user"
                  size="100px"
                  border="4px solid #FFFB6D"
                />
              </div>
              <div v-else-if="index === 1" class="user-img-container">
                <div class="star-icon-container-3">
                  <img
                    class="icon-1"
                    src="@/assets/img/star-icon-leaderboard.svg"
                    :alt="$t('leaderboard_page.star_icon')"
                  />
                </div>
                <div class="star-icon-container-4">
                  <img
                    class="icon-1"
                    src="@/assets/img/star-icon-leaderboard.svg"
                    :alt="$t('leaderboard_page.star_icon')"
                  />
                </div>
                <div class="star-icon-container-5">
                  <img
                    class="icon-1"
                    src="@/assets/img/star-icon-leaderboard.svg"
                    :alt="$t('leaderboard_page.star_icon')"
                  />
                </div>
                <img
                  class="user-crown"
                  src="@/assets/img/crown-number-two.svg"
                  :alt="$t('leaderboard_page.crown_two')"
                />
                <user-image-circle
                  class="user-profile-img empty"
                  :user="item.user"
                  size="60px"
                  border="4px solid #E5E5E5"
                />
              </div>
              <div v-else-if="index === 2" class="user-img-container">
                <div class="star-icon-container-6">
                  <img
                    class="icon-1"
                    src="@/assets/img/star-icon-leaderboard.svg"
                    :alt="$t('leaderboard_page.star_icon')"
                  />
                </div>
                <div class="star-icon-container-7">
                  <img
                    class="icon-1"
                    src="@/assets/img/star-icon-leaderboard.svg"
                    :alt="$t('leaderboard_page.star_icon')"
                  />
                </div>
                <div class="star-icon-container-8">
                  <img
                    class="icon-1"
                    src="@/assets/img/star-icon-leaderboard.svg"
                    :alt="$t('leaderboard_page.star_icon')"
                  />
                </div>
                <img
                  class="user-crown"
                  src="@/assets/img/crown-number-three.svg"
                  :alt="$t('leaderboard_page.crown_three')"
                />
                <user-image-circle
                  class="user-profile-img"
                  :user="item.user"
                  size="60px"
                  border="4px solid #FD9B4D"
                />
              </div>
              <div class="user-name-container-special">
                <div class="mobile-name-container">
                  <div v-if="index === 0">
                    <img
                      class="special-order-number"
                      src="@/assets/img/number-one-icon.svg"
                      :alt="$t('leaderboard_page.numer_one_icon')"
                    />
                  </div>
                  <div v-else-if="index === 1">
                    <img
                      class="special-order-number"
                      src="@/assets/img/number-two-icon.svg"
                      :alt="$t('leaderboard_page.numer_two_icon')"
                    />
                  </div>
                  <div v-else-if="index === 2">
                    <img
                      class="special-order-number"
                      src="@/assets/img/number-three-icon.svg"
                      :alt="$t('leaderboard_page.numer_three_icon')"
                    />
                  </div>
                  <level-badge :user="item.user"></level-badge>
                  <span class="user-name">
                    {{ $t("leaderboard_page.anonymous_user") }}
                  </span>
                </div>
              </div>
              <div class="diamond-container">
                <img
                  class="diamond-icon"
                  src="@/assets/img/coin.svg"
                  :alt="$t('general.coin_icon')"
                />
                <span class="diamond-count">
                  {{ showThousund(item.coins, 1) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="`leader-board-content-container list-item-${index}`"
        v-for="(item, index) in leaderBoardGiftersList"
        :key="`${index}${item.coins}${item.user}`"
      >
        <div v-if="index === 0" class="user-list-order">
          <img
            class="special-order-number"
            src="@/assets/img/number-one-icon.svg"
            :alt="$t('leaderboard_page.numer_one_icon')"
          />
        </div>
        <div v-else-if="index === 1" class="user-list-order">
          <img
            class="special-order-number"
            src="@/assets/img/number-two-icon.svg"
            :alt="$t('leaderboard_page.numer_two_icon')"
          />
        </div>
        <div v-else-if="index === 2" class="user-list-order">
          <img
            class="special-order-number"
            src="@/assets/img/number-three-icon.svg"
            :alt="$t('leaderboard_page.numer_three_icon')"
          />
        </div>
        <div v-if="index > 2" class="user-list-order not-special">
          <span class="user-list-order-span">{{ index + 1 }}</span>
        </div>
        <div class="user-list">
          <div class="user-content">
            <div v-if="index === 0" class="user-img-container">
              <router-link
                v-if="item.user !== null"
                :to="generateBase64Url('/profile/', item.user.user_id)"
              >
                <user-image-circle
                  class="user-profile-img"
                  :user="item.user"
                  size="50px"
                  border=""
                />
              </router-link>
              <router-link v-else to="">
                <user-image-circle
                  class="user-profile-img"
                  :user="item.user"
                  size="50px"
                  border=""
                />
              </router-link>
              <img
                class="user-crown"
                src="@/assets/img/crown-number-one.svg"
                :alt="$t('leaderboard_page.crown_one')"
              />
            </div>
            <div v-else-if="index === 1" class="user-img-container">
              <router-link
                v-if="item.user !== null"
                :to="generateBase64Url('/profile/', item.user.user_id)"
              >
                <user-image-circle
                  class="user-profile-img"
                  :user="item.user"
                  size="50px"
                  border="2px solid #FFFB6D"
                />
              </router-link>
              <router-link v-else to="">
                <user-image-circle
                  class="user-profile-img"
                  :user="item.user"
                  size="50px"
                  border="2px solid #FFFB6D"
                />
              </router-link>
              <img
                class="user-crown"
                src="@/assets/img/crown-number-two.svg"
                :alt="$t('leaderboard_page.crown_two')"
              />
            </div>
            <div v-else-if="index === 2" class="user-img-container">
              <router-link
                v-if="item.user !== null"
                :to="generateBase64Url('/profile/', item.user.user_id)"
              >
                <user-image-circle
                  class="user-profile-img"
                  :user="item.user"
                  size="50px"
                  border="2px solid #FFFB6D"
                />
              </router-link>
              <router-link v-else to="">
                <user-image-circle
                  class="user-profile-img"
                  :user="item.user"
                  size="50px"
                  border="2px solid #FFFB6D"
                />
              </router-link>
              <img
                class="user-crown"
                src="@/assets/img/crown-number-three.svg"
                :alt="$t('leaderboard_page.crown_three')"
              />
            </div>
            <div v-if="index > 2" class="user-img-container">
              <router-link
                v-if="item.user !== null"
                :to="generateBase64Url('/profile/', item.user.user_id)"
              >
                <user-image-circle
                  class="user-profile-img"
                  :user="item.user"
                  size="50px"
                  border=""
                />
              </router-link>
              <router-link v-else to="">
                <user-image-circle
                  class="user-profile-img"
                  :user="item.user"
                  size="50px"
                  border=""
                />
              </router-link>
            </div>
            <div v-if="item.user !== null" class="user-name-container">
              <div class="user-name-vip-container">
                <level-badge :user="item.user"></level-badge>
                <span class="user-name">
                  {{ item.user.name }}
                </span>
                <div class="vip-badge" v-if="item.user.vip_state !== 0">
                  <img
                    v-if="item.user.vip_state == 3"
                    src="@/assets/img/gold-vip.svg"
                    :alt="$t('general.vip_icon')"
                  />
                  <img
                    v-if="item.user.vip_state == 2"
                    src="@/assets/img/silver-vip.svg"
                    :alt="$t('general.vip_icon')"
                  />
                  <img
                    v-if="item.user.vip_state == 1"
                    src="@/assets/img/bronze-vip.svg"
                    :alt="$t('general.vip_icon')"
                  />
                </div>
              </div>
              <div class="diamond-container">
                <img
                  class="diamond-icon"
                  src="@/assets/img/coin.svg"
                  :alt="$t('general.coin_icon')"
                />
                <span class="diamond-count">
                  {{ showThousund(item.coins, 1) }}
                </span>
              </div>
            </div>
            <div v-if="item.user === null" class="user-name-container">
              <span class="user-name">
                {{ $t("leaderboard_page.anonymous_user") }}
              </span>
              <div class="diamond-container">
                <img
                  class="diamond-icon"
                  src="@/assets/img/coin.svg"
                  :alt="$t('general.coin_icon')"
                />
                <span class="diamond-count">
                  {{ showThousund(item.coins, 1) }}
                </span>
              </div>
            </div>
          </div>
          <div class="user-status-follow">
            <router-link
              v-if="item.user !== null && item.user.livestream_id !== null"
              :to="
                '/stream/' +
                getCryptedString(item.user.livestream_id, item.user.user_id)
              "
            >
              <span
                class="live-status-mobile"
                @click="
                  setLiveStreamSource('leaderboard_gift_senders_live_button')
                "
              >
                <img
                  src="@/assets/img/live-status-icon-animation.gif"
                  :alt="$t('general.status_icon')"
                />
                {{ $t("labels.live") }}
              </span>
            </router-link>
            <v-btn
              v-if="
                item.user !== null &&
                item.user.is_followed === true &&
                item.user.user_id !== leaderBoardGiftersUserSelf.user.user_id
              "
              class="follow-icon-svg"
              :loading="item.user.isFollowLoading"
              :disabled="item.user.isFollowLoading"
              @click="clickUnfollowUser(item.user.user_id)"
              rounded
              small
            >
              <img
                src="@/assets/img/unfollow-icon.svg"
                :alt="$t('general.unfollow_icon')"
              />
            </v-btn>
            <v-btn
              v-else-if="
                item.user !== null &&
                item.user.is_followed === false &&
                item.user.user_id !== leaderBoardGiftersUserSelf.user.user_id
              "
              class="follow-icon-svg"
              :loading="item.user.isFollowLoading"
              :disabled="item.user.isFollowLoading"
              @click="clickFollowUser(item.user.user_id)"
              rounded
              small
            >
              <img
                :src="require(`@/assets/img/follow-icon_${brandName}.svg`)"
                :alt="$t('general.follow_icon')"
              />
            </v-btn>
          </div>
        </div>
      </div>
      <div v-if="this.leaderBoardGiftersUserSelf" class="user-self-container">
        <div
          v-if="this.leaderBoardGiftersUserSelf.rank !== null"
          class="user-self-order"
        >
          <span class="user-self-order-span"
            >{{ this.leaderBoardGiftersUserSelf.rank }}
          </span>
        </div>
        <div v-else class="user-self-order">-</div>
        <div class="user-info-container">
          <div
            v-if="this.isShowUserAnonymous === false"
            class="user-self-content"
          >
            <div class="user-self-image">
              <user-image-circle
                :user="this.leaderBoardGiftersUserSelf.user"
                size="50px"
                border=""
              />
            </div>
            <div class="self-name-diamond-container">
              <div class="self-name-span">
                <span class="user-self-name">
                  {{ this.leaderBoardGiftersUserSelf.user.name }}
                </span>
              </div>
              <div class="diamond-self-container">
                <img
                  class="diamond-icon"
                  src="@/assets/img/coin.svg"
                  :alt="$t('general.diamond_icon')"
                />
                <span class="diamond-count">
                  {{ showThousund(this.leaderBoardGiftersUserSelf.coins, 1) }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="this.isShowUserAnonymous === true"
            class="user-self-content"
          >
            <user-image-circle :user="null" size="50px" border="" />
            <div class="user-self-name-container">
              <div class="self-name-span">
                <span class="user-self-name">
                  {{ $t("leaderboard_page.anonymous_user") }}
                </span>
              </div>
              <div class="diamond-self-container">
                <img
                  class="diamond-icon"
                  src="@/assets/img/coin.svg"
                  :alt="$t('general.diamond_icon')"
                />
                <span class="diamond-count">
                  {{ showThousund(this.leaderBoardGiftersUserSelf.coins, 1) }}
                </span>
              </div>
            </div>
          </div>
          <div class="anonymous-container">
            <span class="be-anonymous">{{
              $t("leaderboard_page.be_anonymous")
            }}</span>
            <label class="switch">
              <input
                type="checkbox"
                :checked="this.isShowUserAnonymous"
                @change="makeAnonymous($event)"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="this.isLeaderBoardLoading === true"
      class="live-stream-loading live-stream-screen"
    >
      <div class="overlay">
        <i class="fas fa-circle-notch fa-spin"></i>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "LeaderBoardGifters.scss";
</style>
<script>
import { mapState, mapActions } from "vuex";
import UserImageCircle from "@/components/Shared/UserImageCircle/UserImageCircle.vue";
import { LeaderBoardEnums, FollowPageEnums } from "@/helper/enums.js";
import {
  encrypt,
  decodeUrlParameter,
  generateBase64Url,
  showThousund,
} from "@/helper/index.js";

import LevelBadge from "@/components/Shared/LevelBadge/LevelBadge.vue";

export default {
  name: "LeaderBoardGifters",
  data() {
    return {
      activeTab: LeaderBoardEnums.DAILY,
      LeaderBoardEnums,
      FollowPageEnums,
    };
  },
  components: {
    UserImageCircle,
    LevelBadge,
  },
  computed: {
    ...mapState({
      leaderBoardGiftersList: (state) => state.client.leaderBoardGiftersList,
      leaderBoardGiftersUserSelf: (state) =>
        state.client.leaderBoardGiftersUserSelf,
      isFollowLoading: (state) => state.client.isFollowLoading,
      isLeaderBoardLoading: (state) => state.client.isLeaderBoardLoading,
      isShowUserAnonymous: (state) => state.client.isShowUserAnonymous,
      brandName: (state) => state.brandName,
    }),
  },
  mounted() {
    this.getGiftersLeaderBoardDaily();
  },
  methods: {
    ...mapActions("client", [
      "getGiftersLeaderBoard",
      "followUser",
      "unfollowUser",
      "setUserGifterLeaderboardAnonymous",
      "leaderboardFollowActions",
    ]),
    ...mapActions("live", ["setLiveStreamSource"]),
    getGiftersLeaderBoardDaily() {
      this.getGiftersLeaderBoard({ leaderboard_type: LeaderBoardEnums.DAILY });
      this.activeTab = LeaderBoardEnums.DAILY;
    },
    getGiftersLeaderBoardWeekly() {
      this.getGiftersLeaderBoard({ leaderboard_type: LeaderBoardEnums.WEEKLY });
      this.activeTab = LeaderBoardEnums.WEEKLY;
    },
    getGiftersLeaderBoardAllTime() {
      this.getGiftersLeaderBoard({
        leaderboard_type: LeaderBoardEnums.DEFAULT,
      });
      this.activeTab = LeaderBoardEnums.DEFAULT;
    },
    getCryptedString(streamId, userId) {
      let cryptObj = { streamId: streamId, userId: userId };
      return encrypt(cryptObj);
    },
    generateBase64Url,
    decodeUrlParameter,
    showThousund,
    clickFollowUser(user_id) {
      this.leaderboardFollowActions({
        user_id: user_id,
        page_enum: FollowPageEnums.OTHER_PROFILE,
        type: "follow",
        leaderboard_type: this.activeTab,
        leaderboard_list_type: "gifter",
      });
    },
    clickUnfollowUser(user_id) {
      this.leaderboardFollowActions({
        user_id: user_id,
        page_enum: FollowPageEnums.OTHER_PROFILE,
        type: "unfollow",
        leaderboard_type: this.activeTab,
        leaderboard_list_type: "gifter",
      });
    },
    makeAnonymous(e) {
      if (e.target.checked === true) {
        this.setUserGifterLeaderboardAnonymous({
          is_anonymous: true,
          leaderboard_type: this.activeTab,
        });
      } else {
        this.setUserGifterLeaderboardAnonymous({
          is_anonymous: false,
          leaderboard_type: this.activeTab,
        });
      }
    },
  },
};
</script>
