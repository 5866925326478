<template>
  <div class="container is-fluid" :class="{ 'hybrid-container': isHybridPage }">
    <CoinSelect v-if="stripeActivePage === 'coinSelect'"></CoinSelect>
    <Checkout v-if="stripeActivePage === 'checkout'"></Checkout>
    <CreditCardPayment
      v-if="stripeActivePage === 'cardPayment'"
    ></CreditCardPayment>
    <CompletePayment
      v-if="stripeActivePage === 'completePayment'"
    ></CompletePayment>
    <GetEmail v-if="stripeActivePage === 'getEmail'"></GetEmail>
  </div>
</template>
<style lang="scss">
@import "@/components/Payment/Payment.scss";
</style>
<script>
"use strict";

import { mapState, mapActions } from "vuex";
import CoinSelect from "@/components/Payment/Stripe/CoinSelect.vue";
import Checkout from "@/components/Payment/Stripe/Checkout.vue";
import CreditCardPayment from "@/components/Payment/Stripe/CreditCardPayment.vue";
import CompletePayment from "@/components/Payment/Stripe/CompletePayment.vue";
import GetEmail from "@/components/Payment/Stripe/GetEmail.vue";

export default {
  name: "Payment",
  components: {
    CoinSelect,
    Checkout,
    CreditCardPayment,
    CompletePayment,
    GetEmail,
  },
  computed: {
    ...mapState({
      stripeActivePage: (state) => state.payment.stripeActivePage,
      isHybridPage: (state) => state.client.isHybridPage,
    }),
  },
  mounted() {
    let platform = this.$route.query.platform;
    this.setPlatform(platform != null ? platform : null);

    if (platform == "android") {
      this.$store.dispatch("client/setHybridPage", true);
      this.redirectCheckout();
    }
  },
  methods: {
    ...mapActions(["setPlatform"]),
    redirectCheckout() {
      this.$store.dispatch("payment/setStripeActivePage", "checkout");
    },
  },
};
</script>
