import { actions } from "./actions.js";
// import { localStore, STORE_KEYS } from "@/storage.js";

const state = () => ({
  stripeActivePage: "coinSelect",
  selectedPaymentMethod: null,
  stripePaymentSucceeded: false,
  selectedProduct: null,
  // hybridPaymentFlowInformation: {
  //   user_id: null,
  //   device_guid: null,
  //   product_id: null,
  //   coins: null,
  //   price: null,
  //   currency: null,
  //   page_source: null,
  //   flow_id: null,
  //   is_new_card: 0,
  //   order_id: null,
  //   payment_type: null,
  //   hybrid_method: null,
  // },
  showPromotionExpired: false,
  fromPromotion: false,
  isPromotionPayment: false,
  promotionReferrer: null,
});

// getters **************************

const getters = {
  stripeActivePage: (state) => {
    return state.stripeActivePage;
  },
  selectedPaymentMethod: (state) => {
    return state.selectedPaymentMethod;
  },
  stripePaymentSucceeded: (state) => {
    return state.stripePaymentSucceeded;
  },
  selectedProduct: (state) => {
    return state.selectedProduct;
  },
  // NOTE(purchase-bug-fix-pass): Deprecated hybrid payment flow events. Was missing updates and missing event triggers.
  // hybridPaymentFlowInformation: (state) => {
  //   return state.hybridPaymentFlowInformation;
  // },
  showPromotionExpired: (state) => {
    return state.showPromotionExpired;
  },
  isPromotionPayment: (state) => {
    return state.isPromotionPayment;
  },
  promotionReferrer: (state) => {
    return state.promotionReferrer;
  },
};

// mutations ************************

const mutations = {
  setStripeActivePage(state, stripeActivePage) {
    state.stripeActivePage = stripeActivePage;
  },
  setSelectedPaymentMethod(state, selectedPaymentMethod) {
    state.selectedPaymentMethod = selectedPaymentMethod;
  },
  setStripePaymentSucceeded(state, stripePaymentSucceeded) {
    state.stripePaymentSucceeded = stripePaymentSucceeded;
  },
  setSelectedProduct(state, selectedProduct) {
    state.selectedProduct = selectedProduct;
  },
  // NOTE(purchase-bug-fix-pass): Deprecated hybrid payment flow events. Was missing updates and missing event triggers.
  // setHybridPaymentFlowInformation(state, payload) {
  //   state.hybridPaymentFlowInformation = payload;
  //   localStore.setItem(
  //     STORE_KEYS.HYBRID_PAYMENT_FLOW_INFORMATION,
  //     JSON.stringify(state.hybridPaymentFlowInformation)
  //   );
  // },
  setShowPromotionExpired(state, payload) {
    state.showPromotionExpired = payload;
  },
  setFromPromotion(state, payload) {
    state.fromPromotion = payload;
  },
  setIsPromotionPayment(state, payload) {
    state.isPromotionPayment = payload;
  },
  setPromotionReferrer(state, payload) {
    state.promotionReferrer = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
