<template>
  <div class="columns is-multiline">
    <div class="back-button">
      <v-btn
        icon
        class="back-button-item"
        @click="setActiveLoginComponent('email-login')"
      >
        <v-icon class="fas fa-arrow-left fa-2x" />
      </v-btn>
    </div>
    <template v-if="!showVerifyCode && !showResetPassword && !showSuccess">
      <div class="column is-12">
        <h1 class="login-header">{{ $t("forgot_password.header") }}</h1>
        <div class="sub-title" v-html="$t('forgot_password.sub_header')"></div>
      </div>
      <div
        class="column is-12"
        :class="{ error: validation.hasError('email') }"
      >
        <label for="email">{{ $t("labels.email") }}</label>
        <input
          type="text"
          class="input"
          placeholder="example@example.com"
          v-model="email"
        />
        <div class="validation-error-message">
          {{ validation.firstError("email") }}
        </div>
      </div>
      <div class="column is-12">
        <button
          class="forgot-password-verify special-button primary-button"
          :disabled="loading"
          @click="sendRenewCode"
        >
          {{ $t("labels.verify") }}
        </button>
        <div class="lds-ellipsis" v-if="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="validation-error-message" v-if="forgotError">
          {{ forgotError }}
        </div>
      </div>
    </template>
    <template v-if="showVerifyCode">
      <div class="column is-12">
        <h1 class="login-header">{{ $t("forgot_password.header") }}</h1>
      </div>
      <div class="column is-12">
        <div
          class="has-text-centered code-sub-header"
          v-html="$t('forgot_password.code_sub_header')"
        ></div>
      </div>
      <div class="column is-12" :class="{ error: validation.hasError('code') }">
        <input
          type="text"
          class="input has-text-centered"
          id="code"
          :placeholder="$t('labels.six_digit_code')"
          v-model="code"
        />
        <div class="validation-error-message">
          {{ validation.firstError("code") }}
        </div>
      </div>
      <div class="column is-12">
        <button
          class="forgot-password-verify special-button primary-button"
          :disabled="loading"
          @click="verifyCode"
        >
          {{ $t("labels.verify") }}
        </button>
        <div class="lds-ellipsis" v-if="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="validation-error-message" v-if="forgotError">
          {{ forgotError }}
        </div>
      </div>
      <div class="column is-12">
        <div v-if="showResend">
          <a href="javscript:;" @click="resendCode">{{
            $t("forgot_password.resend_code")
          }}</a>
        </div>
        <div v-else>
          <span
            v-html="
              $t('forgot_password.reset_email', {
                email: email,
                second: formatSecond(second),
              })
            "
          >
          </span>
          <a href="javascript:;" @click="changeEmail">
            {{ $t("forgot_password.change_email") }}</a
          >
        </div>
      </div>
    </template>
    <template v-if="showResetPassword">
      <div class="column is-12">
        <h1 class="login-header">{{ $t("forgot_password.header") }}</h1>
      </div>
      <div
        class="column is-12"
        :class="{ error: validation.hasError('password') }"
      >
        <label for="password">{{ $t("labels.password") }}</label>
        <input
          type="password"
          class="input"
          id="password"
          v-model="password"
          :placeholder="$t('labels.password')"
        />
        <div class="validation-error-message">
          {{ validation.firstError("password") }}
        </div>
      </div>
      <div
        class="column is-12"
        :class="{ error: validation.hasError('repeat') }"
      >
        <label for="password-repeat">{{ $t("labels.repeat_password") }}</label>
        <input
          type="password"
          class="input"
          id="password-repeat"
          v-model="repeat"
          :placeholder="$t('labels.repeat_password')"
        />
        <div class="validation-error-message">
          {{ validation.firstError("repeat") }}
        </div>
      </div>
      <div class="column is-12">
        <button
          class="forgot-password-verify special-button primary-button"
          :disabled="loading"
          @click="resetPassword"
        >
          {{ $t("labels.continue") }}
        </button>
        <div class="lds-ellipsis" v-if="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="validation-error-message" v-if="forgotError">
          {{ forgotError }}
        </div>
      </div>
    </template>
    <template v-if="showSuccess">
      <div class="column is-12 forgot-password-success">
        <img src="@/assets/img/success-icon.svg" />
        <div class="mt-3">
          <b>{{ $t("forgot_password.change_success_header") }}</b>
        </div>
        <div
          class="mt-3"
          v-html="$t('forgot_password.change_success_text')"
        ></div>
      </div>
      <div class="column is-12">
        <button
          class="special-button primary-button"
          @click="setActiveLoginComponent('email-login')"
        >
          {{ $t("labels.login") }}
        </button>
      </div>
    </template>
  </div>
</template>
<style lang="scss" scoped>
.code-sub-header {
  font-size: 14px;
  color: $gray-darker;
}
.forgot-password-success {
  text-align: center;
}
</style>
<script>
"use strict";
import SimpleVueValidation from "simple-vue-validator";
import { mapActions } from "vuex";
const Validator = SimpleVueValidation.Validator;

const endpoints = require("../../../api/endpoints");

export default {
  name: "ForgotPasswordComponent",
  data: () => {
    return {
      email: "",
      code: null,
      password: null,
      repeat: null,
      password_renewal_id: null,
      loading: false,
      forgotError: null,
      showVerifyCode: false,
      showResetPassword: false,
      showSuccess: false,
      showResend: false,
      second: 300,
      timerStorage: null,
    };
  },
  mounted() {},
  validators: {
    email: function (value) {
      return Validator.value(value).required().email();
    },
    code: function (value) {
      return Validator.value(value).required().digit().maxLength(6);
    },
    password: function (value) {
      return Validator.value(value).required().minLength(8);
    },
    "repeat, password": function (repeat, password) {
      if (this.submitted || this.validation.isTouched("repeat")) {
        return Validator.value(repeat).required().match(password);
      }
    },
  },
  methods: {
    ...mapActions("client", ["setActiveLoginComponent"]),
    sendRenewCode() {
      this.forgotError = false;
      let that = this;
      this.$validate("email").then(function (success) {
        if (success) {
          that.loading = true;
          endpoints
            .send_renewal_code(that.$axios, {
              email: that.email,
              force_new: false,
            })
            .then((renewalCodeResponse) => {
              renewalCodeResponse = renewalCodeResponse.data;
              that.loading = false;
              if (renewalCodeResponse.error) {
                if (
                  renewalCodeResponse.error.code == 35 ||
                  renewalCodeResponse.error.code == 36
                ) {
                  that.forgotError = that.$t("errors.no_user_with_this_mail");
                } else {
                  that.forgotError = renewalCodeResponse.error.message;
                }
              } else {
                that.password_renewal_id =
                  renewalCodeResponse.password_renewal_id;
                that.showVerifyCode = true;
                that.startTimer();
                that.forgotError = false;
              }
            });
        }
      });
    },
    verifyCode() {
      this.forgotError = false;
      let that = this;
      this.$validate("code").then(function (success) {
        if (success) {
          that.loading = true;

          endpoints
            .verify_password_renewal(that.$axios, {
              password_renewal_id: that.password_renewal_id,
              code: that.code,
            })
            .then((verifyPasswordRenewalResponse) => {
              verifyPasswordRenewalResponse =
                verifyPasswordRenewalResponse.data;
              that.loading = false;
              if (verifyPasswordRenewalResponse.error) {
                if (verifyPasswordRenewalResponse.error.code == 34) {
                  that.forgotError = that.$t("errors.incorrect_renewal_code");
                } else {
                  that.forgotError =
                    verifyPasswordRenewalResponse.error.message;
                }
              } else {
                that.showVerifyCode = false;
                that.showResetPassword = true;
                that.forgotError = false;
                this.clearTimer();
                this.resetTimer();
              }
            });
        }
      });
    },
    resetPassword() {
      this.forgotError = false;
      let that = this;
      this.$validate(["password", "repeat"]).then(function (success) {
        if (success) {
          that.loading = true;

          endpoints
            .renew_password(that.$axios, {
              password_renewal_id: that.password_renewal_id,
              password: that.password,
            })
            .then((renewPasswordResponse) => {
              renewPasswordResponse = renewPasswordResponse.data;
              that.loading = false;
              if (renewPasswordResponse.error) {
                that.forgotError = renewPasswordResponse.error.message;
              } else {
                that.showVerifyCode = false;
                that.showResetPassword = false;
                that.showSuccess = true;
              }
            });
        }
      });
    },
    resendCode() {
      let that = this;
      that.loading = true;
      endpoints
        .send_renewal_code(that.$axios, { email: that.email, force_new: true })
        .then((renewalCodeResponse) => {
          renewalCodeResponse = renewalCodeResponse.data;
          that.loading = false;
          if (renewalCodeResponse.error) {
            that.forgotError = renewalCodeResponse.error.message;
          } else {
            that.password_renewal_id = renewalCodeResponse.password_renewal_id;
            that.showVerifyCode = true;
            that.resetTimer();
            that.clearTimer();
            that.startTimer();
            that.forgotError = false;
          }
        });
    },
    changeEmail() {
      this.email = null;
      this.code = null;
      this.showVerifyCode = false;
      this.showResetPassword = false;
      this.showSuccess = false;
      this.forgotError = false;
      this.clearTimer();
      this.resetTimer();
    },
    startTimer() {
      this.showResend = false;
      this.timerStorage = setInterval(this.timer, 1000);
    },
    resetTimer() {
      this.second = 300;
    },
    clearTimer() {
      clearInterval(this.timerStorage);
    },
    timer() {
      if (this.second > 0) {
        this.second--;
      } else {
        this.showResend = true;
        this.clearTimer();
      }
    },
    formatSecond(time) {
      let minutes = Math.floor(time / 60);
      let seconds = time - minutes * 60;
      return minutes + ":" + seconds;
    },
  },
};
</script>
