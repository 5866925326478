<template>
  <div>
    <div class="page-left-container">
      <DiscoverLeft />
    </div>
    <div class="page-right-container">
      <my-level />
      <discover />
    </div>
  </div>
</template>
<script>
import MyLevel from "@/components/MyLevel/MyLevel.vue";
import Discover from "@/components/Discover/Discover.vue";
import DiscoverLeft from "@/components/Discover/DiscoverLeft.vue";

export default {
  name: "MyLevelView",
  components: {
    MyLevel,
    DiscoverLeft,
    Discover,
  },
};
</script>
