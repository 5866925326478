<template>
  <div class="contact container is-fluid">
    <div class="columns">
      <div class="column">
        <div class="contact-content">
          <h1>{{ $t("menu.contact") }}</h1>
          <p>{{ $t("contact_page.content", { emailDomain }) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.contact {
  text-align: left;
  color: $gray-darkest;
  .contact-content {
    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
      color: $primary;
    }
    p {
      margin-bottom: 10px;
    }
  }
}
</style>
<script>
import { mapState } from "vuex";

export default {
  name: "Contact",
  computed: {
    ...mapState({
      emailDomain: (state) => state.emailDomain,
    }),
  },
};
</script>
