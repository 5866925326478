<template>
  <div class="columns is-multiline">
    <div class="back-button">
      <v-btn
        icon
        class="back-button-item"
        @click="setActiveLoginComponent('social-login')"
      >
        <v-icon class="fas fa-arrow-left fa-2x" />
      </v-btn>
    </div>
    <div class="column is-12">
      <h1 class="login-header">{{ $t("login_page.email_signup_header") }}</h1>
      <div
        class="sub-title"
        v-html="$t('login_page.email_signup_sub_header')"
      ></div>
    </div>
    <div class="column is-12">
      <div
        class="sign-up-email special-button primary-button"
        @click="setActiveLoginComponent('email-register')"
      >
        {{ $t("login_page.signup_with_email") }}
      </div>
    </div>
    <div class="column is-12">
      <div class="have-account-label">
        {{ $t("login_page.do_you_have_account") }}
      </div>
    </div>
    <div class="column is-12">
      <div
        class="sign-up-email special-button secondary-button"
        @click="setActiveLoginComponent('email-login')"
      >
        {{ $t("login_page.login_with_email") }}
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "./EmailLoginRegister.scss";
</style>
<script>
"use strict";
import { mapActions } from "vuex";

export default {
  name: "EmailLoginRegister",
  methods: { ...mapActions("client", ["setActiveLoginComponent"]) },
};
</script>
