<template>
  <div class="columns is-multiline">
    <div class="back-button">
      <v-btn
        icon
        class="back-button-item"
        @click="setActiveLoginComponent('login')"
      >
        <v-icon class="fas fa-arrow-left fa-2x" />
      </v-btn>
    </div>
    <div style="text-align: center" class="column is-12">
      <img
        v-bind:src="require(`@/assets/img/${brandName}.png`)"
        v-bind:srcset="require(`@/assets/img/${brandName}@2x.png`) + ' 2x'"
        v-bind:alt="brandTitle + ' Logo'"
      />
    </div>

    <div
      class="column is-full verification-column"
      v-if="showVerificationModal"
    >
      <h2>{{ $t("labels.verify_number") }}</h2>
      <p>{{ $t("labels.enter_verify_code") }}</p>
      <div class="verification-code-container">
        <input
          id="code1"
          v-model="code[0]"
          v-mask="'#'"
          class="code-input"
          autocomplete="off"
          tabindex="1"
          @keyup="changeCode($event)"
          @paste="handlePaste($event)"
          inputmode="numeric"
          pattern="[0-9]*"
        />
        <input
          id="code2"
          v-model="code[1]"
          v-mask="'#'"
          class="code-input"
          autocomplete="off"
          tabindex="2"
          @keyup="changeCode($event)"
          @paste="handlePaste($event)"
          inputmode="numeric"
          pattern="[0-9]*"
        />
        <input
          id="code3"
          v-model="code[2]"
          v-mask="'#'"
          class="code-input"
          autocomplete="off"
          tabindex="3"
          @keyup="changeCode($event)"
          @paste="handlePaste($event)"
          inputmode="numeric"
          pattern="[0-9]*"
        />
        <input
          id="code4"
          v-model="code[3]"
          v-mask="'#'"
          class="code-input"
          autocomplete="off"
          tabindex="4"
          @keyup="changeCode($event)"
          @paste="handlePaste($event)"
          inputmode="numeric"
          pattern="[0-9]*"
        />
        <input
          id="code5"
          v-model="code[4]"
          v-mask="'#'"
          class="code-input"
          autocomplete="off"
          tabindex="5"
          @keyup="changeCode($event)"
          @paste="handlePaste($event)"
          inputmode="numeric"
          pattern="[0-9]*"
        />
        <input
          id="code6"
          v-model="code[5]"
          v-mask="'#'"
          class="code-input"
          autocomplete="off"
          tabindex="6"
          @keyup="changeCode($event)"
          @paste="handlePaste($event)"
          inputmode="numeric"
          pattern="[0-9]*"
        />
      </div>
      <b-button
        class="sign-in-button is-info is-fullwidth"
        tabindex="7"
        @click="validateCode"
        :disabled="disableButton || code.join('').length < 6"
        >{{ $t("labels.verify") }}</b-button
      >
      <div class="lds-ellipsis" v-if="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div v-if="!showVerificationModal" class="column is-full">
      <label for="tel-input" class="phone-label">{{
        $t("labels.phone_number")
      }}</label>
      <vue-tel-input
        id="tel-input"
        class="phone-input"
        :inputOptions="phoneInputOptions"
        v-model="phone_number_entered"
        @input="changePhone"
      ></vue-tel-input>
    </div>
    <div v-if="!showVerificationModal" class="column is-full">
      <b-button
        id="sign-in-button"
        @click="openVerification"
        class="sign-in-button is-info is-fullwidth"
        :disabled="disableButton"
        >{{ $t("labels.login") }}</b-button
      >
      <div class="lds-ellipsis" v-if="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="column is-full" v-if="$store.getters.errorMessage">
      <div class="error-message">
        {{ $store.getters.errorMessage }}
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "./PhoneLogin.scss";
</style>
<script>
"use strict";

import firebase from "firebase/app";
import "firebase/auth";
import { VueTelInput } from "vue-tel-input";
import { mask } from "vue-the-mask";
import { mapState, mapActions } from "vuex";
import { signupAttemptEvent } from "@/helper/marketingevents.js";
import { signupUuid } from "@/helper/index.js";

const endpoints = require("../../../api/endpoints");

export default {
  name: "PhoneLogin",
  directives: {
    mask,
  },
  components: {
    VueTelInput,
  },
  data() {
    return {
      loading_login: true,
      phone_number_entered: null,
      phone_detail_object: null,
      country_selected: null,
      phone_code_entered: "",
      loading_phone_verification: false,
      showVerificationModal: false,
      recaptchaVerifier: undefined,
      verificationCode: null,
      confirmationResult: undefined,
      code: [],
      loading: false,
      disableButton: false,
      phoneInputOptions: {
        placeholder: this.$i18n.t("labels.phone_number"),
      },
      redirectPage: null,
      redirectToken: null,
    };
  },
  created() {
    this.redirectPage = this.$route.query.to;
    this.redirectToken = this.$route.query.token;
  },
  computed: {
    ...mapState({
      brandName: (state) => state.brandName,
      brandTitle: (state) => state.brandTitle,
      deviceId: (state) => state.client.deviceId,
    }),
  },
  mounted() {
    this.recaptchaVerifierInvisible();
  },
  methods: {
    ...mapActions("client", ["setActiveLoginComponent"]),
    openVerification() {
      // Marketing event
      signupAttemptEvent(this.deviceId, signupUuid(), "phone");
      this.cleanError();
      if (this.phone_detail_object.valid) {
        this.changeLoading();
        const phoneNumber = this.phone_detail_object.number;
        const appVerifier = this.recaptchaVerifier;
        firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier)
          .then((confirmationResult) => {
            // Daha sonrasında code'u validate etmek için kullanılan observer
            this.confirmationResult = confirmationResult;
            this.showVerificationModal = true;
            this.changeLoading();
            // Input'un dom'a yüklenmesi için bekletiliyor.
            setTimeout(() => {
              document.getElementById("code1").focus();
            }, 500);
          })
          .catch((error) => {
            // console.log('ERROR : ', error);
            this.$store.commit(
              "client/setErrorMessage",
              this.$i18n.t("errors.sms_not_sent")
            );
            if (error.code == "auth/too-many-requests") {
              this.$store.commit(
                "client/setErrorMessage",
                this.$i18n.t("errors.too_many_attemp")
              );
            }
            this.renderRecaptcha();
            this.showVerificationModal = false;
            this.changeLoading();
          });
      } else {
        this.$store.commit(
          "client/setErrorMessage",
          this.$i18n.t("errors.invalid_phone")
        );
        this.renderRecaptcha();
        this.showVerificationModal = false;
      }
    },
    validateCode() {
      const that = this;
      this.verificationCode = this.code.join("");
      if (this.verificationCode) {
        this.changeLoading();
        this.confirmationResult
          .confirm(this.verificationCode)
          .then(() => {
            // const user = result.user;
            firebase
              .auth()
              .currentUser.getIdToken(true)
              .then((idToken) => {
                endpoints
                  .login_phone(that.$axios, { token: idToken })
                  .then((result) => {
                    if (result.data.error) {
                      that.$store.commit(
                        "client/setErrorMessage",
                        result.data.error.message
                      );
                    } else {
                      this.$store.dispatch("client/postLogin", {
                        authToken: result.data.token,
                        redirectUrl: "/",
                        loginFrom: "phone",
                      });
                    }
                    that.changeLoading();
                  });
              })
              .catch((error) => {
                that.$store.commit(
                  "client/setErrorMessage",
                  that.$i18n.t("errors." + error.code)
                );
                that.changeLoading();
              });
          })
          .catch((error) => {
            that.$store.commit(
              "client/setErrorMessage",
              that.$i18n.t("errors." + error.code)
            );
            that.changeLoading();
          });
      }
    },
    changePhone(number, pObj) {
      this.phone_detail_object = pObj;
    },
    recaptchaVerifierInvisible() {
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
        }
      );
      // this.renderRecaptcha();
    },
    renderRecaptcha() {
      this.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });
    },
    changeCode(event) {
      if (event.keyCode == 8) {
        if (event.target.previousElementSibling) {
          event.target.previousElementSibling.focus();
        }
      } else {
        if (event.target.nextElementSibling) {
          event.target.nextElementSibling.focus();
        }
      }
    },
    handlePaste(e) {
      const that = this;
      const pasteData = e.clipboardData.getData("text/plain").split("");
      pasteData.forEach((element, index) => {
        that.code[index] = element;
      });
    },
    changeLoading() {
      // TODO : (Ugurtan) bu kısım vuex store'a taşınacak.
      this.loading = !this.loading;
      this.disableButton = !this.disableButton;
    },
    cleanError() {
      this.$store.commit("client/setErrorMessage", null);
    },
  },
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
