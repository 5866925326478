import { mapActions } from "vuex";
import { Logger } from "@/logging";
import PayPal from "@/paypal";
import { generatePaymentUUID, getPaymentUUID } from "@/helper";
import { PaymentOperationStatus, PaymentProvider } from "@/helper/enums";
import DeviceHybridCommunication from "./DeviceHybridCommunication";

const logger = new Logger("PaymentMethodProceedMixin");

/** @import  {Payment} from '@/components/Payment/types/payment' */
/** @import  {Product} from '@/components/Payment/types/product' */

/**
 * @typedef ReactiveData
 * @property {boolean} isFullLoading
 */

/** @typedef {import('vue') & ReactiveData} Mixin */

/**
 * @mixin
 * @mixes DeviceHybridCommunication
 */
export default {
  mixins: [DeviceHybridCommunication],
  data() {
    return {
      isFullLoading: false,
    };
  },
  methods: {
    ...mapActions("payment", [
      "payermaxPayment",
      "razerPayment",
      "trustPayPayment",
      "paypalPayment",
      "rapydPayment",
    ]),
    /**
     * @this Mixin
     * @param {Product} product
     * @param {Payment.PaymentMethod} paymentMethod
     * @param {string|null} [savedPaymentTokenId=null]
     * @param {string|null} [bannerId=null]
     */
    async onProceedPayment(
      product,
      paymentMethod,
      savedPaymentTokenId = null,
      bannerId = null
    ) {
      if (paymentMethod.is_available && !this.isFullLoading) {
        if (
          [PaymentProvider.APPLE, PaymentProvider.GOOGLE].includes(
            paymentMethod.payment_provider
          )
        ) {
          await this.proceedWithInAppPurchase(product.sku);
          this.$hybridapi("showLoading");
        } else if (
          paymentMethod.payment_provider === PaymentProvider.TRUSTPAY
        ) {
          if (this.$refs.savedCardsBottomSheet == null) {
            logger.debugError(
              "Saved card bottom sheet not available. Check bottom sheet with ref savedCardsBottomSheet"
            );
          } else {
            this.$refs.savedCardsBottomSheet.open();
          }
        } else if (
          paymentMethod.payment_provider === PaymentProvider.PAYERMAX
        ) {
          if (this.savedPaymentTokens.length > 0) {
            if (this.$refs.savedCardsBottomSheet == null) {
              logger.debugError(
                "Saved card bottom sheet not available. Check bottom sheet with ref savedCardsBottomSheet"
              );
            } else {
              this.$refs.savedCardsBottomSheet.open();
            }
          } else {
            this.isFullLoading = true;
            await this.proceedWithPayermax(
              product.sku,
              paymentMethod,
              savedPaymentTokenId,
              bannerId
            )
              .then((redirecting) => {
                if (!redirecting) {
                  this.isFullLoading = false;
                }
              })
              .catch((e) => {
                this.isFullLoading = false;
                logger.debugError(
                  "Failed to proceed with Payermax",
                  product,
                  paymentMethod,
                  e
                );
              });
          }
        } else if (paymentMethod.payment_provider === PaymentProvider.RAPYD) {
          this.isFullLoading = true;
          await this.proceedWithRapyd(product.sku, paymentMethod, bannerId)
            .then((redirecting) => {
              if (!redirecting) {
                this.isFullLoading = false;
              }
            })
            .catch((e) => {
              this.isFullLoading = false;
              logger.debugError(
                "Failed to proceed with Rapyd",
                product,
                paymentMethod,
                e
              );
            });
        } else if (paymentMethod.payment_provider === PaymentProvider.RAZER) {
          this.isFullLoading = true;
          await this.proceedWithRazer(product.sku, paymentMethod, bannerId)
            .then((redirecting) => {
              if (!redirecting) {
                this.isFullLoading = false;
              }
            })
            .catch((e) => {
              this.isFullLoading = false;
              logger.debugError(
                "Failed to proceed with Razer",
                product,
                paymentMethod,
                e
              );
            });
        } else if (paymentMethod.payment_provider === PaymentProvider.PAYPAL) {
          if (this.$refs.savedCardsBottomSheet == null) {
            logger.debugError(
              "Saved card bottom sheet not available. Check bottom sheet with ref savedCardsBottomSheet"
            );
          } else {
            this.$refs.savedCardsBottomSheet.open();
          }
        } else if (paymentMethod.payment_provider === PaymentProvider.DEALER) {
          this.$store.dispatch("payment/setSelectedProduct", product);
          this.$hybridapi("showLoading");
          this.$router.push("/payermax/coin-reseller");
        } else {
          logger.debugError(
            "Payment method provider is not implemented",
            paymentMethod
          );
        }
      }
    },
    /**
     * @param {string} sku Product identifier
     */
    async proceedWithInAppPurchase(sku) {
      this.$hybridapi("triggerPurchase", sku);
      return Promise.resolve();
    },
    /**
     * @param {string} sku Product identifier
     * @param {Payment.PaymentMethod} paymentMethod
     * @param {number|null} [savedPaymentTokenId=null]
     * @param {number|null} [bannerId=null]
     * @returns {Promise<boolean>} Is redirect required
     */
    async proceedWithPayermax(
      sku,
      paymentMethod,
      savedPaymentTokenId = null,
      bannerId = null
    ) {
      const idempotence = getPaymentUUID();
      const payload = {
        product_id: sku,
        order_id: idempotence,
        payment_type: paymentMethod.code,
        saved_card_id: savedPaymentTokenId,
        ...(bannerId != null ? { banner_id: bannerId } : {}),
      };

      try {
        const response = await this.payermaxPayment(payload);
        generatePaymentUUID();

        if (response.error) {
          logger.debugError(
            "Failed to proceed over Payermax",
            payload,
            response
          );
          const message =
            response.error.message ?? this.$t("errors.general_error");
          this.$toast.error(message);
          return false;
        } else if (response.request_url) {
          if (response.open_on_external_browser) {
            this.$hybridapi("openLinkInBrowser", response.request_url);
            setTimeout(() => this.onHybridClose(), 100);
            return false;
          } else {
            this.handleTopBar();
            window.location.href = response.request_url;
            return true;
          }
        } else {
          logger.debugError(
            "Unexpected Payermax exception caught",
            payload,
            response
          );
          this.$toast.error(this.$t("errors.general_error"));
          this.hybridClose("close");
          return false;
        }
      } catch (e) {
        logger.debugError("Failed to proceed over Payermax", payload, e);
        this.$toast.error(this.$t("errors.general_error"));
        return false;
      }
    },
    /**
     * @param {string} sku Product identifier
     * @param {string|null} [savedPaymentTokenId=null]
     * @param {boolean} [isSavePaymentTokenPermission=false]
     * @param {number|null} [bannerId=null]
     * @returns {Promise<boolean>} Is redirect required
     */
    async proceedWithTrustPay(
      sku,
      savedPaymentTokenId = null,
      isSavePaymentTokenPermission = false,
      bannerId = null
    ) {
      const payload = {
        idempotency_key: getPaymentUUID(),
        product_id: sku,
        ...(savedPaymentTokenId != null
          ? { card_id: savedPaymentTokenId }
          : { save_card: isSavePaymentTokenPermission }),
        ...(bannerId != null ? { banner_id: bannerId } : {}),
      };

      try {
        const response = await this.trustPayPayment(payload);
        generatePaymentUUID();

        if (response.error) {
          logger.debugError(
            "Failed to proceed over TrustPay",
            payload,
            response
          );
          const message =
            response.error.message ?? this.$t("errors.general_error");
          this.$toast.error(message);
          return false;
        } else if (!response.request_url) {
          const status =
            response.status === "Rejected"
              ? PaymentOperationStatus.FAILED
              : PaymentOperationStatus.PENDING;

          this.$router.push({
            path: "/payermax/payment-complete",
            query: {
              status,
              payment_id: response.payment_request_id,
            },
          });

          return true;
        } else if (response.redirect_url) {
          this.handleTopBar();
          window.location.href = response.redirect_url;
          return true;
        } else {
          logger.debugError(
            "Unexpected TrustPay exception caught",
            payload,
            response
          );
          this.$toast.error(this.$t("errors.general_error"));
          this.hybridClose("close");
          return false;
        }
      } catch (e) {
        logger.debugError("Failed to proceed over TrustPay", payload, e);
        this.$toast.error(this.$t("errors.general_error"));
        return false;
      }
    },
    /**
     * @param {string} sku Product identifier
     * @param {string|null} [savedPaymentTokenId=null]
     * @param {boolean} [isSavePaymentTokenPermission=false]
     * @param {number|null} [bannerId=null]
     * @returns {Promise<boolean>} Is redirect required
     */
    async proceedWithPayPal(
      sku,
      savedPaymentTokenId = null,
      isSavePaymentTokenPermission = false,
      bannerId = null
    ) {
      const payload = {
        session_id: PayPal.getPayPalSessionId(),
        idempotency_key: getPaymentUUID(),
        product_id: sku,
        ...(savedPaymentTokenId != null
          ? { vault_id: savedPaymentTokenId }
          : { is_vault_store: isSavePaymentTokenPermission }),
        ...(bannerId != null ? { banner_id: bannerId } : {}),
      };

      try {
        const response = await this.paypalPayment(payload);
        generatePaymentUUID();

        if (response.error) {
          logger.debugError("Failed to proceed over PayPal", payload, response);
          const message =
            response.error.message ?? this.$t("errors.general_error");
          this.$toast.error(message);
          return false;
        } else if (response.redirect_url) {
          this.handleTopBar();
          window.location.href = response.redirect_url;
          return true;
        } else {
          logger.debugError(
            "Unexpected PayPal exception caught",
            payload,
            response
          );
          this.$toast.error(this.$t("errors.general_error"));
          this.hybridClose("close");
          return false;
        }
      } catch (e) {
        logger.debugError("Failed to proceed over PayPal", payload, e);
        this.$toast.error(this.$t("errors.general_error"));
        return false;
      }
    },
    /**
     * @param {string} sku Product identifier
     * @param {Payment.PaymentMethod} paymentMethod
     * @param {number|null} [bannerId=null]
     * @returns {Promise<boolean>} Is redirect required
     */
    async proceedWithRazer(sku, paymentMethod, bannerId = null) {
      const payload = {
        product_id: sku,
        idempotency_key: getPaymentUUID(),
        channel_id: paymentMethod.code,
        ...(bannerId != null ? { banner_id: bannerId } : {}),
      };

      try {
        const response = await this.razerPayment(payload);
        generatePaymentUUID();

        if (response.error) {
          logger.debugError("Failed to proceed over Razer", payload, response);
          const message =
            response.error.message ?? this.$t("errors.general_error");
          this.$toast.error(message);
          return false;
        } else if (response.redirect_url) {
          if (response.open_on_external_browser) {
            this.$hybridapi("openLinkInBrowser", response.redirect_url);
          } else {
            this.handleTopBar();
            window.location.href = response.redirect_url;
          }
          return true;
        } else {
          logger.debugError(
            "Unexpected Razer exception caught",
            payload,
            response
          );
          this.$toast.error(this.$t("errors.general_error"));
          this.hybridClose("close");
          return false;
        }
      } catch (e) {
        logger.debugError("Failed to proceed over Razer", payload, e);
        this.$toast.error(this.$t("errors.general_error"));
        return false;
      }
    },
    /**
     * @param {string} sku Product identifier
     * @param {Payment.PaymentMethod} paymentMethod
     * @param {number|null} [bannerId=null]
     * @returns {Promise<boolean>} Is redirect required
     */
    async proceedWithRapyd(sku, paymentMethod, bannerId = null) {
      const payload = {
        product_id: sku,
        idempotency_key: getPaymentUUID(),
        payment_type: paymentMethod.code,
        ...(bannerId != null ? { banner_id: bannerId } : {}),
      };

      try {
        const response = await this.rapydPayment(payload);
        generatePaymentUUID();

        if (response.error) {
          logger.debugError("Failed to proceed over Rapyd", response.error);
          const message =
            response.error.message ?? this.$t("errors.general_error");
          this.$toast.error(message);
          return false;
        } else if (response.redirect_url) {
          this.handleTopBar();
          window.location.href = response.redirect_url;
          return true;
        } else {
          logger.debugError(
            "Unexpected Rapyd exception caught",
            payload,
            response
          );
          this.$toast.error(this.$t("errors.general_error"));
          this.hybridClose("close");
          return false;
        }
      } catch (e) {
        logger.debugError("Failed to proceed over Rapyd", payload, e);
        this.$toast.error(this.$t("errors.general_error"));
        return false;
      }
    },
  },
};
