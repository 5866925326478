// @ts-check

const { v4: uuidv4 } = require("uuid");

const { localStore, STORE_KEYS } = require("../storage");

// const logger = new (require("../logging").Logger)("api.installation_id");

//

/** @type {string} */
let _id = localStore.getItem(STORE_KEYS.INSTALLATION_ID_KEY);

if (_id == null) {
  _id = uuidv4();

  localStore.setItem(STORE_KEYS.INSTALLATION_ID_KEY, _id);
}

//

function getInstallationID() {
  return _id;
}
module.exports.getInstallationID = getInstallationID;
