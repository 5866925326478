<template>
  <div>
    <div class="search">
      <!-- <input type="text" placeholder="Search" /> -->
    </div>
    <div :class="'left-box primary-box ' + brandName">
      <img src="@/assets/img/crown.png" alt="Crown" class="banner-crown" />
      <span class="banner-title">
        {{ $t("banners.banner_leaderboard_crown_text") }}
      </span>
      <router-link :to="'/leaderboard/'">
        {{ $t("banners.banner_leaderboard_crown_button") }}
      </router-link>
    </div>
    <div class="left-box black-box">
      <img src="@/assets/img/gold_coins.png" alt="Gold coins" />
      <span class="banner-title">
        {{ $t("banners.banner_support_streamers_buying_coin_text") }}
      </span>
      <a @click="checkShowCoinModal()">{{
        $t("banners.banner_support_streamers_buying_coin_button")
      }}</a>
    </div>
    <div class="left-box default-box">
      <img
        :src="require(`@/assets/img/users_${brandName}.png`)"
        alt="Gold coins"
      />
      <h2>{{ $t("banners.banner_follow_streamers_you_like_title") }}</h2>
      <p>
        {{ $t("banners.banner_follow_streamers_you_like_text") }}
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "DiscoverLeft.scss";
</style>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "DiscoverLeft",
  computed: {
    ...mapState({
      brandName: (state) => state.brandName,
    }),
  },
  methods: {
    ...mapActions("client", ["setShowBuyCoinsModal"]),
    checkShowCoinModal() {
      if (this.$route.path.includes("/login")) {
        this.setShowBuyCoinsModal(false);
      } else {
        this.setShowBuyCoinsModal({
          showBuyCoinsModal: true,
          buyCoinBackground: "black-opac-bg",
          pageName: "full_screen",
        });
      }
    },
  },
};
</script>
