<template>
  <div class="guest-drag-outer">
    <VueDragResize
      :isActive="true"
      :isResizable="false"
      :x="mobileGuestContainer.x"
      :y="30"
      :w="120"
      :h="160"
      :parentW="window.width"
      :parentH="window.height - 325"
      :parentLimitation="true"
      v-if="activeGuest && isMobile()"
      ref="refDragResize"
    >
      <div class="mobile-guest-container" :class="{ show: showGuest }">
        <!-- EF: guest kapatma butonunu sadece yayıncı ve guest görebilir, şimdilik pasif edildi -->
        <a
          href="javascript:"
          @click="closeGuest"
          class="close-guest"
          v-if="false"
          ><i class="fas fa-times"></i
        ></a>
        <div class="guest-inner"></div>
        <div
          class="guest-stopped"
          :style="{
            backgroundImage:
              'url(' + activeGuest.profile_image.thumbnail_url + ')',
          }"
          v-if="guestStopped && activeGuest && isMobile()"
        >
          <div class="overlay">
            <h2>{{ $t("livestream.guest_waiting") }}</h2>
          </div>
        </div>
        <v-btn
          class="guest-name"
          v-if="activeGuest"
          @click="openHalfProfile(activeGuest.user_id)"
          text
        >
          {{ activeGuest.name }}
        </v-btn>
      </div>
    </VueDragResize>
  </div>
</template>

<style lang="scss" scoped>
@import "GuestCard.scss";
</style>
<script>
import { mapState, mapActions } from "vuex";
import VueDragResize from "vue-drag-resize";
export default {
  name: "GuestCard",
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      mobileGuestContainer: {
        x: 0,
        y: 110,
      },
    };
  },
  computed: {
    ...mapState({
      activeGuest: (state) => state.live.activeGuest,
      guestStopped: (state) => state.live.guestStopped,
      showGuest: (state) => state.live.showGuest,
    }),
  },
  components: {
    VueDragResize,
  },
  created() {
    window.addEventListener("resize", this.handleResizeWindow);
    this.handleResizeWindow();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResizeWindow);
  },
  watch: {
    window: {
      handler() {
        this.mobileGuestContainer.x = this.window.width - 120;
        this.mobileGuestContainer.y = this.window.height / 3;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("live", ["closeGuest"]),
    ...mapActions("client", ["openHalfProfile"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    handleResizeWindow() {
      this.window.height = window.innerHeight;
      this.window.width = window.innerWidth;
    },
  },
};
</script>
