<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <div class="image-container">
        <img class="header-image" src="@/assets/img/reseller-icon.svg" />
      </div>
      <div class="card-title">
        <span>
          {{ $t("coin_reseller.official_dealer") }}
        </span>
      </div>
      <div class="card-body">
        <span>
          {{ $t("coin_reseller.popup_body_first") }}
        </span>
      </div>
      <div class="card-body">
        <span>
          {{ $t("coin_reseller.popup_body_second") }}
          <button class="card-mail" @click="sendMailToSupport()">
            {{ $t("coin_reseller.popup_info_mail") }}
          </button>
          {{ $t("coin_reseller.popup_body_third") }}
        </span>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "OfficialDealerModal",
  computed: {
    ...mapState({
      isHybridPage: (state) => state.client.isHybridPage,
    }),
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (state.modalStatus) {
        this.$store.dispatch("setDealerModalCloseStatus", false);
        this.$emit("close");
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    sendMailToSupport() {
      const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      if (this.isHybridPage) {
        if (isIOS) {
          this.$hybridapi("openLinkInApp", "mailto:info@superlivellc.com");
        } else {
          this.$hybridapi("openLinkInBrowser", "mailto:info@superlivellc.com");
        }
      } else {
        window.location.href = `mailto:info@superlivellc.com`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./OfficialDealerModal.scss";
</style>
