// @ts-check

let IS_PROD = false;

/**
 * @param {boolean} value
 */
module.exports.setIsProd = function setIsProd(value) {
  IS_PROD = value;
};

class Logger {
  /**
   * @param {string} location e.g. `api.network.wrapper`
   */
  constructor(location) {
    this.prefix = location + ": ";
  }

  /**
   * Debug only.
   * @param {string} message
   * @param {...*} args
   * @returns {void}
   */
  debug(message, ...args) {
    if (IS_PROD) return;
    console.debug(this.prefix + message, ...args);
  }

  /**
   * Debug only error log.
   * @param {string} message
   * @param {...*} args
   * @returns {void}
   */
  debugError(message, ...args) {
    if (IS_PROD) return;
    console.error(this.prefix + message, ...args);
  }

  /**
   * Debug only.
   * @param {string} message
   * @param {...*} args
   * @returns {void}
   */
  log(message, ...args) {
    if (IS_PROD) return;
  }

  /**
   * @param {string} message
   * @param {...*} args
   * @returns {void}
   */
  info(message, ...args) {
    console.info(this.prefix + message, ...args);
  }

  /**
   * @param {string} message
   * @param {...*} args
   * @returns {void}
   */
  warn(message, ...args) {
    console.warn(this.prefix + message, ...args);
  }

  /**
   * @param {string} message
   * @param {...*} args
   * @returns {void}
   */
  error(message, ...args) {
    console.error(this.prefix + message, ...args);
  }

  /**
   * Debug only.
   * @param {string} message
   * @returns {void}
   */
  time(message) {
    if (IS_PROD) return;
    console.time(this.prefix + message);
  }

  /**
   * Debug only.
   * @param {string} message
   * @param {...*} args
   * @returns {void}
   */
  timeLog(message, ...args) {
    if (IS_PROD) return;
    console.timeLog(this.prefix + message, ...args);
  }

  /**
   * Debug only.
   * @param {string} message
   * @returns {void}
   */
  timeEnd(message) {
    if (IS_PROD) return;
    console.timeEnd(this.prefix + message);
  }
}

module.exports.Logger = Logger;
