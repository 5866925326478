<template>
  <div v-if="this.userProfile" class="half-profile-container">
    <div @click="setShowHalfProfile(false)" class="half-profile-bg-layer"></div>
    <div class="half-profile-wrapper">
      <div class="img-container">
        <div class="profile-img-container">
          <router-link
            class="router-link"
            :to="generateBase64Url('/profile/', this.userProfile.user_id)"
          >
            <img
              v-if="this.userProfile.profile_images.length === 0"
              src="@/assets/img/profile-image-default.svg"
            />
            <img
              class="profile-img"
              v-if="this.userProfile.profile_images.length > 0"
              :src="this.userProfile.profile_images[0].thumbnail_url"
            />
          </router-link>
        </div>
      </div>
      <div class="report-container">
        <button class="report-wrapper" @click="showDownloadApp()">
          <img
            src="@/assets/img/half-profile-report.svg"
            alt="report svg"
            class="report-svg"
          />
          <span>{{ $t("half_profile_page.report") }}</span>
        </button>
      </div>
      <div class="user-name-container">
        <level-badge :user="this.userProfile"></level-badge>
        <span>{{ this.userProfile.name }}</span>
        <img
          v-if="returnVipString(this.userProfile.vip_state)"
          :src="
            require(`@/assets/img/${returnVipString(
              this.userProfile.vip_state
            )}-vip.svg`)
          "
          class="vip-badge"
        />
      </div>
      <div class="place-container">
        <div>
          <img src="@/assets/img/place-icon.svg" alt="place icon" />
        </div>
        <span class="location">
          {{ displayCountry(this.userProfile.country) }}
        </span>
      </div>
      <div class="counter-columns">
        <div class="follower-count-column">
          <router-link
            class="router-link"
            :to="generateBase64Url('/followers/', this.userProfile.user_id)"
          >
            <span class="counter padd">{{
              showThousund(this.userProfile.follower_count, 1)
            }}</span>
            <span class="counter-title">{{
              $t("half_profile_page.follower")
            }}</span>
          </router-link>
        </div>

        <div class="following-count-column">
          <router-link
            class="router-link"
            :to="generateBase64Url('/followings/', this.userProfile.user_id)"
          >
            <span class="counter padd">{{
              showThousund(this.userProfile.following_count, 1)
            }}</span>
            <span class="counter-title">{{
              $t("half_profile_page.following")
            }}</span>
          </router-link>
        </div>

        <div class="diamond-count-column">
          <div class="counter padd">
            <span>{{ showThousund(this.userProfile.diamonds) }}</span>
          </div>
          <div class="counter-title">
            <img class="width-15" src="@/assets/img/gems.svg" />
            {{ $t("half_profile_page.diamonds") }}
          </div>
        </div>

        <div class="send-count-column">
          <div class="counter padd">
            <span>{{ showThousund(this.userProfile.coins) }}</span>
          </div>
          <div class="counter-title">
            <img src="@/assets/img/coin-half-profile.svg" alt="" />
            {{ $t("half_profile_page.sent") }}
          </div>
        </div>
      </div>
      <div class="footer-button-columns">
        <v-btn
          v-if="userProfile.user_id !== ownProfile.user.user_id"
          class="footer-button-message"
          @click="checkLoginSendMessageClick(userProfile.user_id)"
        >
          <img
            class="message-svg"
            :src="require(`@/assets/img/message-icon_${brandName}.svg`)"
            :alt="$t('general.send_message_button')"
          />
          <span>{{ $t("half_profile_page.message") }}</span>
        </v-btn>

        <v-btn
          v-if="this.userProfile.is_followed === false"
          class="footer-button-follow"
          :loading="isFollowLoading"
          @click="clickFollowUser()"
        >
          <img
            class="follow-svg"
            :src="require(`@/assets/img/follow-icon_${brandName}.svg`)"
            alt="follow svg"
          />
          <span>{{ $t("half_profile_page.follow") }}</span>
        </v-btn>
        <v-btn
          v-if="this.userProfile.is_followed === true"
          :loading="isFollowLoading"
          class="footer-button-unfollow"
          @click="clickUnfollowUser()"
        >
          <img
            class="unfollow-svg"
            src="@/assets/img/unfollow.svg"
            alt="follow svg"
          />
          <span>{{ $t("half_profile_page.unfollow") }}</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "HalfProfile.scss";
</style>
<script>
import { mapState, mapActions } from "vuex";
import DownloadAppDialog from "@/components/DownloadAppDialog/DownloadAppDialog.vue";
import { displayCountry } from "@/helper/displaycountry.js";
import {
  generateBase64Url,
  showThousund,
  returnVipString,
} from "@/helper/index.js";
import { FollowPageEnums } from "@/helper/enums.js";
import LevelBadge from "@/components/Shared/LevelBadge/LevelBadge.vue";

export default {
  name: "HalfProfile",
  data() {
    return {
      FollowPageEnums,
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.client.halfProfileUser,
      ownProfile: (state) => state.client.ownProfile,
      isFollowLoading: (state) => state.client.isFollowLoading,
      brandName: (state) => state.brandName,
    }),
  },
  methods: {
    ...mapActions("client", [
      "logout",
      "setShowHalfProfile",
      "setShowFullProfile",
      "followUser",
      "unfollowUser",
      "getUserMessageInfo",
      "checkLoginSendMessageClick",
    ]),
    returnVipString,
    showThousund: showThousund,
    displayCountry,
    generateBase64Url,
    showDownloadApp() {
      this.$buefy.modal.open({
        parent: this,
        component: DownloadAppDialog,
        "has-modal-card": true,
        trapFocus: true,
      });
    },
    clickFollowUser() {
      this.followUser({
        user_id: this.userProfile.user_id,
        page_enum: FollowPageEnums.OTHER_PROFILE,
      });
    },
    clickUnfollowUser() {
      this.unfollowUser({
        user_id: this.userProfile.user_id,
        page_enum: FollowPageEnums.OTHER_PROFILE,
      });
    },
  },
  components: {
    LevelBadge,
  },
};
</script>
