<template>
  <div>
    <div class="page-left-container notif-left">
      <DiscoverLeft />
    </div>
    <div class="page-right-container notif-right">
      <Notifications />
      <Discover />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "NotificationsView.scss";
</style>
<script>
import Notifications from "@/components/Notifications/Notifications.vue";
import Discover from "@/components/Discover/Discover.vue";
import DiscoverLeft from "@/components/Discover/DiscoverLeft.vue";

export default {
  name: "NotificationsView",
  components: {
    Notifications,
    DiscoverLeft,
    Discover,
  },
};
</script>
