<template>
  <div class="write-message">
    <i class="fa fa-comment-alt"></i>
    <input
      name="message"
      placeholder="Birşeyler yaz..."
      v-on:keyup="inputKeyup"
      v-on:focus="inputFocus"
      v-on:blur="inputBlur"
      v-model="msg"
      ref="messageInput"
      autocomplete="off"
    />
    <v-btn
      v-if="msg"
      class="send-message-button"
      @click="sendMessageAction"
      depressed
      rounded
      small
    >
      <img src="@/assets/img/send-arrow.svg" alt="Send Message" />
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
@import "SendMessage.scss";
</style>

<script>
import { generateUUID } from "@/helper/index.js";
import { mapState, mapActions } from "vuex";
const endpoints = require("../../api/endpoints");

export default {
  name: "SendMessage",
  data() {
    return {
      msg: "",
    };
  },
  computed: {
    ...mapState({
      livestreamId: (state) => state.live.livestreamId,
      rtmChannel: (state) => state.live.rtmChannel,
    }),
  },
  mounted() {},
  methods: {
    ...mapActions("client", ["checkLoggedInByModal"]),
    ...mapActions("live", ["handleChannelMessageContent"]),
    inputKeyup(e) {
      if (e.keyCode === 13 && this.msg) {
        this.sendMessageAction();
      }
    },
    inputFocus(e) {
      this.$emit("disableFullScreen", true);
      if (navigator.userAgent.indexOf("iPhone OS 15") != -1) {
        setTimeout(() => {
          window.scrollTo(0, 270);
        }, 250);
      }
    },
    sendMessageByButton() {
      this.$refs.messageInput.focus();
      this.sendMessageAction();
    },
    inputBlur() {
      this.$emit("disableFullScreen", false);
    },
    sendMessageAction() {
      const that = this;
      this.$refs.messageInput.focus();

      this.checkLoggedInByModal()
        .then((res) => {
          const guid = generateUUID();
          const messageToSend = this.msg;
          this.msg = "";
          endpoints
            .livestream_send_message(this.$axios, {
              guid,
              text: messageToSend,
              livestream_id: this.livestreamId,
            })
            .then((res) => {
              this.$refs.messageInput.focus();

              const { success, agora_events } = res.data;

              if (success) {
                const messageBody = {
                  text: JSON.stringify(agora_events[0]),
                };
                that.rtmChannel
                  .sendMessage(messageBody)
                  .then(() => {
                    const msgContent = agora_events[0];
                    msgContent.data = JSON.parse(msgContent.data);
                    this.handleChannelMessageContent(msgContent);
                  })
                  .catch((error) => {
                    this.$toast.error(
                      "An error occurred while sending the message."
                    );
                  });
              } else {
                let errMsg = "";
                //Error code for muted user
                if (res.data.error.code === 7) {
                  errMsg = this.$i18n.t("livestream.muted_by_streamer");
                } else {
                  errMsg =
                    res.data.error.message ||
                    "An error occurred while sending the message.";
                }
                this.$toast.error(errMsg);
              }
            });
        })
        .catch((err) => {
          console.error("You should logged in to contuniue!");
        });
    },
  },
};
</script>
