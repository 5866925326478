<template>
  <v-container class="notif-page-container">
    <v-container class="notif-header">
      <div class="notif-header-back-button">
        <v-btn class="back-button-header" @click="goBack">
          <img
            class="back-button-img"
            :src="require(`@/assets/img/back-button_${brandName}.svg`)"
            :alt="$t('general.back_button')"
          />
        </v-btn>
      </div>
      <div class="notif-header-container">
        <v-icon v-if="$vuetify.breakpoint.width > 1024" class="notif-icon"
          >fas fa-bell</v-icon
        >
        <span class="notif-header-title ml-1">{{
          $t("notifications_page.notifications")
        }}</span>
      </div>
    </v-container>
    <div class="notif-content-wrapper" v-on:scroll.passive="handleScroll">
      <v-list>
        <div v-for="(item, index) in unseenNotification" :key="`${index}`">
          <NotificationCard :item="item" :brandName="brandName" />
          <v-divider class="profile-seperator mx-1"></v-divider>
        </div>
      </v-list>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
@import "Notifications.scss";
</style>

<script>
import { mapState, mapActions } from "vuex";
import NotificationCard from "./NotificationCard.vue";
export default {
  name: "Notifications",
  components: {
    NotificationCard,
  },
  computed: {
    ...mapState({
      unseenNotification: (state) => state.client.unseenNotification,
      unseenNotificationMeta: (state) => state.client.unseenNotificationMeta,
      brandName: (state) => state.brandName,
    }),
  },
  mounted() {
    this.getNotifications({ next: null });
  },
  methods: {
    ...mapActions("client", ["getNotifications"]),
    goBack() {
      this.$router.go(-1);
    },
    handleScroll(event) {
      if (
        event.target.offsetHeight + event.target.scrollTop >=
          event.target.scrollHeight &&
        this.unseenNotificationMeta !== null &&
        this.unseenNotificationMeta !== undefined
      ) {
        this.getNotifications({
          next: this.unseenNotificationMeta,
        });
      }
    },
  },
};
</script>
