<template>
  <div class="login container is-fluid">
    <div class="columns">
      <div class="column is-6 left-side">
        <div class="in-column">
          <onboarding />
        </div>
      </div>
      <div class="column is-6 right-side" v-if="brandName == 'superlive'">
        <div class="in-column">
          <div class="columns">
            <div class="column is-6">
              <div class="screenshot">
                <img
                  src="@/assets/img/phone-mockup.png"
                  v-bind:alt="$t('login_page.app_screenshot', { brandName })"
                />
              </div>
            </div>
            <div class="column is-6 info">
              <h1>{{ $t("login_page.slogan_1") }}</h1>
              <p>{{ $t("login_page.slogan_2") }}</p>
              <div class="download-container">
                <div class="download-on-apple">
                  <a href="https://sprlv.link/web/store/apple" target="_blank">
                    <img
                      src="@/assets/img/download-on-apple.png"
                      alt="Download on apple"
                    />
                  </a>
                </div>
                <div class="download-on-google">
                  <a
                    href="https://sprlv.link/web/store/google-play"
                    target="_blank"
                  >
                    <img
                      src="@/assets/img/download-on-google.png"
                      alt="Download on google"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "./LoginView.scss";
</style>
<script>
import Onboarding from "@/components/Login/Onboarding/Onboarding.vue";
import { mapState } from "vuex";

export default {
  name: "OnboardingView",
  components: {
    Onboarding,
  },
  computed: {
    ...mapState({
      brandName: (state) => state.brandName,
    }),
  },
};
</script>
