<template>
  <div>
    <div
      id="app"
      v-bind:class="{
        'is-login': onboarding,
        'is-mobile-profile': mobileProfile || mobileChatActive === true,
        'is-hybrid-page': isHybridPage,
      }"
      data-app
      v-if="!forbidden && !registering"
    >
      <Navigation v-if="!isHybridPage" />
      <div
        :class="
          !$route.path.includes('/profile') && !mobileChatActive
            ? 'page-container'
            : 'page-container mobile-padding'
        "
      >
        <router-view />
        <login-modal-view />
        <buy-coins-modal-view />
      </div>
    </div>
    <div class="registering" v-if="registering">
      <v-overlay color="#fff">
        <v-progress-circular
          color="#999"
          indeterminate
          size="75"
        ></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<style lang="scss">
@import "./assets/styles/App.scss";
</style>

<script>
import { mapState, mapActions } from "vuex";

import Navigation from "@/components/Navigation/Navigation.vue";
import LoginModalView from "@/views/Login/LoginModalView.vue";
import BuyCoinsModalView from "@/views/BuyCoins/BuyCoinsModalView.vue";
import PayPal from "@/paypal";

const helpers = require("@/api/helpers");

export default {
  components: {
    Navigation,
    LoginModalView,
    BuyCoinsModalView,
  },
  computed: {
    ...mapState({
      isHybridPage: (state) => state.client.isHybridPage,
      isLoggedIn: (state) => !!state.client.authToken,
      authToken: (state) => state.client.authToken,
      loaderState: (state) => state.live.loaderState,
      brandName: (state) => state.brandName,
      brandTitle: (state) => state.brandTitle,
      deviceId: (state) => state.client.deviceId,
      mobileChatActive: (state) => state.client.mobileChatActive,
      conversationId: (state) => state.client.conversationId,
      showEditProfile: (state) => state.client.showEditProfile,
      forbidden: (state) => state.forbidden,
      registering: (state) => state.registering,
      settings: (state) => state.client.settings,
    }),
    onboarding() {
      return this.$route.name == "OnboardingView";
    },
    mobileProfile() {
      return this.$route.name == "Profile";
    },
  },
  methods: {
    ...mapActions("client", [
      "getSettings",
      "setDeviceId",
      "setAuthToken",
      "setRegistered",
      "getOwnProfile",
      "facebookPixelInit",
      "adjustInit",
      "setMobileChatActive",
      "setConversationIdProfile",
      "editProfile",
      "setShowRemoveImgModal",
    ]),
    ...mapActions(["setForbidden", "setRegistering"]),
    ...mapActions("live", [
      "connectWebsocket",
      "getDiscover",
      "browserStateCheckBindings",
    ]),
    toggleMenu() {
      this.$store.dispatch("setMenuOpened", !this.$store.getters.isMenuOpened);
    },
  },
  watch: {
    $route() {
      this.$store.dispatch("setMenuOpened", false);
      this.$store.dispatch("addHistory", window.location.pathname);
      if (!this.$route.path.includes("/messages")) {
        this.setMobileChatActive(false);
      }
      if (this.showEditProfile && !this.$route.path.includes("/profile")) {
        this.editProfile(false);
        this.setShowRemoveImgModal(false);
      }
    },
  },
  created() {
    /**
     * This fragment was moved from beforeCreate() to created()
     * because the variable isHybridPage is undefined in the beforeCreate() method.
     **/
    let theURL = new URL(location.href);
    let TheURLSearchParams = theURL.searchParams;
    this.$store.dispatch("addHistory", window.location.pathname);
    if (
      !this.isHybridPage &&
      !TheURLSearchParams.get("auth_token") &&
      !TheURLSearchParams.get("device_id")
    ) {
      helpers
        .preLogin(this.$axios)
        .then(({ guid, loggedIn }) => {
          this.setRegistering(false);

          if (loggedIn) {
            this.getOwnProfile();
          } else {
            this.setAuthToken(null);
          }

          if (guid !== this.deviceId) {
            this.setDeviceId(guid);
            this.facebookPixelInit();
            this.adjustInit();
          }

          this.getDiscover(this.loaderState);

          this.setRegistered(true);
          this.getSettings().then(() => {
            this.connectWebsocket();
          });
        })
        .catch((err) => {
          this.setRegistering(false);
          if (err.error.code === 57) {
            this.setForbidden(true);
          }
          this.setDeviceId(null);
        });
    } else {
      this.setRegistering(false);
      PayPal.initialize();
    }
    this.browserStateCheckBindings();
  },
};
</script>
<style>
@font-face {
  font-family: "Avenir-Heavy";
  src: url("./assets/fonts/Avenir/woff/Avenir-Heavy-05.woff") format("woff"),
    url("./assets/fonts/Avenir/ttf/Avenir-Heavy-05.ttf") format("truetype");
}
@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/Avenir/woff/Avenir-Medium-09.woff") format("woff"),
    url("./assets/fonts/Avenir/ttf/Avenir-Medium-09.ttf") format("truetype");
}
</style>
