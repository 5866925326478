<template>
  <div
    class="vip-message-container"
    :class="[
      {
        'show-gift': showVipMessageClass,
      },
      returnVipString(activeVipMessage.vip_state),
    ]"
  >
    <user-image-circle :user="activeVipMessage" :size="'40px'" />

    <div class="user-info">
      <div class="user-name-and-badge">
        <span class="name">{{ activeVipMessage.name }}</span>
        <span v-if="returnVipString(activeVipMessage.vip_state)">
          <img
            :src="
              require(`@/assets/img/${returnVipString(
                activeVipMessage.vip_state
              )}-vip.svg`)
            "
            class="vip-badge"
          />
        </span>
      </div>
      <span class="vip-message-text">{{ activeVipMessage.text }}</span>
    </div>
  </div>
</template>

<script>
import { returnVipString } from "@/helper/index.js";
import { mapState } from "vuex";
import UserImageCircle from "@/components/Shared/UserImageCircle/UserImageCircle.vue";

export default {
  name: "VipMessage",
  components: {
    UserImageCircle,
  },
  computed: {
    ...mapState({
      activeVipMessage: (state) => state.live.activeVipMessage,
      showVipMessageClass: (state) => state.live.showVipMessageClass,
    }),
  },
  methods: {
    returnVipString,
  },
};
</script>

<style lang="scss" scoped>
@import "VipMessage.scss";
</style>
