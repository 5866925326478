<template>
  <v-container class="edit-profile-container" v-if="showEditProfile === true">
    <v-container
      class="edit-profile-bg-layer"
      @click="closeModals"
    ></v-container>
    <v-container class="edit-profile-wrapper">
      <v-container class="edit-header-container">
        <v-container class="edit-header-title-container">
          <span class="edit-header-title">{{
            $t("edit_profile_page.edit_title")
          }}</span>
        </v-container>
        <v-container class="edit-header-back-button-container">
          <v-btn
            depressed
            class="back-button-header"
            @click="editProfile(false)"
          >
            <i class="fa fa-times back-button-icon"></i>
          </v-btn>
        </v-container>
        <v-container class="edit-header-back-arrow-container">
          <v-btn
            depressed
            class="back-button-header"
            @click="editProfile(false)"
          >
            <img
              class="back-arrow-img"
              :src="require(`@/assets/img/back-button_${brandName}.svg`)"
              :alt="$t('general.back_button')"
            />
          </v-btn>
        </v-container>
      </v-container>
      <v-container class="edit-content-wrapper">
        <v-container class="content-header-container">
          <span class="content-header-title">
            {{ $t("edit_profile_page.content_title") }}
          </span>
          <span class="content-header-info">
            {{ $t("edit_profile_page.content_info") }}
          </span>
        </v-container>
        <v-row class="edit-content-container">
          <v-col
            v-for="(item, index) in uploadImageIds"
            :key="`${index}`"
            cols="12"
            sm="4"
            :data-dropzone-index="`${index}`"
            class="edit-list dropzone"
            @drop="drop($event, index)"
            @dragenter.prevent
            @dragover.prevent
          >
            <v-btn
              fab
              x-small
              depressed
              class="remove-profile pa-0"
              @click="setModalAndUploadImgId(item)"
            >
              <img src="@/assets/img/remove-profile-img.svg" />
            </v-btn>
            <v-card
              draggable="true"
              @dragstart="startDrag($event, item)"
              outlined
              tile
              class="item-cards pa-2"
            >
              <v-img
                class="card-img"
                :src="item.thumbnail_url"
                :alt="$t('edit_profile_page.profile_img')"
              ></v-img>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            xs="4"
            class="edit-list"
            v-for="(n, index) in uploaderCount"
            :key="`u${n}${index}`"
          >
            <v-card outlined tile class="item-cards pa-2">
              <label v-if="isUploadImgLoading === false" class="upload-label">
                <img
                  class="upload-svg"
                  src="@/assets/img/upload-img.svg"
                  :alt="$t('labels.upload_profile_photo')"
                />
                <input
                  type="file"
                  @change="handleImage"
                  :class="
                    uploaderId === index
                      ? 'card-img upload-input active'
                      : 'card-img upload-input'
                  "
                  accept="image/png, image/jpg, image/jpeg"
                />
              </label>
              <div v-if="isUploadImgLoading === true" class="loader-container">
                <div class="overlay">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-container class="content-info-tag-container">
          <span class="info-tag">{{
            $t("edit_profile_page.content_info_tag")
          }}</span>
        </v-container>
        <v-container class="user-content-container">
          <v-row class="user-content-row">
            <v-col cols="12" md="2" class="user-name-col pb-md-2">
              <span class="user-name">{{ $t("edit_profile_page.name") }}</span>
            </v-col>
            <v-col cols="12" md="10" class="user-name-input-col pb-md-2">
              <input v-model="name" class="user-name-input" maxlength="20" />
            </v-col>
          </v-row>
          <v-row class="user-content-row">
            <v-col cols="12" md="2" class="user-name-col">
              <span class="user-name">{{
                $t("edit_profile_page.date_of_birth")
              }}</span>
            </v-col>
            <v-col cols="12" md="5" class="user-name-input-col">
              <input
                class="user-name-input"
                disabled
                :value="moment(ownProfile.user.birthday).format('YYYY-M-D')"
              />
            </v-col>
            <v-col cols="12" md="2" class="user-gender-col">
              <span class="user-name">{{
                $t("edit_profile_page.gender")
              }}</span>
            </v-col>
            <v-col cols="12" md="3" class="user-name-input-col">
              <input
                class="user-name-input"
                disabled
                :value="
                  ownProfile.user.gender === GenderEnums.UNKNOWN
                    ? 'Unknown'
                    : ownProfile.user.gender === GenderEnums.MALE
                    ? 'Male'
                    : ownProfile.user.gender === GenderEnums.FEMALE
                    ? 'Female'
                    : ''
                "
              />
            </v-col>
          </v-row>
          <v-row class="user-content-row">
            <v-col cols="12" md="2" class="user-name-col">
              <span class="user-name">{{ $t("edit_profile_page.bio") }}</span>
            </v-col>
            <v-col cols="12" md="10" class="user-name-input-col">
              <input class="user-name-input" v-model="bio" maxlength="80" />
            </v-col>
          </v-row>
          <v-row class="user-content-row">
            <v-col cols="12" md="2" class="user-name-col">
              <span class="user-name">{{ $t("edit_profile_page.id") }}</span>
            </v-col>
            <v-col cols="12" md="10" class="user-name-input-col">
              <input
                disabled
                class="user-name-input"
                :value="ownProfile.user.shared_id"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-container>
      <v-container class="button-container">
        <v-row class="btn-row">
          <v-col class="btn-col">
            <v-btn
              @click="
                updateProfileDetails({
                  uploadedName: name,
                  uploadedBio: bio,
                  uploadedUserId: ownProfile.user.user_id,
                })
              "
              class="save-button"
            >
              <span class="button-title">{{ $t("labels.save") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="button-container-mobile">
        <v-row class="btn-row">
          <v-col class="btn-col">
            <v-btn
              @click="
                updateProfileDetails({
                  uploadedName: name,
                  uploadedBio: bio,
                  uploadedUserId: ownProfile.user.user_id,
                })
              "
              text
              class="save-button"
            >
              <span class="button-title">{{ $t("labels.save") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <remove-img v-if="showRemoveImgModal === true" />
    </v-container>
  </v-container>
</template>
<style lang="scss" scoped>
@import "EditProfile.scss";
</style>
<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import { GenderEnums } from "@/helper/enums.js";
import RemoveImg from "@/components/Profiles/EditProfile/RemoveImg/RemoveImg.vue";

const endpoints = require("../../../api/endpoints");

export default {
  name: "EditProfile",
  data() {
    return {
      GenderEnums,
      name: null,
      bio: null,
      imageDataForUpload: [],
      uploaderId: null,
      selectedImgId: null,
    };
  },
  components: {
    RemoveImg,
  },
  computed: {
    uploaderCount: function () {
      return 6 - this.uploadImageIds.length;
    },
    ...mapState({
      ownProfile: (state) => state.client.ownProfile,
      showEditProfile: (state) => state.client.showEditProfile,
      showRemoveImgModal: (state) => state.client.showRemoveImgModal,
      isUploadImgLoading: (state) => state.client.isUploadImgLoading,
      uploadImageIds: (state) => state.client.uploadImageIds,
      brandName: (state) => state.brandName,
    }),
  },
  watch: {
    ownProfile() {
      this.name = this.ownProfile.user.name;
      this.bio = this.ownProfile.user.bio;
      this.setUploadImageIds(this.ownProfile.user.profile_images);
    },
  },
  mounted() {
    this.setUploadImageIds(this.ownProfile.user.profile_images);
    this.name = this.ownProfile.user.name;
    this.bio = this.ownProfile.user.bio;
  },
  methods: {
    ...mapActions("client", [
      "editProfile",
      "getOwnProfileForEdit",
      "getSelectedUser",
      "setShowRemoveImgModal",
      "uploadProfileImages",
      "setUploadImageIds",
      "setIsUploadImgLoading",
      "updateProfileDetails",
      "setUploadSelectedImgId",
    ]),
    moment,
    handleImage(e) {
      this.imageSizeError = false;
      let selectedImage = e.target.files[0];
      if (selectedImage.size / 1000 / 1000 > 3) {
        this.imageSizeError = true;
        this.$toast.error(this.$t("edit_profile_page.image_size_error"));
      } else {
        this.uploadProfileImages(selectedImage);
      }
    },
    startDrag(event, item) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("itemID", item.image_id);
    },
    arraymove(arr, fromIndex, toIndex) {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
      return arr;
    },
    drop(event, index) {
      const itemID = event.dataTransfer.getData("itemID");
      const oldIndex = this.uploadImageIds.findIndex(
        (item) => item.image_id === itemID
      );
      this.setUploadImageIds(
        this.arraymove([...this.uploadImageIds], oldIndex, index)
      );
    },
    closeModals() {
      this.editProfile(false);
      this.setShowRemoveImgModal(false);
    },
    setModalAndUploadImgId(item) {
      this.setShowRemoveImgModal(true);
      this.setUploadSelectedImgId(item.image_id);
    },
  },
};
</script>
