var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      !_vm.$route.path.includes('/leaderboard') &&
      !_vm.$route.path.includes('/notifications') &&
      !_vm.$route.path.includes('/my-level')
    )?_c('v-container',{staticClass:"discover-container"},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-row',{staticClass:"discover-container-row"},[_c('v-col',[_c('h1',{staticClass:"suggested-live-stream-header"},[_c('img',{attrs:{"alt":"live tv icon","src":require(("@/assets/img/live-tv-icon_" + _vm.brandName + ".svg"))}}),_vm._v(" "+_vm._s(_vm.$t("discover_page.suggested_live_stream"))+" ")])])],1):_vm._e(),_c('v-col',{staticClass:"discover-container-col"},[_c('v-row',{staticClass:"discover-container-stream-box"},_vm._l((_vm.discoverStreams),function(streamItem){return _c('v-card',{key:streamItem &&
            streamItem.stream_details &&
            streamItem.stream_details.livestream_id,staticClass:"special-square-column discover-card"},[_c('stream-card',{attrs:{"streamItem":streamItem},on:{"clicked":function($event){return _vm.setLiveStreamSource('discover')}}})],1)}),1)],1),_c('infinite-loading',{attrs:{"spinner":"bubbles"},on:{"infinite":_vm.infiniteLoader}},[_c('div',{staticClass:"infinite-no-more",attrs:{"slot":"no-more"},slot:"no-more"},[_vm._v(" "+_vm._s(_vm.$t("discover_page.infinite_no_more"))+" ")]),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})])],1):_c('v-container',{staticClass:"discover-container-else"},[_c('v-row',[_c('v-col',{attrs:{"lg":"12","md":"12","sm":"12","xs":"12"}},[_c('v-row',_vm._l((_vm.discoverStreams),function(streamItem){return _c('div',{key:streamItem &&
              streamItem.stream_details &&
              streamItem.stream_details.livestream_id,staticClass:"special-square-column"},[_c('stream-card',{attrs:{"streamItem":streamItem},on:{"clicked":function($event){return _vm.setLiveStreamSource('leaderboard_feed')}}})],1)}),0)],1)],1),_c('infinite-loading',{attrs:{"spinner":"bubbles"},on:{"infinite":_vm.infiniteLoader}},[_c('div',{staticClass:"infinite-no-more",attrs:{"slot":"no-more"},slot:"no-more"},[_vm._v(" "+_vm._s(_vm.$t("discover_page.infinite_no_more"))+" ")]),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }