<template>
  <div>
    <div class="page-left-container">
      <LivestreamLeft />
    </div>
    <div id="LiveStreamView" class="page-right-container">
      <LiveStream />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "LivestreamView.scss";
</style>
<script>
import LiveStream from "@/components/LiveStream/LiveStream.vue";
import LivestreamLeft from "@/components/LiveStream/LivestreamLeft.vue";

export default {
  name: "LivestreamView",
  components: {
    LiveStream,
    LivestreamLeft,
  },
};
</script>
