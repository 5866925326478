<template>
  <div class="columns is-multiline">
    <div class="back-button">
      <v-btn
        icon
        class="back-button-item"
        @click="setActiveLoginComponent('social-login')"
      >
        <v-icon class="fas fa-arrow-left fa-2x" />
      </v-btn>
    </div>
    <div class="column is-12">
      <h1 class="login-header">{{ $t("login_page.login_with_email") }}</h1>
      <div
        class="sub-title"
        v-html="$t('login_page.enter_email_password')"
      ></div>
    </div>
    <div class="column is-12" :class="{ error: validation.hasError('email') }">
      <label for="email">{{ $t("labels.email") }}</label>
      <input
        type="text"
        class="input"
        placeholder="example@example.com"
        v-model="email"
      />
      <div class="validation-error-message">
        {{ validation.firstError("email") }}
      </div>
    </div>
    <div
      class="column is-12"
      :class="{ error: validation.hasError('password') }"
    >
      <label for="password">{{ $t("labels.password") }}</label>
      <input
        type="password"
        class="input"
        id="password"
        v-model="password"
        :placeholder="$t('labels.password')"
      />
      <div class="validation-error-message">
        {{ validation.firstError("password") }}
      </div>
    </div>
    <div class="column is-12">
      <label for="aggrement" class="custom-check">
        <div class="checkbox-container">
          <input
            type="checkbox"
            id="aggrement"
            v-model="aggrement"
            name="aggrement"
          />
          <span class="checkmark"></span>
        </div>
        <span
          class="custom-check-label"
          v-html="$t('login_page.login_aggrement')"
        ></span>
      </label>
      <div
        class="validation-error-message"
        v-if="validation.hasError('aggrement')"
      >
        {{ $t("errors.login_aggrement_check") }}
      </div>
    </div>
    <div class="column is-12">
      <button
        class="sign-up-email special-button primary-button"
        :disabled="loading"
        @click="login"
      >
        {{ $t("labels.login") }}
      </button>
      <div class="lds-ellipsis" v-if="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="validation-error-message" v-if="loginError">
        {{ loginError }}
      </div>
    </div>
    <div class="column is-12">
      <div class="forgot-password">
        <button
          class="btn-link"
          @click="setActiveLoginComponent('forgot-password')"
        >
          {{ $t("labels.forgot_password") }}
        </button>
        <!-- TODO: add for register email -->
        <!-- |
        <button
          class="btn-link"
          @click="setActiveLoginComponent('email-register')"
        >
          {{ $t("labels.register") }}
        </button> -->
      </div>
    </div>
  </div>
</template>
<script>
"use strict";
import SimpleVueValidation from "simple-vue-validator";
import { mapActions, mapState } from "vuex";
import { signupAttemptEvent } from "@/helper/marketingevents.js";
import { signupUuid } from "@/helper/index.js";

const Validator = SimpleVueValidation.Validator;
const endpoints = require("../../../api/endpoints");
const helpers = require("../../../api/helpers");

export default {
  name: "EmailLogin",
  data: () => {
    return {
      email: "",
      password: "",
      aggrement: false,
      loading: false,
      loginError: null,
    };
  },
  computed: {
    ...mapState({
      deviceId: (state) => state.client.deviceId,
    }),
  },
  mounted() {},
  validators: {
    email: function (value) {
      return Validator.value(value).required().email();
    },
    password: function (value) {
      return Validator.value(value).required();
    },
    aggrement: function (value) {
      return Validator.custom(function () {
        if (!value) {
          return "Required";
        }
      });
    },
  },
  methods: {
    ...mapActions("client", ["setActiveLoginComponent"]),
    login() {
      // Marketing event
      signupAttemptEvent(this.deviceId, signupUuid(), "email");
      this.loginError = false;
      let that = this;
      this.$validate().then(function (success) {
        if (success) {
          that.loading = true;
          let loginData = { email: that.email, password: that.password };
          endpoints
            .login_email(that.$axios, loginData)
            .then((emailLoginResponse) => {
              emailLoginResponse = emailLoginResponse.data;
              that.loading = false;
              if (emailLoginResponse.error) {
                that.loginError = that.$i18n.t(
                  "errors.login_error_" + emailLoginResponse.error.code
                );
              } else {
                that.$store.dispatch("client/postLogin", {
                  authToken: emailLoginResponse.token,
                  redirectUrl: "/",
                  loginFrom: "email",
                });
              }
            });
        }
      });
    },
  },
};
</script>
