<template>
  <div
    :class="
      this.isDiamondActive === true
        ? 'leader-board-container'
        : 'leader-board-container anonymous'
    "
  >
    <div class="leader-board-title-container">
      <div class="back-button-container">
        <button class="back-button-header" @click="goBack">
          <img
            class="back-button-img"
            src="@/assets/img/black-back-button.svg"
            :alt="$t('general.back_button')"
          />
        </button>
      </div>
      <div class="leader-board-header-container">
        <span class="leader-board-header">{{
          $t("leaderboard_page.title")
        }}</span>
      </div>
      <div class="leader-board-item">
        <button
          @click="getInitialLeaderBoard()"
          :class="
            this.isDiamondActive === true
              ? 'board-item-diamond active-board'
              : 'board-item-diamond'
          "
        >
          {{ $t("leaderboard_page.diamonds") }}
        </button>
        <button
          @click="getInitialGiftersLeaderBoard()"
          :class="
            this.isDiamondActive === false
              ? 'board-item-gift-senders active-board'
              : 'board-item-gift-senders'
          "
        >
          {{ $t("leaderboard_page.gifters") }}
        </button>
      </div>
    </div>
    <leader-board-diamond v-if="isLeaderBoardShow === true" />
    <leader-board-gifters v-if="isGiftersLeaderBoardShow === true" />
  </div>
</template>
<style lang="scss" scoped>
@import "LeaderBoard.scss";
</style>
<script>
import { mapState, mapActions } from "vuex";
import LeaderBoardDiamond from "@/components/LeaderBoard/LeaderBoardDiamond/LeaderBoardDiamond.vue";
import LeaderBoardGifters from "@/components/LeaderBoard/LeaderBoardGifters/LeaderBoardGifters.vue";
import { LeaderBoardEnums } from "@/helper/enums.js";

export default {
  name: "LeaderBoard",
  data() {
    return {
      isDiamondActive: true,
    };
  },
  computed: {
    ...mapState({
      isLeaderBoardShow: (state) => state.client.isLeaderBoardShow,
      isGiftersLeaderBoardShow: (state) =>
        state.client.isGiftersLeaderBoardShow,
    }),
  },
  components: {
    LeaderBoardDiamond,
    LeaderBoardGifters,
  },
  methods: {
    ...mapActions("client", ["getLeaderBoard", "getGiftersLeaderBoard"]),
    getInitialLeaderBoard() {
      this.isDiamondActive = true;
      this.getLeaderBoard({
        leaderboard_type: LeaderBoardEnums.DAILY,
      });
    },
    getInitialGiftersLeaderBoard() {
      this.isDiamondActive = false;
      this.getGiftersLeaderBoard({
        leaderboard_type: LeaderBoardEnums.DAILY,
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.getLeaderBoard({
      leaderboard_type: LeaderBoardEnums.DAILY,
    });
  },
};
</script>
