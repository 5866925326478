// @ts-check

const axios = require("axios").default;

/**
 * @typedef ApiAxiosInstanceOptions
 * @property {string} [base_url=null]
 * @property {string} [device_id=null]
 * @property {string} [auth_token=null]
 * @property {Object<string, *>} [base_request_data=null]
 */

/**
 * @param {ApiAxiosInstanceOptions} options
 */
function getHybridAxiosInstance(options) {
  const { base_url, device_id, auth_token, base_request_data } = options;

  let common_headers = {};
  if (auth_token != null) {
    common_headers["Authorization"] = `Token ${auth_token}`;
  }
  if (device_id != null) {
    common_headers["Device-ID"] = device_id;
  }
  this.common_headers = common_headers;

  const instance = axios.create({
    baseURL: base_url,
    headers: {
      // NOTE(baris): Adding auth and device_id headers here.
      common: this.common_headers,
      post: {
        "Content-Type": "application/json",
      },
    },
    transformRequest: [
      function (data) {
        // "Do whatever you want to transform the data" - Axios

        // NOTE(baris): Adding request base data here.
        return Object.assign({ from_web: true }, base_request_data, data);
      },
      // @ts-ignore
      ...axios.defaults.transformRequest,
    ],
    validateStatus: function (status) {
      return status != null && status >= 200 && status < 500;
    },
  });

  // ...

  return instance;
}
module.exports.getHybridAxiosInstance = getHybridAxiosInstance;
