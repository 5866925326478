import ReconnectingWebSocket from "reconnecting-websocket";
import { localStore, STORE_KEYS } from "@/storage";
import router from "@/router";
import mockSocket from "@/mocks/index";
const endpoints = require("@/api/endpoints");
const { v4: uuidv4 } = require("uuid");

const logger = new (require("@/logging").Logger)("store.payment.actions");

/**
 * @param {string} hybridSocketUrl
 * @returns {ReconnectingWebSocket}
 */
function connectToPaymentWebsocket(hybridSocketUrl) {
  let auth_token = localStore.getItem(STORE_KEYS.AUTH_TOKEN);
  let device_id = localStore.getItem(STORE_KEYS.DEVICE_ID);
  let heartbeat = 5;

  // https://github.com/pladaria/reconnecting-websocket#available-options
  /** @type {import('reconnecting-websocket').Options} */
  const options = {
    maxReconnectionDelay: 10000,
    minReconnectionDelay: 500,
    reconnectionDelayGrowFactor: 1.5,
    connectionTimeout: 10000,
    startClosed: false,
  };

  let webSocket;
  if (
    ["development", "test"].includes(process.env.NODE_ENV) &&
    process.env.VUE_APP_WITH_MOCK == "true"
  ) {
    logger.debug("connectToPaymentWebsocket: mock");
    webSocket = new ReconnectingWebSocket(
      "wss://demo.piesocket.com/v3/channel_1?api_key=VCXCEuvhGcBDP7XhiJJUDvR1e1D3eiVjgZ9VRiaV&device=test_123&auth=test_123",
      [],
      options
    );
  } else if (window.paymentSocketConnection == null) {
    webSocket = new ReconnectingWebSocket(
      hybridSocketUrl + "?device=" + device_id + "&auth=" + auth_token,
      [],
      options
    );
  } else {
    logger.debug(
      "connectToPaymentWebsocket: window.paymentSocketConnection exists"
    );
    return window.paymentSocketConnection;
  }

  // Starting and stopping heartbeat on WebSocket open and close
  let interval;
  webSocket.addEventListener("open", () => {
    logger.debug("connectToPaymentWebsocket: open");
    interval = setInterval(() => {
      logger.debug("connectToPaymentWebsocket: heartbeat", interval);
      webSocket.send(
        JSON.stringify({
          id: uuidv4(),
          action: "heartbeat",
          data: { state: "general" },
        })
      );
    }, heartbeat * 1000);
  });
  webSocket.addEventListener("close", () => {
    logger.debug("connectToPaymentWebsocket: close");
    clearInterval(interval);
  });

  window.paymentSocketConnection = webSocket;
  return webSocket;
}

function closePaymentWebSocket() {
  if (window.paymentSocketConnection != null) {
    window.paymentSocketConnection.close();
    window.paymentSocketConnection = null;
  }
}

/**
 * @template D -- request data type
 * @template R -- response data type
 * @callback EndpointFunction
 * @param {import("axios").AxiosInstance} axios
 * @param {D} data
 * @returns {Promise<import("axios").AxiosResponse<R>>}
 */

/**
 * @template D -- request data type
 * @template R -- response data type
 * @param {EndpointFunction<D, R>} endpoint
 * @param {import("axios").AxiosInstance} axios
 * @param {D} data
 * @param {boolean} [auto_retry=false]
 * @returns {Promise<R>}
 */
function axiosErrorHandlingWrapper(endpoint, axios, data, auto_retry = false) {
  return new Promise((resolve, reject) => {
    endpoint(axios, data).then(
      (response) => {
        // Success or error response from server. (status code in 200<=...<500 range)
        resolve(response.data);
      },
      (error) => {
        // 500 status code or failed request (e.g. network)
        if (
          auto_retry &&
          (error.response == null || error.response.status == null)
        ) {
          // One time recursive auto retry.
          resolve(axiosErrorHandlingWrapper(endpoint, axios, data, false));
        } else {
          console.error("axiosErrorHandlingWrapper retry");
          // Throw the error via wrapper Promise.
          reject(error);
        }
      }
    );
  });
}

export const actions = {
  promotionBannerInterval({ commit }, bannerData) {
    // NOTE(purchase-bug-fix-pass): Running a interval for a certain future time is wrong way to do this. Not fixing it for now.
    // NOTE(purchase-bug-fix-pass): This logic interrupts user mid payment. Not fixing it for now.
    const promotionInterval = setInterval(() => {
      if (bannerData.banner.end_time - Date.now() <= 0) {
        commit("setIsPromotionPayment", false);
        commit("setShowPromotionExpired", true);
        clearInterval(promotionInterval);
        router.push({
          path: "/payermax",
        });
      }
    }, 1000);
  },
  setShowPromotionExpired({ commit }, payload) {
    commit("setShowPromotionExpired", payload);
  },
  setFromPromotion({ commit }, payload) {
    commit("setFromPromotion", payload);
  },
  setIsPromotionPayment({ commit }, payload) {
    commit("setIsPromotionPayment", payload);
  },
  setPromotionReferrer({ commit }, payload) {
    commit("setPromotionReferrer", payload);
  },
  setStripeActivePage({ commit }, payload) {
    commit("setStripeActivePage", payload);
  },
  setSelectedPaymentMethod({ commit }, payload) {
    commit("setSelectedPaymentMethod", payload);
  },
  setStripePaymentSucceeded({ commit }, payload) {
    commit("setStripePaymentSucceeded", payload);
  },
  setSelectedProduct({ commit }, payload) {
    commit("setSelectedProduct", payload);
  },
  // NOTE(purchase-bug-fix-pass): Deprecated hybrid payment flow events. Was missing updates and missing event triggers.
  // setHybridPaymentFlowInformation({ commit }, payload) {
  //   if (!payload.flow_id) payload["flow_id"] = uuidv4();
  //   commit("setHybridPaymentFlowInformation", payload);
  // },
  // setHybridPaymentFlowInformationProps({ commit, state }, payload) {
  //   const hybridPaymentFlowInformation = {
  //     ...state.hybridPaymentFlowInformation,
  //     ...payload,
  //   };
  //   commit("setHybridPaymentFlowInformation", hybridPaymentFlowInformation);
  // },
  coinUpdateWebsocket({ commit }, hybridSocketUrl) {
    const webSocket = connectToPaymentWebsocket(hybridSocketUrl);

    webSocket.onmessage = (event) => {
      let socketMessage = null;
      if (process.env.VUE_APP_WITH_MOCK == "true") {
        socketMessage = mockSocket;
      } else {
        socketMessage = JSON.parse(event.data);
      }

      if (socketMessage.type == "invalid_auth") {
        logger.debugError("coinUpdateWebsocket: invalid_auth", socketMessage);
        webSocket.close();
        return;
      }

      if (
        socketMessage.type == "stripe_payment_succeeded" ||
        socketMessage.type == "payermax_payment_succeeded" ||
        socketMessage.type == "native_purchase_succeeded"
      ) {
        // Update payermax coin info
        commit(
          "client/setUserCoinInfo",
          socketMessage.data.products.coin_info,
          { root: true }
        );
      }
    };
  },
  stripePaymentWebsocket(_, hybridSocketUrl) {
    return new Promise((resolve) => {
      const webSocket = connectToPaymentWebsocket(hybridSocketUrl);

      webSocket.onmessage = (event) => {
        // WHEN MESSAGE COME FROM SOCKET
        let socketMessage = JSON.parse(event.data);

        if (socketMessage.type == "invalid_auth") {
          logger.debugError(
            "stripePaymentWebsocket: invalid_auth",
            socketMessage
          );
          webSocket.close();
          return;
        }

        if (socketMessage.data) {
          // PAYMENT SUCCEEDED
          if (socketMessage.type == "stripe_payment_succeeded") {
            return resolve("succeeded");
          }
        }
      };
    });
  },
  paymentStatusWebsocket(_, { url, onConnect = null }) {
    return new Promise((resolve) => {
      const webSocket = connectToPaymentWebsocket(url);
      webSocket.onopen = () => (onConnect != null ? onConnect() : null);
      webSocket.onmessage = (event) => {
        let socketMessage = JSON.parse(event.data);

        if (socketMessage.type == "invalid_auth") {
          logger.debugError(
            "hybridPaymentWebsocket: invalid_auth",
            socketMessage
          );
          webSocket.close();
          return;
        }

        if (
          socketMessage.type === "hybrid_payment_status" &&
          socketMessage.data != null
        ) {
          return resolve(socketMessage);
        }
      };
    });
  },
  postHybridCloseChecks() {
    closePaymentWebSocket();
  },
  stripeDetachPaymentMethod(_, paymentMethodId) {
    return axiosErrorHandlingWrapper(
      endpoints.detach_payment_method,
      this._vm.$axios,
      { payment_method_id: paymentMethodId },
      true
    );
  },
  // Deprecated
  // createPaymentMethod(_, createPaymentMethodData) {
  //   return new Promise((resolve, reject) => {
  //     endpoints
  //       .create_payment_method(this._vm.$axios, createPaymentMethodData)
  //       .then((createPaymentMethodResponse) => {
  //         if (            createPaymentMethodResponse &&
  //           createPaymentMethodResponse.error
  //         ) {
  //           reject(createPaymentMethodResponse.error);
  //         } else {
  //           resolve(createPaymentMethodResponse.data);
  //         }
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },
  stripePayment(_, paymentData) {
    return axiosErrorHandlingWrapper(
      endpoints.confirm_payment,
      this._vm.$axios,
      paymentData,
      false // NOTE(purchase-bug-fix-pass): Backend should handle idempotency better to enable retry.
    );
  },
  reConfirmPayment(_, paymentData) {
    return axiosErrorHandlingWrapper(
      endpoints.reconfirm_payment,
      this._vm.$axios,
      paymentData,
      false // NOTE(purchase-bug-fix-pass): Backend should handle idempotency better to enable retry.
    );
  },
  goComplete() {
    router.push({
      path: "/payermax/payment-complete",
      query: { status: "1" },
    });
  },
  stripePaymentMethods(_, productId) {
    return axiosErrorHandlingWrapper(
      endpoints.payment_methods,
      this._vm.$axios,
      { product_id: productId },
      true
    );
  },
  payermaxSelectCountry(_, country) {
    return axiosErrorHandlingWrapper(
      endpoints.set_payment_country,
      this._vm.$axios,
      { country: country },
      true
    );
  },
  promotionSelectCountry(_, promotionCountryData) {
    return axiosErrorHandlingWrapper(
      endpoints.set_promotion_payment_country,
      this._vm.$axios,
      promotionCountryData,
      true
    );
  },
  payermaxInappPurchaseSettings() {
    return axiosErrorHandlingWrapper(
      endpoints.inapp_purchase_settings,
      this._vm.$axios,
      {},
      true
    );
  },
  promotionInappPurchaseSettings(_, promotionBannerIdData) {
    return axiosErrorHandlingWrapper(
      endpoints.promotion_inapp_purchase_settings,
      this._vm.$axios,
      promotionBannerIdData,
      true
    );
  },
  payermaxPaymentCountries() {
    return axiosErrorHandlingWrapper(
      endpoints.payment_countries,
      this._vm.$axios,
      {},
      true
    );
  },
  getBanner(_, bannerId) {
    return axiosErrorHandlingWrapper(
      endpoints.get_banner,
      this._vm.$axios,
      { banner_id: bannerId },
      true
    );
  },
  payermaxPayment(_, paymentData) {
    return axiosErrorHandlingWrapper(
      endpoints.payermax_payment,
      this._vm.$axios,
      paymentData,
      false // NOTE(purchase-bug-fix-pass): Backend should handle idempotency better to enable retry.
    );
  },
  rapydPayment(_, paymentData) {
    return axiosErrorHandlingWrapper(
      endpoints.rapyd_payment,
      this._vm.$axios,
      paymentData,
      false // NOTE(purchase-bug-fix-pass): Backend should handle idempotency better to enable retry.
    );
  },
  razerPayment(_, paymentData) {
    return axiosErrorHandlingWrapper(
      endpoints.razer_payment,
      this._vm.$axios,
      paymentData,
      false // NOTE(purchase-bug-fix-pass): Backend should handle idempotency better to enable retry.
    );
  },
  trustPayPayment(_, paymentData) {
    return axiosErrorHandlingWrapper(
      endpoints.trustpay_payment,
      this._vm.$axios,
      paymentData,
      false
    );
  },
  getSavedCards(_, data) {
    return axiosErrorHandlingWrapper(
      endpoints.saved_cards,
      this._vm.$axios,
      data,
      false
    );
  },
  deleteSavedCard(_, data) {
    return axiosErrorHandlingWrapper(
      endpoints.delete_saved_card,
      this._vm.$axios,
      data,
      false
    );
  },
  getCoinResellerAccounts(_, body) {
    return axiosErrorHandlingWrapper(
      endpoints.get_coin_reseller_accounts,
      this._vm.$axios,
      body,
      false // NOTE(purchase-bug-fix-pass): Backend should handle idempotency better to enable retry.
    );
  },
  retrievePaymentStatus(_, body) {
    return axiosErrorHandlingWrapper(
      endpoints.retrieve_payment_status,
      this._vm.$axios,
      body,
      true
    );
  },
  getActivePromotionBanners(_, body) {
    return axiosErrorHandlingWrapper(
      endpoints.get_active_promotion_banners,
      this._vm.$axios,
      body,
      false
    );
  },
  paypalPayment(_, body) {
    return axiosErrorHandlingWrapper(
      endpoints.paypal_payment,
      this._vm.$axios,
      body,
      false
    );
  },
  paypalCapture(_, body) {
    return axiosErrorHandlingWrapper(
      endpoints.paypal_capture,
      this._vm.$axios,
      body,
      false
    );
  },
  getPayPalSavedAccounts(_, body) {
    return axiosErrorHandlingWrapper(
      endpoints.paypal_saved_accounts,
      this._vm.$axios,
      body,
      false
    );
  },
  deletePayPalSavedAccount(_, body) {
    return axiosErrorHandlingWrapper(
      endpoints.paypal_delete_saved_account,
      this._vm.$axios,
      body,
      false
    );
  },
};
