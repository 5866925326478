<template>
  <div class="viewercard-container">
    <v-card class="half-viewer-wrapper">
      <v-banner color="#fff" single-line sticky>
        <v-row class="mx-1">
          <v-col align="start">
            <v-row align="center" class="mt-1">
              <v-icon class="eye-icon">fas fa-eye</v-icon>
              <p class="ml-1 font-viewer">
                {{ $t("livestream.total_viewer") }} {{ totalViewers }}
              </p>
            </v-row>
          </v-col>
          <v-col align="end" class="font-viewer-vip">
            Vip: {{ vipViewers }}
          </v-col>
        </v-row>
      </v-banner>
      <v-col class="full-width-height mt-2 mb-2">
        <v-progress-circular
          :size="50"
          indeterminate
          v-if="viewers.length == 0"
        ></v-progress-circular>
        <v-row
          v-for="viewer in orderedViewers"
          :key="viewer.user_id"
          align="start"
          class="user-row ml-1 pb-2"
          @click="showProfile(viewer)"
        >
          <UserImageCircle
            class="user-profile-img"
            :user="viewer"
            size="40px"
          />
          <v-col align="start" class="user-desc-container ml-0">
            <level-badge :user="viewer" :fontSize="'14'"></level-badge>
            <p class="ml-1 user-name">{{ viewer.name }}</p>
            <img
              v-if="viewer.vip_state != 0 && viewer.vip_state != null"
              class="ml-1 mt-1"
              :src="vipAttributes(viewer.vip_state).image"
              :alt="$t('general.vip_icon')"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-card>
    <div class="viewercard-bg" @click="toggleViewerCard"></div>
  </div>
</template>
<style lang="scss" scoped>
@import "ViewerCard.scss";
</style>
<script>
import { mapState, mapActions } from "vuex";
import UserImageCircle from "@/components/Shared/UserImageCircle/UserImageCircle.vue";
import LevelBadge from "@/components/Shared/LevelBadge/LevelBadge.vue";
import { vipAttributes } from "@/helper/vipattributes.js";

export default {
  name: "ViewerCard",
  data() {
    return {
      totalViewers: 0,
      vipViewers: 0,
      viewers: [],
    };
  },
  components: {
    UserImageCircle,
    LevelBadge,
  },
  computed: {
    ...mapState({
      livestreamId: (state) => state.live.livestreamId,
      activeViewers: (state) => state.live.activeViewers,
      showViewerCard: (state) => state.live.showViewerCard,
    }),
    orderedViewers: function () {
      return this.viewers.concat().sort((a, b) => b.vip_state - a.vip_state);
    },
  },
  mounted() {
    this.getActiveViewers(this.livestreamId);
  },
  watch: {
    livestreamId: function () {
      this.getActiveViewers(this.livestreamId);
    },
    activeViewers: {
      handler() {
        this.totalViewers = this.activeViewers.count_info.total_viewers;
        this.vipViewers = this.activeViewers.count_info.vip_viewers;
        this.viewers = this.activeViewers.viewers;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("live", ["getActiveViewers", "toggleViewerCard"]),
    ...mapActions("client", ["openHalfProfile"]),
    showProfile(viewer) {
      if (viewer.user_id) {
        this.openHalfProfile(viewer.user_id);
        this.toggleViewerCard();
      }
    },
    onClickOutside() {
      this.toggleViewerCard();
    },
    vipAttributes,
  },
};
</script>
