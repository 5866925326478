import Vue from "vue";
import Vuex from "vuex";
import live from "./modules/live/live";
import client from "./modules/client/client";
import payment from "./modules/payment/payment";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    live,
    client,
    payment,
  },
  state: {
    language: "en",
    isMenuOpened: false,
    brandName: "",
    brandTitle: "",
    emailDomain: "",
    history: [],
    forbidden: false,
    registering: true,
    platform: null,
  },
  getters: {
    language: (state) => {
      return state.language;
    },
    isMenuOpened: (state) => {
      return state.isMenuOpened;
    },
    brandName: (state) => {
      return state.brandName;
    },
    brandTitle: (state) => {
      return state.brandTitle;
    },
    emailDomain: (state) => {
      return state.emailDomain;
    },
    history: (state) => {
      return state.history;
    },
    platform: (state) => {
      return state.platform;
    },
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language;
    },
    setMenuOpened(state, isMenuOpened) {
      state.isMenuOpened = isMenuOpened;
    },
    setBrandName(state, brandName) {
      state.brandName = brandName;
    },
    setBrandTitle(state, brandTitle) {
      state.brandTitle = brandTitle;
    },
    setEmailDomain(state, emailDomain) {
      state.emailDomain = emailDomain;
    },
    addHistory(state, newPath) {
      state.history.push(newPath);
    },
    setDealerModalCloseStatus(state, status) {
      state.modalStatus = status;
    },
    setHistory(state, history) {
      state.history = history;
    },
    setForbidden(state, payload) {
      state.forbidden = payload;
    },
    setRegistering(state, payload) {
      state.registering = payload;
    },
    setPlatform(state, platform) {
      state.platform = platform;
    },
  },
  actions: {
    setLanguage({ commit }, payload) {
      commit("setLanguage", payload);
    },
    setMenuOpened({ commit }, payload) {
      commit("setMenuOpened", payload);
    },
    addHistory({ commit }, newPath) {
      commit("addHistory", newPath);
    },
    setDealerModalCloseStatus({ commit }, status) {
      commit("setDealerModalCloseStatus", status);
    },
    setHistory({ commit }, historyNew) {
      commit("setHistory", historyNew);
    },
    setForbidden({ commit }, payload) {
      commit("setForbidden", payload);
    },
    setRegistering({ commit }, payload) {
      commit("setRegistering", payload);
    },
    setPlatform({ commit }, platform) {
      commit("setPlatform", platform);
    },
  },
});
