import { render, staticRenderFns } from "./OfficialDealerModal.vue?vue&type=template&id=20edf20c&scoped=true"
import script from "./OfficialDealerModal.vue?vue&type=script&lang=js"
export * from "./OfficialDealerModal.vue?vue&type=script&lang=js"
import style0 from "./OfficialDealerModal.vue?vue&type=style&index=0&id=20edf20c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20edf20c",
  null
  
)

export default component.exports