<template>
  <v-list-item class="list-item-container">
    <v-list-item-avatar @click="routeToUser(item.data.user.user_id)">
      <UserImageCircle :user="item.data.user" />
    </v-list-item-avatar>
    <v-list-item-content class="ml-2">
      <v-list-item-title justify="center" class="items-reverse">
        <img
          v-if="item.data.user !== null && item.data.user.vip_state > 0"
          :src="vipAttributes(item.data.user.vip_state).image"
          :alt="$t('general.vip_icon')"
          class="vip-image"
        />
        <span class="user-name text-ellipsis">
          <LevelBadge class="mr-0" :user="item.data.user" />
          <span class="user-name">
            {{ item.data.user.name }}
          </span>
        </span>
      </v-list-item-title>
      <v-list-item-subtitle>
        <span
          v-if="item.type === NotificationsTypeEnums.FOLLOWER"
          class="ma-2 notif-type"
        >
          {{ $t("notifications_page.follower") }} -
        </span>
        <span
          v-if="item.type === NotificationsTypeEnums.VIP_STATUS_CHANGE"
          class="notif-type pa-2"
        >
          >{{ $t("notifications_page.vip_status_change") }} -
        </span>
        <span
          v-if="item.type === NotificationsTypeEnums.PROFILE_VIEWER"
          class="notif-type"
        >
          {{ $t("notifications_page.profile_viewer") }}
        </span>
        <span class="notif-timestamp">
          {{ moment(item.data.timestamp).format("DD.MM.YYYY  h:mm:ss ") }}
        </span>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon allign="center">
      <span
        @click="
          routeToUser(item.data.user.user_id, item.data.user.livestream_id)
        "
        v-if="item.data.user.livestream_id !== null"
        class="live-status"
      >
        <img
          src="@/assets/img/live-status-icon-animation.gif"
          :alt="$t('general.status_icon')"
        />
        {{ $t("labels.live") }}
      </span>
      <v-btn
        v-if="item.data.user !== null && item.data.user.is_followed === true"
        class="follow-icon-svg"
        :loading="isFollowLoading"
        :disabled="isFollowLoading"
        @click="clickUnfollowUser(item.data.user.user_id)"
        rounded
        small
      >
        <v-img
          src="@/assets/img/unfollow-icon.svg"
          :alt="$t('general.unfollow_icon')"
        />
      </v-btn>
      <v-btn
        v-else-if="
          item.data.user !== null && item.data.user.is_followed === false
        "
        class="follow-icon-svg"
        :loading="isFollowLoading"
        :disabled="isFollowLoading"
        @click="clickFollowUser(item.data.user.user_id)"
        rounded
        small
      >
        <v-img
          :src="require(`@/assets/img/follow-icon_${brandName}.svg`)"
          :alt="$t('general.follow_icon')"
        />
      </v-btn>
    </v-list-item-icon>
  </v-list-item>
</template>

<style lang="scss" scoped>
@import "NotificationCard.scss";
</style>

<script>
import router from "@/router";
import { mapActions } from "vuex";
import { NotificationsTypeEnums, FollowPageEnums } from "@/helper/enums.js";
import UserImageCircle from "@/components/Shared/UserImageCircle/UserImageCircle.vue";
import LevelBadge from "@/components/Shared/LevelBadge/LevelBadge.vue";
import { vipAttributes } from "@/helper/vipattributes.js";
import moment from "moment";
import { encrypt, generateBase64Url } from "@/helper/index.js";
export default {
  name: "NotificationCard",
  data() {
    return {
      vipAttributes,
      NotificationsTypeEnums,
      moment,
      generateBase64Url,
      isFollowLoading: false,
    };
  },
  props: {
    item: {
      type: Object,
    },
    brandName: {
      type: String,
    },
  },
  components: {
    UserImageCircle,
    LevelBadge,
  },
  watch: {
    item: {
      handler() {
        this.isFollowLoading = false;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("client", [
      "notificationsFollowActions",
      "getNextNotifications",
    ]),
    ...mapActions("live", ["setLiveStreamSource"]),
    clickFollowUser(user_id) {
      this.isFollowLoading = true;
      this.notificationsFollowActions({
        user_id: user_id,
        page_enum: FollowPageEnums.NOTIFICATIONS,
        type: "follow",
      });
    },
    clickUnfollowUser(user_id) {
      this.isFollowLoading = true;
      this.notificationsFollowActions({
        user_id: user_id,
        page_enum: FollowPageEnums.NOTIFICATIONS,
        type: "unfollow",
      });
    },
    getCryptedString(streamId, userId) {
      let cryptObj = { streamId: streamId, userId: userId };
      return encrypt(cryptObj);
    },
    routeToUser(user_id, stream_id) {
      if (stream_id)
        router.push("/stream/" + this.getCryptedString(stream_id, user_id));
      else if (user_id) router.push(generateBase64Url("/profile/", user_id));
    },
  },
};
</script>
