<template>
  <div>
    <div class="card-number-container">
      <div class="card-number" @click="selectPaymentMethod">
        <img :src="require(`@/assets/img/${cardData.card.brand}-icon.svg`)" />
        **** **** **** {{ cardData.card.last4 }} -
        {{ cardData.card.expiration_month }}/{{ cardData.card.expiration_year }}
      </div>
      <div class="delete-card" @click="openRemove">
        <i class="fas fa-times"></i>
      </div>
    </div>
    <div class="remove-container" v-if="showRemove">
      {{ $t("payermax.remove_credit_card") }}
      <div class="columns is-mobile">
        <div class="column is-half">
          <button class="button custom-button gray-button" @click="closeRemove">
            {{ $t("labels.cancel") }}
          </button>
        </div>
        <div class="column is-half">
          <button class="button custom-button red-button" @click="detach">
            {{ $t("labels.remove") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
"use strict";

const endpoints = require("@/api/endpoints");

export default {
  name: "CardItem",
  props: ["cardData"],
  data() {
    return {
      showRemove: false,
    };
  },
  beforeMount() {},
  mounted() {},
  methods: {
    openRemove() {
      this.showRemove = true;
    },
    closeRemove() {
      this.showRemove = false;
    },
    detach() {
      endpoints
        .detach_payment_method(this.$axios, {
          payment_method_id: this.cardData.id,
        })
        .then(() => {
          this.$emit("detached", this.cardData.id);
        });

      this.$emit("detached", this.cardData.id);
    },
    selectPaymentMethod() {
      this.$store.dispatch("payment/setSelectedPaymentMethod", this.cardData);
      this.$emit("removeError", null);
    },
  },
};
</script>
