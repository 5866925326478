// @ts-check

// NOTE(baris): How this might effect agora is not tested. Might wanna use agora to get RTCPeerConnection instead.

const { localStore, STORE_KEYS } = require("../storage");

const logger = new (require("../logging").Logger)("api.rtc_id");

//

const RTC_ID_STORAGE_KEY = STORE_KEYS.RTC_ID;

//

let _rtcid = null;
try {
  _rtcid = localStore.getItem(RTC_ID_STORAGE_KEY) || null;
} catch (error) {
  logger.debugError("Failed to get stored rtc_id", error);
}

/**
 * Returns already set rtc_id. Returns null if not set.
 * @returns {string|null}
 */
function getCurrentRTCId() {
  return _rtcid;
}
module.exports.getCurrentRTCId = getCurrentRTCId;

function _getFreshRTCId() {
  return new Promise(function (resolve) {
    if (typeof RTCPeerConnection == "undefined") {
      logger.log("_getFreshRTCId no RTCPeerConnection");
      resolve(null);
      return;
    }

    let connection = new RTCPeerConnection();

    // Required for Safari, causes an error on some other browsers.
    try {
      // @ts-ignore
      const stream = document.createElement("canvas").captureStream();
      stream.getTracks().forEach((track) => connection.addTrack(track));
    } catch (e) {
      logger.error(e);
    }

    function iceCandidateEventListener(event) {
      const candidate = event.candidate;

      if (connection.iceGatheringState == "complete" && _rtcid == null) {
        logger.log("_getFreshRTCId complete with no id");
        resolve(null);
        return;
      }

      if (!candidate) {
        logger.log("_getFreshRTCId no candidate in event", event);
        resolve(null);
        return;
      }

      // For Chromium
      if (candidate.foundation) {
        connection.removeEventListener(
          "icecandidate",
          iceCandidateEventListener
        );

        _rtcid = candidate.foundation;
        logger.log("_getFreshRTCId chrome success", _rtcid);
        resolve(_rtcid);
        return;
      }

      // For Safari
      if (candidate.candidate) {
        const matches = /^candidate:(\d+)\s/.exec(candidate.candidate);
        logger.debug("_getFreshRTCId candidate.candidate matches", matches);

        if (!matches || matches[1].length < 2) {
          logger.log("_getFreshRTCId no candidate.candidate in event", event);
          resolve(null);
          return;
        } else {
          connection.removeEventListener(
            "icecandidate",
            iceCandidateEventListener
          );

          _rtcid = matches[1];
          logger.log("_getFreshRTCId safari success", _rtcid);
          resolve(_rtcid);
          return;
        }
      }

      logger.debugError("_getFreshRTCId unknown 'candidate' in event", event);
      resolve(null);
      return;
    }
    connection.addEventListener("icecandidate", iceCandidateEventListener);

    connection.addEventListener("icecandidateerror", function (event) {
      logger.debugError("_getFreshRTCId icecandidateerror", event);
      resolve(null);
    });

    return connection
      .createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      })
      .then((offer) => {
        connection.setLocalDescription(offer);
      });
  });
}

let _getRTCIdPromise = null;
function getRTCId(forceRefresh = false) {
  logger.debug("getRTCId", forceRefresh);
  if (_getRTCIdPromise == null || forceRefresh) {
    if (forceRefresh) {
      _rtcid = null;
      try {
        localStore.removeItem(RTC_ID_STORAGE_KEY);
      } catch (error) {
        logger.debugError("getRTCId failed to delete stored rtc_id", error);
      }
    }

    if (_rtcid != null) {
      logger.debug("getRTCId found _rtcid", _rtcid);
      _getRTCIdPromise = Promise.resolve(_rtcid);
    } else {
      _getRTCIdPromise = _getFreshRTCId().then(
        (rtc_id) => {
          _rtcid = rtc_id;
          try {
            localStore.setItem(RTC_ID_STORAGE_KEY, _rtcid);
          } catch (error) {
            logger.debugError(
              "getRTCId failed to save rtc_id to storage",
              error
            );
          }
          return _rtcid;
        },
        (error) => {
          logger.debugError("getRTCId _getFreshRTCId error", error);
          return null;
        }
      );
    }
  }

  return _getRTCIdPromise;
}
module.exports.getRTCId = getRTCId;
