const logger = new (require("../logging").Logger)("helper");

/* eslint-disable no-prototype-builtins */
export const generateUUID = () => {
  let d = new Date().getTime(),
    d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c == "x" ? r : (r & 0x7) | 0x8).toString(16);
  });
};

export const isElementOverflowing = (element) => {
  var overflowX = element.offsetWidth < element.scrollWidth,
    overflowY = element.offsetHeight < element.scrollHeight;
  return overflowX || overflowY;
};

export const wrapContentsInMarquee = (element) => {
  var marquee = document.createElement("marquee"),
    contents = element.innerText;

  marquee.innerText = contents;
  element.innerHTML = "";
  element.appendChild(marquee);
};

export const showThousund = (count, fixCount = 2) => {
  let returnValue = count;
  if (count > 999999) {
    returnValue = count / 1000000;
    returnValue = returnValue.toFixed(fixCount) + "m";
  }
  if (count > 1000 && count < 1000000) {
    returnValue = count / 1000;
    returnValue = returnValue.toFixed(fixCount) + "k";
  }
  return returnValue;
};

export const generateRandomStringId = () => {
  return Math.random().toString();
};

export const stripeUuid = () => {
  logger.debugError("stripeUuid called");
  throw "stripeUuid is deprecated";
};

const UUID_KEY = "payment_uuid";

/**
 * Returns Payment UUID from session storage.
 * Expects generatePaymentUUID to be called before hand.
 * @returns {string}
 */
export const getPaymentUUID = () => {
  logger.debug("getPaymentUUID called");
  const uuid = sessionStorage.getItem(UUID_KEY);
  if (uuid == null) {
    throw "No active payment. Missing uuid";
  }
  return uuid;
};

/**
 * Should be called at user action, before request.
 * Should be re-called when payment requests changes.
 * @returns {void}
 */
export const generatePaymentUUID = () => {
  logger.debug("generatePaymentUUID called");
  sessionStorage.setItem(UUID_KEY, generateUUID());
};

/**
 * Clears Payment UUID in storage.
 * Should be called to prevent mistakes.
 * @returns {void}
 */
export const clearPaymentUUID = () => {
  logger.debug("clearPaymentUUID called");
  sessionStorage.removeItem(UUID_KEY);
};

export const signupUuid = () => {
  let uuid = "";
  if (sessionStorage.getItem("uuid_signup")) {
    uuid = sessionStorage.getItem("uuid_signup");
  } else {
    uuid = generateUUID();
    sessionStorage.setItem("uuid_signup", uuid);
  }
  return uuid;
};

export const encrypt = (o) => {
  return btoa(unescape(encodeURIComponent(JSON.stringify(o))));
};

export const decrypt = (o) => {
  return JSON.parse(decodeURIComponent(escape(window.atob(o))));
};

function toBinary(string) {
  const codeUnits = new Uint16Array(string.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = string.charCodeAt(i);
  }
  const charCodes = new Uint8Array(codeUnits.buffer);
  let result = "";
  for (let i = 0; i < charCodes.byteLength; i++) {
    result += String.fromCharCode(charCodes[i]);
  }
  return result;
}

function fromBinary(binary) {
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  const charCodes = new Uint16Array(bytes.buffer);
  let result = "";
  for (let i = 0; i < charCodes.length; i++) {
    result += String.fromCharCode(charCodes[i]);
  }
  return result;
}

export const generateBase64Url = (url, parameter) => {
  if (!parameter) return url;

  return `${url}${btoa(toBinary(parameter))}`;
};

export const decodeUrlParameter = (param) => fromBinary(atob(param));

export const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const returnVipString = (vipState = 0) => {
  if (
    typeof vipState === "object" &&
    vipState.hasOwnProperty("current_state")
  ) {
    return [null, "bronze", "silver", "gold"][vipState.current_state];
  } else {
    return [null, "bronze", "silver", "gold"][vipState ? vipState : 0];
  }
};

export const stripePaymentRequestData = (
  productId,
  paymentMethodId,
  paymentUUID,
  forterToken
) => {
  return {
    product_id: productId,
    payment_method_id: paymentMethodId,
    idempotency_key: paymentUUID,
    forter_token_cookie: forterToken,
    forter_mobile_uid:
      window.ftr__mob && window.ftr__mob.getMobileId()
        ? window.ftr__mob.getMobileId()
        : null,
  };
};

// Deprecated
// export const createPaymentMethodData = (productId, cardInfo, forterToken) => {
//   return {
//     product_id: productId,
//     idempotency_key: getPaymentUUID(),
//     forter_token_cookie: forterToken,
//     forter_mobile_uid:
//       window.ftr__mob && window.ftr__mob.getMobileId
//         ? window.ftr__mob.getMobileId()
//         : null,
//     card_info: cardInfo,
//   };
// };

export const formatCoin = (value) => {
  if (value) {
    let val = value.toString().replace(".", ",");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else {
    return "";
  }
};
