<template>
  <v-overlay color="#fff" :value="true">
    <v-progress-circular color="#333" :size="50" indeterminate />
  </v-overlay>
</template>

<script>
import { mapActions } from "vuex";
import { Logger } from "@/logging";

const logger = new Logger("PayPalCapture");

export default {
  name: "PayPalCapture",
  methods: {
    ...mapActions("payment", ["paypalCapture"]),
  },
  mounted() {
    const idempotence = this.$route.params.idempotence;

    if (idempotence == null) {
      return this.$router.go(-1);
    }

    this.paypalCapture({ idempotency_key: idempotence })
      .then((response) => {
        if (response.redirect_url != null) {
          window.location.href = response.redirect_url;
        } else {
          logger.debugError(
            "Failed to capture given idempotence and redirecting to the failed page",
            response
          );
          this.$router.push("/payermax/payment-complete?status=2");
        }
      })
      .catch((e) => {
        logger.debugError(
          "Failed to capture given idempotence and redirecting to the failed page",
          e
        );
        this.$router.push("/payermax/payment-complete?status=2");
      });
  },
};
</script>
