// @ts-check

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

const { localStore, STORE_KEYS } = require("../storage");

const rtc_id_module = require("./rtc_id");
const client_params_module = require("./client_params");
const instance_module = require("./instance");

const endpoints = require("./endpoints");

/**
 * Gets Device-ID and updates axios instance device-id header
 * @param {AxiosInstance} axios
 * @returns {Promise<void>}
 */
function preLogin(axios) {
  return rtc_id_module.getRTCId(false).then(() => {
    client_params_module.getClientParams(true); // For refresh

    return new Promise((resolve, reject) => {
      endpoints.device_register(axios, {}).then((response) => {
        if (response.data != null && response.data.guid != null) {
          const device_id = response.data.guid;
          localStore.setItem(STORE_KEYS.DEVICE_ID, device_id);
          instance_module.updateAxiosInstanceDeviceID(axios, device_id);
          if (window["firebase_analytics_id"])
            endpoints.firebase_analytics_record(axios);
          const authToken = localStore.getItem(STORE_KEYS.AUTH_TOKEN);
          if (authToken) {
            userEntry(axios).then((res) => {
              resolve({ guid: response.data.guid, loggedIn: res });
            });
          } else {
            resolve({ guid: response.data.guid, loggedIn: false });
          }
        } else {
          // TODO(baris): Better handle error here.
          reject(response.data);
        }
      });
    });
  });
}
module.exports.preLogin = preLogin;

function userEntry(axios) {
  return new Promise((resolve, reject) => {
    endpoints.user_entry(axios).then((res) => {
      const err = res.data.error && res.data.error.code == "2";
      if (err) {
        localStore.removeItem(STORE_KEYS.AUTH_TOKEN);
        instance_module.updateAxiosInstanceAuthToken(axios, null);
      }
      resolve(!err);
    });
  });
}

/**
 * Sets Auth token to axios instance. Might add extra requests in future.
 * @param {AxiosInstance} axios
 * @param {string} auth_token
 * @returns {Promise<void>}
 */
function postLogin(axios, auth_token) {
  localStore.setItem(STORE_KEYS.AUTH_TOKEN, auth_token);
  instance_module.updateAxiosInstanceAuthToken(axios, auth_token);

  return Promise.resolve();
}
module.exports.postLogin = postLogin;
