// @ts-check
"use strict";

const i18n = require("../i18n").default;
const helpers = require("../helper/index.js");

const getInstallationID = require("./installation_id").getInstallationID;
const getCurrentRTCId = require("./rtc_id").getCurrentRTCId;

const logger = new (require("../logging").Logger)("api.client_params");
const { localStore, STORE_KEYS } = require("./../storage");

var pjson = require("./../../package.json");

let APP = process.env.VUE_APP_BRAND_NAME;
if (APP == "red") {
  APP = "liveflamingo";
}
const APP_BUILD = pjson.version;

/**
 * @typedef ClientParams
 * @property {"web"} os_type
 * @property {string} app
 * @property {string} app_build
 * @property {string} installation_id
 * @property {string} rtc_id
 * @property {string} app_language
 * @property {string} device_language
 * @property {string[]} device_preferred_languages
 * @property {string} [firebase_analytics_id]
 * @property {string} [adid]
 * @property {string} [adjust_attribution_data]
 * @property {string} [adjust_web_uuid]
 * @property {string} fbc
 * @property {string} fbp
 * @property {string} source_url
 */

let client_params = null;
/**
 * @param {boolean} forceRegenerate
 * @returns {ClientParams}
 */
function getClientParams(forceRegenerate = false) {
  if (client_params == null || forceRegenerate) {
    let installation_id = getInstallationID();
    let rtc_id = getCurrentRTCId();

    // NOTE(baris): Relies on the way we package localization texts and set the locale.
    let app_language = i18n.locale;
    if (i18n.messages[app_language] == null) {
      // @ts-ignore
      app_language = i18n.fallbackLocale;
    }

    let device_language = window.navigator.language || null;
    let device_preferred_languages =
      window.navigator.languages != null
        ? Array.from(window.navigator.languages)
        : null;

    /** @type {ClientParams} */
    const param_object = {
      os_type: "web",
      app: APP,
      app_build: APP_BUILD,
      //
      installation_id: installation_id,
      rtc_id: rtc_id,
      //
      app_language: app_language,
      device_language: device_language,
      device_preferred_languages: device_preferred_languages,
      //
      fbc: helpers.getCookie("_fbc"),
      fbp: helpers.getCookie("_fbp"),
      source_url: window.location.href,
    };
    if (window["firebase_analytics_id"]) {
      param_object["firebase_analytics_id"] = window["firebase_analytics_id"];
    }

    const adjustAttributionsDataStr = localStore.getItem(
      STORE_KEYS.ADJUST_ATTRIBUTION_DATA
    );
    if (adjustAttributionsDataStr)
      param_object["adjust_attribution_data"] = JSON.parse(
        adjustAttributionsDataStr
      );

    const adid = localStore.getItem(STORE_KEYS.ADJUST_ADID);
    if (adid) param_object["adid"] = adid;

    const adjust_web_uuid = localStore.getItem(STORE_KEYS.ADJUST_WEB_UUID);
    if (adjust_web_uuid) param_object["adjust_web_uuid"] = adjust_web_uuid;

    // Saving as string for dirty/quick object clone
    client_params = JSON.stringify(param_object);
    logger.debug("client_params generated", client_params);
  }
  // Refresh source_url in every method call
  else {
    let encoded_data = JSON.parse(client_params);
    encoded_data.source_url = window.location.href;
    client_params = JSON.stringify(encoded_data);
  }

  // Parsing saved string for dirty/quick object clone
  return JSON.parse(client_params);
}
module.exports.getClientParams = getClientParams;
