<template>
  <v-container class="top-gifters-main-container">
    <v-container
      @click="setShowTopGifters(false)"
      class="top-gifters-bg-layer"
    ></v-container>
    <v-card v-if="topGiftersList" class="top-gifters-container">
      <v-row class="top-gifters-header">
        <span class="top-gifters-header-title">
          {{ $t("top_gifters_layout.title") }}
        </span>
      </v-row>
      <v-row
        class="top-gifters-content-wrapper"
        v-on:scroll.passive="handleScroll"
      >
        <v-col class="top-gifters-total-diamond">
          <img
            class="dimond-img"
            src="@/assets/img/diamond-icon.svg"
            :alt="$t('general.diamond_icon')"
          />
          <span class="diamond-count">
            {{ topGiftersList.total_diamonds }}
          </span>
        </v-col>
        <v-col
          class="empty-container"
          v-if="topGiftersList.gifters.length === 0"
        >
          <span class="empty-content">
            {{ $t("top_gifters_layout.empty_gifters") }}
          </span>
        </v-col>
        <v-row
          class="top-gifters-content-container"
          v-for="(item, index) in topGiftersList.gifters"
          :key="`${index}`"
        >
          <v-row class="user-info-container">
            <v-col class="order-box" v-if="index === 0">
              <img
                class="special-order-number"
                src="@/assets/img/number-one-icon.svg"
                :alt="$t('leaderboard_page.numer_one_icon')"
              />
            </v-col>
            <v-col class="order-box" v-else-if="index === 1">
              <img
                class="special-order-number"
                src="@/assets/img/number-two-icon.svg"
                :alt="$t('leaderboard_page.numer_two_icon')"
              />
            </v-col>
            <v-col class="order-box" v-else-if="index === 2">
              <img
                class="special-order-number"
                src="@/assets/img/number-three-icon.svg"
                :alt="$t('leaderboard_page.numer_three_icon')"
              />
            </v-col>
            <v-col v-else-if="index > 2" class="not-special-order-number">
              {{ index + 1 }}
            </v-col>
            <v-col class="top-gifters-user-img" v-if="item">
              <v-btn
                v-if="item.user !== null"
                @click="openHalfProfile(item.user.user_id)"
              >
                <user-image-circle
                  class="user-profile-img"
                  :user="item.user"
                  size="45px"
                  border=""
                />
              </v-btn>
            </v-col>
            <v-col class="top-gifters-list" v-if="item">
              <level-badge :user="item.user" :fontSize="'14'"></level-badge>
              <span class="user-name">
                {{ item.user.name }}
              </span>
              <v-col class="vip-badge-container" v-if="item.user.vip_state > 0">
                <span class="vip-badge">
                  <img
                    v-if="item.user.vip_state == 3"
                    class="vip-badge-img"
                    src="@/assets/img/gold-vip.svg"
                    :alt="$t('general.vip_icon')"
                  />
                  <img
                    v-if="item.user.vip_state == 2"
                    class="vip-badge-img"
                    src="@/assets/img/silver-vip.svg"
                    :alt="$t('general.vip_icon')"
                  />
                  <img
                    v-if="item.user.vip_state == 1"
                    class="vip-badge-img"
                    src="@/assets/img/bronze-vip.svg"
                    :alt="$t('general.vip_icon')"
                  />
                </span>
              </v-col>
            </v-col>
          </v-row>
          <v-col class="user-diamond-info-container">
            <img
              class="user-dimond-img"
              src="@/assets/img/diamond-icon.svg"
              :alt="$t('general.diamond_icon')"
            />
            <span class="user-diamond-count-info">
              {{ showThousund(item.diamonds, 1) }}
            </span>
          </v-col>
        </v-row>
      </v-row>
    </v-card>
  </v-container>
</template>

<style lang="scss" scoped>
@import "TopGifters.scss";
</style>
<script>
import { mapState, mapActions } from "vuex";
import { generateBase64Url, showThousund } from "@/helper/index.js";
import UserImageCircle from "@/components/Shared/UserImageCircle/UserImageCircle.vue";
import LevelBadge from "@/components/Shared/LevelBadge/LevelBadge.vue";

export default {
  name: "TopGifters",
  components: {
    UserImageCircle,
    LevelBadge,
  },
  computed: {
    ...mapState({
      topGiftersList: (state) => state.live.topGiftersList,
      showTopGifters: (state) => state.live.showTopGifters,
      selectedUser: (state) => state.live.selectedUser,
      topGiftersListMetaNext: (state) => state.live.topGiftersListMetaNext,
      brandName: (state) => state.brandName,
    }),
  },
  methods: {
    ...mapActions("client", ["openHalfProfile"]),
    ...mapActions("live", ["setShowTopGifters"]),
    showThousund,
    generateBase64Url,
    handleScroll(event) {
      if (
        event.target.offsetHeight + event.target.scrollTop >=
          event.target.scrollHeight &&
        this.topGiftersListMetaNext !== null &&
        this.topGiftersListMetaNext !== undefined &&
        this.selectedUser &&
        this.selectedUser.livestream_id
      ) {
        this.setShowTopGifters({
          payload: true,
          next: this.topGiftersListMetaNext,
          livestream_id: this.selectedUser.livestream_id,
        });
      }
    },
  },
};
</script>
