import { render, staticRenderFns } from "./PhoneLogin.vue?vue&type=template&id=14572bcd"
import script from "./PhoneLogin.vue?vue&type=script&lang=js"
export * from "./PhoneLogin.vue?vue&type=script&lang=js"
import style0 from "./PhoneLogin.vue?vue&type=style&index=0&id=14572bcd&prod&lang=scss"
import style1 from "vue-tel-input/dist/vue-tel-input.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})
