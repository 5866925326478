<template>
  <div>
    <div class="page-left-leaderboard-container">
      <DiscoverLeft />
    </div>
    <div class="page-right-leaderboard-container">
      <leader-board />
      <discover />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "LeaderBoardView.scss";
@import "../Discover/DiscoverView.scss";
</style>
<script>
import LeaderBoard from "@/components/LeaderBoard/LeaderBoard.vue/";
import Discover from "@/components/Discover/Discover.vue";
import DiscoverLeft from "@/components/Discover/DiscoverLeft.vue";

export default {
  name: "LeaderBoardView",
  components: {
    LeaderBoard,
    DiscoverLeft,
    Discover,
  },
};
</script>
