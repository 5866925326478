<template>
  <div class="columns">
    <div class="column is-12 has-text-centered mt-5">
      <div class="redirecting">{{ $t("labels.redirecting") }}</div>
      <div class="callback-loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "./TwitterCallback.scss";
</style>
<script>
"use strict";

const endpoints = require("../../../api/endpoints");
const helpers = require("../../../api/helpers");

export default {
  name: "TwitterCallback",
  mounted() {
    let that = this;
    if (!this.$route.query.oauth_token || !this.$route.query.oauth_verifier) {
      this.$router.push("/");
    }
    endpoints
      .twitter_access_token(that.$axios, {
        oauth_token_key: that.$route.query.oauth_token,
        oauth_verifier: that.$route.query.oauth_verifier,
      })
      .then((twitterAccessTokenResponse) => {
        twitterAccessTokenResponse = twitterAccessTokenResponse.data;
        if (twitterAccessTokenResponse.error) {
          // Burda router push kullanılmadı çünkü router'ın guard'ı 2 kere redirecti engelliyor.
          window.location = "/";
        } else {
          let twitterSignupData = {
            access_token_key: twitterAccessTokenResponse.oauth_token,
            access_token_secret: twitterAccessTokenResponse.oauth_token_secret,
          };

          endpoints
            .signup_twitter(that.$axios, twitterSignupData)
            .then(function (twitterSignupResponse) {
              twitterSignupResponse = twitterSignupResponse.data;

              if (twitterSignupResponse.error) {
                if (twitterSignupResponse.error.code == 13) {
                  that.$store.commit(
                    "client/setErrorMessage",
                    that.$i18n.t("errors.sign_up_on_app")
                  );
                } else {
                  that.$store.commit(
                    "client/setErrorMessage",
                    twitterSignupResponse.error.message
                  );
                }
                that.$router.push("/login");
              } else {
                that.$store.dispatch("client/postLogin", {
                  authToken: twitterSignupResponse.token,
                  redirectUrl: "/",
                  loginFrom: "twitter",
                });
              }
            });
        }
      });
  },
};
</script>
