export const countryCodeToFlagName = (countryCode) => {
  switch (countryCode.toLowerCase()) {
    //this should be sorted based on country name code.
    case "ad": //andorra
      return "flag_andorra";
    case "ae": //united arab emirates
      return "flag_uae";
    case "af": //afghanistan
      return "flag_afghanistan";
    case "ag": //antigua & barbuda
      return "flag_antigua_and_barbuda";
    case "ai": //anguilla // Caribbean Islands
      return "flag_anguilla";
    case "al": //albania
      return "flag_albania";
    case "am": //armenia
      return "flag_armenia";
    case "ao": //angola
      return "flag_angola";
    case "aq": //antarctica // custom
      return "flag_antarctica";
    case "ar": //argentina
      return "flag_argentina";
    case "as": //American Samoa
      return "flag_american_samoa";
    case "at": //austria
      return "flag_austria";
    case "au": //australia
      return "flag_australia";
    case "aw": //aruba
      return "flag_aruba";
    case "ax": //alan islands
      return "flag_aland";
    case "az": //azerbaijan
      return "flag_azerbaijan";
    case "ba": //bosnia and herzegovina
      return "flag_bosnia";
    case "bb": //barbados
      return "flag_barbados";
    case "bd": //bangladesh
      return "flag_bangladesh";
    case "be": //belgium
      return "flag_belgium";
    case "bf": //burkina faso
      return "flag_burkina_faso";
    case "bg": //bulgaria
      return "flag_bulgaria";
    case "bh": //bahrain
      return "flag_bahrain";
    case "bi": //burundi
      return "flag_burundi";
    case "bj": //benin
      return "flag_benin";
    case "bl": //saint barthélemy
      return "flag_saint_barthelemy"; // custom
    case "bm": //bermuda
      return "flag_bermuda";
    case "bn": //brunei darussalam // custom
      return "flag_brunei";
    case "bo": //bolivia, plurinational state of
      return "flag_bolivia";
    case "br": //brazil
      return "flag_brazil";
    case "bs": //bahamas
      return "flag_bahamas";
    case "bt": //bhutan
      return "flag_bhutan";
    case "bw": //botswana
      return "flag_botswana";
    case "by": //belarus
      return "flag_belarus";
    case "bz": //belize
      return "flag_belize";
    case "ca": //canada
      return "flag_canada";
    case "cc": //cocos (keeling) islands
      return "flag_cocos"; // custom
    case "cd": //congo, the democratic republic of the
      return "flag_democratic_republic_of_the_congo";
    case "cf": //central african republic
      return "flag_central_african_republic";
    case "cg": //congo
      return "flag_republic_of_the_congo";
    case "ch": //switzerland
      return "flag_switzerland";
    case "ci": //côte d\'ivoire
      return "flag_cote_divoire";
    case "ck": //cook islands
      return "flag_cook_islands";
    case "cl": //chile
      return "flag_chile";
    case "cm": //cameroon
      return "flag_cameroon";
    case "cn": //china
      return "flag_china";
    case "co": //colombia
      return "flag_colombia";
    case "cr": //costa rica
      return "flag_costa_rica";
    case "cu": //cuba
      return "flag_cuba";
    case "cv": //cape verde
      return "flag_cape_verde";
    case "cw": //curaçao
      return "flag_curacao";
    case "cx": //christmas island
      return "flag_christmas_island";
    case "cy": //cyprus
      return "flag_cyprus";
    case "cz": //czech republic
      return "flag_czech_republic";
    case "de": //germany
      return "flag_germany";
    case "dj": //djibouti
      return "flag_djibouti";
    case "dk": //denmark
      return "flag_denmark";
    case "dm": //dominica
      return "flag_dominica";
    case "do": //dominican republic
      return "flag_dominican_republic";
    case "dz": //algeria
      return "flag_algeria";
    case "ec": //ecuador
      return "flag_ecuador";
    case "ee": //estonia
      return "flag_estonia";
    case "eg": //egypt
      return "flag_egypt";
    case "er": //eritrea
      return "flag_eritrea";
    case "es": //spain
      return "flag_spain";
    case "et": //ethiopia
      return "flag_ethiopia";
    case "fi": //finland
      return "flag_finland";
    case "fj": //fiji
      return "flag_fiji";
    case "fk": //falkland islands (malvinas)
      return "flag_falkland_islands";
    case "fm": //micronesia, federated states of
      return "flag_micronesia";
    case "fo": //faroe islands
      return "flag_faroe_islands";
    case "fr": //france
      return "flag_france";
    case "ga": //gabon
      return "flag_gabon";
    case "gb": //united kingdom
      return "flag_united_kingdom";
    case "gd": //grenada
      return "flag_grenada";
    case "ge": //georgia
      return "flag_georgia";
    case "gf": //guyane
      return "flag_guyane";
    case "gg": //Guernsey
      return "flag_guernsey";
    case "gh": //ghana
      return "flag_ghana";
    case "gi": //gibraltar
      return "flag_gibraltar";
    case "gl": //greenland
      return "flag_greenland";
    case "gm": //gambia
      return "flag_gambia";
    case "gn": //guinea
      return "flag_guinea";
    case "gp": //guadeloupe
      return "flag_guadeloupe";
    case "gq": //equatorial guinea
      return "flag_equatorial_guinea";
    case "gr": //greece
      return "flag_greece";
    case "gt": //guatemala
      return "flag_guatemala";
    case "gu": //Guam
      return "flag_guam";
    case "gw": //guinea-bissau
      return "flag_guinea_bissau";
    case "gy": //guyana
      return "flag_guyana";
    case "hk": //hong kong
      return "flag_hong_kong";
    case "hn": //honduras
      return "flag_honduras";
    case "hr": //croatia
      return "flag_croatia";
    case "ht": //haiti
      return "flag_haiti";
    case "hu": //hungary
      return "flag_hungary";
    case "id": //indonesia
      return "flag_indonesia";
    case "ie": //ireland
      return "flag_ireland";
    case "il": //israel
      return "flag_israel";
    case "im": //isle of man
      return "flag_isleof_man"; // custom
    case "is": //Iceland
      return "flag_iceland";
    case "in": //india
      return "flag_india";
    case "io": //British indian ocean territory
      return "flag_british_indian_ocean_territory";
    case "iq": //iraq
      return "flag_iraq_new";
    case "ir": //iran, islamic republic of
      return "flag_iran";
    case "it": //italy
      return "flag_italy";
    case "je": //Jersey
      return "flag_jersey";
    case "jm": //jamaica
      return "flag_jamaica";
    case "jo": //jordan
      return "flag_jordan";
    case "jp": //japan
      return "flag_japan";
    case "ke": //kenya
      return "flag_kenya";
    case "kg": //kyrgyzstan
      return "flag_kyrgyzstan";
    case "kh": //cambodia
      return "flag_cambodia";
    case "ki": //kiribati
      return "flag_kiribati";
    case "km": //comoros
      return "flag_comoros";
    case "kn": //st kitts & nevis
      return "flag_saint_kitts_and_nevis";
    case "kp": //north korea
      return "flag_north_korea";
    case "kr": //south korea
      return "flag_south_korea";
    case "kw": //kuwait
      return "flag_kuwait";
    case "ky": //Cayman_Islands
      return "flag_cayman_islands";
    case "kz": //kazakhstan
      return "flag_kazakhstan";
    case "la": //lao people\'s democratic republic
      return "flag_laos";
    case "lb": //lebanon
      return "flag_lebanon";
    case "lc": //st lucia
      return "flag_saint_lucia";
    case "li": //liechtenstein
      return "flag_liechtenstein";
    case "lk": //sri lanka
      return "flag_sri_lanka";
    case "lr": //liberia
      return "flag_liberia";
    case "ls": //lesotho
      return "flag_lesotho";
    case "lt": //lithuania
      return "flag_lithuania";
    case "lu": //luxembourg
      return "flag_luxembourg";
    case "lv": //latvia
      return "flag_latvia";
    case "ly": //libya
      return "flag_libya";
    case "ma": //morocco
      return "flag_morocco";
    case "mc": //monaco
      return "flag_monaco";
    case "md": //moldova, republic of
      return "flag_moldova";
    case "me": //montenegro
      return "flag_of_montenegro"; // custom
    case "mf":
      return "flag_saint_martin";
    case "mg": //madagascar
      return "flag_madagascar";
    case "mh": //marshall islands
      return "flag_marshall_islands";
    case "mk": //macedonia, the former yugoslav republic of
      return "flag_macedonia";
    case "ml": //mali
      return "flag_mali";
    case "mm": //myanmar
      return "flag_myanmar";
    case "mn": //mongolia
      return "flag_mongolia";
    case "mo": //macao
      return "flag_macao";
    case "mp": // Northern mariana islands
      return "flag_northern_mariana_islands";
    case "mq": //martinique
      return "flag_martinique";
    case "mr": //mauritania
      return "flag_mauritania";
    case "ms": //montserrat
      return "flag_montserrat";
    case "mt": //malta
      return "flag_malta";
    case "mu": //mauritius
      return "flag_mauritius";
    case "mv": //maldives
      return "flag_maldives";
    case "mw": //malawi
      return "flag_malawi";
    case "mx": //mexico
      return "flag_mexico";
    case "my": //malaysia
      return "flag_malaysia";
    case "mz": //mozambique
      return "flag_mozambique";
    case "na": //namibia
      return "flag_namibia";
    case "nc": //new caledonia
      return "flag_new_caledonia"; // custom
    case "ne": //niger
      return "flag_niger";
    case "nf": //Norfolk
      return "flag_norfolk_island";
    case "ng": //nigeria
      return "flag_nigeria";
    case "ni": //nicaragua
      return "flag_nicaragua";
    case "nl": //netherlands
      return "flag_netherlands";
    case "no": //norway
      return "flag_norway";
    case "np": //nepal
      return "flag_nepal";
    case "nr": //nauru
      return "flag_nauru";
    case "nu": //niue
      return "flag_niue";
    case "nz": //new zealand
      return "flag_new_zealand";
    case "om": //oman
      return "flag_oman";
    case "pa": //panama
      return "flag_panama";
    case "pe": //peru
      return "flag_peru";
    case "pf": //french polynesia
      return "flag_french_polynesia";
    case "pg": //papua new guinea
      return "flag_papua_new_guinea";
    case "ph": //philippines
      return "flag_philippines";
    case "pk": //pakistan
      return "flag_pakistan";
    case "pl": //poland
      return "flag_poland";
    case "pm": //saint pierre and miquelon
      return "flag_saint_pierre";
    case "pn": //pitcairn
      return "flag_pitcairn_islands";
    case "pr": //puerto rico
      return "flag_puerto_rico";
    case "ps": //palestine
      return "flag_palestine";
    case "pt": //portugal
      return "flag_portugal";
    case "pw": //palau
      return "flag_palau";
    case "py": //paraguay
      return "flag_paraguay";
    case "qa": //qatar
      return "flag_qatar";
    case "re": //la reunion
      return "flag_martinique"; // no exact flag found
    case "ro": //romania
      return "flag_romania";
    case "rs": //serbia
      return "flag_serbia"; // custom
    case "ru": //russian federation
      return "flag_russian_federation";
    case "rw": //rwanda
      return "flag_rwanda";
    case "sa": //saudi arabia
      return "flag_saudi_arabia";
    case "sb": //solomon islands
      return "flag_soloman_islands";
    case "sc": //seychelles
      return "flag_seychelles";
    case "sd": //sudan
      return "flag_sudan";
    case "se": //sweden
      return "flag_sweden";
    case "sg": //singapore
      return "flag_singapore";
    case "sh": //saint helena, ascension and tristan da cunha
      return "flag_saint_helena"; // custom
    case "si": //slovenia
      return "flag_slovenia";
    case "sk": //slovakia
      return "flag_slovakia";
    case "sl": //sierra leone
      return "flag_sierra_leone";
    case "sm": //san marino
      return "flag_san_marino";
    case "sn": //senegal
      return "flag_senegal";
    case "so": //somalia
      return "flag_somalia";
    case "sr": //suriname
      return "flag_suriname";
    case "ss": //south sudan
      return "flag_south_sudan";
    case "st": //sao tome and principe
      return "flag_sao_tome_and_principe";
    case "sv": //el salvador
      return "flag_el_salvador";
    case "sx": //sint maarten
      return "flag_sint_maarten";
    case "sy": //syrian arab republic
      return "flag_syria";
    case "sz": //swaziland
      return "flag_swaziland";
    case "tc": //turks & caicos islands
      return "flag_turks_and_caicos_islands";
    case "td": //chad
      return "flag_chad";
    case "tg": //togo
      return "flag_togo";
    case "th": //thailand
      return "flag_thailand";
    case "tj": //tajikistan
      return "flag_tajikistan";
    case "tk": //tokelau
      return "flag_tokelau"; // custom
    case "tl": //timor-leste
      return "flag_timor_leste";
    case "tm": //turkmenistan
      return "flag_turkmenistan";
    case "tn": //tunisia
      return "flag_tunisia";
    case "to": //tonga
      return "flag_tonga";
    case "tr": //turkey
      return "flag_turkey";
    case "tt": //trinidad & tobago
      return "flag_trinidad_and_tobago";
    case "tv": //tuvalu
      return "flag_tuvalu";
    case "tw": //taiwan, province of china
      return "flag_taiwan";
    case "tz": //tanzania, united republic of
      return "flag_tanzania";
    case "ua": //ukraine
      return "flag_ukraine";
    case "ug": //uganda
      return "flag_uganda";
    case "us": //united states
      return "flag_united_states_of_america";
    case "uy": //uruguay
      return "flag_uruguay";
    case "uz": //uzbekistan
      return "flag_uzbekistan";
    case "va": //holy see (vatican city state)
      return "flag_vatican_city";
    case "vc": //st vincent & the grenadines
      return "flag_saint_vicent_and_the_grenadines";
    case "ve": //venezuela, bolivarian republic of
      return "flag_venezuela";
    case "vg": //british virgin islands
      return "flag_british_virgin_islands";
    case "vi": //us virgin islands
      return "flag_us_virgin_islands";
    case "vn": //vietnam
      return "flag_vietnam";
    case "vu": //vanuatu
      return "flag_vanuatu";
    case "wf": //wallis and futuna
      return "flag_wallis_and_futuna";
    case "ws": //samoa
      return "flag_samoa";
    case "xk": //kosovo
      return "flag_kosovo";
    case "ye": //yemen
      return "flag_yemen";
    case "yt": //mayotte
      return "flag_martinique"; // no exact flag found
    case "za": //south africa
      return "flag_south_africa";
    case "zm": //zambia
      return "flag_zambia";
    case "zw": //zimbabwe
      return "flag_zimbabwe";
    default:
      return "flag_transparent";
  }
};
