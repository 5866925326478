<template>
  <v-container
    v-if="selectedUserFollowers && selectedUserFollowers.length > 0"
    class="followers-container"
  >
    <v-row class="item-rows-title">
      <v-col class="item-cols-title" mb-12>
        <button class="back-button-header" @click="goBack()">
          <img
            :src="require(`@/assets/img/back-button_${brandName}.svg`)"
            :alt="$t('general.back_button')"
          />
        </button>
        <h1 class="suggested-live-stream-header">
          {{ $t("user_followers_page.followers") }}
        </h1>
      </v-col>
    </v-row>
    <v-row class="item-rows" v-on:scroll.passive="handleScroll">
      <v-col
        class="item-cols"
        v-for="(user, index) in selectedUserFollowers"
        :key="`user${user.user.user_id}${index}`"
      >
        <router-link :to="generateBase64Url('/profile/', user.user.user_id)">
          <v-card
            class="item-cards pa-2"
            outlined
            tile
            :style="{
              backgroundImage: `url(${
                user.user.profile_image && user.user.profile_image.thumbnail_url
              })`,
            }"
          >
            <img
              class="item-img"
              v-if="!user.user.profile_image"
              src="@/assets/img/empty_profile.png"
            />
            <div class="user-info-container">
              <span class="user-name">{{ user.user.name }}</span>
              <span
                :title="user.user.bio"
                v-if="user.user.bio"
                class="user-bio"
              >
                {{ user.user.bio }}
              </span>
            </div>
          </v-card>
        </router-link>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-else class="followers-wrapper-empty">
    <v-row class="followers-empty-row">
      <v-col class="followers-empty-col">
        <v-card class="item-cards pa-2" outlined tile>
          <img
            class="item-empty-icon"
            :src="require(`@/assets/img/empty-followings_${brandName}.svg`)"
            :alt="$t('general.empty_profile_svg')"
          />
          <span class="card-empty-span-title">{{
            $t("user_followings_page.no_stream_title")
          }}</span>
          <span class="card-empty-span">{{
            $t("user_followings_page.no_stream_content")
          }}</span>
          <router-link to="/">
            <v-btn class="button-discovery" rounded color="primary" dark>
              {{ $t("user_followings_page.no_stream_button") }}
            </v-btn>
          </router-link>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss" scoped>
@import "UserFollowers.scss";
</style>
<script>
import { mapState, mapActions } from "vuex";
import { decodeUrlParameter } from "@/helper/index";
import { generateBase64Url } from "@/helper/index.js";

export default {
  name: "UserFollowers",
  computed: {
    ...mapState({
      selectedUserFollowers: (state) => state.client.selectedUserFollowers,
      selectedUserFollowersMeta: (state) =>
        state.client.selectedUserFollowersMeta,
      brandName: (state) => state.brandName,
    }),
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions("client", [
      "logout",
      "getSelectedUserFollowers",
      "getNextSelectedUserFollowers",
    ]),
    decodeUrlParameter,
    generateBase64Url,
    getData() {
      this.getSelectedUserFollowers({
        user_id: decodeUrlParameter(this.$route.params.profileId),
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    handleScroll(event) {
      if (
        event.target.offsetHeight + event.target.scrollTop >=
          event.target.scrollHeight &&
        this.selectedUserFollowersMeta !== null
      ) {
        this.getNextSelectedUserFollowers({
          user_id: decodeUrlParameter(this.$route.params.profileId),
          next: this.selectedUserFollowersMeta,
        });
      }
    },
  },
};
</script>
