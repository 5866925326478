<template>
  <component v-bind:is="activeLoginComponent"></component>
</template>
<style lang="scss"></style>
<script>
import { mapState, mapActions } from "vuex";
import PhoneLogin from "./PhoneLogin/PhoneLogin.vue";
import SocialLogin from "./SocialLogin/SocialLogin.vue";
import EmailLogin from "./EmailLogin/EmailLogin.vue";
import EmailLoginRegister from "./EmailLogin/EmailLoginRegister.vue";
import EmailRegister from "./EmailLogin/EmailRegister.vue";
import ForgotPassword from "./EmailLogin/ForgotPassword.vue";
import { signupViewEvent } from "@/helper/marketingevents.js";
import { signupUuid } from "@/helper/index.js";

("use strict");

export default {
  components: {
    SocialLogin,
    PhoneLogin,
    EmailLogin,
    EmailLoginRegister,
    EmailRegister,
    ForgotPassword,
  },
  name: "Login",
  computed: {
    ...mapState({
      activeLoginComponent: (state) => state.client.activeLoginComponent,
      deviceId: (state) => state.client.deviceId,
    }),
  },
  methods: {
    ...mapActions("client", ["setActiveLoginComponent"]),
  },
  created() {
    this.setActiveLoginComponent("social-login");
    // Marketing Event
    signupViewEvent(this.deviceId, signupUuid());
  },
};
</script>
