import Vue from "vue";
import i18n from "./i18n";
import Buefy from "buefy";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import GoogleAuth from "@/config/google_oAuth.js";
import SimpleVueValidation from "simple-vue-validator";
import hybridapi from "./hybridapi";
import logger_module from "./logging";
import { getAxiosInstance } from "./api/instance";
import { getHybridAxiosInstance } from "./api/hybrid_instance";
import { StripePlugin } from "@vue-stripe/vue-stripe";
import VueDragResize from "vue-drag-resize";

// https://github.com/mariomka/vue-datetime
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

//Outside component add into vue
Vue.component("vue-drag-resize", VueDragResize);

const { localStore, STORE_KEYS } = require("./storage");

Vue.config.productionTip = false;

try {
  Vue.config.devtools = process.env.NODE_ENV !== "production";
  // eslint-disable-next-line
} catch (ignored) {}

const TheURLSearchParams = window.TheURLSearchParams;
const isHybridPage =
  (TheURLSearchParams.get("auth_token") &&
    TheURLSearchParams.get("device_id")) ||
  window.location.href.indexOf("/payermax") > -1 ||
  window.location.href.indexOf("/paypal") > -1;

store.commit("client/setHybridPage", isHybridPage);

window["i18n"] = i18n;

////////////////////
// Backend Config //
////////////////////

/**
 * @param {?string} [apiUrl]
 * @returns {?string}
 */
function fillHybridApiURL(apiUrl) {
  if (apiUrl) {
    try {
      /** @type {URL} */
      let url;
      if (!apiUrl.startsWith("http://") && !apiUrl.startsWith("https://")) {
        url = new URL("https://" + apiUrl);
      } else {
        url = new URL(apiUrl);
      }

      url.pathname = "/api/v1";
      return url.href;
    } catch (error) {
      console.error("fillHybridApiURL failed", error);
    }
  }
  return null;
}

/**
 * @param {?string} [apiUrl]
 * @returns {?string}
 */
function getWebSocketFromAPI(apiUrl) {
  if (apiUrl) {
    try {
      /** @type {URL} */
      let url;
      if (!apiUrl.startsWith("http://") && !apiUrl.startsWith("https://")) {
        url = new URL("https://" + apiUrl);
      } else {
        url = new URL(apiUrl);
      }

      url.protocol = "wss";
      url.pathname = "";
      if (
        process.env.NODE_ENV === "production" ||
        location.host === "superlive-staging.web.app"
      ) {
        url.hostname = url.hostname.replace("api.", "ws.");
      } else {
        url.hostname = url.hostname.replace("api-", "tornado-");
      }
      return url.href;
    } catch (error) {
      console.error("Failed to get ws url from api url", error);
    }
  }
  return null;
}

const api_url = process.env.VUE_APP_API_URL;
const hybrid_api_url =
  fillHybridApiURL(TheURLSearchParams.get("api_url")) ||
  process.env.VUE_APP_HYBRID_API_URL;
const IS_PROD = process.env.NODE_ENV === "production";
store.commit("client/setProd", IS_PROD);
store.commit("setBrandName", process.env.VUE_APP_BRAND_NAME);
store.commit("setBrandTitle", process.env.VUE_APP_BRAND_TITLE);
store.commit("setEmailDomain", process.env.VUE_APP_EMAIL_DOMAIN);

Vue.prototype.$hybrid_websocket_url =
  getWebSocketFromAPI(TheURLSearchParams.get("api_url")) ||
  process.env.VUE_APP_HYBRID_WEBSOCKET_URL;
Vue.prototype.$hybridapi = hybridapi;

i18n.silentFallbackWarn = process.env.VUE_APP_I18N_SILENT_FALLBACK_WARN;
i18n.silentTranslationWarn = process.env.VUE_APP_I18N_SILENT_TRANSLATION_WARN;

logger_module.setIsProd(IS_PROD);

// LOCALSTORE: Auth Token & Device Id
let auth_token = TheURLSearchParams.get("auth_token");
if (auth_token != null) {
  localStore.setItem(STORE_KEYS.AUTH_TOKEN, auth_token);
} else {
  auth_token = localStore.getItem(STORE_KEYS.AUTH_TOKEN);
}
store.commit("client/setAuthToken", auth_token);
let device_id = TheURLSearchParams.get("device_id");
if (device_id != null) {
  localStore.setItem(STORE_KEYS.DEVICE_ID, device_id);
} else {
  device_id = localStore.getItem(STORE_KEYS.DEVICE_ID);
}
store.commit("client/setDeviceId", device_id);

// Firebase
import firebase from "firebase/app";
import "firebase/analytics";
const endpoints = require("./api/endpoints");
const client_params_module = require("./api/client_params");

if (!isHybridPage) {
  const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID,
  };
  const firebaseApp = firebase.initializeApp(firebaseConfig);
  let analytics; // = firebase.analytics();
  firebase.analytics.isSupported().then((isSupported) => {
    if (isSupported) {
      analytics = firebase.analytics();
    }
  });
  window.gtag(
    "get",
    process.env.VUE_APP_MEASUREMENT_ID,
    "client_id",
    (client_id) => {
      window["firebase_analytics_id"] = client_id;
      client_params_module.getClientParams(true);
      if (device_id) {
        endpoints.firebase_analytics_record(Vue.prototype.$axios);
      }
    }
  );
}
// Firebase End

// Facebook Pixel & Adjust
if (!isHybridPage && device_id) {
  if (process.env.VUE_APP_FB_PIXEL_ID) {
    store.dispatch("client/facebookPixelInit");
  }
  store.dispatch("client/adjustInit");
}

// Forter Event Listener
// if (!isHybridPage) {
//   document.addEventListener("ftr:tokenReady", event => {
//     const forterToken = event.detail;
//     store.commit("client/setForterToken", forterToken);
//   });
// }

// Axios
if (isHybridPage) {
  // Assuming hybrid mode
  let base_request_data = {};

  let device_info_json = TheURLSearchParams.get("bdi");
  try {
    let device_info = JSON.parse(device_info_json);
    if (device_info.client_params != null) {
      base_request_data.client_params = device_info.client_params;
    } else {
      // TODO(baris): Double check if needed
      base_request_data.client_params = device_info;
    }
  } catch (error) {
    console.error("failed to parse device_info_json");
  }
  Vue.prototype.$axios = getHybridAxiosInstance({
    base_url: hybrid_api_url,
    auth_token: auth_token,
    device_id: device_id,
    base_request_data: base_request_data,
  });
  store.commit("client/setAxiosReady", true);
} else {
  Vue.prototype.$axios = getAxiosInstance({
    base_url: api_url,
    auth_token: auth_token,
    device_id: device_id,
  });
  store.commit("client/setAxiosReady", true);
}
window["axios"] = Vue.prototype.$axios;

//////////////
// Vue Libs //
//////////////

Vue.use(Buefy, {
  defaultIconPack: "fas",
});
Vue.use(Datetime);

if (!isHybridPage) {
  // Google Auth
  const googleAuthOptions = {
    clientId: process.env.VUE_APP_GA_CLIENT_ID,
    scope: process.env.VUE_APP_GA_SCOPE,
    prompt: process.env.VUE_APP_GA_PROMPT,
  };
  Vue.use(GoogleAuth, googleAuthOptions);
}

// Validation translation and registiration
SimpleVueValidation.extendTemplates({
  required: i18n.t("errors.required"),
  length: i18n.t("errors.must_be_n"),
  between: i18n.t("errors.must_be_between"),
  lengthBetween: i18n.t("errors.must_be_between"),
  email: i18n.t("errors.email_not_valid"),
  match: i18n.t("errors.not_match"),
  minLength: i18n.t("errors.password_min_8_char"),
});
Vue.use(SimpleVueValidation, { mode: "conservative" });

// Stripe
const stripeOptions = {
  pk: process.env.VUE_APP_STRIPE_PK,
  locale: i18n.locale,
};
Vue.use(StripePlugin, stripeOptions);

// TOAST
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import vuetify from "./plugins/vuetify";
const options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 1,
  newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter((t) => t.type === toast.type).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  },
};
Vue.use(Toast, options);

// Marketing

//Restore JOIN_STREAM_COUNT from store
let joinStreamCount = localStore.getItem(STORE_KEYS.JOIN_STREAM_COUNT);
if (joinStreamCount != null)
  store.commit("live/restoreJoinStreamCount", parseInt(joinStreamCount));
else {
  localStore.setItem(STORE_KEYS.JOIN_STREAM_COUNT, 0);
}
/////////////////
// The Vue App //
/////////////////

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

let pjson = require("./../package.json");
console.log(
  process.env.VUE_APP_BRAND_TITLE +
    " v" +
    pjson.version +
    " ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ "
);
