<template>
  <div class="columns is-multiline">
    <div style="text-align: center" class="text-center column is-12">
      <img
        :src="require(`@/assets/img/${brandName}.png`)"
        :srcset="require(`@/assets/img/${brandName}@2x.png`) + ' 2x'"
        v-bind:alt="brandTitle + ' Logo'"
      />
      <h1 class="login-header">
        {{ $t("login_page.header", { brandName: brandTitle }) }}
      </h1>
      <div class="sub-title">{{ $t("login_page.sub_header") }}</div>
    </div>
    <div class="column is-12">
      <div id="googleSignIn" class="google-sign-in btn-sign-in"></div>
      <div
        class="phone-sign-in btn-sign-in"
        @click="setActiveLoginComponent('phone-login')"
      >
        <img src="@/assets/img/signup-phone-icon.svg" alt="Phone Sign In" />
        {{ $t("login_page.signup_with_phone") }}
      </div>
    </div>
    <div class="column is-12">
      <div class="login-divider">
        <span>{{ $t("login_page.or") }}</span>
      </div>
    </div>
    <div class="column is-12">
      <div class="social-buttons">
        <div
          id="email-signin"
          class="email-sign-in icon-sign-in"
          @click="setActiveLoginComponent('email-login')"
        >
          <v-img
            max-width="62"
            max-height="62"
            src="@/assets/img/email-login.svg"
            alt="Email Login"
          />
        </div>
        <div id="twitter-signin" class="twitter-sign-in icon-sign-in">
          <v-img
            max-width="62"
            max-height="62"
            src="@/assets/img/twitter-login.svg"
            alt="Twitter Login"
            @click="twitterLogin"
          />
        </div>
        <div class="apple-sign-in icon-sign-in">
          <v-img
            id="appleid-signin"
            max-height="62"
            max-width="62"
            data-mode="logo-only"
            data-color="black"
            data-border="false"
            data-border-radius="62"
            @click="appleLogin"
          />
        </div>
        <!-- <div id="facebookSignIn" class="facebook-sign-in" @click="facebookLogin"><i class="fab fa-facebook-f"></i></div> -->
      </div>
    </div>
    <div
      class="validation-error-message has-text-centered mt-3 mb-3"
      v-if="$store.getters.errorMessage"
    >
      {{ $store.getters.errorMessage }}
    </div>
    <div class="column is-12">
      <div
        class="aggrement-text"
        v-html="$t('login_page.aggrement_label')"
      ></div>
    </div>
  </div>
</template>
<style lang="scss">
@import "./SocialLogin.scss";
</style>
<script>
"use strict";

import { mapState, mapActions } from "vuex";
import { signupAttemptEvent } from "@/helper/marketingevents.js";
import { signupUuid } from "@/helper/index.js";

const endpoints = require("../../../api/endpoints");
const appleAuthSettings = {
  clientId: process.env.VUE_APP_APPLE_CLIENT_ID,
  scope: "email name",
  redirectURI: process.env.VUE_APP_URL + "/login",
  usePopup: true,
};

export default {
  name: "SocialLogin",
  data() {
    return {
      auth2: undefined,
      redirectPage: null,
      redirectUrl: "/",
    };
  },
  mounted() {
    this.$store.commit("client/setEmailLoginOrRegister", null);
    this.redirectPage = this.$route.query.to;
    this.redirectUrl =
      this.redirectPage == "sensitive_content" ? "settings" : "/";
    this.redirectToken = this.$route.query.token;

    // SENSITIVE CONTENT LOGIN REDIRECT
    if (this.redirectPage === "sensitive_content" && this.redirectToken) {
      this.sensitiveContentLogin();
    }

    //google sign-in
    this.$gAuth.load(this.onGoogleSignInSuccess, this.onGoogleSignInFail);

    // initFbsdk();

    if (appleAuthSettings.clientId) {
      window.AppleID.auth.init(appleAuthSettings);
    }

    // APPLE LOGIN RESPONSE
    document.addEventListener(
      "AppleIDSignInOnSuccess",
      (appleSignInResponse) => {
        let id_token = appleSignInResponse.detail.authorization.id_token;
        endpoints
          .login_apple(this.$axios, { token: id_token })
          .then((appleLoginResponse) => {
            if (appleLoginResponse.data.error) {
              this.$store.commit(
                "client/setErrorMessage",
                this.$i18n.t("errors.sign_up_on_app")
              );
            } else {
              this.$store.dispatch("client/postLogin", {
                authToken: appleLoginResponse.data.token,
                redirectUrl: this.redirectUrl,
                loginFrom: "apple",
              });
            }
          });
      }
    );
  },
  computed: {
    ...mapState({
      brandName: (state) => state.brandName,
      brandTitle: (state) => state.brandTitle,
      deviceId: (state) => state.client.deviceId,
    }),
  },
  methods: {
    ...mapActions("client", ["setActiveLoginComponent"]),
    // Bütün login methodlar için genel bir error handler yapılmalı
    facebookLogin() {
      // Marketing event
      signupAttemptEvent(this.deviceId, signupUuid(), "facebook");
      window.FB.login((response) => {
        endpoints
          .login_facebook(this.$axios, {
            token: response.authResponse.accessToken,
          })
          .then((facebookLoginResponse) => {
            if (facebookLoginResponse.data.error) {
              // console.log('error', facebookLoginResponse.data.error);
            } else {
              this.$store.dispatch("client/postLogin", {
                authToken: facebookLoginResponse.data.token,
                redirectUrl: this.redirectUrl,
                loginFrom: "facebook",
              });
            }
          });
      }, this.params);
    },
    twitterLogin() {
      // Marketing event
      signupAttemptEvent(this.deviceId, signupUuid(), "twitter");
      let that = this;
      let callbackURI = encodeURI(window.location.origin + "/twitter_callback");
      endpoints
        .twitter_request_token(that.$axios, { callback_uri: callbackURI })
        .then((requestTokenResponse) => {
          requestTokenResponse = requestTokenResponse.data;
          if (requestTokenResponse.oauth_token) {
            window.location =
              "https://api.twitter.com/oauth/authorize?oauth_token=" +
              requestTokenResponse.oauth_token;
          }
        });
    },
    appleLogin() {
      // Marketing event
      signupAttemptEvent(this.deviceId, signupUuid(), "apple");
    },
    sensitiveContentLogin() {
      this.$store.dispatch("client/postLogin", {
        authToken: this.redirectToken,
        redirectUrl: this.redirectUrl,
        loginFrom: "sensitive_content",
      });
    },
    onGoogleSignInSuccess(response) {
      signupAttemptEvent(this.deviceId, signupUuid(), "google");
      const that = this;
      let google_id_token = response.credential;
      endpoints
        .login_google(this.$axios, { token: google_id_token })
        .then((googleLoginResponse) => {
          if (
            googleLoginResponse.data.error &&
            googleLoginResponse.data.error.code == 13
          ) {
            this.$store.commit(
              "client/setErrorMessage",
              this.$i18n.t("errors.sign_up_on_app")
            );
          } else {
            that.$store.dispatch("client/postLogin", {
              authToken: googleLoginResponse.data.token,
              redirectUrl: this.redirectUrl,
              loginFrom: "google",
            });
          }
        })
        .catch(() => {
          this.$store.commit(
            "client/setErrorMessage",
            this.$i18n.t("errors.general_error")
          );
        });
    },
    onGoogleSignInFail() {
      this.$store.commit(
        "client/setErrorMessage",
        this.$i18n.t("errors.general_error")
      );
    },
  },
};
</script>
