<template>
  <v-card class="send-gift-container">
    <div class="header">
      {{ $t("labels.send_gift_header") }}
      <div class="is-pulled-right mr-2" @click="closeGift">
        <i class="fa fa-times"></i>
      </div>
    </div>
    <carousel :perPage="1">
      <slide v-for="(gifts_part, index) in gifts" :key="`gift-part-${index}`">
        <div class="gift-item-container">
          <div
            class="gift-item"
            v-for="gift in gifts_part"
            :key="gift.gift_id"
            @mousedown="giftMouseDown($event)"
            @mouseup="giftMouseUp(gift, $event)"
          >
            <div class="image-container">
              <img :src="gift.image" />
            </div>
            <button class="gift-coin-count">
              <img src="@/assets/img/coin.svg" alt="coin" />
              {{ gift.cost }}
            </button>
          </div>
        </div>
      </slide>
    </carousel>
    <div class="send-gift-bottom">
      <div class="user-coin-count" v-if="userCoinInfo">
        <img src="@/assets/img/coin.svg" alt="coin" />
        {{ Number(userCoinInfo.coins).toLocaleString() }}
      </div>
      <div class="user-coin-count" v-else>
        <img src="@/assets/img/coin.svg" alt="coin" />
        0
      </div>
      <div class="buy-coin" @click="checkShowCoinModal()">
        {{ $t("menu.purchase") }}
      </div>
    </div>
  </v-card>
</template>
<style lang="scss" scoped>
@import "./SendGift.scss";
</style>
<script>
import { Carousel, Slide } from "vue-carousel";
import { mapState, mapActions } from "vuex";

const endpoints = require("@/api/endpoints");

export default {
  name: "SendGift",
  components: {
    Carousel,
    Slide,
  },
  computed: {
    ...mapState({
      userCoinInfo: (state) => state.client.userCoinInfo,
    }),
  },
  props: ["dividerCount"],
  data() {
    return {
      gifts: null,
      touchendX: null,
      touchstartX: null,
      isMessages: null,
    };
  },
  mounted() {
    this.isMessages = this.$route.path.includes("/messages");
    // TODO: Uğurtan burada neden getSettings i bi daha alıyoruz?
    // normalde app açılışında bunu çekiyoruz
    this.getSettings().then((settingsResult) => {
      let defaultCount = 8;
      if (
        this.isMessages === true &&
        (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg)
      ) {
        defaultCount = 12;
      }
      if (this.dividerCount > 0) {
        defaultCount = this.dividerCount;
      }
      this.gifts = this.sliceIntoChunks(settingsResult.gift, defaultCount);
    });
  },
  methods: {
    ...mapActions("client", [
      "getSettings",
      "checkLoggedInByModal",
      "setShowBuyCoinsModal",
    ]),
    ...mapActions("live", ["sendGift", "closeGift"]),
    ...mapActions("client", ["sendMessageGift"]),
    checkShowCoinModal() {
      if (this.$route.path.includes("/login")) {
        this.setShowBuyCoinsModal(false);
      } else {
        this.setShowBuyCoinsModal({
          showBuyCoinsModal: true,
          buyCoinBackground: "black-opac-bg",
          pageName: "livestream_overlay",
        });
      }
    },
    sliceIntoChunks(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += Number(chunkSize)) {
        const chunk = arr.slice(i, i + Number(chunkSize));
        res.push(chunk);
      }
      return res;
    },
    // This code block has been added to prevent clicking on the swipe effect.
    // When the click event was handled and swiped left and right, the click event was triggered.
    giftMouseDown(event) {
      this.touchstartX = event.screenX;
    },
    giftMouseUp(gift, event) {
      this.touchendX = event.screenX;
      if (this.touchstartX === this.touchendX) {
        if (this.isMessages === false) {
          this.checkLoggedInByModal()
            .then((res) => {
              this.sendGift(gift);
            })
            .catch(() => {
              console.error("You need to login to send gift.");
            });
        } else {
          this.sendMessageGift(gift);
        }
      }
    },
  },
};
</script>
